import { Tooltip, Zoom } from '@mui/material'
import React from 'react'

const FilterSelect = ({
	options,
	setFilters,
	initialPageData,
	className,
	filterKey = 'status',
	label = 'Status'
}) => {
	return (
		<>
			<Tooltip
				id='toot-tip'
				TransitionComponent={Zoom}
				title={label}
				arrow
				placement='top'
			>
				<div className={className}>
					{/* <label htmlFor={filterKey}>{'Status'}</label> */}

					<select
						className='date-input'
						id={filterKey}
						onChange={({ target: { value } }) =>
							setFilters(prev => ({
								...prev,
								[filterKey]: value,
								...initialPageData
							}))
						}
					>
						<option hidden>{label}</option>
						{options.map(item => {
							return <option value={item.value}>{item.name}</option>
						})}
					</select>
				</div>
			</Tooltip>
		</>
	)
}

export default FilterSelect
