import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Modal,
	ModalBody
} from 'reactstrap'
import Helper from '../../Helper/Helper'
import parkcoin from '../../assets/images/parkcoin.svg'
import { ScreenLoader, SpinnerLoader } from '../../component/Loaders'
import Footer from '../../component/footer'
import NewHeader from '../../component/header/NewHeader'
import './pricing1.scss'
import { useDispatch, useSelector } from 'react-redux'
import { isObjectHaveKey } from '../../component/utils'
import { setCallProfileApi } from '../../component/header/headerslice'
import { Link, useNavigate } from 'react-router-dom'
import cancel from '../../assets/images/cancel.webp'
import classnames from 'classnames'
import nodata from '../../assets/images/nodata.png'
import { useForm } from 'react-hook-form'
const Pricing = ({ footer, from }) => {
	const [membershipList, setMembershipList] = useState([])
	const [loading, setLoading] = useState(true)
	const [btnLoading, setBtnLoading] = useState({ status: false, id: -1 })
	const Profile = useSelector(state => state.header.profileData)
	const membership_status = localStorage.getItem('membership_status')
	const [wallet, setWallet] = useState({})

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		getPricingList()
	}, [])

	const getPricingList = () => {
		Helper.PostData(
			'local',
			'trade_bot/front/list_membership',
			{
				userId: localStorage.getItem('tip') || ''
			},
			false
		).then(res => {
			if (res.status) {
				let { data, wallet_obj } = res
				let sortedData = [...data].sort((a, b) => b.id - a.id)
				setMembershipList(sortedData)
				setWallet(wallet_obj || {})
				setLoading(false)
			}
		})
	}

	const handlePurchase = ({ id, ...data }) => {
		setBtnLoading({ status: true, id })
		Helper.PostData(
			'aabu',
			'trade_bot/buy_membership',
			{ id, ...data },
			true
		).then(res => {
			if (res.status) {
				localStorage.setItem('membership_status', 1)
				dispatch(setCallProfileApi())
				setTimeout(() => {
					setBtnLoading({ status: false, id: -1 })
					navigate('/bot/dashboard')
				}, 2000)
			} else {
				setBtnLoading({ status: false, id: -1 })
			}
		})
	}

	return (
		<>
			<NewHeader />
			{/* <div className='leftrightCir'> */}
			<ScreenLoader loading={loading}>
				<section
					className={classnames('demo pricing-section mt-5', {
						'pt-5': from == 'main'
					})}
				>
					<div class='container mt-5'>
						{/* <div class='pricing-header'>
						<p>Your current plan:</p>
						<h3>
							Starter Trial <span>•</span> 500MAUs
						</h3>
					</div> */}
						<div class='row justify-content-center'>
							{membershipList.length > 0
								? membershipList?.map((item, index) => {
										let choosedLoading =
											btnLoading.id == item.id && btnLoading.status
										return (
											<div
												className={classnames({
													'col-md-3 col-sm-4 col-lg-3': from == 'main',
													'col-md-4 col-sm-6 col-lg-4': from != 'main',
													jik_kil: true
												})}
												key={index}
											>
												<div className={item.buy_status}>
													<Card>
														<CardHeader>
															<div className='ribbon'>
																<span>{item.title}</span>
															</div>
															<span class='card-title'>{item.title}</span>
															<h2 class='card-price-member'>
																<img
																	src={parkcoin}
																	width={'30px'}
																	className='mr-1'
																/>
																{item.amount}
																<span className='days'>
																	/{item.duration} Days
																</span>
															</h2>
														</CardHeader>
														<CardBody>
															<ul className='pricing-content'>
																<li>
																	<span className='ftr_lble'>Bot Count</span>

																	<span>
																		<b>{item.bot_count}</b>
																	</span>
																</li>
																<li>
																	<span className='ftr_lble'>Profit %</span>
																	<span>
																		<b>{item.profit_percentage} %</b>
																	</span>
																</li>
																<li>
																	<span className='ftr_lble'>
																		Initial Deposit
																	</span>
																	<span>
																		<b>{item.initial_deposit} KPK</b>
																	</span>
																</li>
																<li className='ftr_lble'>
																	<span>Manual</span>

																	<span>
																		<b>
																			{(() => {
																				if (item.manual == 1) {
																					return (
																						<FontAwesomeIcon
																							icon={faCheck}
																							color='green'
																						/>
																					)
																				} else {
																					return (
																						<FontAwesomeIcon
																							icon={faTimes}
																							color='red'
																						/>
																					)
																				}
																			})()}
																		</b>
																	</span>
																</li>
																{/* <li>
														<b>15</b> Domains
													</li> */}
															</ul>
														</CardBody>
														<CardFooter>
															{(() => {
																if (!localStorage.getItem('tip')) {
																	return (
																		<button
																			onClick={() => navigate('/oauth/login')}
																		>
																			Login to Choose
																		</button>
																	)
																} else if (isObjectHaveKey(Profile)) {
																	if (
																		membership_status == 1 &&
																		item.buy_status != 'Active'
																	) {
																		return (
																			<button
																				disabled
																				style={{ cursor: 'not-allowed' }}
																			>
																				Choose Plan
																			</button>
																		)
																	} else if (choosedLoading) {
																		return (
																			<button disabled>
																				<SpinnerLoader />
																			</button>
																		)
																	} else if (item.buy_status == 'Active') {
																		return (
																			<button
																				disabled
																				style={{ cursor: 'not-allowed' }}
																			>
																				Purchased
																			</button>
																		)
																		// return (
																		// 	<TermsandCondition
																		// 		id={item.id}
																		// 		data={item}
																		// 		handlePurchase={handlePurchase}
																		// 		wallet={wallet}
																		// 	/>
																		// )
																	} else if (item.buy_status == 'In-active') {
																		return (
																			<TermsandCondition
																				id={item.id}
																				data={item}
																				handlePurchase={handlePurchase}
																				wallet={wallet}
																			/>
																		)
																	}
																}
															})()}
														</CardFooter>
													</Card>
													<p className='text-gray mt-2 p-2'>
														{item.remaining_days}
													</p>
												</div>
											</div>
										)
								  })
								: !loading && (
										<>
											<div>
												<img src={nodata} alt='nodata' />
												<p className='fw-bold'>No Data Found</p>
											</div>
										</>
								  )}
						</div>
					</div>
				</section>
			</ScreenLoader>
			{/* </div> */}
			{footer && <Footer />}
		</>
	)
}

export default Pricing

const TermsandCondition = ({
	id,
	handlePurchase,
	btnLoading,
	wallet,
	data
}) => {
	const [modal, setModal] = useState(false)
	const [balance, setBalance] = useState('')

	const toggle = () => {
		setModal(prev => !prev)
	}
	let Data = {}
	const {
		register,
		formState: { errors },
		handleSubmit
	} = useForm({ mode: 'all', reValidateMode: 'onChange' })

	return (
		<>
			<button onClick={() => toggle()}>Choose Plan</button>
			<Modal
				isOpen={modal}
				// isOpen={true}
				toggle={toggle}
				className={'modal-md modal-dialog-centered mymodal'}
				size={'md'}
				backdrop='static'
				keyboard={false}
			>
				<ModalBody className='p-4'>
					{/* <div className='text-center mb-2'>
						<img src={cancel} className='img-fluid' />
					</div> */}
					<div>
						<h4>
							<b>Activate {data.title} to get:</b>
						</h4>
						<ul className='plan-access mt-4'>
							<li className='mb-1 w-50 d-flex justify-content-between'>
								<span>
									<span className='text-success mr-1 '>✔</span>
									Bot Count
								</span>
								<span>{data.bot_count}</span>
							</li>
							<li className='mb-1 w-50 d-flex justify-content-between'>
								<span>
									<span className='text-success mr-1 '>✔</span>
									Profit Percentage
								</span>
								<span>{data.profit_percentage} %</span>
							</li>
							<li className='mb-1 w-50 d-flex justify-content-between'>
								<span>
									<span className='text-success mr-1 '>✔</span>
									Initial Deposit
								</span>
								<span>{data.initial_deposit} KPK</span>
							</li>
						</ul>
						<div className='d-flex justify-content-between'>
							<span>Price</span>
							<span className='fw-bold'>{data.amount} KPK</span>
						</div>
						<div className='d-flex justify-content-between'>
							<span>Duration</span>
							<span className='fw-bold'>{data.duration} Days</span>
						</div>
					</div>
					<form
						onSubmit={handleSubmit(data => {
							handlePurchase({ id, ...data })
						})}
						className='mt-3'
					>
						{data.amount ? (
							<>
								{balance && (
									<div className='d-flex justify-content-end'>
										<span>Balance&nbsp;</span>
										<span className='fw-bold'>{wallet[balance]} KPK</span>
									</div>
								)}
								<div className='form-group formInputs loginInput'>
									<select
										autoFocus
										className='form-control form-control-lg'
										{...register('wallet_type', {
											required: '* Please select wallet'
										})}
										onChange={({ target: { selectedOptions } }) => {
											setBalance(selectedOptions[0].getAttribute('data-key'))
										}}
									>
										<option selected value={''} hidden>
											Select Wallet
										</option>
										<option data-key='mainWallet' value='Main'>
											Main Wallet
										</option>
										<option data-key='rewardWallet' value='Reward'>
											Reward Wallet
										</option>
									</select>
									{errors.wallet_type && (
										<p className='text-danger'>{errors.wallet_type.message}</p>
									)}
								</div>
							</>
						) : (
							<></>
						)}
						<div className='row'>
							<div className='col-12'>
								<div className='custom-control custom-checkbox custom_checkbox logincheckbox'>
									<input
										type='checkbox'
										id='customCheck'
										name='example1'
										className='custom-control-input'
										{...register('example1', {
											required: '* Please check Recurring Payment Policy'
										})}
									/>
									<label
										for='customCheck'
										className='custom-control-label light-text'
									>
										&nbsp; I agree to payment amount and accept &nbsp;
										<a
											href='/payment-policy'
											target='_blank'
											className='text-primary'
										>
											Recurring Payment Policy
										</a>
										.
									</label>
								</div>
								{errors.example1 && (
									<p className='text-danger'>{errors.example1.message}</p>
								)}
							</div>
						</div>

						<div className='d-flex justify-content-around mt-3'>
							<div>
								<button type='button' class='btn-exg  px-4' onClick={toggle}>
									Cancel
								</button>
							</div>
							<div>
								{!btnLoading ? (
									<button type='submit' class='btn-exg-del px-4'>
										Submit
									</button>
								) : (
									<button type='button' class='btn-exg-del px-4' disabled>
										<SpinnerLoader />
									</button>
								)}
							</div>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</>
	)
}
