import React from 'react'

const LevelSvg = () => {
	return (
		<svg
			width='29'
			height='23'
			viewBox='0 0 29 23'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M23.8855 1.22091C23.4953 1.23512 23.1335 1.21339 22.7674 1.23595C22.673 1.2418 22.556 1.26019 22.5402 1.13484C22.5059 0.877471 22.4875 0.616757 22.4758 0.356878C22.4691 0.220672 22.5744 0.189755 22.6881 0.18307C23.634 0.120398 24.5782 0.0451902 25.5242 0.000902207C25.942 -0.0191527 26.1751 0.29755 26.0581 0.710348C25.8074 1.60363 25.5325 2.4919 25.2726 3.38434C25.2225 3.55731 25.144 3.61163 24.9551 3.56818C24.1613 3.3835 24.1596 3.39019 24.3819 2.6139C24.4278 2.45513 24.4704 2.29469 24.5231 2.105C24.3718 2.12506 24.2916 2.22366 24.2022 2.29469C20.0688 5.58266 15.5749 8.39 10.8072 10.6626C8.13525 11.9379 5.38365 13.039 2.56961 13.9591C2.1518 14.0945 2.1518 14.097 2.02812 13.6934C1.79331 12.9246 1.7624 13.0792 2.4543 12.8503C9.74739 10.4435 16.5856 6.83148 22.6847 2.16433C23.0875 1.85515 23.4836 1.53511 23.8855 1.22091Z'
				fill='#FFB721'
			></path>
			<path
				d='M22.5176 13.4226C22.5176 11.1876 22.5176 8.95255 22.5176 6.71754C22.5176 6.16101 22.6672 6.0106 23.2204 6.00977C24.1173 6.00977 25.0139 6.00977 25.9103 6.00977C26.3991 6.00977 26.5637 6.17689 26.5637 6.67242C26.5637 11.1848 26.5659 15.6971 26.5704 20.2095C26.5704 20.5087 26.4868 20.6114 26.1827 20.6006C25.4164 20.573 25.5217 20.6841 25.5217 19.9271C25.5172 15.7818 25.5172 11.6357 25.5217 7.48882C25.5217 7.05847 25.5217 7.05847 25.0972 7.05764C24.6902 7.05764 24.2833 7.07101 23.878 7.05346C23.6223 7.04176 23.5555 7.14204 23.5605 7.38186C23.573 8.00858 23.5605 8.63529 23.5605 9.26201V20.1627C23.5605 20.5914 23.5605 20.5956 23.1293 20.5922C22.4332 20.5922 22.5176 20.6674 22.5168 19.9705C22.5146 17.7879 22.5148 15.6052 22.5176 13.4226Z'
				fill='white'
			></path>
			<path
				d='M14.001 22.5978H0.463862C0.00176297 22.5978 -0.00074634 22.5978 0.00176053 22.1374C0.00176053 21.4823 -0.0625824 21.5525 0.586697 21.5525C9.55014 21.5525 18.5136 21.5525 27.477 21.5525C27.5815 21.5525 27.6859 21.5592 27.7896 21.5525C27.9893 21.5391 28.0687 21.6235 28.0662 21.8257C28.0536 22.7583 28.1648 22.5962 27.3517 22.597C22.9017 22.602 18.4515 22.6023 14.001 22.5978Z'
				fill='white'
			></path>
			<path
				d='M15.5118 15.4339C15.5118 13.8568 15.5118 12.2797 15.5118 10.7026C15.5118 10.1837 15.6689 10.0224 16.1803 10.0208C17.0978 10.0208 18.015 10.0208 18.932 10.0208C19.3832 10.0208 19.5595 10.1962 19.5604 10.6458C19.5604 13.8412 19.5604 17.0366 19.5671 20.2321C19.5671 20.5312 19.4601 20.6048 19.1852 20.5972C18.4122 20.5747 18.5167 20.6808 18.5167 19.9287C18.5128 17.1294 18.5128 14.3306 18.5167 11.5324C18.5167 11.0644 18.5167 11.0644 18.0354 11.0636C17.6602 11.0636 17.2833 11.0795 16.9098 11.0586C16.6349 11.0436 16.5513 11.1363 16.553 11.4129C16.5655 12.917 16.5588 14.4211 16.5588 15.9253C16.5588 17.3558 16.553 18.7864 16.5638 20.217C16.5638 20.4961 16.492 20.6064 16.1962 20.5964C15.404 20.5697 15.5193 20.68 15.516 19.9112C15.5076 18.4196 15.5118 16.9272 15.5118 15.4339Z'
				fill='white'
			></path>
			<path
				d='M8.51006 17.173C8.51006 16.1808 8.51006 15.1889 8.51006 14.1973C8.51006 13.6959 8.67718 13.5288 9.16017 13.5288C10.0771 13.5288 10.9941 13.5288 11.911 13.5288C12.3832 13.5288 12.5553 13.6959 12.5553 14.1689C12.5553 16.1844 12.5553 18.1991 12.5612 20.2146C12.5612 20.4895 12.496 20.6065 12.1968 20.5965C11.4013 20.5698 11.5166 20.6801 11.5141 19.9138C11.5074 18.2751 11.5141 16.6357 11.5141 14.9962C11.5141 14.5784 11.5141 14.5784 11.0796 14.5742C10.6843 14.5742 10.2874 14.5875 9.89218 14.57C9.63397 14.5583 9.55041 14.646 9.55208 14.9042C9.56127 16.6482 9.55709 18.3921 9.55626 20.1352C9.55626 20.5865 9.55626 20.5898 9.11672 20.5865C8.43485 20.5865 8.51591 20.6583 8.5134 19.9865C8.50337 19.0523 8.51006 18.113 8.51006 17.173Z'
				fill='white'
			></path>
			<path
				d='M5.54781 18.6911C5.54781 19.2234 5.53945 19.7566 5.54781 20.2889C5.55282 20.5203 5.46424 20.6005 5.23862 20.5964C4.35955 20.578 4.51247 20.695 4.50161 19.8769C4.49214 19.2084 4.49214 18.5399 4.50161 17.8714C4.50161 17.6533 4.42807 17.5756 4.21081 17.5806C3.75289 17.5923 3.29163 17.5948 2.83538 17.5806C2.59472 17.5722 2.53372 17.6717 2.53706 17.8948C2.54625 18.6778 2.52954 19.4607 2.54541 20.2437C2.55126 20.5237 2.45349 20.6047 2.18442 20.5972C1.38473 20.5738 1.50339 20.6858 1.49754 19.9195C1.49002 19.0112 1.49754 18.1029 1.49754 17.1954C1.49754 16.7032 1.66467 16.5394 2.15602 16.5386C3.07297 16.5386 3.98993 16.5386 4.90688 16.5386C5.374 16.5386 5.54029 16.7115 5.54279 17.187C5.54948 17.6892 5.54781 18.1898 5.54781 18.6911Z'
				fill='white'
			></path>
		</svg>
	)
}

export default LevelSvg
