import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Helper from "../../Helper/Helper";
import Footer from "../../component/footer";
import Header from "../../component/header";
import "./event.scss";
import NewHeader from "../../component/header/NewHeader";
import NewHeaderOne from "../../component/header/NewHeaderOne";
import nodata from "../../assets/images/nodata.png";
import { ScreenLoader } from "../../component/Loaders";

const PCEvent = (props) => {
  var settings1 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };
  const [response, setResponse] = useState([]);
  const [screenLoading, setScreenLoading] = useState(false);

  useEffect(() => {
    setScreenLoading(true);
    Helper.PostData("localEve", "user/viewallEvent", {}, false).then((res) => {
      if (res.status) {
        setScreenLoading(false);
        setResponse(res.data);
      }
    }).finally(()=>{
      setScreenLoading(false)
    });
  }, []);
  return (
    <Fragment>
      <NewHeader />
      <ScreenLoader loading={screenLoading}>
        <div className="leftrightCir">
          <div class="pkchinsec">
            <section class="kpeventbanner px-3">
              <div class="container">
                <div class="row kpEvRw align-items-center">
                  <div>
                    <h2>
                      Parkchain Ecosystem,
                      <br />
                      in real life
                    </h2>
                    <p>
                      Lorem ipsum dolor sit amet consectetur.Lorem ipsum dolor
                      <br /> sit amet consectetur.
                    </p>
                    <a href="#" class="submitbtn ">
                      Submit Event
                    </a>
                  </div>
                </div>
              </div>
            </section>
            <div class="container">
              <div class="row align-items-start justify-content-center mt-3">
                {response.length > 0 ? (
                  response.map((item, index) => (
                    <div class="col-lg-6 mb-4">
                      <div class="kpblkChain kpeventchain">
                        <img
                          src={item.banner_image}
                          className="img-fluid"
                          alt=""
                          style={{ maxHeight: "270px" }}
                        />
                        <div class="kpblogcnt kpeventcnt">
                          <h6 class="text-light">{item.event_name}</h6>
                          <h3 class="text-light">Sept 28</h3>
                          <div class="text-center">
                            <Link
                              to={`/event/${item.id}`}
                              class="submitbtn submitbtn1"
                            >
                              Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-md-12 text-center mt-5 mb-5">
                    <p className="text-center" style={{ color: "#bab5b5" }}>
                      <img
                        alt="cryptocurrency exchange"
                        src={nodata}
                        className="img-fluid"
                      />{" "}
                    </p>
                    <span
                      className="text-center"
                      style={{
                        color: "#848e9c",
                        fontSize: "13px",
                      }}
                    >
                      No record found
                    </span>{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </ScreenLoader>
      <Footer />
    </Fragment>
  );
};
export default PCEvent;
