import React, { useState, useEffect } from 'react'
import { dataTableConfigs } from '../../configs/dataTableConfigs'
import '../exchange/exchange.scss'
import DatePickerReuse from '../../component/Reusable/DatePickerReuse'
import SearchReuse from '../../component/Reusable/searchReuse'
import DataTable, { Alignment } from 'react-data-table-component'
import Helper from '../../Helper/Helper'
import { toFixedLength } from '../../component/utils'
import { TableLoader } from '../../component/Loaders'
import NoDataComponent from '../../component/Reusable/no-data-component'
import moment from 'moment'
import CopyData from '../../component/Reusable/CopyData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

const TicketHistory = ({ apiCall }) => {
	const initialPageData = { pageNo: 0, pageSize: 10 }
	const [exchangeHistory, setExchangeHistory] = useState({
		data: [],
		count: 0
	})
	const [Loading, setLoading] = useState(false)
	const [filters, setFilter] = useState({
		search_by: '',
		status: 'all',
		start_date: '',
		end_date: '',
		order_by: 'desc',
		order_position: 0,
		...initialPageData,
		user_id: localStorage.getItem('tip')
	})

	useEffect(() => {
		getStateHistory()
	}, [filters, apiCall])

	const getStateHistory = () => {
		setLoading(true)
		Helper.PostData('arun', 'user/viewallTicekets', filters, false)
			.then(res => {
				if (res.status) {
					setExchangeHistory({ data: res.data, count: res.count })
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const columns = [
		{
			name: 'S.No',
			sortable: true,
			selector: (row, index) => row.index,
			cell: (row, index) => <td>{index + 1}</td>
		},

		{
			name: 'Ticket Id',
			selector: (row, index) => (
				<CopyData data={row.ticketId} formatValue={false} index={index} />
			),
			sortable: true
		},
		{
			name: 'Subject',
			selector: (row, index) => (
				<span title={row.subject}>
					<CopyData data={row.subject} formatValue={false} index={index} />
				</span>
			),
			sortable: true
		},

		{
			name: 'Date & Time',
			sortable: true,
			selector: (row, index) => row.created_at,

			cell: (row, index) => (
				<div className='text-center'>
					{moment(row.created_at).format('MMM D, YYYY ,h:mm:ss A')}
				</div>
			)
		},

		{
			name: 'Status',
			sortable: true,
			selector: row => row.status,
			cell: row => {
				if (row.status == 2) {
					return <div className=' badge text-warn'>Re-Open</div>
				} else if (row.status == 0) {
					return <div className='badge bg-success'>Open</div>
				} else if (row.status == 1) {
					return <div className='badge bg-danger'>Closed</div>
				}
			}
		},
		{
			name: 'Action',
			sortable: true,
			cell: (row, index) => (
				<td className='text-center cursor-pointer'>
					<FontAwesomeIcon
						icon={faEye}
						title='View'
						onClick={() => window.open(`/user/ticket?Id=${row.id}`, '_blank')}
					/>
				</td>
			)
		}
	]
	return (
		<>
			<div class='row'>
				<div class='col-lg-12'>
					<div class='bonus-Body'>
						<div className='row'>
							<div className='col-md-4 '>
								<div class='sDashtit'>
									<h5>Ticket History</h5>
								</div>
							</div>
							<div className='col-md-8'>
								<div class='tbl-showentriessearch flex-wrap d_Fds     justify-content-start'>
									<div class='showentries-RightCnt'>
										<div class='inr-searchcnt d_Fds2'>
											<div className='mx-md-2 my-2'>
												<select
													name=''
													id=''
													className='form-control'
													onChange={({ target: { value } }) => {
														setFilter(prev => ({
															...prev,
															status: value
														}))
													}}
												>
													<option value='all'>All</option>
													<option value='0'>Open</option>
													<option value='1'>Closed</option>
													<option value='2'>Re-Open</option>
												</select>
											</div>
											<DatePickerReuse
												setFilters={setFilter}
												className='col-lg-3'
											/>
											<SearchReuse
												className='col-lg-4'
												initialPageData={initialPageData}
												placeholder={'Ticket Id/Subject'}
												setFilters={setFilter}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class='cmntbl'>
							<div className='table-responsive'>
								<DataTable
									columns={columns}
									className='text-center'
									data={exchangeHistory.data}
									paginationTotalRows={exchangeHistory.count}
									customStyles={dataTableConfigs}
									pagination
									paginationServer
									onChangePage={pageNo => {
										setFilter(prev => ({
											...prev,
											pageNo: pageNo - 1
										}))
									}}
									subHeaderAlign={Alignment.CENTER}
									progressPending={Loading}
									progressComponent={<TableLoader />}
									noDataComponent={<NoDataComponent />}
									onChangeRowsPerPage={pageSize => {
										setFilter(prev => ({ ...prev, pageSize }))
									}}
									persistTableHead={true}
									paginationRowsPerPageOptions={[5, 10, 20, 30]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default TicketHistory
