import React from 'react'

const TableDataStatus = ({ status }) => {
	return (
		<div>
			{(() => {
				if (status == 0) {
					return <p className='badge bg-pending'>Pending</p>
				} else if (status == 1) {
					return <p className='badge bg-success'>Completed</p>
				} else if (status == 2) {
					return <p className='badge bg-danger'>Rejected</p>
				} else {
					return <></>
				}
			})()}
		</div>
	)
}

export default TableDataStatus
