import { createSlice } from '@reduxjs/toolkit'
const initialState = {
	profileData: {},
	stakeModal: false,
	investors:false,
	siteData: {},
	callProfileApi: 1
}
const HeaderSlice = createSlice({
	name: 'header',
	initialState,
	reducers: {
		setProfileData: (state, action) => {
			state.profileData = action.payload
		},
		setStakeModel: (state, action) => {
			state.stakeModal = action.payload
		},
		setInvestorModal: (state, action) => {
			state.investors = action.payload
		},
		setSiteDetails: (state, action) => {
			state.siteData = action.payload
		},
		setCallProfileApi: (state, action) => {
			state.callProfileApi = state.callProfileApi + 1
		}
	}
})
export const {
	setProfileData,
	setStakeModel,
	setInvestorModal,
	setSiteDetails,
	setCallProfileApi
} = HeaderSlice.actions
export default HeaderSlice.reducer
