import React from 'react'
import Header from '../../component/header'
import Footer from '../../component/footer'
import ScrollToTop from '../../component/utils/useScrollToTop'
import metadata from '../../metaData/metadata'
import { Helmet } from 'react-helmet'
import NewHeader from '../../component/header/NewHeader'
import { useSearchParams } from 'react-router-dom'
const TermsandConditionPage = () => {
	const [searchparams] = useSearchParams();
	const mobType = searchparams.get("type");
	return (
		<>
			{mobType !== "mobile" && <NewHeader />}
			<Helmet>
				<title>{metadata.terms.title}</title>
				<meta name='description' content={metadata.terms.description} />
				<meta name='keywords' content={metadata.terms.keywords} />
			</Helmet>
			<ScrollToTop />
			<div class='maincontent leftrightCir'>
				<div class=' pt-0'>
					<div class='box-primary'>
						<div class='container'>
							<div class='row'>
								<div class='col-12'>
									<div class='bt-yellow mb-1'>KPK PARK</div>
									<div class='secTit mb-0'>Terms and Conditions</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section class='pb-5 mt-5'>
					<div class='container'>
						<div class='row'>
							<div class='col-12'>
								<div class='mycard'>
									<div class='cmspara'>
										<p>
											<p>
												KPK Park (referred to as "we," "us," "our," "KPK," or
												"KPKPARK," ) aims to ensure that you understand the
												inherent risks involved in cryptocurrency markets, as
												well as all other types of investments.
											</p>
											<p>
												<strong>Vision&nbsp;</strong>&nbsp;
											</p>
											<p>
												KPK Park (referred to as "we," "us," "our," "KPK," or
												"KPKPARK," ) aims to ensure that you understand the
												inherent risks involved in cryptocurrency markets, as
												well as all other types of investments.
											</p>
											<p>
												<strong>
													Risk Disclosure &amp; Disclaimer Statement -
													Full&nbsp;&nbsp;
												</strong>
											</p>
											<p>
												The educational services, content, and information
												offered by KPK Park are provided solely for educational
												purposes and should not be considered trade
												recommendations, investment advice, or financial
												advisory services.
											</p>
											<p>
												Engaging in trading and investment activities carries a
												significant risk of financial loss and is not advisable
												for individuals without proper training as investors. It
												is highly recommended that you never risk more than the
												amount you are prepared to lose. Leverage can cause
												significant losses. Any decision to utilize leverage,
												margin, or shorting techniques is entirely at your own
												discretion, and KPK Park cannot be held responsible for
												any losses incurred.
											</p>
											<p>
												The services, content, and information provided by KPK
												Park are not tailored or customized in any way for
												individual customers or specific customer groups.
											</p>
											<p>
												<strong>
													WARNING: Past performance and results do not guarantee
													future results.&nbsp;&nbsp;
												</strong>
											</p>
											<p>
												By becoming a member of the KPK Park website and making
												a payment, whether monthly or annually, or by accessing
												any free content or services, you acknowledge and accept
												full responsibility for your trading decisions. Neither
												KPK Park, nor its employees, owners, founders, officers,
												board members, affiliates, investors, or advisors
												provide any guarantees regarding the performance of any
												content, actions, statements, recommendations,
												strategies, presentation of facts, presentation of
												opinions, forward-looking statements, testimonials,
												marketing copy, case studies, analysis, news, analysis
												of news, or risk assessments shared through any medium
												during your voluntary participation in the membership
												group. This includes but is not limited to, text,
												videos, images, content, audio notes, chat rooms, online
												apps (Slack, Facebook, Telegram, etc.), news, analysis,
												or any other content.
											</p>
											<p>
												As a paying member or buyer of any cryptocurrency, you
												accept and agree to the following:
											</p>
											<p>
												1 . Your participation in KPK Park and any investments
												made are done so at your own risk and discretion.
											</p>
											<p>
												2 . You are solely responsible for applying the
												knowledge gained from the membership and any content, in
												any format, at your own risk and discretion.
											</p>
											<p>
												3 . Trading, investing, and entrepreneurship carry a
												high level of risk and can lead to significant losses.
												You are engaging in trading at your own risk.
											</p>
											<p>
												4 . Please note that neither KPK Park nor its employees,
												owners, founders, affiliates, officers, board members,
												investors, or advisors are providing investment or
												trading advice.
											</p>
											<p>
												5 . None of the information provided should be
												interpreted as a recommendation to buy or sell any
												investment or asset, nor should it be considered a
												guarantee of results. rading and investing offer no
												guarantees or certainties.
											</p>
											<p>
												6 . Numerous traders and investors experience financial
												losses. It is crucial to avoid trading with funds that
												you cannot afford to lose.
											</p>
											<p>
												7 . Bitcoin and other cryptocurrencies can yield
												significant profits; however, they also carry
												substantial risks, both known and unknown.
											</p>
											<p>
												8 . KPK Park cannot be held accountable for the results
												of your trades, investment decisions, errors, omissions,
												mistakes, or transactions.
											</p>
											<p>
												9 . Please note that refunds are not available under any
												circumstances or at any point in time.
											</p>
											<p>
												10 . KPK Park cannot regulate and assumes no
												responsibility for the statements, opinions, or
												recommendations expressed by other members of the
												program.
											</p>
											<p>
												11 . Considering the dynamic and unpredictable nature of
												markets, it is impossible to account for every aspect of
												an assessment or opinion.
											</p>
											<p>
												12 . KPK Park cannot be held accountable for any errors,
												omissions, or oversights.
											</p>
											<p>
												13 . Cryptocurrencies are inherently volatile and
												unpredictable, and this is expected to persist in the
												future.
											</p>
											<p>
												14 . KPK Park does not assume responsibility for any
												favorable or unfavorable changes in the market or news
												cycle.
											</p>
											<p>
												15 . By engaging in cryptocurrency investments, you
												acknowledge the presence of significant counterparty
												risks associated with third-party exchanges, potential
												hacks, transfer errors, and government sanctions. It is
												important to note that KPK Park has no control over
												these outcomes.
											</p>
											<p>
												16 . You understand and accept that there is a
												possibility of losing your investment principal or any
												gains at any given time.
											</p>
											<p>
												17 . Please be aware that when KPK Park highlights their
												investments and actions, it does not constitute, nor is
												it intended to constitute, recommendations for you to
												take any specific action.
											</p>
											<p>
												18 . Furthermore, KPK Park is not obligated to disclose
												or mention any buying or selling actions they undertake
												at any time. This includes any updates regarding
												previously communicated actions. For instance, if we
												inform you about a purchase, you understand that we are
												not obliged to notify you if or when we decide to sell.
											</p>
											<p>
												19 . KPK Park cannot be held accountable for the outcome
												of any individual trade. You assume full responsibility
												for all your trades and investments, whether you choose
												to make them or not. You acknowledge that you enter into
												transactions based on your own judgment and undertake
												all transactions and investments at your own risk.
											</p>
											<p>
												20 . Hypothetical statements are subject to inherent
												limitations.
											</p>
											<p>
												21 . Any statements made on the discussion board, chat
												rooms, or online apps should not be considered
												investment advice.
											</p>
											<p>
												22 . Past performance cannot guarantee or predict future
												results.
											</p>
											<p>
												23 . KPK Park cannot be held accountable for
												identifying, foreseeing, or detecting all known and/or
												unknown risks associated with the markets.
											</p>
											<p>
												24 . It is your responsibility to verify the legal
												status of cryptocurrency investments in your country
												always.
											</p>
											<p>
												You are advised and alerted that you should not rely on
												any forward-looking statements, information, or opinions
												conveyed by KPK Park or any of its owners, members,
												affiliates, investors, advisors, employees, board
												members, or officers.
											</p>
											<p>
												KPK Park along with its owners, members, investors,
												affiliates, employees, advisors, board members, or
												officers, may, at its discretion, but is not obliged to,
												update or modify its forward-looking statements, whether
												due to new information, subsequent events, or any other
												reason.
											</p>
											<p>
												Prior to investing in the commodity futures market,
												cryptocurrencies, ICOs, commodities, stocks, bonds, or
												any other financial investment, it is essential that you
												carefully assess your capabilities, level of experience,
												skill level, risk tolerance, and investment objectives.
												<strong>
													&nbsp;Please refrain from investing money that you
													cannot afford to lose.
												</strong>
											</p>
											<p>
												Please note that KPK Park does not endorse, support, or
												provide recommendations for any particular trading
												platform or cryptocurrency.
											</p>
											<p>
												Moreover, you recognize the presence of inherent risks
												and associations linked to the utilization of
												internet-based trading systems. These risks encompass
												but are not restricted to, potential failures of
												hardware, software, and internet connectivity. Our
												accountability does not extend to communication failures
												or delays that may occur during internet-based trading.
												We cannot be held responsible for any losses or damages,
												including but not limited to profit loss, that may arise
												from the direct or indirect use of or reliance on such
												information. Please note that the testimonials provided
												have not been independently verified.
											</p>
											<p>
												The world of cryptocurrencies is a dynamic and uncertain
												realm, influenced by numerous variables and factors
												beyond your and KPK Park's control. This ever-changing
												landscape introduces constant risks and uncertainties,
												posing challenges to investments and market conditions.
												KPK Park cannot be held accountable for identifying,
												predicting, or managing all known or unknown risks
												associated with the markets.
											</p>
											<p>
												The outcomes, achievements, or occurrences presented in
												the forward-looking statements,&nbsp;&nbsp;testimonials,
												communications, marketing information, case studies,
												sales material, course information, videos, content, and
												opinions shared by KPK Park or any of its members,
												owners, investors, employees, advisors, affiliates,
												board members, or officers might significantly vary from
												the actual results, performance, or events.&nbsp;
											</p>
											<p>
												There are various factors that could lead to differences
												between the forward-looking statements presented here
												and the actual results, performance, or events. These
												factors include but are not limited to, market
												volatility; hacks and security breaches that result in
												theft or damage to currencies, investors, or companies;
												government policies and regulations; price manipulation
												by high-net-worth investors; the influence of news and
												fake news; competition; general market, economic, or
												business conditions; macroeconomic conditions;
												geopolitical conflict, or any other unforeseen
												influences that may have a positive or negative impact
												on the cryptocurrency markets.
											</p>
											<p>
												KPK Park, along with its members, board members,
												investors, owners, employees, advisors, or officers,
												acknowledges that any forward-looking statements are
												only applicable at the time they were made. KPK Park and
												its associated individuals or entities have no
												obligation to update or modify these forward-looking
												statements, and they explicitly disclaim any such
												obligation, regardless of whether there is new
												information, subsequent events, or any other reason.
											</p>
											<p>
												Investing in&nbsp;&nbsp;ICOs, cryptocurrencies,
												commodity futures, and equities carries significant risk
												and offers NO guarantees of profitability or protection
												against losses. Hypothetical outcomes have innate
												limitations and cannot accurately predict real-world
												success. KPK Park does not claim that any trade will
												replicate the demonstrated profits or achieve any profit
												at all. It is important to recognize that there are
												distinct disparities between hypothetical performance
												results and the actual results attained by individual
												traders, trading programs, or membership courses. The
												performance results and evaluations provided here are
												based on hypothetical scenarios and reflect information
												obtained after the fact. Both anticipated and unforeseen
												factors that influence the markets have the potential to
												influence the actual trading outcomes of a particular
												trading program negatively.
											</p>
											<p>
												The content provided by KPK Park, in any form, does not
												aim to present factual statements. By using this
												content, you agree not to interpret the statements or
												information as facts or guarantees of future
												performance. The opinions, research, analysis, news,
												content, prices, instruction, or any other information
												found on this website are solely for general market
												commentary purposes and should NOT be considered
												investment advice.
											</p>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			{mobType !== "mobile" && <Footer />}
		</>
	)
}

export default TermsandConditionPage
