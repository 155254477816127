import React, { useEffect, useState } from 'react'
import searchicon from '../../assets/images/search-icon.svg'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import Helper from '../../Helper/Helper'
import { dataTableConfigs } from '../../configs/dataTableConfigs'
import { TableLoader } from '../../component/Loaders'
import NoDataComponent from '../../component/Reusable/no-data-component'
import DatePickerReuse from '../../component/Reusable/DatePickerReuse'
import SearchReuse from '../../component/Reusable/searchReuse'
import { toFixedUtil } from '../../component/utils'
import CopyData from '../../component/Reusable/CopyData'
const GrowthLevelBonus = () => {
	const initialPageData = { pageNo: 0, pageSize: 10 }

	const [growthLevelBonusData, setGrowthBonus] = useState({
		data: [],
		count: 0
	})
	const [Loading, setLoading] = useState(false)
	const [filters, setFilter] = useState({
		currency_symbol: 'all',
		search_by: '',
		status: 'all',
		start_date: '',
		end_date: '',
		...initialPageData,
		search: '',
		sort: 'Oldest'
	})

	useEffect(() => {
		getStateHistory()
	}, [filters])

	const getStateHistory = () => {
		setLoading(true)
		Helper.PostData('user', 'stake/growthLevelBonusHistory', filters, false)
			.then(res => {
				if (res.status) {
					setGrowthBonus({ data: res.data, count: res.totalCount })
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}
	//S.no	Date & Time	User ID	Level	Debited From	Amount	Currency	Percentage

	//S.No Staker Name,Level,Incentive,Currency,Percentage,D&T
	const columns = [
		{
			name: 'S.No',
			sortable: true,
			selector: (row, index) => row.index,
			cell: (row, index) => <td>{index + 1}</td>
		},

		// {
		// 	name: 'User ID',
		// 	selector: row => row.level,
		// 	sortable: true
		// },
		{
			name: 'Staker Name',
			selector: row => row.stakerusername,
			cell: (row, index) => {
				return (
					<CopyData
						data={row.stakerusername}
						index={index}
						formatValue={false}
					/>
				)
			},
			sortable: true
		},
		{
			name: 'Tnx.Id',
			sortable: true,
			selector: (row, index) => row.staking_trans_id,
			cell: (row, index) => {
				return (
					<CopyData
						data={row.staking_trans_id}
						index={index}
						formatValue={true}
					/>
				)
			}
		},

		{
			name: 'Level',
			selector: row => row.level,
			sortable: true
		},
		{
			name: 'Incentive',
			selector: row => toFixedUtil(row.growthbonusamount, 2),

			sortable: true
		},
		{
			name: 'Currency',
			sortable: true,
			selector: row => row.currency
		},
		{
			name: 'Percentage',
			sortable: true,
			selector: row => row.Bonuspercentage
		},
		{
			name: 'Date & Time',
			sortable: true,
			selector: (row, index) => row.created_at,

			cell: (row, index) =>
				moment(row.created_at).format('YYYY-MM-DD hh:mm:ss A')
		}
	]

	return (
		<div>
			<div className='trans-body'>
				<div className='trans-TabCnt'>
					<div className='stakingbonus-tabcnt'>
						<div className='bonus-Head'>
							<h5>Growth Level Incentive</h5>
						</div>
						<div className='bonus-Body'>
							<div className='tbl-showentriessearch'>
								<div className='showentries-RightCnt'>
									<div className='inr-searchcnt justify-content-end'>
										<div className='row'>
											<DatePickerReuse
												setFilters={setFilter}
												className='col-lg-4'
											/>
											<SearchReuse
												setFilters={setFilter}
												className='col-lg-4'
												initialPageData={initialPageData}
												placeholder={'Staker Name/Tnx.Id/Level/Amount'}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='cmntbl'>
								<div className='table-responsive'>
									<DataTable
										columns={columns}
										className='text-center'
										data={growthLevelBonusData.data}
										paginationTotalRows={growthLevelBonusData.count}
										customStyles={dataTableConfigs}
										pagination
										paginationServer
										onChangePage={pageNo => {
											setFilter(prev => ({ ...prev, pageNo: pageNo - 1 }))
										}}
										progressPending={Loading}
										progressComponent={<TableLoader />}
										noDataComponent={<NoDataComponent />}
										onChangeRowsPerPage={pageSize => {
											setFilter(prev => ({ ...prev, pageSize }))
										}}
										persistTableHead={true}
										// paginationPerPage={10}
										paginationRowsPerPageOptions={[5, 10, 20, 30]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default GrowthLevelBonus
