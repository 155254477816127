import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable, { Alignment } from "react-data-table-component";
import { Helmet } from "react-helmet";
import { Controller, useForm } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  UncontrolledDropdown,
} from "reactstrap";
import Helper from "../../Helper/Helper";
import convertIcon from "../../assets/images/kp-new/convertIcon.svg";
import { ScreenLoader, TableLoader } from "../../component/Loaders";
import DatePickerReuse from "../../component/Reusable/DatePickerReuse";
import NoDataComponent from "../../component/Reusable/no-data-component";
import SearchReuse from "../../component/Reusable/searchReuse";
import { toFixedLength, toFixedUtil } from "../../component/utils";
import { dataTableConfigs } from "../../configs/dataTableConfigs";
import metadata from "../../metaData/metadata";
import ConfirmExchangeModal from "../modalpage/exchange";
import "./exchange.scss";
const Exchangepage = () => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  const initialPageData = { pageNo: 0, pageSize: 10 };
  const [exchangeHistory, setExchangeHistory] = useState({
    data: [],
    count: 0,
  });
  const [Loading, setLoading] = useState(false);
  const [filters, setFilter] = useState({
    currency_symbol: "all",
    search_by: "",
    status: "all",
    start_date: "",
    end_date: "",
    ...initialPageData,
    search: "",
    sort: "Oldest",
  });
  const [selectedFromCurrency, setSelectedFromCurrency] = useState({});
  const [selectedToCurrency, setSelectedToCurrency] = useState({});

  const [fromCurrency, setFromCurrency] = useState([]);
  const [toCurrency, setToCurrency] = useState([]);
  const [screenLoading, setScreenLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [exchangeFormData, setExchangeFormData] = useState({});

  const toggleConfirmationModal = () =>
    setConfirmationModal(!confirmationModal);

  useEffect(() => {
    getStateHistory();
  }, [filters]);

  const getStateHistory = () => {
    setLoading(true);
    Helper.PostData("user", "user/exchangehistory", filters, false)
      .then((res) => {
        if (res.status) {
          setExchangeHistory({ data: res.data, count: res.count });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setScreenLoading(true);
    getCurrencyBalance();
  }, []);

  const getCurrencyBalance = () => {
    Helper.PostData("user", "user/firstcurrencybalance", {}, false).then(
      (res) => {
        if (res.status) {
          setFromCurrency(res.firstcurrency);
          setSelectedFromCurrency(res.firstcurrency[0]);
        }
      }
    );

    Helper.PostData("user", "user/secondcurrencybalance", {}, false).then(
      (res) => {
        if (res.status) {
          setToCurrency(res.secondcurrency);
          setSelectedToCurrency(res.secondcurrency[0]);
          setScreenLoading(false);
        }
      }
    );
  };

  const handleCurrencyChange = (cur) => {
    if (selectedFromCurrency.firstcurrency != cur.firstcurrency) {
      setSelectedFromCurrency(cur);
      clearInputs();
    }
  };

  const calculatingExchangeValues = (value) => {
    let secondCurrencyExchangeValue = value * selectedFromCurrency.market_price;
    setValue("to_amount", secondCurrencyExchangeValue);
  };

  const clearInputs = () => {
    reset({}); // clearing errors
    setValue("from_amount", "");
    setValue("to_amount", ""); // clearing fields
  };

  const handleFormSubmit = (data) => {
    let params = {
      ...data,
      from_currency: selectedFromCurrency.firstcurrency,
      to_currency: selectedToCurrency.secondcurrency,
      exchange_fee: selectedFromCurrency.exchangefee,
      final_amount: data.to_amount - selectedFromCurrency.exchangefee,
    };
    setExchangeFormData(params);
    toggleConfirmationModal();
  };

  const handleConvert = () => {
    let { exchange_fee, final_amount, ...params } = exchangeFormData;
    setButtonLoading(true);
    Helper.PostData("user", "user/exchangecrypto", params, true)
      .then((res) => {
        if (res.status) {
          clearInputs();
          setConfirmationModal(false);
          getCurrencyBalance();
          getStateHistory();
        }
      })
      .finally(() => setButtonLoading(false));
  };
  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },

    {
      name: "Pair",
      selector: (row) => row.pair,
      sortable: true,
    },
    {
      name: "From Amount",
      selector: (row) => toFixedLength(row.from_amount, 8),
      sortable: true,
    },
    {
      name: "To Amount ",
      selector: (row) => toFixedLength(row.to_amount, 8),

      sortable: true,
    },
    {
      name: "Date & Time",
      sortable: true,
      selector: (row, index) => row.created_at,

      cell: (row, index) => (
        <div className="text-center">
          {moment(row.created_at).format("MMM D, YYYY ,h:mm:ss A")}
        </div>
      ),
    },

    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => {
        if (row.status == "Pending") {
          return <div className=" badge text-warn">Pending</div>;
        } else if (row.status == "Completed") {
          return <div className="badge bg-success">Completed</div>;
        } else if (row.status == "Rejected") {
          return <div className="badge bg-danger">Rejected</div>;
        }
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>{metadata.exchange.title}</title>
        <meta name="description" content={metadata.exchange.description} />
        <meta name="keywords" content={metadata.exchange.keywords} />
      </Helmet>
      <ScreenLoader loading={screenLoading}>
        <div class="dashboard-main">
          <div class="container dash-container">
            <div class="row">
              <div class="col-lg-12">
                <div class="exchangecard mb-4">
                  <div class="row justify-content-center">
                    <div class="col-lg-5">
                      <div class="exchangetit">
                        <h5>Exchange</h5>
                      </div>
                      <Form
                        onSubmit={handleSubmit(handleFormSubmit)}
                        autoComplete="off"
                      >
                        <div class="exchangecard2 py-lg-5">
                          <div class="row justify-content-center">
                            <div class="col-md-11">
                              <div class="form-group formInputs loginInput inputgrpfrms mb-2">
                                <div class="d-flex flex-wrap">
                                  <label class="fs-14 fw-400">From</label>
                                  <span class="ml-auto fs-14 fw-400">
                                    <span class="light-text">Balance :</span>{" "}
                                    {selectedFromCurrency.walletbalance}{" "}
                                    {selectedFromCurrency.firstcurrency}
                                  </span>
                                </div>
                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <UncontrolledDropdown className="btn-group cusdropdown">
                                      <DropdownToggle className="btn btndropdown dropdown-toggle">
                                        <img
                                          src={selectedFromCurrency.image}
                                          class="w-24img"
                                        />
                                        <span class="px-2">
                                          {selectedFromCurrency.firstcurrency}
                                        </span>
                                        <span>
                                          <i class="bi bi-chevron-down fs-12"></i>
                                        </span>
                                      </DropdownToggle>
                                      <DropdownMenu className="exchangeDropdown ">
                                        {fromCurrency?.map((cur) => (
                                          <DropdownItem
                                            className="text-light item-bg-hov"
                                            onClick={() =>
                                              handleCurrencyChange(cur)
                                            }
                                          >
                                            <img
                                              src={cur.image}
                                              class="w-24img"
                                            />
                                            <span class="px-2">
                                              {cur.firstcurrency}
                                            </span>
                                          </DropdownItem>
                                        ))}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </div>

                                  <Controller
                                    control={control}
                                    name="from_amount"
                                    rules={{
                                      required: "* From Amount is required ",
                                      validate: (value) => {
                                        if (
                                          +value <=
                                          +selectedFromCurrency.walletbalance
                                        ) {
                                          if (
                                            +value <
                                            +selectedFromCurrency.min_amount
                                          ) {
                                            return "* Amount must be greater than min Exchange amount";
                                          } else if (
                                            +value >
                                            +selectedFromCurrency.max_amount
                                          ) {
                                            return "* Amount must be less than max Exchange amount";
                                          } else {
                                            return true;
                                          }
                                        } else {
                                          return `* Insufficient ${selectedFromCurrency.firstcurrency} Balance`;
                                        }
                                      },
                                    }}
                                    render={({
                                      field: { onChange, name, value },
                                    }) => (
                                      <NumericFormat
                                        onChange={onChange}
                                        onValueChange={({ value }) =>
                                          calculatingExchangeValues(value)
                                        }
                                        placeholder="0.00"
                                        className="form-control text-right pr-4"
                                        name={name}
                                        value={value}
                                        allowNegative={false}
                                        decimalScale={2}
                                        maxLength={7}
                                      />
                                    )}
                                  />
                                </div>
                                {errors.from_amount && (
                                  <p className="text-danger">
                                    {errors.from_amount.message}
                                  </p>
                                )}
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div class=" d-flex justify-content-between light-text fs-14 fw-400 mb-2">
                                    <span>
                                      Min : {selectedFromCurrency.min_amount}
                                    </span>
                                    <span>
                                      Max : {selectedFromCurrency.max_amount}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div class=" d-flex justify-content-between light-text fs-14 fw-400 mb-4">
                                    <span>
                                      1 {selectedFromCurrency.firstcurrency}
                                    </span>{" "}
                                    <span>~</span>
                                    <span>
                                      {toFixedUtil(
                                        selectedFromCurrency.market_price,
                                        2
                                      )}{" "}
                                      {selectedFromCurrency.secondcurrency}
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>

                              <div class="form-group formInputs loginInput inputgrpfrms mb-4">
                                <div class="d-flex flex-wrap">
                                  <label class="fs-14 fw-400">To</label>
                                  <span class="ml-auto fs-14 fw-400">
                                    <span class="light-text">Balance :</span>{" "}
                                    {selectedToCurrency.walletbalance}{" "}
                                    {selectedToCurrency.secondcurrency}
                                  </span>
                                </div>

                                <div class="input-group">
                                  <div class="input-group-prepend">
                                    <UncontrolledDropdown
                                      disabled
                                      className="btn-group cusdropdown"
                                    >
                                      <DropdownToggle
                                        disabled
                                        className="btn btndropdown dropdown-toggle"
                                      >
                                        <img
                                          src={selectedToCurrency.image}
                                          class="w-24img"
                                        />
                                        <span class="px-2">
                                          {selectedToCurrency.secondcurrency}
                                        </span>
                                      </DropdownToggle>
                                    </UncontrolledDropdown>
                                  </div>

                                  <Controller
                                    control={control}
                                    name="to_amount"
                                    render={({
                                      field: { onChange, name, value },
                                    }) => (
                                      <NumericFormat
                                        disabled
                                        onChange={onChange}
                                        placeholder="0.00"
                                        className="form-control text-right pr-4"
                                        name={name}
                                        value={value}
                                        allowNegative={false}
                                        decimalScale={2}
                                        // maxLength={7}
                                      />
                                    )}
                                  />
                                </div>
                                {errors.to_amount && (
                                  <p className="text-danger">
                                    {errors.to_amount.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="row justify-content-center">
                            <div class="col-md-6">
                              <button
                                class="btn btn-primary btn-block"
                                type="submit"
                              >
                                <img src={convertIcon} class="mr-2" />
                                Convert
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-lg-12">
                    <div class="bonus-Body">
                      <div className="row">
                        <div className="col-md-4 ">
                          <div class="sDashtit mt-3">
                            <h5>Exchange History</h5>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div class="tbl-showentriessearch flex-wrap d_Fds     justify-content-start">
                            <div class="showentries-RightCnt">
                              <div class="inr-searchcnt d_Fds2">
                                <DatePickerReuse
                                  setFilters={setFilter}
                                  className="col-lg-4"
                                />
                                <SearchReuse
                                  className="col-lg-4"
                                  initialPageData={initialPageData}
                                  placeholder={"Search Pair"}
                                  setFilters={setFilter}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="cmntbl">
                        <div className="table-responsive">
                          <DataTable
                            columns={columns}
                            className="text-center"
                            data={exchangeHistory.data}
                            paginationTotalRows={exchangeHistory.count}
                            customStyles={dataTableConfigs}
                            pagination
                            paginationServer
                            onChangePage={(pageNo) => {
                              setFilter((prev) => ({
                                ...prev,
                                pageNo: pageNo - 1,
                              }));
                            }}
                            subHeaderAlign={Alignment.CENTER}
                            progressPending={Loading}
                            progressComponent={<TableLoader />}
                            noDataComponent={<NoDataComponent />}
                            onChangeRowsPerPage={(pageSize) => {
                              setFilter((prev) => ({ ...prev, pageSize }));
                            }}
                            persistTableHead={true}
                            paginationRowsPerPageOptions={[5, 10, 20, 30]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ConfirmExchangeModal
          toggle={toggleConfirmationModal}
          modal={confirmationModal}
          FormData={exchangeFormData}
          handleAPICall={handleConvert}
          loader={buttonLoading}
        />
      </ScreenLoader>
    </>
  );
};

export default Exchangepage;
