import React from 'react'
import { Form, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { ButtonLoader } from '../../../component/Loaders'
import CloseBtn from '../../../component/Reusable/CloseBtn'
import { toFixedLength } from '../../../component/utils'

const ConfirmExchangeModal = ({
	modal,
	toggle,
	loader,
	handleAPICall,
	FormData
}) => {
	return (
		<>
			<Modal
				isOpen={modal}
				toggle={toggle}
				className={'modal-md modal-dialog-centered mymodal'}
				size={'md'}
				backdrop='static'
				keyboard={false}
			>
				<ModalHeader toggle={toggle} close={<CloseBtn onClick={toggle} />}>
					Exchange Information
				</ModalHeader>
				<ModalBody className='px-4'>
					<div class='row'>
						<div class='col-12'>
							<div class='form-group formInputs walletInput d-flex justify-content-between'>
								<label class='col-form-label'>From Currency</label>
								<label class='col-form-label'>{FormData.from_currency}</label>
							</div>
							<div class='form-group formInputs walletInput d-flex justify-content-between'>
								<label class='col-form-label'>To Currency</label>
								<label class='col-form-label'>{FormData.to_currency}</label>
							</div>
							<div class='form-group formInputs walletInput d-flex justify-content-between'>
								<label class='col-form-label'>From Currency Amount</label>
								<label class='col-form-label'>
									{toFixedLength(FormData.from_amount, 8) +
										' ' +
										FormData.from_currency}
								</label>
							</div>
							<div class='form-group formInputs walletInput d-flex justify-content-between'>
								<label class='col-form-label'>To Currency Amount</label>

								<label class='col-form-label'>
									{toFixedLength(FormData.to_amount, 8) +
										' ' +
										FormData.to_currency}
								</label>
							</div>
							<div class='form-group formInputs walletInput d-flex justify-content-between'>
								<label class='col-form-label'>Exchange Fee</label>
								<label class='col-form-label'>
									{FormData.exchange_fee + ' ' + FormData.to_currency}
								</label>
							</div>
							<div class='form-group formInputs walletInput d-flex justify-content-between'>
								<label class='col-form-label'>You Will Get</label>
								<label class='col-form-label'>
									{toFixedLength(FormData.final_amount, 8) +
										' ' +
										FormData.to_currency}
								</label>
							</div>
							{/* <div class='form-group formInputs walletInput d-flex justify-content-between'>
									<label class='col-form-label'>OverAll Reward Amount</label>
									<label class='col-form-label'>1350</label>
								</div>
								<div class='form-group formInputs walletInput d-flex justify-content-between'>
									<label class='col-form-label'>Percentage</label>
									<label class='col-form-label'>3</label>
								</div> */}
							<div class='text-center mb-2'>
								{loader ? (
									<button type='button' disabled class='btn btn-primary px-4'>
										<ButtonLoader />
									</button>
								) : (
									<button
										type='button'
										onClick={handleAPICall}
										class='btn btn-primary px-4'
									>
										Exchange
									</button>
								)}
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default ConfirmExchangeModal
