import React, { useEffect } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import Footer from "../component/footer";
import NewHeader from "../component/header/NewHeader";

const ContestLayout = () => {
  const [searchparams] = useSearchParams();
	const mobType = searchparams.get("type");
  return (
    <>
     {mobType !== "mobile" && <NewHeader />}{" "}
      <div class="maincontent">
        <div class="">
          <Outlet />
        </div>
      </div>
      {mobType !== "mobile" && <Footer />}
    </>
  );
};

export default ContestLayout;
