// import React, { Fragment, useEffect, useState } from "react";
// import { Controller, useForm } from "react-hook-form";
// import { Form } from "reactstrap";
// import { v4 as uuidv4 } from "uuid";
// import Helper from "../../Helper/Helper";

// const Test = () => {
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     reset,
//     control
//   } = useForm({
//     mode: "all",
//     reValidateMode: "onChange",
//   });

//   const [input, setInput] = useState([
//       { id: uuidv4(), target_goal_count: "", goal_name: "" },
//     ]);
//     console.log("input: ", input);
//   const [GoalsName, setGoalsName] = useState([]);

//   useEffect(() => {
//     Helper.PostData("goals", "goal/goalDropdown", {}, false).then((res) => {
//       if (res.status) {
//         setGoalsName(res.data);
//       }
//     });
//   }, []);

//   const addInput = () => {
//     setInput([
//       ...input,
//       { id: uuidv4(), target_goal_count: "", goal_name: "" },
//     ]);
//   };

//   const removeInput = (id) => {
//     const newInput = input.filter((item) => item.id !== id);
//     setInput(newInput);
//   };

//   const onSubmit = (data) => {
//     const formattedData = input.map((item) => ({
//       target_goal_count: data[`${item.id}-target_goal_count`],
//       goal_name: data[`${item.id}-goal_name`],
//     }));

//     console.log("Data to be sent to API: ", formattedData);

//     // Example API call (replace with your actual API endpoint)
//     fetch("https://your-api-endpoint.com/submit", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(formattedData),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log("Success:", data);
//         reset(); // Reset the form after successful submission
//       })
//       .catch((error) => {
//         console.error("Error:", error);
//       });
//   };

//   return (
//     <Fragment>
//       <div className="row">
//         <div className="col-lg-4">
//           <Form onSubmit={handleSubmit(onSubmit)}>
//             {input.map((item) => (
//               <Fragment key={item.id}>
//                 <div className="form-group formInputs walletInput">
//                   <label className="col-form-label">
//                     Target Count<span className="text-primary">*</span>
//                   </label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     placeholder="Enter Target Count"
//                     {...register(`${item.id}-target_goal_count`, {
//                       required: "* Target Count is required",
//                     })}
//                   />
//                   {errors[`${item.id}-target_goal_count`] && (
//                     <p className="text-danger">
//                       {errors[`${item.id}-target_goal_count`].message}
//                     </p>
//                   )}
//                 </div>
//                 <div className="form-group formInputs walletInput">
//                   <label className="col-form-label">
//                     Select Goal Name
//                     <span className="text-primary">*</span>
//                   </label>
//                   <Controller
//                     name={`${item.id}-goal_name`}
//                     control={control}
//                     rules={{
//                       required: "* Goal Name is required",
//                     }}
//                     render={({ field }) => (
//                       <select
//                         className="form-control"
//                         {...field}
//                         onChange={(e) => {
//                           field.onChange(e);
//                         }}
//                       >
//                         <option hidden value="">
//                           Select Goal
//                         </option>
//                         {GoalsName?.map((item, idx) => {
//                             console.log("input.some((d) => d?.goal_name",input.some((d) => d?.goal_name));
//                           return (
//                             <option
//                               hidden={input.some((d) => d?.goal_name === item)}
//                               key={idx}
//                               value={item}
//                             >
//                               {item}
//                             </option>
//                           );
//                         })}
//                       </select>
//                     )}
//                   />
//                   {/* <select
//                     className="form-control"
//                     {...register(`${item.id}-goal_name`, {
//                       required: "* Goal Name is required",
//                     })}
//                   >
//                     <option value="" hidden>
//                       Select Goal Name
//                     </option>
//                     {GoalsName.map((goal, idx) => (
//                       <option key={idx} value={goal} hidden={input.some(
//                         (d) => d?.goal_name === goal
//                       )}>
//                         {goal}
//                       </option>
//                     ))}
//                   </select> */}
//                   {errors[`${item.id}-goal_name`] && (
//                     <p className="text-danger">
//                       {errors[`${item.id}-goal_name`].message}
//                     </p>
//                   )}
//                 </div>

//                 <div className="btn-group">
//                   <button
//                     className="btn btn-primary"
//                     type="button"
//                     onClick={addInput}
//                   >
//                     Add
//                   </button>
//                   <button
//                     className="btn btn-danger"
//                     type="button"
//                     onClick={() => removeInput(item.id)}
//                   >
//                     Remove
//                   </button>
//                 </div>
//               </Fragment>
//             ))}
//             <button className="btn btn-success" type="submit">
//               Submit All
//             </button>
//           </Form>
//         </div>
//       </div>
//     </Fragment>
//   );
// };

// export default Test;

import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const Test = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  const [input, setInput] = useState([
    { id: uuidv4(), target_goal_count: "", goal_name: "" },
  ]);
  console.log("input: ", input);

  // Hardcoded goal names
  const GoalsName = [
    "Staking Rewards",
    "Level Bonus",
    "Growth Level Incentive",
    "Direct Earning",
    "Team Earning",
    "Direct Members",
    "Team Members",
    "Leads",
  ];

  const addInput = () => {
    setInput([
      ...input,
      { id: uuidv4(), target_goal_count: "", goal_name: "" },
    ]);
  };

  const removeInput = (id) => {
    const newInput = input.filter((item) => item.id !== id);
    setInput(newInput);
  };

  const handleInputChange = (id, field, value) => {
    console.log("id, field, value: ", id, field, value);
    setInput((prevInput) =>
      prevInput.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const onSubmit = (data) => {
    const formattedData = input.map((item) => ({
      target_goal_count: item.target_goal_count,
      goal_name: item.goal_name,
    }));

    console.log("Data to be sent to API: ", formattedData);

    // Example API call (replace with your actual API endpoint)
    fetch("https://your-api-endpoint.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formattedData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        reset(); // Reset the form after successful submission
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            {input.map((item) => (
              <Fragment key={item.id}>
                <div className="form-group formInputs walletInput">
                  <label className="col-form-label">
                    Target Count<span className="text-primary">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Target Count"
                    defaultValue={item.target_goal_count}
                    onChange={(e) =>
                      handleInputChange(
                        item.id,
                        "target_goal_count",
                        e.target.value
                      )
                    }
                  />
                  {errors[`${item.id}-target_goal_count`] && (
                    <p className="text-danger">
                      {errors[`${item.id}-target_goal_count`].message}
                    </p>
                  )}
                </div>
                <div className="form-group formInputs walletInput">
                  <label className="col-form-label">
                    Select Goal Name
                    <span className="text-primary">*</span>
                  </label>
                  <select
                    className="form-control"
                    dafaultValue={item.goal_name}
                    onChange={(e) =>
                      handleInputChange(item.id, "goal_name", e.target.value)
                    }
                  >
                    <option value="" hidden>
                      Select Goal Name
                    </option>
                    {GoalsName.map((goal, idx) => (
                      <option
                        key={idx}
                        value={goal}
                        hidden={input.some((d) => d?.goal_name == goal)}
                      >
                        {goal}
                      </option>
                    ))}
                  </select>
                  {errors[`${item.id}-goal_name`] && (
                    <p className="text-danger">
                      {errors[`${item.id}-goal_name`].message}
                    </p>
                  )}
                </div>

                <div className="btn-group">
                  <button
                    className="btn btn-primary"
                    type="button"
                    onClick={addInput}
                  >
                    Add
                  </button>
                  <button
                    className="btn btn-danger"
                    type="button"
                    onClick={() => removeInput(item.id)}
                  >
                    Remove
                  </button>
                </div>
              </Fragment>
            ))}
            <button className="btn btn-success" type="submit">
              Submit All
            </button>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default Test;
