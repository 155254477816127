import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Helper from "../../Helper/Helper";
import closeIcon from "../../assets/images/close-x.svg";
import { TableLoader } from "../../component/Loaders";
import NoDataComponent from "../../component/Reusable/no-data-component";
import { dataTableConfigs } from "../../configs/dataTableConfigs";
import { PlotHash } from "./PlotHash";
import "./plot.css";

const PlotHistory = () => {
  const [response, setResponse] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [plotHash, setPlotHash] = useState();

  useEffect(() => {
    setLoading(true);
    Helper.PostData("user", "user/plotbookinghis", {}, false).then((res) => {
      if (res.status) {
        setResponse(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const toggleClose = () => {
    setModal(!modal);
  };

  const HandleViewHash = (id) => {
    toggle();
    const filter =
      response &&
      response
        .filter((item, index) => item.id === id)
        .map((item, index) => item.Plot);
    const arrayOfObjects = filter.reduce((acc, curr) => {
      return acc.concat(curr);
    }, []);
    setPlotHash(arrayOfObjects);
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
      width: "100px",
    },

    {
      name: "Plot State",
      selector: "state",
      sortable: true,
    },
    {
      name: "Plot Count",
      sortable: true,
      selector: (row, index) => row.Plots,
    },
    {
      name: "Per Plot Count",
      sortable: true,
      selector: (row, index) => +row.bookingamount / +row.Plots,
    },
    {
      name: "Plot Amount",
      sortable: true,
      selector: (row, index) => row.bookingamount,
    },
    {
      name: "Plot Hash",
      sortable: true,
      selector: (row, index) => row.status,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">
          <FontAwesomeIcon
            icon={faEye}
            onClick={() => HandleViewHash(row.id)}
          />
        </td>
      ),
    },
    {
      name: "Date & Time",
      sortable: true,
      selector: (row, index) => row.created_at,
      cell: (row, index) =>
        moment(row.created_at).format("YYYY-MM-DD hh:mm:ss A"),
    },
  ];

  const columns1 = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },
    {
      name: "Plot Count",
      selector: "Plots",
      sortable: true,
    },
    {
      name: "Plot Hash",
      sortable: true,
      selector: (row, index) => row.plotId,
    },
  ];

  const closeBtn = (
    <button
      type="button"
      className="close"
      aria-label="Close"
      onClick={toggleClose}
    >
      <img src={closeIcon} alt="close-x" />
    </button>
  );

  return (
    <Fragment>
      <div>
        <div className="trans-body">
          <div className="trans-TabCnt">
            <div className="stakingbonus-tabcnt">
              <div className="bonus-Head">
                <h5>Plot History</h5>
              </div>
              <div className="bonus-Body">
                <div className="tbl-showentriessearch">
                  <div className="showentries-LeftCnt">
                    <div className="inr-showecnt"></div>
                  </div>
                  <div className="showentries-RightCnt">
                    <div className="inr-searchcnt">
                      <div className="mx-2 my-2"></div>
                      {/* <div className='search-input'>
												<input type='text' placeholder='Search' />
												<span>
													<img src={searchicon} className='img-fluid' alt='' />
												</span>
											</div> */}
                    </div>
                  </div>
                </div>
                <div className="cmntbl">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      fixedHeader
                      data={response && response}
                      customStyles={dataTableConfigs}
                      persistTableHead={true}
                      highlightOnHover={true}
                      paginationPerPage={10}
                      noDataComponent={<NoDataComponent />}
                      paginationRowsPerPageOptions={[5, 10, 20, 30]}
                      pagination
                      progressComponent={<TableLoader />}
                      progressPending={Loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PlotHash
        modal={modal}
        plotHash={plotHash}
        toggle={toggle}
        columns1={columns1}
        closeBtn={closeBtn}
        customStyles={dataTableConfigs}
      />
    </Fragment>
  );
};

export default PlotHistory;
