import React, { useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'
import Helper from '../../Helper/Helper'
import lineChartNoDataImg from '../../assets/images/chart_no-data.webp'
import pieChartNoDataImg from '../../assets/images/pie-no-data.svg'
import { CheckNegativeValue, toFixedLength } from '../../component/utils/index'
import './bot-dashboard.scss'
// import BotHistory from './Bot-history'
// import BotAssets from './BotAssets'
// import BotAssetCard from './botAssetCard'
import { TableLoader } from '../../component/Loaders'
const BotsInfo = ({
	botBalance,
	botPieChartLoading,
	botPieChartData,
	botTotalValue
}) => {
	const [botLineChartLoading, setBotLineChartLoading] = useState(false)
	const [botLineChartData, setBotLineChartData] = useState([['Date', 'USD']])
	console.log('botLineChartData: ', botLineChartData)

	useEffect(() => {
		getBotLineChart()
	}, [])

	const LineChart = () => (
		<div className='mt-3'>
			{(() => {
				if (botLineChartData.length > 1) {
					return (
						<Chart
							chartType='LineChart'
							width='100%'
							// loader={<TableLoader />}
							height='200px'
							data={botLineChartData}
							options={optionsLine}
						/>
					)
				} else {
					return (
						<div className='pt-1'>
							<img
								src={lineChartNoDataImg}
								alt='No Data'
								width={'100%'}
								height={'120px'}
							/>
						</div>
					)
				}
			})()}
		</div>
	)

	const PieChart = () => (
		<div>
			{(() => {
				if (botPieChartData.length > 1) {
					return (
						<Chart
							chartType='PieChart'
							width='100%'
							height='200px'
							data={botPieChartData}
							options={options}
						/>
					)
				} else {
					return (
						<div className='pt-3'>
							<img
								src={pieChartNoDataImg}
								alt='No Data'
								width={'100%'}
								height={'120px'}
							/>
						</div>
					)
				}
			})()}
		</div>
	)

	const Statistics = () => (
		<div className='ststics'>
			<span>Total Volume</span>
			<span>
				$ {toFixedLength(botTotalValue.usd_value, 2)}
				{console.log(
					'toFixedLength(botTotalValue.usd_value, 2: ',
					toFixedLength(botTotalValue.usd_value, 2)
				)}
				{/* <CheckNegativeValue
					value={botBalance.day_profit_usd_percentage}
					content={'%'}
					tag={'span'}
				/> */}
			</span>
			<span>
				≈ {toFixedLength(botTotalValue.btc_value, 8)} BTC
				{/* <CheckNegativeValue
					value={botBalance.day_profit_btc_percentage}
					content={'%'}
					tag={'span'}
				/> */}
			</span>
		</div>
	)

	// const [botBalance, setBotBalance] = useState({})
	// const [botTotalValue, setBotTotalValue] = useState({})
	// const [exchangeAssetData, setExchangeAssetData] = useState([])

	// const [botPieChartData, setBotPieChartData] = useState([
	// 	['Exchange', 'Amount']
	// ])
	// const [botPieChartLoading, setBotPieChartLoading] = useState(false)

	// useEffect(() => {
	// 	getBotPortfolio()
	// 	getBotPieChart()
	// }, [])

	// const getBotPortfolio = () => {
	// 	// setBotHistoryLoading(false)
	// 	Helper.GetData('local', 'user/botRouter/user_balance')
	// 		.then(res => {
	// 			if (res.status) {
	// 				setBotBalance(res.data)
	// 			}
	// 		})
	// 		.finally(() => {
	// 			// setBotHistoryLoading(false)
	// 		})
	// }

	// const getBotPieChart = () => {
	// 	setBotPieChartLoading(true)
	// 	Helper.GetData('local', 'user/botRouter/pie_chart_data')
	// 		.then(res => {
	// 			if (res.status) {
	// 				const { data } = res
	// 				const modifiedPieData = [...botPieChartData]
	// 				let totalValue = { usd_value: 0, btc_value: 0 }
	// 				for (let { name, amount, usd_value, btc_value } of data) {
	// 					totalValue.usd_value += +usd_value
	// 					totalValue.btc_value += +btc_value

	// 					if (name && amount) {
	// 						modifiedPieData.push([name, amount])
	// 					}
	// 				}
	// 				console.log('totalValue: ', totalValue)
	// 				setBotPieChartData(modifiedPieData)
	// 				setBotTotalValue(totalValue)
	// 				setExchangeAssetData(res.data)
	// 			}
	// 		})
	// 		.finally(() => {
	// 			setBotPieChartLoading(false)
	// 		})
	// }

	const getBotLineChart = () => {
		setBotLineChartLoading(true)
		Helper.GetData('local', 'user/botRouter/profit_chart')
			.then(res => {
				if (res.status) {
					let { data } = res
					let modifiedChartData = [...botLineChartData]
					for (let { date, USD } of data) {
						if (date && USD) {
							modifiedChartData.push([date, USD])
						}
					}
					setBotLineChartData(modifiedChartData)
				}
			})
			.finally(() => {
				setBotLineChartLoading(false)
			})
	}

	const options = {
		title: '',
		pieHole: 0.9,
		is3D: false,
		backgroundColor: 'transparent',
		pieSliceText: 'none',
		// legend: 'none',
		tooltip: {
			textStyle: {
				color: 'black' // Set the desired color for the tooltip text
			}
		},

		showInfoWindow: false,
		noDataMessage: 'No data found1'
	}

	const optionsLine = {
		title: '',
		curveType: 'function',
		legend: { position: 'bottom' },
		backgroundColor: 'transparent',

		vAxis: {
			gridlines: {
				color: '#0f0f0f' // Set the color to 'none' to hide vertical gridlines
			}
		},

		hAxis: {
			gridlines: {
				color: '#0f0f0f' // Set the color to 'none' to hide horizontal gridlines
			}
		},
		tooltip: {
			textStyle: {
				color: 'black' // Set the desired color for the tooltip text
			}
		}
	}

	return (
		<>
			{/* <div className='leftrightCir'> */}
			{/* <ScreenLoader
				loading={botHistoryloading || botLineChartLoading || botPieChartLoading}
			> */}
			<div className='bot-db'>
				<div class='dashboard-main'>
					<div class='container dash-container'>
						<div className='row'>
							<div className='col-lg-12 text-right'>
								<div className='d-flex justify-content-center'>
									<div className='bonus-Head'>
										<h5>Portfolio</h5>
									</div>
								</div>
							</div>
						</div>
						{/* <div className='row mt-3'>
							<div className='col-lg-12'>
								<div className='portfolio-bot'>
									<div className='row'>
										<div className='col-lg-3 '>
											<div className='pt-3'>
												<h4>
													<b>Portfolio</b>
												</h4>
											</div>
										</div>
										<div className='col-lg-3 mb-xm-15px'>
											<div className='tot-prf'>
												<p>Total Portfolio value</p>
												<p>$ {toFixedLength(botBalance.day_profit_usd, 2)}</p>
												<p>{toFixedLength(botBalance.day_profit_btc, 8)} BTC</p>
											</div>
										</div>
										<div className='col-lg-3'>
											<div className='tot-prf'>
												<p>24 Hrs Changes</p>
												<p>$ {toFixedLength(botBalance.usd_amount, 2)}</p>
												<p>{toFixedLength(botBalance.btc_amount, 8)} BTC</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}

						<div className='row mt-3 '>
							<div className='col-lg-12'>
								<div className='portfolio-bot'>
									<div className='row justify-content-between align-items-center mh-200px'>
										{(() => {
											if (botPieChartLoading && botLineChartLoading) {
												return (
													<div className='col-lg-12 text-center'>
														<TableLoader />
													</div>
												)
											} else if (botPieChartLoading) {
												return (
													<>
														<div className='col-lg-12 text-center '>
															<div className='pt-3'>
																<TableLoader />
															</div>
														</div>
														<div className='col-lg-12 bot-line'>
															<LineChart />
														</div>
													</>
												)
											} else if (botLineChartLoading) {
												return (
													<>
														<div className='col-lg-5 bot-pie'>
															<PieChart />
														</div>
														<div className='col-lg-3'>
															<Statistics />
														</div>
														<div className='col-lg-4 text-center'>
															<TableLoader />
														</div>
													</>
												)
											} else if (
												botLineChartData.length == 1 &&
												botPieChartData.length == 1
											) {
												return (
													<>
														<div className='col-lg-3 bot-pie'>
															<PieChart />
														</div>
														<div className='col-lg-3'>
															<Statistics />
														</div>
														<div className='col-lg-6 bot-line'>
															<LineChart />
														</div>
													</>
												)
											} else if (botLineChartData.length == 1) {
												return (
													<>
														<div className='col-lg-5 bot-pie'>
															<PieChart />
														</div>
														<div className='col-lg-3'>
															<Statistics />
														</div>
														<div className='col-lg-4 bot-line'>
															<LineChart />
														</div>
													</>
												)
											} else if (!botPieChartLoading && !botLineChartLoading) {
												return (
													<>
														<div className='col-lg-8 bot-pie'>
															<PieChart />
														</div>
														<div className='col-lg-4'>
															<Statistics />
														</div>
														<div className='col-lg-12 bot-line'>
															<LineChart />
														</div>
													</>
												)
											}
										})()}

										{/* <div className='col-lg-6 '>
											<div className='bot-pie d-flex justify-content-start'>
												<div>
													<Chart
														chartType='PieChart'
														width='100%'
														height='200px'
														data={botPieChartData}
														options={options}
													/>
												</div>

												<div className='ststics'>
													<span>Total / Change 24 hr</span>
													<span>
														$ {botTotalValue.usd_value}
														<span>0.0%</span>
													</span>
													<span>
														≈ {botTotalValue.btc_value} BTC <span>0.0%</span>
													</span>
												</div>
											</div>
										</div> */}
									</div>
								</div>
							</div>
						</div>

						{/* <hr class='hr-text' data-content='Exchanges'></hr>

						<BotAssetCard
							botBalance={botBalance}
							botPieChartLoading={botPieChartLoading}
							data={exchangeAssetData}
						/>

						<BotAssets
							botPieChartLoading={botPieChartLoading}
							data={exchangeAssetData}
						/> */}
						{/* <BotHistory /> */}
						{/* <UpdateExchange ExchangeData={{}} /> */}
					</div>
				</div>
			</div>
			{/* </ScreenLoader> */}
			{/* </div> */}
		</>
	)
}

export default BotsInfo
