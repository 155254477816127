import React, { useEffect, useState } from "react";
import searchicon from "../../assets/images/search-icon.svg";
import DataTable from "react-data-table-component";
import moment from "moment";
import Helper from "../../Helper/Helper";
import { dataTableConfigs } from "../../configs/dataTableConfigs";
import { TableLoader } from "../../component/Loaders";
import NoDataComponent from "../../component/Reusable/no-data-component";
import DatePickerReuse from "../../component/Reusable/DatePickerReuse";
import SearchReuse from "../../component/Reusable/searchReuse";
import { toFixedUtil } from "../../component/utils";
import CopyData from "../../component/Reusable/CopyData";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { LevelMissedBonus } from "./LevelMissedBonus";
import { MissedColumn } from "./MissedColumn";

const LevelBonus = () => {
  const initialPageData = { pageNo: 0, pageSize: 10 };

  const [levelBonusData, setLevelBonusHistory] = useState({
    data: [],
    count: 0,
  });
  const [MissedBonus, setMissedBonus] = useState({
    data: [],
    count: 0,
  });
  const [Loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [filters, setFilter] = useState({
    currency_symbol: "all",
    search_by: "",
    status: "all",
    start_date: "",
    end_date: "",
    ...initialPageData,
    search: "",
    sort: "Oldest",
  });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getStateHistory();
  }, [filters]);

  const getStateHistory = () => {
    setLoading(true);
    Helper.PostData("user", "stake/levelBonusHistory", filters, false)
      .then((res) => {
        if (res.status) {
          setLevelBonusHistory({ data: res.data, count: res.totalCount });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },
    {
      name: "Tnx.Id",
      sortable: true,
      selector: (row, index) => row.stake_trans_id,
      cell: (row, index) => {
        return (
          <CopyData
            data={row.stake_trans_id}
            index={index}
            formatValue={true}
          />
        );
      },
    },
    {
      name: "Level",
      selector: (row) => row.level,
      sortable: true,
    },
    {
      name: "Reward KPK",
      selector: (row) => toFixedUtil(row.levelbonusamount, 2),

      sortable: true,
    },
    {
      name: "Percentage",
      sortable: true,
      selector: (row) => row.Bonuspercentage,
    },
    {
      name: "Staker Name",
      sortable: true,
      selector: (row) => row.stakerusername,
      cell: (row, index) => {
        return (
          <CopyData
            data={row.stakerusername}
            index={index}
            formatValue={false}
          />
        );
      },
    },
    {
      name: "Date & Time",
      sortable: true,
      selector: (row, index) => row.created_at,

      cell: (row, index) =>
        moment(row.created_at).format("YYYY-MM-DD hh:mm:ss A"),
    },
  ];
  const columns1 = MissedColumn();

  useEffect(() => {
    if (activeTab == "2") {
      Helper.PostData(
        "user",
        "stake/not_eligible_level_bonus_his",
        filters,
        true
      ).then((res) => {
        if (res.status) {
          setMissedBonus({ data: res.data, count: res.totalCount });
        }
      });
    }
  }, [activeTab, filters]);
  return (
    <div>
      <div className="trans-body">
        <div className="trans-TabCnt">
          <div className="stakingbonus-tabcnt">
            <div className="bonus-Head">
              <h5>Level Bonus</h5>
            </div>
            <div className="bonus-Body">
              <div className="tbl-showentriessearch">
                <div className="showentries-RightCnt">
                  <div className="inr-searchcnt">
                    <div className="row">
                      <div className="col-lg-3">
                        <Nav tabs className="cursor-pointer">
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "1",
                              })}
                              onClick={() => {
                                toggle("1");
                              }}
                            >
                              Received
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({
                                active: activeTab === "2",
                              })}
                              onClick={() => {
                                toggle("2");
                              }}
                            >
                              Missed
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </div>

                      <DatePickerReuse
                        setFilters={setFilter}
                        className="col-lg-3"
                      />
                      <SearchReuse
                        className="col-lg-3"
                        setFilters={setFilter}
                        initialPageData={initialPageData}
                        placeholder={"Staker Name/Tnx.Id/Level"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="cmntbl">
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        className="text-center"
                        data={levelBonusData.data}
                        paginationTotalRows={levelBonusData.count}
                        customStyles={dataTableConfigs}
                        pagination
                        paginationServer
                        onChangePage={(pageNo) => {
                          setFilter((prev) => ({
                            ...prev,
                            pageNo: pageNo - 1,
                          }));
                        }}
                        progressPending={Loading}
                        progressComponent={<TableLoader />}
                        noDataComponent={<NoDataComponent />}
                        onChangeRowsPerPage={(pageSize) => {
                          setFilter((prev) => ({ ...prev, pageSize }));
                        }}
                        persistTableHead={true}
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <LevelMissedBonus
                    MissedBonus={MissedBonus}
                    Loading={Loading}
                    setFilter={setFilter}
                    columns1={columns1}
                  ></LevelMissedBonus>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LevelBonus;
