import React, { useState } from 'react'
import searchicon from '../../assets/images/search-icon.svg'
import './scss/picker.scss'

const SearchReuse2 = ({
	setFilters,
	initialPageData,
	placeholder,
	className,
	filterKey = 'search_by'
}) => {
	const [searchValue, setSearchData] = useState('')

	const onSubmit = e => {
		e.preventDefault()
		if (searchValue) {
			setFilters(prev => ({
				...prev,
				...initialPageData,
				[filterKey]: searchValue
			}))
		}
	}

	return (
		<div className={className}>
			{/* <div className='jik_kil'> */}
			<form onSubmit={onSubmit}>
				<input
					className='search-flr-input'
					type='text'
					id={filterKey}
					placeholder={placeholder}
					onChange={({ target: { value } }) => {
						if (!value) {
							setFilters(prev => ({
								...prev,
								...initialPageData,
								[filterKey]: ''
							}))
						}
						setSearchData(() => value)
					}}
				/>
				<span className='search-ico'>
					<a type='button' onClick={onSubmit}>
						<img src={searchicon} className='img-fluid ' alt='' />
					</a>
				</span>
			</form>
		</div>
		// </div>
	)
}

export default SearchReuse2
