import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import Helper from '../../Helper/Helper'
const CopyData2 = ({ data, formatValue = true, padding = 4 }) => {
	const [copiedIndex, setCopiedIndex] = useState(false)

	const copyStatus = () => {
		setCopiedIndex(true)
		setTimeout(() => {
			setCopiedIndex(false)
		}, 500)
	}

	return (
		<>
			<div title={data}>
				{(() => {
					if (data) {
						if (formatValue) {
							return Helper.formatAddress(data, padding)
						} else {
							return data
						}
					} else {
						return '---'
					}
				})()}
				{(() => {
					if (data) {
						if (copiedIndex) {
							return <span className='ml-2'>✔</span>
						} else {
							return (
								<FontAwesomeIcon
									className='cursor-pointer ml-2'
									style={{ color: 'green' }}
									icon={faCopy}
									title='Copy'
									onClick={() => Helper.copyText(data, copyStatus)}
								/>
							)
						}
					}
				})()}
			</div>
		</>
	)
}

export default CopyData2
