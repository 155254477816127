import React from 'react'
import closeIcon from '../../assets/images/close-x.svg'

const CloseBtn = ({ onClick }) => {
	return (
		<button
			type='button'
			className='close'
			aria-label='Close'
			onClick={onClick}
			title='Close'
		>
			<img src={closeIcon} alt='close-x' />
		</button>
	)
}

export default CloseBtn
