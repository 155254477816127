import { Modal } from "reactstrap";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useEffect, useState } from "react";
import TodoForm from "./TodoForm";
import BopForm from "./BopForm";

export const EventModal = ({
  modal,
  toggle,
  className,
  date,
  GetItem,
  setModal,
  ShowInfoModalClose,
  GetEventList,
}) => {
  const [radioBtn, setRadioBtn] = useState("todo");
  useEffect(() => {
    if (GetItem?.type) {
      setRadioBtn(GetItem?.type);
    }
  }, [GetItem]);
  const toggleClose = () => {
    setRadioBtn("todo");
    setModal(false);
  };
  const handleRadioChange = (event) => {
    setRadioBtn(event.target.value);
  };

  return (
    <Modal
      isOpen={modal}
      toggleModal={toggle}
      className={className}
      modalClassName="tms_rl right fade fade-right"
    >
      <div class="modal-body">
        <div class="mki_ds">
          <div className="d-flex align-items-center justify-content-between">
            <div class="hdski">
              <h2 className="fs-16">Schedule Event</h2>
            </div>
            <div class="singup-close" onClick={toggleClose}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.16992 14.8299L14.8299 9.16992"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.8299 14.8299L9.16992 9.16992"
                  stroke="#fff"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <FormControl>
            {/* <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleRadioChange}
              value={radioBtn}
            > */}
            {GetItem?.type === "todo" ? (
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleRadioChange}
                value={radioBtn}
              >
                <FormControlLabel
                  value="todo"
                  control={<Radio />}
                  label="Todo"
                />
              </RadioGroup>
            ) : GetItem?.type === "meeting" ? (
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={handleRadioChange}
                value={radioBtn}
              >
                <FormControlLabel
                  value="meeting"
                  control={<Radio />}
                  label="Meeting"
                />
              </RadioGroup>
            ) : (
              <>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={handleRadioChange}
                  value={radioBtn}
                >
                  <FormControlLabel
                    value="todo"
                    control={<Radio />}
                    label="Todo"
                  />
                  <FormControlLabel
                    value="meeting"
                    control={<Radio />}
                    label="Meeting"
                  />
                </RadioGroup>
              </>
            )}
            {/* </RadioGroup> */}
          </FormControl>
          {(() => {
            if (radioBtn === "todo") {
              return (
                <TodoForm
                  date={date}
                  toggleClose={toggleClose}
                  GetItem={GetItem}
                  ShowInfoModalClose={ShowInfoModalClose}
                  GetEventList={GetEventList}
                />
              );
            } else {
              return (
                <BopForm
                  toggleClose={toggleClose}
                  date={date}
                  GetItem={GetItem}
                  ShowInfoModalClose={ShowInfoModalClose}
                  GetEventList={GetEventList}
                />
              );
            }
          })()}
        </div>
      </div>
    </Modal>
  );
};
