import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

export default () => {
	const { membership_status } = useSelector(state => state.header.profileData)
	let access =
		localStorage.getItem('membership_status') == 1 || membership_status
			? true
			: false

	if (!access) {
		return <Navigate to={'/bot-membership'} />
	} else {
		return <Outlet />
	}
}
