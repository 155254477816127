import React from "react";
import { Modal, ModalBody } from "reactstrap";
import successImg from "../../assets/images/success.gif";

export function PlotBookingSuccess(props) {
  return (
    <Modal isOpen={props.successModal} toggleModal={props.toggleSuccess}>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="sucess-dep text-center">
                  <img
                    alt="cryptocurrency exchange"
                    src={successImg}
                    width={90} />
                  <h2 class="pt-3">Plot Booking Successful</h2>
                  <p class="pt-2">
                    Your plot has been booked. You may visit history to check
                    the order status
                  </p>
                  <div class="mt-3">
                    <div class="">
                      <div class="row">
                        <div class="col-md-6">
                          <button
                            class="btn btn-primary"
                            onClick={() => props.navigate("/user/dashboard")}
                          >
                            View Dashboard
                          </button>
                        </div>
                        <div class="col-md-6">
                          <button
                            class="btn btn-primary"
                            onClick={() => props.navigate(-1)}
                          >
                            Go Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
