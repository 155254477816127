import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	callPieChartAPI: 1
}

const botSlice = createSlice({
	name: 'bot_slice',
	initialState,
	reducers: {
		setCallPieChartAPI: state => {
			state.callBotRequestHistoryAPI += 1
		}
	}
})

export const { setCallPieChartAPI } = botSlice.actions
export default botSlice.reducer
