import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Helper from '../../Helper/Helper'
import SingleBotHistory from '../BotHistoryTables/SingleBotHistory'
import './bot-dashboard.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'
import {
	CheckNegativeValue,
	checkSingleObjectValue
} from '../../component/utils'
import CopyData2 from '../../component/Reusable/CopyData 2'
import classnames from 'classnames'

const ViewSingleBotInfo = () => {
	const [searchParams] = useSearchParams()
	const botId = searchParams.get('bot_id')
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [DealsInfo, setDealsInfo] = useState({})
	let Value = '-123'
	useEffect(() => getOrderInfo(), [])

	const getOrderInfo = () => {
		let params = { bot_id: botId }
		console.log('params: ', params)
		setLoading(true)
		Helper.PostData(
			'aabu',
			'user/botRouter/particular_bot_his_details',
			params,
			false
		)
			.then(res => {
				if (res.status) {
					setDealsInfo(res.data[0])
				}
			})
			.finally(() => setLoading(false))
	}

	return (
		<>
			<div className='bot-db'>
				<div class='dashboard-main'>
					<div class='container dash-container'>
						{/* <div className='row mt-3 mb-3'>
							<div className='col-lg-12'>
								<div className='portfolio-bot'>
									<div className='row mb-4'>
										<div className='col-lg-3 '>
											<div className='pt-1'>
												<h3>
													<FontAwesomeIcon
														icon={faArrowAltCircleLeft}
														onClick={() =>
															navigate('/bot/dashboard?activeTab=4')
														}
														title='Go back'
														className='cursor-pointer'
													/>
													<b className='ml-2'>
														Order <span className='text-primary'>Info</span>
													</b>
												</h3>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-lg-5'>
											<div className='d-flex justify-content-between align mb-2'>
												<span>Exchange</span>
												<span>
													{checkSingleObjectValue(DealsInfo.exchange_name)}
												</span>
											</div>
											<div className='d-flex justify-content-between align mb-2'>
												<span>Profit Sum Bot Id</span>
												<span>
													{
														<CopyData2
															data={DealsInfo.profit_sum_bot_id}
															formatValue={false}
														/>
													}
												</span>
											</div>
											<div className='d-flex justify-content-between align mb-2'>
												<span>Total Profit</span>
												<span>
													{DealsInfo.total_profit ? (
														<CheckNegativeValue
															value={+DealsInfo.total_profit.toFixed(4)}
															tag='span'
														/>
													) : (
														'---'
													)}
												</span>
											</div>
											<div className='d-flex justify-content-between align mb-2'>
												<span>Active Deals</span>
												<span>
													{checkSingleObjectValue(DealsInfo.actvie_deals)}
												</span>
											</div>
											<div className='d-flex justify-content-between align mb-2'>
												<span>Closed Deals</span>
												<span>
													{checkSingleObjectValue(DealsInfo.closed_deals)}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}
						<div className='row mt-3 mb-3'>
							<div className='col-lg-12'>
								<div className='row mb-4'>
									<div className='col-lg-3 '>
										<div className='pt-1'>
											<h3>
												<FontAwesomeIcon
													icon={faArrowAltCircleLeft}
													onClick={() =>
														navigate('/bot/dashboard?activeTab=4')
													}
													title='Go back'
													className='cursor-pointer'
												/>
												<b className='ml-2'>
													Order <span className='text-primary'>Info</span>
												</b>
											</h3>
										</div>
									</div>
								</div>
								<div className='row mt-3 mb-3 view-info'>
									<div className='col-lg-3 mb-2'>
										<div className='text-center card-bot-info'>
											<div className='mb-2'>
												<h6 className='mb-0'>Exchange</h6>
												<span>
													{checkSingleObjectValue(DealsInfo.exchange_name)}
												</span>
											</div>
											<div className=''>
												<h6 className='mb-0'>Profit Sum Id</h6>
												<span>
													{
														<CopyData2
															data={DealsInfo.profit_sum_bot_id}
															formatValue={false}
														/>
													}
												</span>
											</div>
										</div>
									</div>
									<div className='col-lg-3 mb-2'>
										<div className='d-flex justify-content-center align-items-center card-bot-info'>
											<div className=' tot-prof'>
												<h6>Total Profit</h6>
												{DealsInfo.eth_usdt_sum ? (
													<CheckNegativeValue
														value={(+DealsInfo.total_profit).toFixed(2)}
														tag='span'
														content='USD'
													/>
												) : (
													'---'
												)}
											</div>
										</div>
									</div>
									<div className='col-lg-3 mb-2'>
										<div className='card-bot-info all-deals'>
											<h6>Closed deals</h6>
											<span className='closed-deals'>
												{checkSingleObjectValue(DealsInfo.closed_deals)}
											</span>
											<span className='active-deals'>
												Active deals:{' '}
												{checkSingleObjectValue(DealsInfo.actvie_deals)}
											</span>
										</div>
									</div>
									<div className='col-lg-3 mb-2'>
										<div className='card-bot-info best-pairs'>
											<h6>Best pairs</h6>
											<div class='d-flex justify-content-between align-items-center mb-2'>
												<span className='bst-pair-id'>#1</span>
												<img src='' />
												<span className='bst-pair-name'>
													BTCUSDT<span className='bst-pair-id'>/USDT</span>
												</span>
												{DealsInfo.btc_usdt_sum ? (
													<CheckNegativeValue
														value={(+DealsInfo.btc_usdt_sum).toFixed(2)}
														tag='span'
														content='$'
													/>
												) : (
													'---'
												)}
											</div>
											<div class='d-flex justify-content-between align-items-center'>
												<span className='bst-pair-id'>#2</span>
												<img src='' />
												<span className='bst-pair-name'>
													ETHUSDT<span className='bst-pair-id'>/USDT</span>
												</span>
												{DealsInfo.eth_usdt_sum ? (
													<CheckNegativeValue
														value={(+DealsInfo.eth_usdt_sum).toFixed(2)}
														tag='span'
														content='$'
													/>
												) : (
													'---'
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<SingleBotHistory botId={botId} />
					</div>
				</div>
			</div>
		</>
	)
}

export default ViewSingleBotInfo
