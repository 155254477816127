import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch, TablePagination, Tooltip, Zoom } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Helper from '../../Helper/Helper'
import { TableLoader } from '../../component/Loaders'
import CopyData2 from '../../component/Reusable/CopyData 2'
import DatePickerReuse2 from '../../component/Reusable/DatePicker2Reuse'
import NoDataComponent from '../../component/Reusable/no-data-component'
import SearchReuse2 from '../../component/Reusable/searchReuse2'
import FilterSelect from '../../component/Reusable/selectReuse'
import moment from 'moment'
// import '../Bot/bot-dashboard.scss'

const BotHistory = () => {
	const initialPageData = { pageNo: 0, pageSize: 10 }
	const filterOptions = [
		{ name: 'All', value: 'all' },
		{ name: 'Enabled', value: 1 },
		{ name: 'Disabled', value: 2 }
	]
	const label = { inputProps: { 'aria-label': 'Enable or Disable Bot' } }

	const [botHistory, setBotHistory] = useState({ data: [], count: 0 })
	const [botHistoryloading, setBotHistoryLoading] = useState(false)

	const navigate = useNavigate()

	const [filters, setFilter] = useState({
		search_by: '',
		status: 'all',
		strategy_type: 'all',
		pair: 'all',
		start_date: '',
		end_date: '',
		...initialPageData
	})

	let botColumns = [
		'Bot Name',
		'Bot Id',
		'Pair',
		// 'Total Profit',
		'Exchange name',
		// 'Total Deals',
		// 'Active Deals',
		'Date & Time',
		'Status',
		'View'
	]

	useEffect(() => {
		getBotHistory()
	}, [filters])

	const getBotHistory = () => {
		setBotHistoryLoading(true)
		Helper.PostData('local', 'user/botRouter/bot_history', filters, false)
			.then(res => {
				if (res.status) {
					setBotHistory({ data: res.data, count: res.count })
				}
			})
			.finally(() => {
				setBotHistoryLoading(false)
			})
	}

	const handleStatusChange = (checked, bot_id, index) => {
		const toggleSwitch = success => {
			setBotHistory(prev => {
				let bot_history = [...prev.data]
				bot_history[index] = {
					...bot_history[index],
					bot_status: (() => {
						if (success) {
							return checked ? 1 : 0
						} else {
							return checked ? 0 : 1
						}
					})() // if staus false need to change initial value  of the switch
				}
				return { ...prev, data: bot_history }
			})
		}
		toggleSwitch(true)

		let params = {
			bot_id,
			reason: ''
		}
		let url = checked
			? 'user/botRouter/enable_bot'
			: 'user/botRouter/disable_bot'

		Helper.PostData('local', url, params, true).then(res => {
			if (res.status) {
			} else {
				toggleSwitch(false)
			}
		})
	}

	return (
		<>
			<div>
				<div className='trans-body'>
					<div className='trans-TabCnt'>
						<div className='stakingbonus-tabcnt'>
							<div className='d-flex justify-content-center'>
								<div className='bonus-Head'>
									<h5 className='text-end'>Bot History</h5>
								</div>
							</div>

							<div className='bonus-Body'>
								<div className='row mb-2'>
									<div className='col-lg-5'>
										<div className='row'>
											<FilterSelect
												setFilters={setFilter}
												className='col-lg-4'
												initialPageData={initialPageData}
												options={filterOptions}
											/>
											<FilterSelect
												setFilters={setFilter}
												className='col-lg-4'
												initialPageData={initialPageData}
												options={[
													{ name: 'All', value: 'all' },
													{ name: 'Short', value: 'short' },
													{ name: 'Long', value: 'long' }
												]}
												label='Strategy'
												filterKey='strategy_type'
											/>
											<FilterSelect
												setFilters={setFilter}
												className='col-lg-4'
												initialPageData={initialPageData}
												options={[
													{ name: 'All', value: 'all' },
													{ name: 'BTC/USDT', value: 'BTC/USDT' },
													{ name: 'ETH/USDT', value: 'ETH/USDT' }
												]}
												label='Pair'
												filterKey='pair'
											/>
										</div>
									</div>
									<DatePickerReuse2
										setFilters={setFilter}
										className='col-lg-2'
									/>
									<SearchReuse2
										setFilters={setFilter}
										className='col-lg-3'
										placeholder={'Bot Name/Bot Id'}
										initialPageData={initialPageData}
									/>
								</div>
								<div class='cmntbl'>
									<div className='table-responsive'>
										<table className='table bot-tbl'>
											<thead>
												<tr>
													{botColumns.map((item, index) => (
														<th index={index}>{item}</th>
													))}
												</tr>
											</thead>
											{botHistoryloading ? (
												<tbody>
													<tr>
														<td colSpan={botColumns.length}>
															<div className='tbl-load'>
																<TableLoader />
															</div>
														</td>
													</tr>
												</tbody>
											) : (
												<tbody>
													{botHistory?.data.length ? (
														botHistory.data.map((item, index) => {
															let title = 'Enable Bot'
															if (item.bot_status) {
																title = 'Disable Bot'
															}
															return (
																<tr key={index}>
																	<td className='dtails'>
																		<div className='bot-name'>
																			{/* <p>
																				<CopyData2
																					data={item.bot_name}
																					padding={17}
																				/>
																			</p> */}
																			<p>{item.bot_name}</p>
																			{/* <a className='txt-dec-none' href=''>
																					Details
																				</a> */}
																			{/* <br /> */}
																			<span
																				className={(() => {
																					if (item.strategy_type == 'long') {
																						return 'bdge bg-suc'
																					} else if (
																						item.strategy_type == 'short'
																					) {
																						return 'bdge bg-dang'
																					}
																				})()}
																			>
																				{item.strategy_type}
																			</span>
																			{/* <span className='trd-view-txt'>
																					Trading view custom signal
																				</span> */}
																		</div>
																	</td>
																	<td>
																		<CopyData2
																			data={item.bot_id}
																			formatValue={false}
																		/>
																	</td>
																	<td>{item.pair}</td>

																	{/* <td>
																		<CheckNegativeValue
																			value={item.finished_deals_profit_usd}
																		/>
																	</td> */}
																	<td>
																		<div>
																			{/* <p>{item.bot_name}</p> */}
																			<p>{item.exchange_name}</p>
																		</div>
																	</td>
																	{/* <td>{item.finished_deals_count}</td> */}
																	{/* <td>{item.active_deals_count}</td> */}
																	<td>
																		{moment(item.created_at).format(
																			'YYYY-MM-DD hh:mm:ss A'
																		)}
																	</td>
																	<td>
																		<Tooltip
																			id='toot-tip'
																			TransitionComponent={Zoom}
																			title={title}
																			arrow
																		>
																			<Switch
																				{...label}
																				// defaultChecked={item.status}
																				checked={item.bot_status}
																				onChange={({ target: { checked } }) =>
																					handleStatusChange(
																						checked,
																						item.bot_id,
																						index
																					)
																				}
																				size='small'
																			/>
																		</Tooltip>
																	</td>
																	<td>
																		<FontAwesomeIcon
																			icon={faExternalLink}
																			size='1x'
																			title='Open'
																			onClick={() => {
																				navigate(
																					`/bot/view-order-info?bot_id=${item.bot_id}`
																				)
																			}}
																			color='grey'
																			className='cursor-pointer icon-hov'
																		/>
																	</td>
																</tr>
															)
														})
													) : (
														<>
															<tr>
																<td colSpan={botColumns.length}>
																	<NoDataComponent />
																</td>
															</tr>
														</>
													)}
												</tbody>
											)}
										</table>
									</div>
									{botHistory?.data.length > 0 && (
										<TablePagination
											className='cust-cls'
											component='div'
											count={botHistory.count}
											showFirstButton={true}
											showLastButton={true}
											page={filters.pageNo}
											rowsPerPage={filters.pageSize}
											onPageChange={({ target: { value } }) =>
												setFilter(prev => ({ ...prev, pageNo: value }))
											}
											onRowsPerPageChange={({ target: { value } }) =>
												setFilter(prev => ({ ...prev, pageSize: value }))
											}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default BotHistory
