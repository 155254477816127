import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	botRequestModal: false,
	callBotRequestHistoryAPI: 0
}

const ModalSlice = createSlice({
	name: 'modal_slice',
	initialState,
	reducers: {
		setBotRequestModal: (state, action) => {
			state.botRequestModal = action.payload
		},
		setCallBotRequestHistoryAPI: state => {
			state.callBotRequestHistoryAPI += 1
		}
	}
})

export const { setBotRequestModal, setCallBotRequestHistoryAPI } =
	ModalSlice.actions
export default ModalSlice.reducer
