import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Helper from "../../../Helper/Helper";
import { SpinnerLoader, TableLoader } from "../../../component/Loaders";
import NoDataComponent from "../../../component/Reusable/no-data-component";
import { dataTableConfigs } from "../../../configs/dataTableConfigs";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import {
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CloseBtn from "../../../component/Reusable/CloseBtn";
import { Switch } from "@mui/material";
import cancel from "../../../assets/images/cancel.webp";
import DatePickerReuseCrm from "../../../component/Reusable/DatePickerReuse_crm";

const TodoHistory = () => {
  const initialPageData = { pageNo: 0, pageSize: 10 };
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      user_name: "",
      description: "",
      title: "",
      start_date: "",
    },
  });

  const {
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    control: control1,
    reset: reset1,
    register: register1,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      user_name: "",
      description: "",
      title: "",
      start_date: "",
    },
  });

  const [incubatorData, setIncubatorHistory] = useState({
    data: [],
    count: 0,
  });
  const fromMinDate = () => {
    let date = new Date();
    return date;
  };
  const [userDetails, setUserDetsils] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [StatusChange, setStatusChange] = useState(false);
  const [todoRowData, setTodoRowData] = useState();
  const [Loading, setLoading] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [DeleteDetails, setDeleteDetails] = useState({});
  const [filters, setFilter] = useState({
    start_date: "",
    end_date: "",
    search_by: "",
    pageSize: 10,
    pageNo: 0,
    toDo_type: "Self",
    ...initialPageData,
  });

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setFilter((prevFilters) => ({
        ...prevFilters,
        toDo_type: tab === "1" ? "Self" : "Team", // Update type based on tab
      }));
    }
  };

  const toggleDelete = (info) => {
    setModalDelete(!modalDelete);
    setDeleteDetails(info);
  };
  const toggleDeleteClose = () => {
    setModalDelete(false);
    setDeleteDetails({});
  };

  let GetItem = {};
  const [TodoLoad, setTodoLoad] = useState({
    add: false,
    update: false,
  });
  useEffect(() => {
    getStateHistory();
    GetUserRefDetails();
  }, [filters, activeTab]);
  const GetUserRefDetails = () => {
    Helper.GetData("flow-new", "user_crm/direct_ref").then((Res) => {
      if (Res.status) {
        setUserDetsils(Res.data);
      }
    });
  };
  const getStateHistory = () => {
    setLoading(true);
    Helper.PostData("flow-new", "user_crm/toDo_list", filters, false)
      .then((res) => {
        if (res.status) {
          setIncubatorHistory({
            data: res.data,
            count: res.toDo_count,
          });
        } else {
          setIncubatorHistory({
            data: [],
            count: 0,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  //Handle Delete
  const handleDelete = () => {
    let param = {
      toDo_id: DeleteDetails?.id,
      method: "Remove",
    };
    Helper.PostData("flow-new", "user_crm/edit_toDo", param, true)
      .then((res) => {
        if (res.status) {
          toggleDeleteClose();
          setLoading(false);
          getStateHistory();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },

    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      cell: (row, index) => <td className="text-center">{row.title}</td>,
    },
    {
      name: "Description",
      sortable: true,
      selector: (row) => row.description,
      cell: (row, index) => <td className="text-center">{row.description}</td>,
    },
    {
      name: "Allocated By",
      sortable: true,
      selector: (row) => row.added_userName,
      cell: (row, index) => (
        <td className="text-center">
          {row.added_userName}
        </td>
      ),
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
      cell: (row) => {
        if (row.status == 0 || !row.status) {
          return <div className=" badge text-warn">Pending</div>;
        } else {
          return <div className="badge bg-success">Completed</div>;
        }
      },
    },
    {
      name: "Date",
      sortable: true,
      selector: (row) => moment(row?.date).format("YYYY-MM-DD"),
      cell: (row, index) => (
        <td className="text-center">
          {moment(row?.date).format("YYYY-MM-DD")}
        </td>
      ),
    },
    {
      name: "Action",
      sortable: true,
      selector: (row) => row.action,
      cell: (row, index) => (
        <>
          <td className="">
            <FontAwesomeIcon
              className="cursor-pointer"
              icon={faEye}
              size="sm"
              onClick={() => toggleAddUpdate(row)}
            />
            {row?.status == 0 ? (
              <FontAwesomeIcon
                icon={faTrash}
                size="sm"
                color="red"
                className="ml-2 cursor-pointer"
                onClick={() => toggleDelete(row)}
                title="Delete"
              />
            ) : (
              <FontAwesomeIcon
                icon={faTrash}
                size="sm"
                color="red"
                className="ml-2"
                title="Delete"
                style={{ visibility: "hidden" }}
              />
            )}
          </td>
        </>
      ),
    },
  ];

  const column =
    filters?.toDo_type == "Self"
      ? columns
      : columns.filter((col) => col.name !== "Allocated By");

  //Add Modal Todo
  const [modal, setModal] = useState(false);
  const [TabType, setTabType] = useState(false);
  const toggleAdd = (type) => {
    setModal(!modal);
    setTabType(type);
  };
  const toggleClose = () => {
    setModal(false);
    reset({
      user_name: "",
      description: "",
      title: "",
      start_date: "",
    });
  };
  const HandleSubmitTodo = (data) => {
    setTodoLoad((prev) => ({
      ...prev,
      add: true,
    }));
    let selfParam = {
      date: moment(data.start_date).format("YYYY-MM-DD"),
      description: data.description,
      title: data.title,
      toDo_type: "Self",
      method: "Add",
    };
    let teamParam = {
      date: moment(data.start_date).format("YYYY-MM-DD"),
      description: data.description,
      title: data.title,
      toDo_type: "Team",
      method: "Add",
      direct_ref_random_id: data.direct_ref_random_id,
    };
    Helper.PostData(
      "flow-new",
      "user_crm/edit_toDo",
      TabType !== "Team" ? selfParam : teamParam,
      true
    )
      .then((res) => {
        if (res.status) {
          toggleClose();
          reset();
          setTodoLoad((prev) => ({
            ...prev,
            add: false,
          }));
          getStateHistory();
        }
      })
      .finally(() => {
        setTodoLoad((prev) => ({
          ...prev,
          add: false,
        }));
      });
  };

  // update todo
  const [modalUpdate, setModalUpdate] = useState(false);
  const toggleAddUpdate = (rowData) => {
    setModalUpdate(!modalUpdate);
    setTodoRowData(rowData);
  };
  const toggleCloseUpdate = () => {
    setModalUpdate(false);
    reset1();
    setStatusChange();
  };
  //Status Change
  const handleStatusChange = (check) => {
    setStatusChange(check);
  };
  const HandleSubmitTodoUpdate = (data) => {
    setTodoLoad((prev) => ({
      ...prev,
      update: true,
    }));

    let selfParam = {
      date: moment(data.start_date).format("YYYY-MM-DD"),
      description: data.description,
      title: data.title,
      toDo_type: "Self",
      method: "Edit",
      direct_ref_random_id: data.direct_ref_random_id,
      toDo_id: todoRowData?.id,
      status: StatusChange ? 1 : 0,
    };
    let teamParam = {
      date: moment(data.start_date).format("YYYY-MM-DD"),
      description: data.description,
      title: data.title,
      toDo_type: "Team",
      method: "Edit",
      direct_ref_random_id: todoRowData?.user_random_id,
      toDo_id: todoRowData?.id,
      status: StatusChange ? 1 : 0,
    };

    // let param = {
    //   date: moment(data.start_date).format("YYYY-MM-DD"),
    //   description: data.description,
    //   title: data.title,
    //   toDo_type: "Team",
    //   method: "Edit",
    //   direct_ref_random_id: data.direct_ref_random_id,
    //   // toDo_id: GetItem?.id,
    //   // status: StatusChange ? 1 : 0,
    // };
    Helper.PostData(
      "flow-new",
      "user_crm/edit_toDo",
      filters?.toDo_type == "Self" ? selfParam : teamParam,
      true
    )
      .then((res) => {
        if (res.status) {
          toggleClose();
          reset1();
          setModalUpdate(false);
          getStateHistory();
          setTodoLoad((prev) => ({
            ...prev,
            update: false,
          }));
        }
      })
      .finally(() => {
        setTodoLoad((prev) => ({
          ...prev,
          update: false,
        }));
      });
  };

  useEffect(() => {
    if (todoRowData) {
      reset1({
        user_name: todoRowData.user_name || "",
        description: todoRowData.description || "",
        title: todoRowData.title || "",
        start_date: todoRowData.date || "",
      });
    }
  }, [todoRowData, reset1]);

  return (
    <div>
      <div className="trans-body">
        <div className="trans-TabCnt">
          <div className="stakingbonus-tabcnt">
            <div className="bonus-Head">
              <h5>Todo</h5>
            </div>
            <div className="bonus-Body">
              <div className="dashboard-navs">
                <div className="row px-2">
                  <div className="col-lg-4">
                    <Nav tabs className="cursor-pointer">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          Self Todo
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          Team Todo
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                  <div className="col-lg-4"></div>
                </div>
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={"1"}>
                  <div className="cmntbl mt-4">
                    <div className="table-responsive">
                      <div className="row mb-4">
                        <DatePickerReuseCrm
                          setFilters={setFilter}
                          className="col-lg-4"
                        />
                        <div className="col-lg-4 text-right">
                          <button
                            className="btn btn-primary"
                            onClick={() => toggleAdd()}
                          >
                            + Add Todo
                          </button>
                        </div>
                      </div>
                      <DataTable
                        columns={column}
                        className="text-center"
                        data={incubatorData.data}
                        paginationTotalRows={incubatorData.count}
                        customStyles={dataTableConfigs}
                        pagination={true}
                        paginationServer
                        onChangePage={(pageNo) => {
                          setFilter((prev) => ({
                            ...prev,
                            pageNo: pageNo - 1,
                          }));
                        }}
                        progressPending={Loading}
                        progressComponent={<TableLoader />}
                        noDataComponent={<NoDataComponent />}
                        onChangeRowsPerPage={(pageSize) => {
                          setFilter((prev) => ({ ...prev, pageSize }));
                        }}
                        persistTableHead={true}
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId={"2"}>
                  <div className="cmntbl mt-4">
                    <div className="table-responsive">
                      <div className="row mb-4">
                        <DatePickerReuseCrm
                          setFilters={setFilter}
                          className="col-lg-4"
                        />
                        <div className="col-lg-4 text-right">
                          <button
                            className="btn btn-primary"
                            onClick={() => toggleAdd("Team")}
                          >
                            + Add Todo
                          </button>
                        </div>
                      </div>
                      <DataTable
                        columns={column}
                        className="text-center"
                        data={incubatorData.data}
                        paginationTotalRows={incubatorData.count}
                        customStyles={dataTableConfigs}
                        pagination={true}
                        paginationServer
                        onChangePage={(pageNo) => {
                          setFilter((prev) => ({
                            ...prev,
                            pageNo: pageNo - 1,
                          }));
                        }}
                        progressPending={Loading}
                        progressComponent={<TableLoader />}
                        noDataComponent={<NoDataComponent />}
                        onChangeRowsPerPage={(pageSize) => {
                          setFilter((prev) => ({ ...prev, pageSize }));
                        }}
                        persistTableHead={true}
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                      />
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
      {/* ToDo Add self/Team  */}
      <Modal
        isOpen={modal}
        toggle={toggleAdd}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleClose}
          close={<CloseBtn onClick={() => toggleClose()} />}
        >
          Todo Form
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit(HandleSubmitTodo)} autoComplete="off">
            <div className="form-group formInputs loginInput mb-4">
              <label>
                Title<span className="text-primary">*</span>
              </label>
              <input
                type="text"
                name="title"
                className="form-control form-control-lg"
                placeholder="Enter Title"
                {...register("title", {
                  required: "* Title is required",
                })}
              />
              {errors.title && (
                <p className="text-danger">{errors.title?.message}</p>
              )}
            </div>
            {TabType == "Team" && (
              <div className="form-group formInputs loginInput mb-4">
                <label>
                  User Name<span className="text-primary">*</span>
                </label>
                <select
                  className="form-control form-control-lg"
                  name="direct_ref_random_id"
                  placeholder="Select User"
                  {...register("direct_ref_random_id", {
                    required: "* User Name is required",
                  })}
                >
                  <option value="">Select User</option>
                  {userDetails?.map((item, index) => (
                    <option
                      value={item.userrandomid}
                      key={index}
                      selected={GetItem?.user_name == item.username}
                    >
                      {item.username}
                    </option>
                  ))}
                </select>
                {errors.direct_ref_random_id && (
                  <p className="text-danger">
                    {errors.direct_ref_random_id?.message}
                  </p>
                )}
              </div>
            )}
            <div className="form-group formInputs loginInput mb-4">
              <label>
                Description<span className="text-primary">*</span>
              </label>
              <textarea
                class="form-control"
                rows="5"
                name="description"
                placeholder="Enter Description"
                formControlName="message"
                {...register("description", {
                  required: "* Description is required",
                  pattern: {
                    value: /[/^\S]/,
                    message: "* Whitespace not allowed ",
                  },
                })}
                autocomplete="off"
              ></textarea>
              {errors.description && (
                <p className="text-danger">{errors.description.message}</p>
              )}
            </div>
            <div class="wallet-in-ser formInputs walletInput">
              <label>
                Start Date<span className="text-primary">*</span>
              </label>
              <div class="input-login-1  ml-lg-2">
                <Controller
                  name="start_date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => {
                    const selectedStartDate = value ? new Date(value) : null;
                    return (
                      <ReactDatePicker
                        className="form-control"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={selectedStartDate}
                        minDate={fromMinDate()}
                        dropdownMode="select"
                        peekNextMonth
                        showTimeSelect={false}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                      />
                    );
                  }}
                />
                {errors.start_date && (
                  <p>
                    {errors.start_date.type === "required" ? (
                      <p className="text-danger">* Start Date is required</p>
                    ) : (
                      <p className="text-danger">{errors.start_date.message}</p>
                    )}
                  </p>
                )}
              </div>
            </div>
            <div className="btn-todo text-center mt-3">
              {TodoLoad?.add ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled={TodoLoad?.add}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              )}
            </div>
          </Form>
        </ModalBody>
      </Modal>
      {/* ToDo Update self/Team  */}
      <Modal
        isOpen={modalUpdate}
        toggle={toggleAddUpdate}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleCloseUpdate}
          close={<CloseBtn onClick={() => toggleCloseUpdate()} />}
        >
          Todo Form
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={handleSubmit1(HandleSubmitTodoUpdate)}
            autoComplete="off"
          >
            <div className="form-group formInputs loginInput mb-4">
              <label>
                Title<span className="text-primary">*</span>
              </label>
              <input
                type="text"
                name="title"
                className="form-control form-control-lg"
                placeholder="Enter Title"
                defaultValue={todoRowData?.title}
                disabled={
                  (todoRowData?.edit_status === 1 &&
                    todoRowData?.status == 1) ||
                  todoRowData?.edit_status === 0
                }
                {...register1("title", {
                  required: "* Title is required",
                })}
              />
              {errors1.title && (
                <p className="text-danger">{errors1.title?.message}</p>
              )}
            </div>

            {filters?.toDo_type == "Team" && (
              <div className="form-group formInputs loginInput mb-4">
                <label>
                  User Name<span className="text-primary">*</span>
                </label>
                <select
                  className="form-control form-control-lg"
                  name="direct_ref_random_id"
                  placeholder="Select Type"
                  disabled={todoRowData?.user_name}
                  {...register1("direct_ref_random_id", {
                    required: false,
                  })}
                >
                  <option value="">Select Type</option>
                  {userDetails?.map((item, index) => (
                    <option
                      value={item.userrandomid}
                      key={index}
                      selected={todoRowData?.user_name == item.username}
                    >
                      {item.username}
                    </option>
                  ))}
                </select>
                {errors1.direct_ref_random_id && (
                  <p className="text-danger">
                    {errors1.direct_ref_random_id?.message}
                  </p>
                )}
              </div>
            )}

            <div className="form-group formInputs loginInput mb-4">
              <label>
                Description<span className="text-primary">*</span>
              </label>
              <textarea
                class="form-control"
                rows="5"
                name="description"
                placeholder="Enter Description"
                defaultValue={todoRowData?.description}
                disabled={
                  (todoRowData?.edit_status === 1 &&
                    todoRowData?.status == 1) ||
                  todoRowData?.edit_status === 0
                }
                formControlName="message"
                {...register1("description", {
                  required: "* Description is required",
                  pattern: {
                    value: /[/^\S]/,
                    message: "* Whitespace not allowed ",
                  },
                })}
                autocomplete="off"
              ></textarea>
              {errors1.description && (
                <p className="text-danger">{errors1.description.message}</p>
              )}
            </div>
            <div class="wallet-in-ser formInputs walletInput">
              <label>
                Start Date<span className="text-primary">*</span>
              </label>
              <div class="input-login-1  ml-lg-2">
                <Controller
                  name="start_date"
                  control={control1}
                  rules={{ required: true }}
                  defaultValue={moment(todoRowData?.date).format("YYYY-MM-DD")}
                  render={({ field: { onChange, onBlur, value } }) => {
                    const selectedStartDate = value ? new Date(value) : null;
                    return (
                      <ReactDatePicker
                        className="form-control"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={selectedStartDate}
                        minDate={fromMinDate()}
                        dropdownMode="select"
                        peekNextMonth
                        showTimeSelect={false}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        disabled={
                          (todoRowData?.edit_status === 1 &&
                            todoRowData?.status == 1) ||
                          todoRowData?.edit_status === 0
                        }
                      />
                    );
                  }}
                />
                {errors1.start_date && (
                  <p>
                    {errors1.start_date.type === "required" ? (
                      <p className="text-danger">* Start Date is required</p>
                    ) : (
                      <p className="text-danger">
                        {errors1.start_date.message}
                      </p>
                    )}
                  </p>
                )}
              </div>
            </div>
            <br />
            <div className="form-group formInputs loginInput mb-4">
              <label>
                Todo Status<span className="text-primary">*</span>
              </label>
              <div className="d-flex align-items-center justify-content-between">
                {todoRowData?.status == 0 && (
                  <Switch
                    checked={StatusChange}
                    onChange={({ target: { checked } }) =>
                      handleStatusChange(checked)
                    }
                    size="small"
                  />
                )}
                {todoRowData?.status == 0 ? (
                  <p className="text-warning">Pending</p>
                ) : (
                  <p className="text-success">Completed</p>
                )}
              </div>
            </div>
            <div className="btn-todo text-center mt-3">
              {todoRowData?.toDo_type === "Self" &&
                todoRowData?.edit_status === 1 && (
                  <>
                    {TodoLoad?.update ? (
                      <button
                        className="btn btn-primary"
                        type="button"
                        disabled={TodoLoad?.update}
                      >
                        <SpinnerLoader />
                      </button>
                    ) : (
                      <button
                        hidden={todoRowData?.status === 1}
                        className="btn btn-primary"
                        type="submit"
                      >
                        Update
                      </button>
                    )}
                  </>
                )}
              {todoRowData?.toDo_type === "Team" && (
                <>
                  {TodoLoad?.update ? (
                    <button
                      className="btn btn-primary"
                      type="button"
                      disabled={TodoLoad?.update}
                    >
                      <SpinnerLoader />
                    </button>
                  ) : (
                    <button
                      hidden={todoRowData?.status === 1}
                      className="btn btn-primary"
                      type="submit"
                    >
                      Update
                    </button>
                  )}
                </>
              )}
            </div>
          </Form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalDelete}
        toggle={toggleDelete}
        className={"modal-md modal-dialog-centered mymodal"}
        size={"sm"}
        backdrop="static"
        keyboard={false}
      >
        <ModalBody className="p-4">
          <div className="text-center mb-2">
            <img src={cancel} width={"50%"} />
          </div>
          <p className="text-center">
            <b>Do you want to remove {DeleteDetails?.type}?</b>
            <br />
            <b>{DeleteDetails?.title}</b>
          </p>
          <div className="d-flex justify-content-between mt-3">
            <div>
              <button
                type="button"
                class="btn-exg  px-4"
                onClick={toggleDeleteClose}
              >
                Cancel
              </button>
            </div>
            <div>
              {!Loading ? (
                <button
                  type="button"
                  class="btn-exg-del px-4"
                  onClick={() => handleDelete()}
                >
                  Delete
                </button>
              ) : (
                <button
                  type="button"
                  class="btn-exg-del px-4"
                  disabled={Loading}
                >
                  <SpinnerLoader />
                </button>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TodoHistory;
