import { faThList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Badge from "@mui/material/Badge";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  Row,
  // NavLink,
  UncontrolledDropdown,
} from "reactstrap";
import Helper, { clearAll } from "../../Helper/Helper";
import contestImg from "../../assets/images/DashIcons/contest.webp";
import exchangeImg from "../../assets/images/DashIcons/exchange.webp";
import incubatorImg from "../../assets/images/DashIcons/incubator.webp";
import plotbookImg from "../../assets/images/DashIcons/plotbook.webp";
import supportImg from "../../assets/images/DashIcons/support.webp";
import WaletImg from "../../assets/images/bannerAnnounce.webp";
import profileimg from "../../assets/images/dash-profile.svg";
import image from "../../assets/images/server_maintain.png";
import QRCodeGenerator from "../../pages/QrCodegenerator/QrcodeGenerator";
import InvestorModal from "../../pages/modalpage/Investor";
import StakingModal from "../../pages/modalpage/staking";
import "./NewHeader.scss";
import { setProfileData, setSiteDetails } from "./headerslice";
import { useForm } from "react-hook-form";
import { SpinnerLoader } from "../Loaders";
import { NotificationManager } from "react-notifications";

function NewHeader() {
  // header scroll start
  const [scroll, setScroll] = useState(false);
  const [isMaintan, setisMaintain] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
    reset,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const toggleMaintain = () => {
    setisMaintain(!isMaintan);
  };
  const [showPop, setShowPop] = useState(false);
  const togglePop = () => {
    setShowPop(!showPop);
  };
  const togglePopClose = () => {
    setShowPop(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  // header scroll end
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [GetSiteRes, setGetSiteRes] = useState();
  const { PostData } = Helper;
  const logAccess = localStorage.getItem("logaccess");
  const membership_status = localStorage.getItem("membership_status");
  const Profile = useSelector((state) => state.header.profileData);
  const stakeModal = useSelector((state) => state.header.stakeModal);
  const investorModal = useSelector((state) => state.header.investors);
  const callProfileApi = useSelector((state) => state.header.callProfileApi);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 15);
    });
  }, []);
  // header scroll end

  useEffect(() => {
    if (logAccess) {
      getDashboardDetails();
    }
  }, [callProfileApi]);

  useEffect(() => {
    getSiteDetails();
    getState();
  }, []);
  const [UpdateCityModal, setUpdateCityModal] = useState(false);
  const [ToggleLoad, setToggleLoad] = useState(false);
  const [DistrictName, GetDistrictName] = useState([]);
  const [StateName, GetStateName] = useState([]);
  const toggleUpdateCity = () => {
    setUpdateCityModal(!UpdateCityModal);
    setValue("state_name", "");
  };
  const toggleUpdateCityClose = () => {
    setUpdateCityModal(false);
    reset();
  };
  const getState = () => {
    Helper.GetData("user", "user/get_states").then((Res) => {
      if (Res.status) {
        GetStateName(Res.data);
        // getDistrict()
      }
    });
  };
  let GetWatchOfState = watch("state_name");
  useEffect(() => {
    if (GetWatchOfState) {
      let parseStateOfWatch = JSON.parse(GetWatchOfState);
      getDistrict(parseStateOfWatch?.id);
    }
  }, [GetWatchOfState]);
  const getDistrict = (value) => {
    Helper.PostData(
      "user",
      "user/get_district",
      { state_id: value },
      false
    ).then((Res) => {
      if (Res.status) {
        GetDistrictName(Res.data);
      }
    });
  };
  const getDashboardDetails = () => {
    setIsLoading(true);
    PostData("user", "user/getuserprofile", {}, false)
      .then((res) => {
        if (res.status) {
          dispatch(setProfileData(res.user[0]));
          localStorage.setItem(
            "membership_status",
            res.user[0].membership_status
          );
          if (
            res.user[0]?.district_name == "" &&
            res.user[0]?.dial_code !== "+91"
          ) {
            toggleUpdateCity();
          }
        }
      })
      .finally(() => setIsLoading(false));
  };

  const getSiteDetails = () => {
    setIsLoading(true);
    Helper.GetData("user", "user/getsite", {}, false)
      .then((resp) => {
        if (resp.status) {
          dispatch(setSiteDetails(resp.data[0]));
          if (!localStorage.getItem("maint")) {
            localStorage.setItem("maint", true);
            if (resp.maintenance_status == 1) {
              toggleMaintain();
            } else {
              // togglePop()
            }
          }
        }
      })
      .finally(() => setIsLoading(false));
  };
  const OnSubmitPersonalInfo = (data) => {
    let parseData = JSON.parse(data?.district_name);
    let parseStateData = JSON.parse(data?.state_name);
    let param = {
      district_name: parseData?.district_name,
      id: parseData?.id,
      state_name: parseStateData?.state_name,
    };
    setToggleLoad(true);
    Helper.PostData("user", "user/update_district", param, true)
      .then((res) => {
        if (res.status) {
          toggleUpdateCityClose();
        }
      })
      .finally(() => {
        setToggleLoad(false);
      });
  };
  return (
    <>
      <header className={scroll ? "navbgset" : ""}>
        <Navbar dark expand="xl" className="mynavbar px-xl-0" fixed="top">
          <div className="container">
            {" "}
            <NavbarBrand href="/">
              <img
                src="/kpklogo.webp"
                className="logo"
                alt="brandlogo"
                width="211"
                height="29"
              />
            </NavbarBrand>
            <NavbarToggler
              onClick={toggle}
              className={isOpen ? "custom-nav-menu opened" : "custom-nav-menu"}
            >
              <svg width="40" height="40" viewBox="0 0 100 100">
                <path
                  className="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                />
                <path className="line line2" d="M 20,50 H 80" />
                <path
                  className="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                />
              </svg>
            </NavbarToggler>
            <Collapse isOpen={isOpen} navbar>
              <Nav className="mr-auto ml-3 navbar-nav " navbar>
                <UncontrolledDropdown
                  setActiveFromChild
                  className="nav-item kp_hdmenufrp_cry"
                >
                  <DropdownToggle
                    tag="a"
                    className="nav-link"
                    caret
                    href="#"
                    aria-label="Toggle Main Menu"
                  >
                    <span>
                      <FontAwesomeIcon icon={faThList} className="text-white" />
                    </span>
                  </DropdownToggle>
                  <DropdownMenu className="dropdownmenucustom">
                    <div className="row">
                      <div className="col-xl-4">
                        <Link className="vric1 hrfef" to="/user/contest">
                          <div className="vric1in1 ">
                            <img
                              alt="cryptocurrency exchange"
                              className="lithe animate__tada  animate__animated animate__infinite animate__slow"
                              src={contestImg}
                            />
                            <img
                              alt="cryptocurrency exchange"
                              className="drthe animate__tada  animate__animated animate__infinite animate__slow"
                              src={contestImg}
                            />
                          </div>
                          <div>
                            <h1>Contest</h1>
                            <p>
                              Accomplish your targets and earn significant
                              prizes.
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="col-xl-4">
                        <Link
                          className="vric1 hrfef nav-link"
                          to="/user/support"
                        >
                          <div className="vric1in1 ">
                            <img
                              alt="cryptocurrency exchange"
                              className="lithe animate__tada  animate__animated animate__infinite animate__slow"
                              src={supportImg}
                            />
                            <img
                              alt="cryptocurrency exchange"
                              className="drthe animate__tada  animate__animated animate__infinite animate__slow"
                              src={supportImg}
                            />
                          </div>
                          <div>
                            <h1>Support Ticket</h1>
                            <p>Raise a ticket and solve your queries.</p>
                          </div>
                        </Link>
                      </div>
                      <div className="col-xl-4">
                        <Link className="vric1 hrfef" to="/user/exchange">
                          <div className="vric1in1 ">
                            <img
                              alt="cryptocurrency exchange"
                              className="lithe animate__tada  animate__animated animate__infinite animate__slow"
                              src={exchangeImg}
                            />
                            <img
                              alt="cryptocurrency exchange"
                              className="drthe animate__tada  animate__animated animate__infinite animate__slow"
                              src={exchangeImg}
                            />
                          </div>
                          <div>
                            <h1>Exchange</h1>
                            <p>
                              KpkPark: A Global Trusted Crypto Exchange
                              Platform.
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="col-xl-4">
                        <Link className="vric1 hrfef" to="/user/plotbook">
                          <div className="vric1in1 ">
                            <img
                              alt="cryptocurrency exchange"
                              className="lithe animate__tada  animate__animated animate__infinite animate__slow"
                              src={plotbookImg}
                            />
                            <img
                              alt="cryptocurrency exchange"
                              className="drthe animate__tada  animate__animated animate__infinite animate__slow"
                              src={plotbookImg}
                            />
                          </div>
                          <div>
                            <h1>PlotBook</h1>
                            <p>
                              Buy land, build dreams, and rule the metaverse as
                              a politician.
                            </p>
                          </div>
                        </Link>
                      </div>
                      <div className="col-xl-4">
                        <Link className="vric1 hrfef" to="/sub-incubator">
                          <div className="vric1in1 ">
                            <img
                              alt="cryptocurrency exchange"
                              className="lithe animate__tada  animate__animated animate__infinite animate__slow"
                              src={incubatorImg}
                            />
                            <img
                              alt="cryptocurrency exchange"
                              className="drthe animate__tada  animate__animated animate__infinite animate__slow"
                              src={incubatorImg}
                            />
                          </div>
                          <div>
                            <h1>Incubator</h1>
                            <p>Purchase Incubator for various benefits.</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <>
                  <NavItem>
                    <NavLink className="nav-link" to="/user/dashboard">
                      <span>Stake</span>
                    </NavLink>
                    {/* </Badge> */}
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-link" to="/user/wallet">
                      <span>Wallet</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="nav-link" to="/crm/dashboard">
                      <span>CRM</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    {(() => {
                      if (logAccess && membership_status == 1) {
                        //  ||Profile.membership_status == 1)
                        return (
                          <Badge badgeContent={"Beta"} color="secondary">
                            <NavLink className="nav-link" to="/bot/dashboard">
                              <div>
                                <span>Bot</span>
                              </div>
                            </NavLink>
                          </Badge>
                        );
                      } else {
                        return (
                          <Badge badgeContent={"Beta"} color="secondary">
                            <NavLink className="nav-link" to="/bot-membership">
                              <span>Bot</span>
                            </NavLink>
                          </Badge>
                        );
                      }
                    })()}
                  </NavItem>
                </>
              </Nav>
              {!logAccess ? (
                <>
                  <NavbarText>
                    <button
                      className="btn btn-primary btnheader ml-2 mb-2 mb-md-0 text-light"
                      onClick={() => navigate("/oauth/register")}
                    >
                      Register
                    </button>
                    <button
                      className="btn btn-primary btnheader ml-2 mb-2 mb-md-0 text-light"
                      onClick={() => navigate("/oauth/login")}
                    >
                      Login
                    </button>
                  </NavbarText>
                </>
              ) : (
                <>
                  <NavbarText>
                    <div className="menuprofile d-inline-block ml-2">
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          <img
                            src={Profile?.profile_img || profileimg}
                            alt=""
                            className="img-fluid"
                            style={{
                              height: "40px",
                            }}
                          />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={() => clearAll()}>
                            <Link to={"#"}>Logout</Link>
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </NavbarText>
                </>
              )}
              <NavbarText>
                <div className="menuprofile d-md-inline-block ml-2">
                  <li className="nav-item">
                    <UncontrolledDropdown className="text-center drpdnji">
                      <DropdownToggle className="kp_hddrp">
                        <button className="btn btn-primary btnheader ml-2 mb-2 mb-md-0 btn-dwn">
                          Download App
                        </button>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-right" right>
                        <div className="scq_ji">
                          <h2>
                            Scan <span>QR Code </span> to Download App
                          </h2>
                          <Row className="justify-content-center">
                            <Col md={6}>
                              <img
                                src={require("../../assets/images/androidKPK1.webp")}
                                className="img-fluid"
                              />
                              <p className="mt-2">Android</p>
                            </Col>
                            <Col md={6}>
                              <img
                                src={require("../../assets/images/iosKPK.webp")}
                                className="img-fluid"
                              />
                              <p className="mt-2">IOS</p>
                            </Col>
                          </Row>
                        </div>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </li>
                </div>
              </NavbarText>
            </Collapse>
          </div>
        </Navbar>
        {stakeModal && <StakingModal />}
        {investorModal && <InvestorModal />}
        <Modal
          isOpen={isMaintan}
          toggleModal={toggleMaintain}
          centered
          size="sm"
        >
          <ModalBody>
            <div className="trd_anno">
              <div className="trd_img" style={{ textAlign: "center" }}>
                <img
                  src={image}
                  className="img-fluid"
                  style={{ width: "50%" }}
                />
              </div>
              <h2 className="text-light text-center">
                KPKPark Maintenance Mode
              </h2>
              <p
                className="text-light text-left mt-3"
                style={{ fontSize: "13px" }}
              >
                Dear users, <br />
                <span
                  className="text-light text-center mt-2"
                  style={{ fontSize: "13px" }}
                >
                  We're currently working on enhancing your KPKPark experience!
                  Please bear with us as we make important updates. We'll be
                  back soon with exciting new features and improvements. Thank
                  you for your patience!
                </span>
              </p>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          className="wlt-notifi"
          size="lg"
          centered
          isOpen={showPop}
          toggle={togglePop}
        >
          <ModalBody>
            <div className="position-relative">
              <span
                className="close_pop cursor-pointer text-white"
                onClick={togglePopClose}
              >
                x
              </span>

              <img
                src={WaletImg}
                className="img-fluid"
                onClick={togglePopClose}
              />
            </div>
          </ModalBody>
        </Modal>
        <Modal
          className=""
          size="sm"
          centered
          isOpen={UpdateCityModal}
          toggleModal={toggleUpdateCity}
        >
          <ModalHeader>Update Personal Details</ModalHeader>
          <ModalBody>
            <div className="">
              <div className="row">
                <Form
                  onSubmit={handleSubmit(OnSubmitPersonalInfo)}
                  autoComplete="off"
                >
                  <div className="col-12">
                    <div className="form-group formInputs loginInput mb-4">
                      <label>
                        State<span className="text-primary">*</span>
                      </label>
                      <select
                        className="form-control form-control-lg"
                        name="state_name"
                        placeholder="Select State"
                        {...register("state_name", {
                          required: "* State is required",
                        })}
                      >
                        <option value="">Select State Name</option>
                        {StateName?.map((item, index) => (
                          <option value={JSON.stringify(item)} key={index}>
                            {item.state_name}
                          </option>
                        ))}
                      </select>
                      {errors.state_name && (
                        <p className="text-danger">
                          {errors.state_name?.message}
                        </p>
                      )}
                    </div>
                    <div className="form-group formInputs loginInput mb-4">
                      <label>
                        District<span className="text-primary">*</span>
                      </label>
                      <select
                        className="form-control form-control-lg"
                        name="district_name"
                        placeholder="Select District"
                        {...register("district_name", {
                          required: "* District Name is required",
                        })}
                      >
                        <option value="">Select District Name</option>
                        {DistrictName?.map((item, index) => (
                          <option value={JSON.stringify(item)} key={index}>
                            {item.district_name}
                          </option>
                        ))}
                      </select>
                      {errors.district_name && (
                        <p className="text-danger">
                          {errors.district_name?.message}
                        </p>
                      )}
                    </div>
                    <div className="note-personal-update">
                      <p className="text-danger">
                        Note :
                        <span className="ml-2">
                          Kindly update your state and district for reporting
                          purpose.
                        </span>
                      </p>
                    </div>
                    <div className="text-center mt-3">
                      {ToggleLoad ? (
                        <button
                          className="btn btn-primary text-center"
                          type="button"
                          disabled={ToggleLoad}
                        >
                          <SpinnerLoader />
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary text-center"
                          type="submit"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </header>
    </>
  );
}

export default NewHeader;
