import React from "react";
import OtpInput from "react-otp-input";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import Helper from "../../Helper/Helper";

export function PlotBookingOtpVerify(props) {
  return (
    <Modal
      className="ver_pop "
      size="sm"
      isOpen={props.otpModal}
      toggleModal={props.toggleOtp}
    >
      <ModalHeader toggle={props.toggleCloseOtp}>
        <h5 className="text-center text-light">OTP Verification</h5>
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between">
          <p className="mt-2 text-light">OTP Sent To</p>
          <p className="mt-2 text-light">
            <b>{Helper.formatEmailAddress(props.profile?.email)}</b>
          </p>
        </div>
        <div className="otp">
          <label className="mt-3 text-light">Enter the 6 digit code</label>
          <Form onSubmit={props.handleSubmit(props.AdvancePlotBooking)}>
            <div className="mt-3">
              <OtpInput
                value={props.otp}
                onChange={(otp) => {
                  props.setOtp(otp);
                }}
                numInputs={6}
                inputStyle="form-control"
                containerStyle="cdChIpR cdChIpR1"
                className="form-group"
                isInputNum={true}
              />
            </div>

            <div className="mt-3 text-center">
              {props.otp && props.otp.length == 6 ? (
                <button
                  className="btn btn-primary btn-block fs-18 fw-500"
                  disabled={props.loader}
                >
                  {props.loader ? <Spinner size="sm" /> : "Submit"}
                </button>
              ) : (
                <button
                  className="btn btn-primary btn-block fs-18 fw-500"
                  disabled
                >
                  {props.loader ? <Spinner size="sm" /> : "Submit"}
                </button>
              )}
            </div>
            <div className="text-center mt-1">{props.timer}</div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
}
