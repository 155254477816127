import React, { useState } from 'react'
import SearchReuse from '../../component/Reusable/searchReuse'
import { TableLoader } from '../../component/Loaders'
import NoDataComponent from '../../component/Reusable/no-data-component'
import { CheckNegativeValue, toFixedLength } from '../../component/utils'
import DatePickerReuse from '../../component/Reusable/DatePickerReuse'

const BotAssets = ({ botPieChartLoading, data }) => {
	console.log('data: ', data)
	const initialPageData = { pageNo: 0, pageSize: 10 }
	const [filters, setFilter] = useState({
		currency_symbol: 'all',
		search_by: '',
		status: 'all',
		start_date: '',
		end_date: '',
		...initialPageData,
		search: '',
		sort: 'Oldest'
	})

	let assetColumns = [
		'Tokens',
		'Share',
		'Amount',
		'Prices',
		'Changes (24H)',
		'Total'
	]

	return (
		<>
			<div>
				<div className='trans-body'>
					<div className='trans-TabCnt'>
						<div className='stakingbonus-tabcnt'>
							<div className='d-flex justify-content-center'>
								<div className='bonus-Head'>
									<h5 className='text-end'>Assets</h5>
								</div>
							</div>

							<div className='bonus-Body'>
								{/* <div className='tbl-showentriessearch'>
									<div className='showentries-RightCnt'>
										<div className='inr-searchcnt'>
											<div className='row'>
												<DatePickerReuse
													setFilters={setFilter}
													className='col-lg-4'
												/>
												<SearchReuse
													className='col-lg-4'
													setFilters={setFilter}
													initialPageData={initialPageData}
													placeholder={'Bot Req Id/Exchange Name'}
												/>
											</div>
										</div>
									</div>
								</div> */}
								<div class='cmntbl'>
									<div className='table-responsive'>
										<table className='table bot-tbl'>
											<thead>
												<tr>
													{assetColumns.map((item, index) => (
														<th index={index}>{item}</th>
													))}
												</tr>
											</thead>
											{botPieChartLoading ? (
												<tbody>
													<tr>
														<td colSpan={assetColumns.length}>
															<div className='tbl-load'>
																<TableLoader />
															</div>
														</td>
													</tr>
												</tbody>
											) : (
												<tbody>
													{data.length && data[0].name ? (
														data.map((item, index) => {
															return (
																<tr key={index}>
																	<td>
																		<div>
																			<div>{item.name}</div>
																			<div>{item.code}</div>
																		</div>
																	</td>

																	<td>
																		<CheckNegativeValue
																			value={item.percentage}
																			content={'%'}
																		/>
																	</td>
																	<td>{toFixedLength(item.amount, 2)}</td>

																	<td>
																		$
																		{item.code == 'ETHUSDT'
																			? 'ETH'
																			: item.code == 'BTCUSDT'
																			? 'BTC'
																			: 1}
																	</td>
																	<td>
																		<CheckNegativeValue
																			value={0}
																			content={'%'}
																		/>
																	</td>
																	<td>
																		<div>
																			$ {toFixedLength(item.usd_value, 2)}
																		</div>
																		<div>
																			{toFixedLength(item.btc_value, 8)} BTC
																		</div>
																	</td>
																</tr>
															)
														})
													) : (
														<>
															<tr>
																<td colSpan={assetColumns.length}>
																	<NoDataComponent />
																</td>
															</tr>
														</>
													)}
												</tbody>
											)}
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default BotAssets
