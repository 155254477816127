import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import { Fragment, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Helper from "../../../Helper/Helper";
import "./caledar.scss";
import { EventModal } from "./EventModal";
// import { Tooltip as ReactTooltip } from "react-tooltip";
// import "react-tooltip/dist/react-tooltip.css";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css";
import classnames from "classnames";
import cancel from "../../../assets/images/cancel.webp";
import { SpinnerLoader, TableLoader } from "../../../component/Loaders";
import CloseBtn from "../../../component/Reusable/CloseBtn";
import dotImg from "../../../assets/images/dotst.png";
import tododotImg from "../../../assets/images/tododot.png";
import followdotImg from "../../../assets/images/followupdot.png";
import lineSvg from "../../../assets/images/line-chart.svg";
import Slider from "react-slick";
import { truncateText } from "../../../utils/truncateText";

const Eventcalandar = (props) => {
  const { buttonLabel, className } = props;
  const [activeTab, setActiveTab] = useState("1");
  const [PageLoading, setPageLoading] = useState({
    tdyload: false,
    charLoad: false,
  });
  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [sendData, setSendDate] = useState();
  const [GetItem, setGetItem] = useState({});
  const [DeleteDetails, setDeleteDetails] = useState({});
  const [infoModal, setInfoModal] = useState(false);
  const [Viewinfo, setViewInfo] = useState({
    details: [],
    date: "",
  });
  const [Reminder, setReminder] = useState({
    today: [],
    tmrw: [],
    dash_count: {},
  });
  console.log("Reminder: ", Reminder);
  const [chart, setChart] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  useEffect(() => {
    setPageLoading((prev) => ({
      ...prev,
      tdyload: true,
    }));
    Helper.PostData("goals", "goal/getMyGoalList", {}, false).then((Res) => {
      if (Res.status) {
        setChart(Res.results);
      }
    });
    Helper.GetData("flow-new", "user_crm/remainder")
      .then((res) => {
        console.log("res: ", res);
        if (res.status) {
          setReminder({
            today: res.tdy_rmd_arr,
            tmrw: res.tmr_rmd_arr,
            dash_count: res.dashboard_leads,
          });
          setPageLoading((prev) => ({
            ...prev,
            tdyload: false,
          }));
        }
      })
      .finally(() => {
        setPageLoading((prev) => ({
          ...prev,
          tdyload: false,
        }));
      });
  }, []);

  //DashBoard Main Tab Start
  const toggleDashboard = (tab) => {
    setActiveTab(tab);
  };

  const ShowInfoModal = (Events, date) => {
    console.log("Events: ", Events);
    if (Events?.length > 0) {
      setInfoModal(!infoModal);
      setViewInfo({
        details: Events,
        date,
      });
    } else {
      setInfoModal(!infoModal);
      setViewInfo({
        details: [],
        date: "",
      });
    }
  };
  const ShowInfoModalClose = () => {
    setInfoModal(false);
    setGetItem({});
    setViewInfo({
      details: [],
      date: "",
    });
  };
  const toggleDelete = (info) => {
    setModalDelete(!modalDelete);
    setDeleteDetails(info);
  };
  const toggleDeleteClose = () => {
    setModalDelete(false);
    setDeleteDetails({});
  };
  const toggle = (getItem) => {
    if (getItem) {
      setGetItem(getItem);
      setModal(!modal);
    }
    setModal(!modal);
  };
  const toggleClose = () => {
    setModal(false);
  };
  useEffect(() => {
    GetEventList();
  }, []);
  const [EventList, setEventList] = useState([]);
  const GetEventList = () => {
    Helper.PostData("flow-new", "calendar/calendarList", {}, false).then(
      (res) => {
        if (res.status) {
          let dataObj = res.data;
          setEventList(dataObj);
        }
      }
    );
  };
  const transformData = (data) => {
    let events = [];

    data.forEach((day) => {
      const date = day.startDate;

      // Process TODOs
      day.todo.forEach((item) => {
        events.push({
          id: item.toDo_id,
          title: item.title,
          start: `${date}`,
          end: `${date}`,
          description: item.description,
          category: item.category,
          type: "todo",
          todo_type: item.toDo_type,
          user_name: item.user_name,
          user_random_id: item.user_random_id,
          buttonStatus: item.edit_status,
          batchStatus: item.status,
          date,
        });
      });

      // Process BopMeetings
      day.bopMeeting.forEach((item) => {
        events.push({
          id: item.bopmeeting_id,
          title: item.title,
          start: item.start_time,
          end: item.end_time,
          description: item.description,
          category: item.category,
          location: item.location,
          address: item.address,
          meet_type: item.type,
          buttonStatus: item.teamLeadStatus,
          batchStatus: item.bop_meeting_status ? item.bop_meeting_status : 0,
          type: "meeting",
          date,
        });
      });
      // Process Follow ups
      day.Follow_up.forEach((item) => {
        console.log("itemfollow: ", item);
        events.push({
          id: item.follow_up_id,
          title: item.title,
          start: item.startDate,
          end: item.end_time,
          description: item.meeting_description,
          category: item.category,
          location: item.location,
          // meet_type: item.type,
          // buttonStatus: item.teamLeadStatus,
          batchStatus: item.status ? item.status : 0,
          type: "followup",
          date,
        });
      });
    });

    return events;
  };
  const preprocessEvents = (events) => {
    const grouped = events.reduce((acc, curr) => {
      if (!acc[curr.date]) acc[curr.date] = {};
      if (!acc[curr.date][curr.type]) acc[curr.date][curr.type] = [];
      acc[curr.date][curr.type].push(curr);
      return acc;
    }, {});

    const summaryEvents = [];
    Object.keys(grouped).forEach((date) => {
      const todos = grouped[date]["todo"] || [];
      const meetings = grouped[date]["meeting"] || [];
      const followups = grouped[date]["followup"] || [];
      if (todos.length > 0) {
        summaryEvents.push({
          id: `summary-todo-${date}`,
          title: todos.length > 1 ? `${todos[0].title}` : todos[0].title,
          date,
          type: "todo",
          isSummary: true,
          // moreCount: todos.length - 1,
          moreCount: todos.length,
        });
      }
      if (meetings.length > 0) {
        summaryEvents.push({
          id: `summary-meeting-${date}`,
          title:
            meetings.length > 1 ? `${meetings[0].title}` : meetings[0].title,
          date,
          type: "meeting",
          isSummary: true,
          // moreCount: meetings.length - 1,
          moreCount: meetings.length,
        });
      }
      if (followups.length > 0) {
        summaryEvents.push({
          id: `summary-follow-${date}`,
          title:
            followups.length > 1 ? `${followups[0].title}` : followups[0].title,
          date,
          type: "followup",
          isSummary: true,
          // moreCount: followups.length - 1,
          moreCount: followups.length,
        });
      }
    });

    return summaryEvents;
  };
  const transForm = transformData(EventList);
  const preprocessedEvents = preprocessEvents(transForm);

  const handleDateClick = (e) => {
    setSendDate(e.dateStr);
    toggle();
  };
  const handleClickEvent = (clickInfo) => {
    const { event } = clickInfo;
    if (event.extendedProps.moreCount >= 0) {
      handleMoreClick(event, event.extendedProps.type);
    }
  };

  const handleMoreClick = (event, type) => {
    const date = event.startStr;
    const filteredEvents = transForm.filter(
      (ev) => ev.date === date && ev.type === type
    );
    ShowInfoModal(filteredEvents, date);
  };

  const renderEventContent = (eventInfo) => {
    const { event } = eventInfo;
    if (event.extendedProps.isSummary) {
      const moreText =
        event.extendedProps.moreCount > 0
          ? `+${event.extendedProps.moreCount}`
          : "";

      return (
        <div
          style={{
            backgroundColor:
              event.extendedProps.type === "meeting"
                ? "lightblue"
                : event.extendedProps.type === "todo"
                ? "lightgreen"
                : "pink",
            padding: "5px",
            borderRadius: "50%",
            width: "25px",
            height: "25px",
            color: "black",
            fontSize: "10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100%",
          }}
          className={event.extendedProps.type ? `cursor-pointer` : ""}
        >
          <b>{moreText}</b>
          {/* <b>{eventInfo.timeText}</b>
          <i>{eventInfo.event.title}</i> */}
          {/* <div>
            <a
              style={{ fontSize: "12px", color: "black" }}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleMoreClick(event, event.extendedProps.type);
              }}
            >
              {moreText}
            </a>
          </div> */}
        </div>
      );
    }

    // return (
    //   <div>
    //     <b>{eventInfo.timeText}</b>
    //     <i>{eventInfo.event.title}</i>
    //   </div>
    // );
  };
  const [Loading, setLoading] = useState(false);
  const handleDelete = () => {
    if (DeleteDetails?.type == "todo") {
      let param = {
        toDo_id: DeleteDetails?.id,
        method: "Remove",
      };
      Helper.PostData("todo", "user_crm/edit_toDo", param, true)
        .then((res) => {
          if (res.status) {
            toggleDeleteClose();
            ShowInfoModalClose();
            setLoading(false);
            GetEventList();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      let param = {
        bopmeeting_id: DeleteDetails?.id,
        method: "Remove",
      };
      Helper.PostData("todo", "bopMeeting/editBopMeeting", param, true)
        .then((res) => {
          if (res.status) {
            toggleDeleteClose();
            ShowInfoModalClose();
            setLoading(false);
            GetEventList();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const colorPalette = {
    todo: "lightgreen",
    meeting: "lightblue",
    followup: "pink",
    // Add more event types and colors as needed
  };
  const ColorLegend = ({ colorPalette }) => {
    return (
      <div style={{ marginBottom: "10px" }}>
        {Object.entries(colorPalette).map(([type, color]) => (
          <div
            key={type}
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <div
              style={{
                width: "20px",
                height: "20px",
                backgroundColor: color,
                borderRadius: "50%",
                marginRight: "5px",
              }}
            ></div>
            <span style={{ textTransform: "capitalize" }}>{type}</span>
          </div>
        ))}
      </div>
    );
  };
  const [reminModal, setReminModal] = useState(false);
  const [reminInfo, setReminInfo] = useState({});
  const toggleReminModal = (info) => {
    console.log("info: ", info);
    setReminModal(!reminModal);
    setReminInfo(info);
  };
  const toggleReminModalClose = () => {
    setReminModal(false);
  };
  return (
    <Fragment>
      <div className="crm-bg-dark">
        <div className="dashboard-navs">
          <div className="row px-2">
            <div className="col-lg-4">
              <Nav tabs className="cursor-pointer" style={{ gap: "30px" }}>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleDashboard("1");
                    }}
                  >
                    Reminder
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleDashboard("2");
                    }}
                  >
                    Calendar
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>

        <TabContent activeTab={activeTab}>
          <TabPane tabId={"1"}>
            <section className="event-calendar mt-4">
              <div className="container">
                <div className="row mt-3">
                  <div className="col-lg-7">
                    <div className="crm-dashcard">
                      <div className="card">
                        <div className="">
                          <h2 className="text-light">Leads</h2>
                        </div>
                        <div className="row crm-dah-count">
                          <div className="col-lg-6">
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="crm-dah-couunt-content">
                                  <h3 className="fs-14">Total</h3>
                                  <p className="fs-20">
                                    {Reminder?.dash_count?.Total_leads || 0}
                                  </p>
                                </div>
                                <img
                                  src={lineSvg}
                                  className="img-fluid"
                                  width={40}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="crm-dah-couunt-content">
                                  <h3 className="fs-14">In Discussion</h3>
                                  <p className="fs-20">
                                    {Reminder?.dash_count?.in_discussion || 0}
                                  </p>
                                </div>
                                <img
                                  src={lineSvg}
                                  className="img-fluid"
                                  width={40}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row crm-dah-count mt-3">
                          <div className="col-lg-6">
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="crm-dah-couunt-content">
                                  <h3 className="fs-14">No Schedule</h3>
                                  <p className="fs-20">
                                    {Reminder?.dash_count?.no_schedule || 0}
                                  </p>
                                </div>
                                <img
                                  src={lineSvg}
                                  className="img-fluid"
                                  width={40}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="card">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="crm-dah-couunt-content">
                                  <h3 className="fs-14">Completed</h3>
                                  <p className="fs-20">
                                    {Reminder?.dash_count?.completed_leads || 0}
                                  </p>
                                </div>
                                <img
                                  src={lineSvg}
                                  className="img-fluid"
                                  width={40}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="crm-goal-card mt-5">
                      <div className="card">
                        <div className="d-flex align-items-center justify-content-between">
                          <h2 className="text-light">Current Month Goals</h2>
                          <div class="qweqwerqwe">
                            <div class="d-flex" style={{ gap: "5px" }}>
                              <p class="dsahfvjfv"></p>
                              <p className="fs-12">Target</p>
                            </div>
                            <div class="d-flex" style={{ gap: "5px" }}>
                              <p class="popupokpk"></p>
                              <p className="fs-12">Achieved</p>
                            </div>
                          </div>
                        </div>
                        <Slider {...settings}>
                          {chart[0]?.goalDetails?.length > 0 ? (
                            chart[0]?.goalDetails?.map((item, index) => {
                              let target = item.targetGoalCount;
                              let achieved = item.acheivedGoalCount;
                              let calculate = (+achieved / +target) * 100;
                              let displyValue =
                                +calculate == 0
                                  ? 1
                                  : +calculate > 100
                                  ? 100
                                  : +calculate;
                              console.log("displyValue: ", displyValue);
                              return (
                                <>
                                  <div className="card">
                                    <div className="goalk-progress-load d-flex">
                                      <div className="progress" key={"index"}>
                                        <div
                                          className="progress-bar target"
                                          style={{
                                            height: `${100}%`,
                                          }}
                                        >
                                          <span className="sr-only">{`Target: ${100}`}</span>
                                        </div>
                                      </div>
                                      <div className="progress" key={"index"}>
                                        <div
                                          className="progress-bar achieved"
                                          style={{
                                            height: `${displyValue}%`,
                                          }}
                                        >
                                          <span className="sr-only">{`Achieved: ${displyValue}`}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="goal-load-content text-center">
                                      <span className="fs-12 text-center">
                                        {item.goalName}
                                      </span>
                                      <br />
                                      <span className="fs-12 text-center">
                                        Target :{" "}
                                        <span className="text-primary fw-500">
                                          {item.targetGoalCount}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <p className="text-center text-muted mt-5">
                              No Chart Data Found
                            </p>
                          )}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="crm-dashcard-led">
                      <div className="card">
                        <div className="position-todo-hed">
                          <h2 className="text-light fs-20">Todo</h2>
                        </div>
                        <div className="position-max-content-todo">
                          <div className="row ">
                            <div className="col-lg-12">
                              <div className="tdy-card-iober">
                                {PageLoading?.tdyload ? (
                                  <div className="text-center">
                                    <TableLoader />
                                  </div>
                                ) : Reminder?.today?.length > 0 ? (
                                  Reminder?.today?.map((item, index) => (
                                    <div
                                      className="listed-todo-crm d-flex align-items-center mt-2 cursor-pointer"
                                      style={{ gap: "10px" }}
                                      key={index}
                                      onClick={() => toggleReminModal(item)}
                                    >
                                      <div className="dot-img">
                                        <img
                                          alt="cryptocurrency exchange"
                                          src={
                                            item.type == "toDo"
                                              ? tododotImg
                                              : item.type == "follow up"
                                              ? followdotImg
                                              : dotImg
                                          }
                                          className="mr-2"
                                        />
                                      </div>
                                      <div>
                                        <p className="text-light mb-0 fs-13">
                                          {item.title}
                                        </p>
                                        <span className="text-light fs-12">
                                          {truncateText(item.description, 25)}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p className="text-center text-muted mt-5">
                                    No Results found
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tmrw-card mt-2">
                        <div className="position-todo-hed">
                          <h2 className="text-light fs-20">
                            Tomorrow Schedule
                          </h2>
                        </div>
                        <div className="position-max-content-todo-tmr">
                          <div className="row ">
                            <div className="col-lg-12">
                              <div className="tdy-card-iober">
                                {PageLoading?.tdyload ? (
                                  <div className="text-center">
                                    <TableLoader />
                                  </div>
                                ) : Reminder?.tmrw?.length > 0 ? (
                                  Reminder?.tmrw?.map((item, index) => (
                                    <div
                                      className="listed-todo-crm d-flex align-items-center mt-2 cursor-pointer"
                                      style={{ gap: "10px" }}
                                      key={index}
                                      onClick={() => toggleReminModal(item)}
                                    >
                                      <div className="dot-img">
                                        <img
                                          alt="cryptocurrency exchange"
                                          src={
                                            item.type == "toDo"
                                              ? tododotImg
                                              : item.type == "follow up"
                                              ? followdotImg
                                              : dotImg
                                          }
                                          className="mr-2"
                                        />
                                      </div>
                                      <div>
                                        <p className="text-light mb-0 fs-13">
                                          {item.title}
                                        </p>
                                        <span className="text-light fs-12">
                                          {truncateText(item.description, 25)}
                                        </span>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p className="text-center text-muted mt-5">
                                    No Results found
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </TabPane>
          <TabPane tabId={"2"}>
            <section className="event-calendar mt-4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <ColorLegend colorPalette={colorPalette} />
                    <FullCalendar
                      plugins={[dayGridPlugin, interactionPlugin]}
                      initialView="dayGridMonth"
                      weekends={true}
                      // dateClick={(e) => handleDateClick(e)}
                      events={preprocessedEvents}
                      eventContent={renderEventContent}
                      eventClick={handleClickEvent}
                      contentHeight="auto"
                      // dayCellDidMount={handleDayCellDidMount}
                    />
                  </div>
                </div>
              </div>
              <EventModal
                modal={modal}
                toggle={toggle}
                className={className}
                date={sendData}
                setModal={setModal}
                GetItem={GetItem}
                ShowInfoModalClose={ShowInfoModalClose}
                GetEventList={GetEventList}
              />
              <Modal
                isOpen={infoModal}
                contentLabel="event-details"
                toggleModal={ShowInfoModal}
                modalClassName="event-details"
                size="md"
              >
                <ModalHeader
                  toggleModal={ShowInfoModalClose}
                  close={<CloseBtn onClick={() => ShowInfoModalClose()} />}
                >
                  <h5 className="text-light">{Viewinfo?.date}</h5>
                </ModalHeader>
                <ModalBody>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        {Viewinfo?.details?.map((item, index) => (
                          <div
                            className="text-left"
                            style={{ paddingTop: "6px" }}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "20px" }}
                              >
                                <p className="redius-green"></p>
                                <div
                                  className="word-break"
                                  style={{ wordBreak: "break-all" }}
                                >
                                  <span>
                                    {item.title}{" "}
                                    {(() => {
                                      if (item.type == "todo") {
                                        if (item.batchStatus == 0) {
                                          return (
                                            <span className="badge bg-warning ml-5">
                                              Pending
                                            </span>
                                          );
                                        } else {
                                          return (
                                            <span className="badge bg-success ml-5">
                                              Completed
                                            </span>
                                          );
                                        }
                                      } else if (item.type == "meeting") {
                                        if (item.batchStatus == 0) {
                                          return (
                                            <span className="badge bg-warning ml-5">
                                              Pending
                                            </span>
                                          );
                                        } else if (item.batchStatus == 1) {
                                          return (
                                            <span className="badge bg-success ml-5">
                                              Completed
                                            </span>
                                          );
                                        } else {
                                          return (
                                            <span className="badge bg-danger ml-5">
                                              Rejected
                                            </span>
                                          );
                                        }
                                      } else {
                                        if (item.batchStatus == 1) {
                                          return (
                                            <span className="badge bg-success ml-5">
                                              Completed
                                            </span>
                                          );
                                        } else if (item.batchStatus == 3) {
                                          return (
                                            <span className="badge bg-danger ml-5">
                                              Rejected
                                            </span>
                                          );
                                        } else if (item.batchStatus == 2) {
                                          return (
                                            <span className="badge bg-info ml-5">
                                              In Discussion
                                            </span>
                                          );
                                        } else {
                                          return (
                                            <span className="badge bg-warning ml-5">
                                              In Schedule
                                            </span>
                                          );
                                        }
                                      }
                                    })()}
                                    {/* {item.batchStatus == 0 ? (
                                      <span className="badge bg-warning ml-5">
                                        Pending
                                      </span>
                                    ) : (
                                      <span className="badge bg-success ml-5">
                                        Completed
                                      </span>
                                    )} */}
                                  </span>
                                  <br />
                                  <span className="text-muted">
                                    {item.description}
                                  </span>
                                  <br />
                                  {item.type == "meeting" && (
                                    <span className="text-muted">
                                      venue :{" "}
                                      <span
                                        style={{ color: "#2c80cb" }}
                                        className="fs-12"
                                      >
                                        {item.address + " , " + item.location}
                                      </span>
                                    </span>
                                  )}
                                  <div
                                    className="d-flex  align-items-center"
                                    style={{ color: "#2c80cb" }}
                                  >
                                    <p className="text-center fs-12">
                                      {item.start}
                                    </p>
                                    {item.end && (
                                      <>
                                        <p className="ml-2">-</p>
                                        <p className="text-center fs-12 ml-2">
                                          {item.end}
                                        </p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* {item.buttonStatus == 1 && item.batchStatus == 0 && (
                        <div
                          className="text-right d-flex align-items-center"
                          style={{ gap: "20px" }}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            size="sm"
                            className="ml-auto cursor-pointer"
                            onClick={() => toggle(item)}
                            title="Edit"
                          />{" "}
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="sm"
                            color="red"
                            className="ml-auto cursor-pointer"
                            onClick={() => toggleDelete(item)}
                            title="Delete"
                          />
                        </div>
                      )} */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              <Modal
                isOpen={modalDelete}
                toggle={toggleDelete}
                className={"modal-md modal-dialog-centered mymodal"}
                size={"sm"}
                backdrop="static"
                keyboard={false}
              >
                <ModalBody className="p-4">
                  <div className="text-center mb-2">
                    <img src={cancel} width={"50%"} />
                  </div>
                  <p className="text-center">
                    <b>Do you want to remove {DeleteDetails?.type}?</b>
                    <br />
                    <b>{DeleteDetails?.title}</b>
                  </p>
                  <div className="d-flex justify-content-between mt-3">
                    <div>
                      <button
                        type="button"
                        class="btn-exg  px-4"
                        onClick={toggleDeleteClose}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      {!Loading ? (
                        <button
                          type="button"
                          class="btn-exg-del px-4"
                          onClick={() => handleDelete()}
                        >
                          Delete
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="btn-exg-del px-4"
                          disabled={Loading}
                        >
                          <SpinnerLoader />
                        </button>
                      )}
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              <Modal
                isOpen={reminModal}
                toggle={toggleReminModal}
                centered
                size="md"
                className="modal-md modal-dialog-centered mymodal"
              >
                <ModalHeader
                  toggle={toggleReminModalClose}
                  close={<CloseBtn onClick={() => toggleReminModalClose()} />}
                >
                  <h5 className="text-uppercase">{reminInfo?.type}</h5>
                </ModalHeader>
                <ModalBody>
                  <div className="mt-2">
                    <div class="info">
                      <span>Title</span>
                      <div className="text-success">{reminInfo?.title}</div>
                    </div>
                    <div class="info">
                      <span>Description</span>
                      <div>{reminInfo?.description}</div>
                    </div>
                    <div class="info">
                      <span>Start Date</span>
                      <div>{reminInfo?.start_date}</div>
                    </div>
                    {reminInfo.type !== "toDo" && (
                      <div class="info">
                        <span>End Date</span>
                        <div>{reminInfo?.end_date}</div>
                      </div>
                    )}
                  </div>
                </ModalBody>
              </Modal>
            </section>
          </TabPane>
        </TabContent>
      </div>
    </Fragment>
  );
};

export default Eventcalandar;
