import React from 'react'
import NewHeader from '../../component/header/NewHeader'
import ScrollToTop from '../../component/utils/useScrollToTop'
const Paymentpolicypage = () => {
	return (
		<>
			<NewHeader />
			<ScrollToTop />
			{/* <Helmet>
				<title>{metadata.privacy.title}</title>
				<meta name='description' content={metadata.privacy.description} />
				<meta name='keywords' content={metadata.privacy.keywords} />
			</Helmet> */}
			<div class='maincontent leftrightCir'>
				<div class='pt-0'>
					<div class='box-primary'>
						<div class='container'>
							<div class='row'>
								<div class='col-12'>
									<div class='bt-yellow mb-1'>KPK PARK</div>
									<div class='secTit mb-0'>Recurring Payment Policy</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<section class='pb-5 mt-5'>
					<div class='container'>
						<div class='row'>
							<div class='col-12'>
								<div class='mycard'>
									<div class='cmspara'>
										<p>
											<p>
												The Recurring Payment Policy becomes effective on Month
												Date, 2023.
											</p>
											<p>
												Refer to the Terms of Use for definitions of capitalized
												terms.
											</p>
											<p>
												<b>Payment Issues:</b> For any payment-related concerns,
												please refer to the KPK Park Help Center. To seek
												assistance, reach out to KPK Park Support using the
												"Contact Us"/"Support" form or by emailing &nbsp;
												<a href={'mailto:disha@uplers.com'}>
													support@kpkpark.io
												</a>
												&nbsp; and expect a response within one business day.
											</p>
											<p>
												<b>Acceptance:</b> By agreeing, you confirm the
												following:
											</p>
											<ol className='or-list'>
												<li>
													1. You will receive information about the expiration
													of your subscription plan at least one day in advance.
												</li>
												<li>
													2. The payment information provided to KPK Park is
													accurate.
												</li>
												<li>
													3. KPK Park is authorized to carry out recurring
													transactions on your behalf.
												</li>
												<li>
													4. You acknowledge and agree that the subscription is
													non-cancellable, and you assume responsibility for all
													recurring payment obligations until the end of the
													subscription period.
												</li>
											</ol>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	)
}

export default Paymentpolicypage
