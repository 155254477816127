import React, { Fragment, useEffect, useState } from "react";
/** Images **/
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { Modal, ModalBody } from "reactstrap";
import Helper from "../../Helper/Helper";
import eventicon1 from "../../assets/images/eventicon1.png";
import eventicon3 from "../../assets/images/sicon1.png";
import eventicon4 from "../../assets/images/sicon2.png";
import eventicon5 from "../../assets/images/sicon3.png";
import Header from "../../component/header";
import Footer from "../../component/footer";
import moment from "moment";
import NewHeader from "../../component/header/NewHeader";
import { ScreenLoader } from "../../component/Loaders";

const PCEventdetail = (props) => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [modal5, setModal5] = useState(false);
  const toggle5 = (image) => {
    setModal5(!modal5);
  };

  const settings1 = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    cssEase: "linear",
  };

  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    cssEase: "linear",
  };
  const { id } = useParams();
  const [response, setResponse] = useState({});
  const [screenLoading, setScreenLoading] = useState(false);

  useEffect(() => {
    setScreenLoading(true);
    Helper.PostData("localEve", "user/ViewsingleEvent", { id: id }, false)
      .then((res) => {
        if (res.status) {
          setScreenLoading(false);
          setResponse(res.data[0]);
        }
      })
      .finally(() => {
        setScreenLoading(false);
      });
  }, []);

  return (
    <Fragment>
      <NewHeader />
      <ScreenLoader loading={screenLoading}>
        <div className="pkchinsec mt-5 pt-2">
          <section className="">
            <div className="container">
              <div className="">
                <div className="row justify-content-center my-5 ">
                  <div className="col-lg-10 kpEvDet">
                    <div className="row justify-content-center">
                      <div className="col-lg-12 col-xl-12 mb-lg-4 mb-lg-0 kporder1">
                        <div>
                          <img
                            src={response?.banner_image}
                            alt=""
                            className="img-fluid"
                            onClick={toggle5}
                            style={{ paddingTop: "10px" }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-xl-12 pr-lg-0  kporder2">
                        <div className="kpeventcnt  kpbksec p-0">
                          <div>
                            <h6 className="text-left text-light text-uppercase">
                              {response?.event_name}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 kpEvDEtail  kporder3">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: response?.description,
                          }}
                        ></p>
                      </div>
                      <div className="col-lg-4 kpEvDEtail  kporder4">
                        <div>
                          <h4 className="text-light">Date and time</h4>
                          <p className="pb-0 mb-2">
                            {moment(response?.created_at).format(
                              " MMM DD, Y hh:mm:ss A"
                            )}
                          </p>
                          <p className="pb-0 mb-2">-</p>
                          <p>
                            {moment(response?.created_at).format(
                              " MMM DD, Y hh:mm:ss A"
                            )}
                          </p>
                        </div>
                        {/* <div>
                        <h4>Location</h4>
                        <p>{response?.event_place}</p>
                      </div> */}
                      </div>
                    </div>
                    <div className="row pb-4">
                      {response &&
                        response.image_data &&
                        response.image_data.map((item, index) => {
                          return (
                            <>
                              <div className="col-xl-4 col-md-6 mb-2">
                                <div className="crd_gal">
                                  <img src={item} onClick={toggle5} alt="" />
                                  <div className="hts_cds">
                                    <h2>Photos</h2>
                                  </div>
                                </div>
                              </div>
                              {/* <Modal
                              isOpen={modal5}
                              toggle={toggle5}
                              className="MdlDfltDv modal-dialog-centered"
                            >
                              <ModalBody>
                                <Slider
                                  {...settings1}
                                  className="rlmsSldR"
                                  asNavFor={nav2}
                                  ref={(slider1) => setNav1(slider1)}
                                >
                                  {subImage?.map((item, index) => (
                                    <div className="SldrKpkIg">
                                      <img src={item} alt="" />
                                    </div>
                                  ))}
                                </Slider>
                                <Slider
                                  {...settings2}
                                  className="rlmsTxR"
                                  asNavFor={nav1}
                                  ref={(slider2) => setNav2(slider2)}
                                  swipeToSlide={true}
                                  focusOnSelect={true}
                                >
                                  {subImage?.map((item, index) => (
                                    <div className="SldrKpkIg">
                                      <img
                                        src={item}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                  ))}
                                </Slider>
                              </ModalBody>
                            </Modal> */}
                            </>
                          );
                        })}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-7">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </ScreenLoader>
      <Footer />
    </Fragment>
  );
};
export default PCEventdetail;
