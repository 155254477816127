import moment from 'moment'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
const DatePickerReuse = ({ setFilters, className }) => {
	const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')

	const fromMinDate = () => {
		let date = new Date()
		date.setFullYear(2023)
		date.setDate(1)
		date.setMonth(0)
		return date
	}
	return (
		<>
			<div className={className}>
				<div class='wallet-in-ser '>
					<div class='input-login-1  ml-lg-2'>
						<DatePicker
							dateFormat='dd-MM-yyyy'
							className='date-input'
							placeholderText='Start Date'
							selected={fromDate}
							minDate={fromMinDate()}
							maxDate={new Date()}
							onChange={date => {
								if (date) {
									setFilters(prev => ({
										...prev,
										start_date: moment(date).format('YYYY-MM-DD')
									}))
									setFromDate(date)
								} else {
									setFromDate('')
									setToDate('')
								}
							}}
						/>
					</div>
				</div>
			</div>
			<div className={className}>
				<div class='wallet-in-ser jik_kil'>
					<div class='input-login-1 ml-lg-2'>
						<DatePicker
							disabled={!fromDate}
							title={!fromDate ? 'Choose Start Date' : ''}
							dateFormat='dd-MM-yyyy'
							className='date-input'
							placeholderText='End Date'
							minDate={fromDate}
							maxDate={new Date()}
							selected={toDate}
							onChange={date => {
								setToDate(date)
								setFilters(prev => ({
									...prev,
									end_date: moment(date).format('YYYY-MM-DD')
								}))
							}}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default DatePickerReuse
