import { useEffect, useState } from 'react'

export const isObjectHaveKey = obj => {
	if (typeof obj == 'object') {
		if (Object.keys(obj).length) {
			return true
		}
	}
	return false
}

export const toFixedUtil = (value, decimal) =>
	value || value == 0 ? (+value).toFixed(decimal) : 0

export const checkSingleValue = value => (value || value == 0 ? value : 0)
export const checkSingleObjectValue = value =>
	value || value == 0 ? value : '---'

export const checkObjValues = obj => {
	if (isObjectHaveKey(obj)) {
		let convertObjtoArr = Object.entries(obj)
		let finalObj = {}
		convertObjtoArr.forEach(
			([key, value]) => (finalObj[key] = checkSingleValue(value))
		)
		return finalObj
	}
	return obj
}

export const toFixedLength = (value, decimal) => {
	if (value || value == 0) {
		value = +value
		let string_value = String(value)
		if (string_value.includes('.')) {
			let split_string_value = string_value.split('.')
			let decimal_length = split_string_value[1].length
			if (+decimal_length > +decimal) {
				return value.toFixed(decimal)
			}
		}
		return value
	}
	return 0
}

export const CheckNegativeValue = ({
	value,
	content = '',
	tag: Tag = 'p',
	...props
}) => {
	const [className, setClassName] = useState('')
	let hasValue = value ? value : '0'
	useEffect(() => {
		if (hasValue) {
			let str_value = String(value)
			if (str_value.includes('-')) {
				setClassName('text-danger mb-0')
			} else {
				setClassName('text-success mb-0')
			}
		}
	}, [value])

	return hasValue ? (
		<Tag className={className}>
			{hasValue} {content}
		</Tag>
	) : (
		<></>
	)
}

export const createObj = value => {
	return {
		name: value,
		value
	}
}
