import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import logoImg from "../../assets/images/kp-new/logo.svg";
import success from "../../assets/images/index/icons8-done-94__1x_.png";
import fail from "../../assets/images/index/icons8-close-94__1x_.png";
// import Footer from '../../components/Footer/Footer'
import { Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import Footer from "../../component/footer";
import Header from "../../component/header";
import Helper from "../../Helper/Helper";
import NewHeader from "../../component/header/NewHeader";

const WebConfirm = () => {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState(0);
  const [message, setMessage] = useState("");
  useEffect(() => {
    let token = decodeURIComponent(searchParams.get("token"))
      .split(" ")
      .join("+");
    let param = {
      verification_id: token,
      type: "mailverify",
    };
    Helper.PostData("user", "user/updatemaillink", param, true).then((res) => {
      if (res.status) {
        setStatus(1);
        setMessage(res.message);
        setTimeout(() => {
          // navigate('/oauth/login')
        }, 2000);
        //
      } else {
        setStatus(2);
      }
    });
  }, []);
  return (
    <>
      <NewHeader />
      <div className="container">
        <div class="blog_header_bg_1 text-center pt-5 pb-lg-3">
          {/* <img
            alt="cryptocurrency exchange"
            src={logoImg}
            style={{ width: "200px" }}
            className="img-fluid"
          ></img> */}
        </div>
      </div>
      <div className="container">
        <section class="blog-sec-1">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-8 mt-5 pt-5">
                <div class="lutc_box">
                  <div class="row justify-content-center mb-3">
                    <div class="col-md-12 text-center">
                      {status == 1 ? (
                        <img
                          alt="cryptocurrency exchange"
                          src={success}
                          style={{ width: "70px" }}
                          className="img-fluid"
                        ></img>
                      ) : status == 2 ? (
                        <img
                          alt="cryptocurrency exchange"
                          src={fail}
                          style={{ width: "70px" }}
                          className="img-fluid"
                        ></img>
                      ) : (
                        <Spinner animation="border" color="primary" />
                      )}
                    </div>
                    <div class="col-md-12 text-center text-light">
                      {message}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mt-5 pt-5">
        <Footer />
      </div>
    </>
  );
};

export default WebConfirm;
