import React from 'react'
import LoadingOverlay from 'react-loading-overlay'
import { HashLoader, PulseLoader, ScaleLoader } from 'react-spinners'
import './loader.css'
import { Spinner } from 'reactstrap'
export const ScreenLoader = ({ loading, children }) => {
	return (
		<LoadingOverlay
			active={loading}
			// active={false}

			spinner={<HashLoader color='#3e40ff' />}
			styles={{ color: '#3e40ff', zIndex: '9999' }}
		>
			{children}
		</LoadingOverlay>
	)
}

export const ButtonLoader = () => {
	return <PulseLoader loading={true} color='white' size='10px' />
}

export const TextLoader = () => {
	return (
		<PulseLoader
			loading={true}
			color='white'
			style={{ display: 'inline', marginTop: '2px' }}
			size='6px'
		/>
	)
}

export const TableLoader = () => {
	return <ScaleLoader color='#364fd6' size='5px' />
}

export const SpinnerLoader = ({ color }) => {
	return <Spinner size='sm' color={color} />
}
