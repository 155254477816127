import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import './assets/scss/styles.scss'
import './custom.scss'
import 'react-notifications/lib/notifications.css'
import 'react-tooltip/dist/react-tooltip.css'
import { Provider } from 'react-redux'
import store from './App/store'
import reportWebVitals from './reportWebVitals'
import { NotificationContainer } from 'react-notifications'
import 'react-photo-view/dist/react-photo-view.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	// <React.StrictMode>
	<Provider store={store}>
		<NotificationContainer />
		<App />
	</Provider>
	// </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
