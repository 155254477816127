import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Form } from "reactstrap";
import Helper from "../../../Helper/Helper";
import { SpinnerLoader } from "../../../component/Loaders";
import { Switch } from "@mui/material";

const TodoForm = ({
  date,
  toggleClose,
  GetItem,
  ShowInfoModalClose,
  GetEventList,
}) => {
  const [loading, setLoading] = useState(false);
  const [StatusChange, setStatusChange] = useState(false);
  const [userDetails, setUserDetsils] = useState([]);
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    reset,
    watch,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  useEffect(() => {
    GetUserRefDetails();
  }, []);
  useEffect(() => {
    if (GetItem?.batchStatus) {
      setStatusChange(true);
    }
  }, [GetItem]);
  const handleStatusChange = (check) => {
    setStatusChange(check);
  };
  const GetUserRefDetails = () => {
    Helper.GetData("todo", "user_crm/direct_ref").then((Res) => {
      if (Res.status) {
        setUserDetsils(Res.data);
      }
    });
  };
  const GetTodoType = watch("toDo_type") || GetItem?.todo_type;

  const HandleSubmitTodo = (data) => {
    setLoading(true);
    let param = {
      date: date,
      description: data.description,
      title: data.title,
      toDo_type: data.toDo_type,
      method: "Add",
      direct_ref_random_id:
        data.toDo_type == "Self" ? "" : data.direct_ref_random_id,
    };
    let updateParam = {
      date: GetItem?.date,
      description: data.description ? data.description : GetItem?.description,
      title: data.title ? data.title : GetItem?.title,
      toDo_type: data.toDo_type ? data.toDo_type : GetItem?.todo_type,
      method: "Edit",
      direct_ref_random_id:
        data.toDo_type == "Self" ? "" : data.direct_ref_random_id,
      toDo_id: GetItem?.id,
      status: StatusChange ? 1 : 0,
    };
    Helper.PostData(
      "todo",
      "user_crm/edit_toDo",
      GetItem?.type ? updateParam : param,
      true
    )
      .then((res) => {
        if (res.status) {
          toggleClose();
          setLoading(false);
          GetEventList();
          if (GetItem?.type) {
            ShowInfoModalClose();
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <Form onSubmit={handleSubmit(HandleSubmitTodo)} autoComplete="off">
          {GetItem?.type && (
              <div className="form-group formInputs loginInput mb-4">
                <label>
                  Todo Status<span className="text-primary">*</span>
                </label>
                <div className="d-flex align-items-center justify-content-between">

                <Switch
                  checked={StatusChange}
                  onChange={({ target: { checked } }) =>
                    handleStatusChange(checked)
                  }
                  size="small"
                />
                {GetItem?.batchStatus == 0 ? (
                    <p className="text-warning">Pending</p>
                  ) : (
                    <p className="text-success">Completed</p>
                  )}
                  </div>
              </div>
            )}
            <div className="form-group formInputs loginInput mb-4">
              <label>
                Title<span className="text-primary">*</span>
              </label>
              <input
                type="text"
                name="title"
                className="form-control form-control-lg"
                defaultValue={GetItem?.title}
                placeholder="Enter Title"
                {...register("title", {
                  required: "* Title is required",
                })}
              />
              {errors.title && (
                <p className="text-danger">{errors.title?.message}</p>
              )}
            </div>

            <div className="form-group formInputs loginInput mb-4">
              <label>
                Type<span className="text-primary">*</span>
              </label>
              <select
                className="form-control form-control-lg"
                name="toDo_type"
                placeholder="Select Type"
                {...register("toDo_type", {
                  required: "* Type is required",
                })}
              >
                <option value="">Select Type</option>
                <option value="Self" selected={GetItem?.todo_type == "Self"}>
                  Self
                </option>
                <option value="Team" selected={GetItem?.todo_type == "Team"}>
                  Team
                </option>
              </select>
              {errors.toDo_type && (
                <p className="text-danger">{errors.toDo_type?.message}</p>
              )}
            </div>

            {GetTodoType == "Team" && (
              <div className="form-group formInputs loginInput mb-4">
                <label>
                  User Name<span className="text-primary">*</span>
                </label>
                <select
                  className="form-control form-control-lg"
                  name="direct_ref_random_id"
                  placeholder="Select Type"
                  {...register("direct_ref_random_id", {
                    required: "* User Name is required",
                  })}
                >
                  <option value="">Select Type</option>
                  {userDetails?.map((item, index) => (
                    <option
                      value={item.userrandomid}
                      key={index}
                      selected={GetItem?.user_name == item.username}
                    >
                      {item.username}
                    </option>
                  ))}
                </select>
                {errors.direct_ref_random_id && (
                  <p className="text-danger">
                    {errors.direct_ref_random_id?.message}
                  </p>
                )}
              </div>
            )}
            <div className="form-group formInputs loginInput mb-4">
              <label>
                Description<span className="text-primary">*</span>
              </label>
              <textarea
                class="form-control"
                rows="5"
                defaultValue={GetItem?.description}
                name="description"
                placeholder="Enter Description"
                formControlName="message"
                {...register("description", {
                  required: "* Description is required",
                  pattern: {
                    value: /[/^\S]/,
                    message: "* Whitespace not allowed ",
                  },
                })}
                autocomplete="off"
              ></textarea>
              {errors.description && (
                <p className="text-danger">{errors.description.message}</p>
              )}
            </div>
            {/* {GetItem?.type && (
              <div className="form-group formInputs loginInput mb-4">
                <label>
                  Todo Status<span className="text-primary">*</span>
                </label>
                <Switch
                  checked={StatusChange}
                  onChange={({ target: { checked } }) =>
                    handleStatusChange(checked)
                  }
                  size="small"
                />
              </div>
            )} */}
            <div className="btn-todo text-center">
              {loading ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled={loading}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};

export default TodoForm;
