import { Switch } from "@mui/material";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Helper from "../../../Helper/Helper";
import { SpinnerLoader } from "../../../component/Loaders";
import CloseBtn from "../../../component/Reusable/CloseBtn";

const BopForm = ({
  toggleClose,
  GetItem,
  date,
  ShowInfoModalClose,
  GetEventList,
}) => {
  const [LoadingAdd, setLoadingAdd] = useState(false);
  const [LoadingUp, setLoadingUp] = useState(false);
  const [StatusChange, setStatusChange] = useState(false);
  const minTime = moment().startOf("day").add(9, "hours").toDate();
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const {
    handleSubmit: handleSubmitUp,
    register: registerUp,
    formState: { errors: errorsUp },
    control: controlUp,
    reset: resetUp,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const fromMinDate = () => {
    let date = new Date();
    return date;
  };
  useEffect(() => {
    if (GetItem?.batchStatus) {
      setStatusChange(true);
    }
  }, [GetItem]);
  const [updateRemarkModal, setUpdateRemarkModal] = useState(false);
  const toggleUpdateRemark = () => {
    setUpdateRemarkModal(!updateRemarkModal);
  };
  const toggleUpdateRemarkClose = () => {
    setUpdateRemarkModal(false);
    setStatusChange(false);
    resetUp({
      attendance_users: "",
      remarks: "",
    });
  };
  const handleStatusChange = (check) => {
    setStatusChange(check);
    if (check) {
      toggleUpdateRemark();
    }
  };

  const HandleAddMeeting = (data) => {
    setLoadingAdd(true);
    let param = {
      ...data,
      start_date: moment(data.start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(data.end_date).format("YYYY-MM-DD HH:mm:ss"),
      type: data.type,
      method: "Add",
    };
    let updateParam = {
      title: data.title ? data.title : GetItem?.title,
      description: data.description ? data.description : GetItem?.description,
      type: data.type ? data.type : GetItem?.meet_type,
      address: data.address ? data.address : GetItem?.address,
      location: data.location ? data.location : GetItem?.location,
      // attendance_users: data?.attendance_users,
      // remarks: data?.remarks,
      start_date: data.start_date
        ? moment(data.start_date).format("YYYY-MM-DD HH:mm:ss")
        : GetItem?.start,
      end_date: data.end_date
        ? moment(data.end_date).format("YYYY-MM-DD HH:mm:ss")
        : GetItem?.end,
      method: "Edit",
      bopmeeting_id: GetItem?.id,
    };
    Helper.PostData(
      "todo",
      "bopMeeting/editBopMeeting",
      GetItem?.type ? updateParam : param,
      true
    )
      .then((res) => {
        if (res.status) {
          setLoadingAdd(false);
          toggleClose();
          reset();
          GetEventList();
          if (GetItem?.type) {
            ShowInfoModalClose();
          }
        }
      })
      .finally(() => {
        setLoadingAdd(false);
      });
  };
  const OnUpdateStatusRemark = (data) => {
    setLoadingUp(true);
    Helper.PostData("", "bopMeeting/updateBopMeetingStatus", {
      bopmeeting_id: GetItem?.id,
      ...data,
    })
      .then((res) => {
        if (res.status) {
          toggleUpdateRemarkClose();
          setLoadingUp(false);
          toggleClose();
          resetUp();
          GetEventList();
          ShowInfoModalClose();
        }
      })
      .finally(() => {
        setLoadingUp(false);
      });
  };
  return (
    <Fragment>
      <form onSubmit={handleSubmit(HandleAddMeeting)} autoComplete="off">
        <div class="row">
          <div className="col-12">
            {GetItem?.type && (
              <>
                <div className="form-group formInputs loginInput mb-4">
                  <label>
                    Meeting Status<span className="text-primary">*</span>
                  </label>
                  <div className="d-flex align-items-center justify-content-between">
                    <Switch
                      checked={StatusChange}
                      onChange={({ target: { checked } }) =>
                        handleStatusChange(checked)
                      }
                      size="small"
                      disabled={GetItem?.batchStatus == 1}
                    />
                    {GetItem?.batchStatus == 0 ? (
                      <p className="text-warning">Pending</p>
                    ) : (
                      <p className="text-success">Completed</p>
                    )}
                  </div>
                </div>
              </>
            )}
            {/* {StatusChange && GetItem?.batchStatus == 0 && (
              <>
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Attendance Count<span className="text-primary">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="attendance_users"
                    placeholder="Enter Attendance Attendance"
                    {...register("attendance_users", {
                      required: "* Attendance count is required",
                    })}
                  />
                  {errors.attendance_users && (
                    <p className="text-danger">
                      {errors.attendance_users.message}
                    </p>
                  )}
                </div>
                <div className="form-group formInputs loginInput mb-4">
                  <label>
                    Remarks<span className="text-primary">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    name="remarks"
                    placeholder="Enter Remarks"
                    formControlName="message"
                    {...register("remarks", {
                      required: "* Remarks is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                    autocomplete="off"
                  ></textarea>
                  {errors.remarks && (
                    <p className="text-danger">{errors.remarks.message}</p>
                  )}
                </div>
              </>
            )} */}
            <div class="form-group formInputs walletInput">
              <label class="col-form-label">
                Select Type<span className="text-primary">*</span>
              </label>
              <select
                className="form-control form-control-lg"
                name="type"
                placeholder="Select Type"
                {...register("type", {
                  required: "* Type is required",
                })}
              >
                <option value="">Select Type</option>
                <option
                  value="common"
                  selected={GetItem?.meet_type == "common"}
                >
                  Common
                </option>
                <option value="team" selected={GetItem?.meet_type == "team"}>
                  Team
                </option>
              </select>
              {errors.type && (
                <p className="text-danger">{errors.type.message}</p>
              )}
            </div>

            <div class="form-group formInputs walletInput">
              <label class="col-form-label">
                Title<span className="text-primary">*</span>
              </label>
              <input
                class="form-control"
                name="title"
                placeholder="Enter Title"
                defaultValue={GetItem?.title}
                {...register("title", {
                  required: "* Title is required",
                })}
              />
              {errors.title && (
                <p className="text-danger">{errors.title.message}</p>
              )}
            </div>
            <div className="form-group formInputs loginInput mb-4">
              <label>
                Description<span className="text-primary">*</span>
              </label>
              <textarea
                class="form-control"
                rows="5"
                name="description"
                placeholder="Enter Description"
                formControlName="message"
                defaultValue={GetItem?.description}
                {...register("description", {
                  required: "* Description is required",
                  pattern: {
                    value: /[/^\S]/,
                    message: "* Whitespace not allowed ",
                  },
                })}
                autocomplete="off"
              ></textarea>
              {errors.description && (
                <p className="text-danger">{errors.description.message}</p>
              )}
            </div>

            <div className="form-group formInputs loginInput mb-4">
              <label>
                Address<span className="text-primary">*</span>
              </label>
              <textarea
                class="form-control"
                rows="5"
                name="address"
                defaultValue={GetItem?.address}
                placeholder="Enter Address"
                formControlName="message"
                {...register("address", {
                  required: "* Address is required",
                  pattern: {
                    value: /[/^\S]/,
                    message: "* Whitespace not allowed ",
                  },
                })}
                autocomplete="off"
              ></textarea>
              {errors.address && (
                <p className="text-danger">{errors.address.message}</p>
              )}
            </div>
            <div class="form-group formInputs walletInput">
              <label class="col-form-label">
                Location<span className="text-primary">*</span>
              </label>
              <input
                class="form-control"
                name="location"
                defaultValue={GetItem?.location}
                placeholder="Enter Location"
                {...register("location", {
                  required: "* Location is required",
                })}
              />
              {errors.location && (
                <p className="text-danger">{errors.location.message}</p>
              )}
            </div>
            <div class="wallet-in-ser formInputs walletInput">
              <label>
                Start Time<span className="text-primary">*</span>
              </label>
              <div class="input-login-1  ml-lg-2">
                <Controller
                  name="start_date"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={GetItem?.start ? new Date(GetItem.start) : ""}
                  render={({ field: { onChange, onBlur, value } }) => {
                    const selectedStartDate = value ? new Date(value) : null;

                    return (
                      <ReactDatePicker
                        className="form-control"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={selectedStartDate}
                        minDate={fromMinDate()}
                        dropdownMode="select"
                        peekNextMonth
                        showTimeSelect={true}
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        placeholderText="DD/MM/YYYY HH:MM:SS"
                      />
                    );
                  }}
                />
                {errors.start_date && (
                  <p>
                    {errors.start_date.type === "required" ? (
                      <p className="text-danger">* Start Time is required</p>
                    ) : (
                      <p className="text-danger">{errors.start_date.message}</p>
                    )}
                  </p>
                )}
              </div>
            </div>
            <br />
            <div class="wallet-in-ser formInputs walletInput">
              <label>
                End Time<span className="text-primary">*</span>
              </label>
              <div class="input-login-1  ml-lg-2">
                <Controller
                  name="end_date"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={GetItem?.end ? new Date(GetItem.end) : ""}
                  render={({ field: { onChange, onBlur, value } }) => {
                    const selectedStartDate = value ? new Date(value) : null;

                    return (
                      <ReactDatePicker
                        className="form-control"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={selectedStartDate}
                        minDate={fromMinDate()}
                        dropdownMode="select"
                        peekNextMonth
                        showTimeSelect={true}
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        placeholderText="DD/MM/YYYY HH:MM:SS"
                      />
                    );
                  }}
                />
                {errors.end_date && (
                  <p>
                    {errors.end_date.type === "required" ? (
                      <p className="text-danger">* End Time is required</p>
                    ) : (
                      <p className="text-danger">{errors.end_date.message}</p>
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="text-center mb-2 mt-3">
          {LoadingAdd ? (
            <button
              class="btn btn-primary px-4"
              type="button"
              disabled={LoadingAdd}
            >
              <SpinnerLoader />
            </button>
          ) : (
            <button class="btn btn-primary px-4" type="submit">
              Confirm
            </button>
          )}
        </div>
      </form>
      <Modal
        isOpen={updateRemarkModal}
        toggleModal={toggleUpdateRemark}
        centered
        size="sm"
      >
        <ModalHeader
          toggle={toggleUpdateRemarkClose}
          close={<CloseBtn onClick={() => toggleUpdateRemarkClose()} />}
        >
          Are you sure want to update your status ?
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={handleSubmitUp(OnUpdateStatusRemark)}
            autoComplete="off"
          >
            <div className="row">
              <div className="col-lg-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Attendance Count<span className="text-primary">*</span>
                  </label>
                  <input
                    type="number"
                    class="form-control"
                    name="attendance_users"
                    placeholder="Enter Attendance Count"
                    {...registerUp("attendance_users", {
                      required: "* Attendance count is required",
                    })}
                  />
                  {errorsUp.attendance_users && (
                    <p className="text-danger">
                      {errorsUp.attendance_users.message}
                    </p>
                  )}
                </div>
                <div className="form-group formInputs loginInput mb-4">
                  <label>
                    Remarks<span className="text-primary">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    name="remarks"
                    placeholder="Enter Remarks"
                    formControlName="message"
                    {...registerUp("remarks", {
                      required: "* Remarks is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                    autocomplete="off"
                  ></textarea>
                  {errorsUp.remarks && (
                    <p className="text-danger">{errorsUp.remarks.message}</p>
                  )}
                </div>
                <div class="text-center mb-2 mt-3">
                  {LoadingUp ? (
                    <button
                      class="btn btn-primary px-4"
                      type="button"
                      disabled={LoadingUp}
                    >
                      <SpinnerLoader />
                    </button>
                  ) : (
                    <button class="btn btn-primary px-4" type="submit">
                      Confirm
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default BopForm;
