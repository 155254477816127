import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Helper from '../../../Helper/Helper'
import { ButtonLoader } from '../../../component/Loaders'
import CloseBtn from '../../../component/Reusable/CloseBtn'
import './connectExchange.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setBotRequestModal } from '../modalSlice'
import BotRequest from '../BotRequest'

const ConnectExchange = ({ getBotPieChart }) => {
	const [modal, setModal] = useState(false)
	const [loading, setLoading] = useState(true)
	const [btnLoading, setBtnLoading] = useState(false)
	const [exchangeHistory, setExchangesHistory] = useState([])
	const modalState = useSelector(state => state.modal.botRequestModal)
	console.log('modalState: ', modalState)

	const {
		handleSubmit,
		register,
		formState: { errors },
		reset
	} = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	})
	const dispatch = useDispatch()

	useEffect(() => {
		modal && getExchangeNetworks()
	}, [modal])

	const toggle = () => {
		reset()
		setModal(!modal)
	}

	const getExchangeNetworks = () => {
		Helper.GetData('aabu', 'user/botRouter/list_exchange')
			.then(res => {
				if (res.status) {
					setExchangesHistory([...res.data])
				}
			})
			.finally(() => setLoading(false))
	}

	const onSubmit = data => {
		setBtnLoading(true)
		Helper.PostData(
			'newBot',
			'user/botRouter/connect_new_account',
			{ ...data, user_id: localStorage.getItem('tip') },
			true
		)
			.then(res => {
				if (res.status) {
					reset()
					setModal(false)
					dispatch(setBotRequestModal(true))
					getBotPieChart()
				}
			})
			.finally(() => setBtnLoading(false))
	}

	const exchangeOptions = useMemo(() => {
		return (
			<>
				{exchangeHistory.length > 1 && (
					<option value='' selected hidden>
						Choose your Network
					</option>
				)}
				{exchangeHistory.map(
					(item, index) =>
						item.status == 1 && (
							<option value={item.exchange_code} key={index}>
								{item.exchange_name}
							</option>
						)
				)}
			</>
		)
	}, [exchangeHistory])

	return (
		<>
			<button type='button' class='btn btn-primary px-4' onClick={toggle}>
				<FontAwesomeIcon size='sm' icon={faPlus} /> Connect Exchange
			</button>
			<Modal
				isOpen={modal}
				toggle={toggle}
				className={'modal-md modal-dialog-centered mymodal'}
				size={'md'}
				backdrop='static'
				keyboard={false}
			>
				<ModalHeader toggle={toggle} close={<CloseBtn onClick={toggle} />}>
					Connect Exchange
				</ModalHeader>
				<ModalBody className='p-4'>
					<div className='connect_ex'>
						<div class='row'>
							<div className='col-12'>
								<div className='note_cnt_exg'>
									<div>
										Connect keys securely
										<a
											className='txt-dec-none ml-1'
											href='https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072'
											target='_blank'
										>
											Full Guide
										</a>{' '}
									</div>
									<div className='mt-2'>
										1. How to Create API Keys on Binance
										<a
											className='txt-dec-none ml-1'
											target='_blank'
											href='https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072'
										>
											Click here
										</a>
									</div>
									<div className='mt-1'>
										2. Turn on IP whitelisting and copy/paste these IP addresses
									</div>
									<div className='copy_ip_bg'>
										<div className='d-flex justify-content-between align-items-center'>
											<span>13.232.22.85</span>
											{/* <span>
												<a className='txt-dec-none ml-1' href='#'>
													<b> Copy</b>
												</a>
											</span> */}
										</div>
									</div>
									<div className='mt-1'>
										3. Paste generated data in inputs below
									</div>
								</div>
							</div>
						</div>
						<Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
							<div class='row'>
								<div className='col-12'>
									<div class='form-group formInputs walletInput'>
										<label class='col-form-label'>
											Network <span className='text-danger'>*</span>
										</label>
										<select
											class='form-control'
											name='exchange_name'
											{...register('exchange_name', {
												required: '* Network is required'
												// onChange: () => {
												// 	reset({ name: '', api_key: '', secret_key: '' })
												// }
											})}
										>
											{exchangeOptions}
										</select>
										{errors.exchange_name && (
											<p className='text-danger'>
												{errors.exchange_name.message}
											</p>
										)}
									</div>
									<div class='form-group formInputs walletInput'>
										<label class='col-form-label'>
											Name <span className='text-danger'>*</span>
										</label>
										<input
											class='form-control'
											name='name'
											placeholder='Enter Name'
											{...register('name', {
												required: '* Name is required',
												pattern: {
													value: /[/^\S]/,
													message: '* Whitespace not allowed '
												}
											})}
										/>
										{errors.name && (
											<p className='text-danger'>{errors.name.message}</p>
										)}
									</div>
									<div class='form-group formInputs walletInput'>
										<label class='col-form-label'>
											API Key <span className='text-danger'>*</span>
										</label>
										<input
											class='form-control'
											name='api_key'
											placeholder='Enter API Key'
											{...register('api_key', {
												required: '* API Key is required',
												pattern: {
													value: /[/^\S]/,
													message: '* Whitespace not allowed '
												}
											})}
										/>
										{errors.api_key && (
											<p className='text-danger'>{errors.api_key.message}</p>
										)}
									</div>
									<div class='form-group formInputs walletInput'>
										<label for='secret_key' class='col-form-label'>
											API Secret <span className='text-danger'>*</span>
										</label>
										<input
											class='form-control'
											name='secret_key'
											id='secret_key'
											placeholder='Enter API Secret'
											{...register('secret_key', {
												required: '* API Secret is required',
												pattern: {
													value: /[/^\S]/,
													message: '* Whitespace not allowed '
												}
											})}
										/>
										{errors.secret_key && (
											<p className='text-danger'>{errors.secret_key.message}</p>
										)}
									</div>
									{/* <div className='mt-3'>
										<a href='' className='txt-dec-none'>
											Available account type
										</a>
									</div> */}
									{!btnLoading ? (
										<button
											className='btn btn-primary w-100 mt-3'
											type='submit'
										>
											Connect Exchange
										</button>
									) : (
										<button
											className='btn btn-primary w-100 mt-3'
											disabled
											type='button'
										>
											<ButtonLoader />
										</button>
									)}
								</div>
							</div>
						</Form>
					</div>
				</ModalBody>
			</Modal>
			<BotRequest />
		</>
	)
}

export default ConnectExchange
