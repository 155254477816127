export const dataTableConfigs = {
	rows: {
		style: {
			backgroundColor: '#151515',
			color: '#ffffff'
		}
	},
	pagination: {
		style: {
		  backgroundColor: '#151515', 
		},
	  },
	headCells: {
		style: {
			fontSize: '14px',
			fontWeight: '400',
			color: '#707a8a',
			width: '50px',
		}
	},
	headRow: {
		style: {
		  backgroundColor: '#151515',
		  color: '#fff',
		},
	  },
	  striped: {
		default: '#f5f5f5',
		highlight: '#e0e0e0',
	  },
	  cells: {
		style: {
		  backgroundColor: '#151515',
		  color: '#fff',
		},
	  },
	  noData: {
		style: {
		  backgroundColor: '#151515',
		  color: '#fff',
		},
	  },
	  progress: {
		style: {
		  backgroundColor: '#151515',
		  color: '#fff',
		},
	  },
	// columns: {
	// 	style: {
	// 		'S.No': {
	// 			width: '10px !important'
	// 		},
	// 		'Date & Time': {
	// 			width: '20px !important'
	// 		}
	// 	},
	// }
}
