import React from "react";
import DataTable from "react-data-table-component";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export function PlotHash(props) {
  return (
    <Modal
      isOpen={props.modal}
      toggle={props.toggle}
      className="modal-md modal-dialog-centered mymodal"
      size="md"
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader toggle={props.toggle} close={props.closeBtn}>
        Plot Hash
      </ModalHeader>
      <ModalBody className="px-4">
        <div className="row">
          <div className="col-12">
            <div className="teble-responsive">
              <DataTable
                columns={props.columns1}
                data={props.plotHash && props.plotHash} customStyles={props.customStyles}/>
                
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
