import React, { useEffect, useState } from 'react'
import Helper from '../../Helper/Helper'
import { ReuseToFixed } from '../../component/utils/ReuseToFixed'
import './History.scss'
import CopyData2 from '../../component/Reusable/CopyData 2'
import moment from 'moment/moment'
import DataTable from 'react-data-table-component'
import { TableLoader } from '../../component/Loaders'
import NoDataComponent from '../../component/Reusable/no-data-component'
import { dataTableConfigs } from '../../configs/dataTableConfigs'

const RewardReport = () => {
	const { formatAddress } = Helper
	const [response, setResponse] = useState({ data: [], count: 0 })
	const [BonusBalance, setBonusBalance] = useState()
	const [Loading, setLoading] = useState(false)

	const [filters, setFilter] = useState({
		pageNo: 0,
		pageSize: 10,
		user_id: localStorage.getItem('tip'),
	})

	useEffect(() => {
		setLoading(true)
		Helper.PostData('', 'report/reward_report', filters, false).then(
			res => {
				if (res.status) {
					setResponse({ data: res.data, count: res.count })
					setBonusBalance(+res.bonusBalance)
					setLoading(false)
				} else {
					setResponse({ data: [], count: 0 })
					setLoading(false)
				}
			}
		)
	}, [filters])
	const columns = [
		{
			name: 'S.No',
			sortable: true,
			cell: (row, index) => <td>{index + 1}</td>
		},
		{
			name: 'Date & Time',
			sortable: true,
			cell: row => moment(row.datetime).format('YYYY-MM-DD hh:mm:ss A')
		},
		{
			name: 'Trans.Id',
			sortable: true,
			cell: row => {
				return <CopyData2 data={row.transaction_id} />
			}
		},

		{
			name: 'Type',
			selector: row => row.type,
			sortable: true
		},
		{
			name: 'CR',
			selector: row => (row.method == 'cr' ? row.amount : ''),
			sortable: true
		},
		{
			name: 'DR',
			selector: row => (row.method == 'dr' ? row.amount : ''),
			sortable: true
		}
	]

	return (
		<div>
			<div className='trans-body'>
				<div className='trans-TabCnt'>
					<div className='stakingbonus-tabcnt'>
						<div className='bonus-Head'>
							<h5>Reward Report</h5>
						</div>
						<div className='bonus-Body'>
							<div className='tbl-showentriessearch'>
								<div className='showentries-LeftCnt'>
									<div className='inr-showecnt'></div>
								</div>
								<div className='showentries-RightCnt'>
									<div className='inr-searchcnt'>
										<div className='mx-2 my-2'></div>
									</div>
								</div>
							</div>
							<div className='cmntbl'>
								<div className={`table-responsive`}>
									{/* <table className='table bot-tbl '>
										<thead>
											<tr>
												<th>Date & Time</th>
												<th>Trans.Id</th>
												<th>Type</th>
												<th>CR</th>
												<th>DR</th>
											</tr>
										</thead>
										<tbody>
											{response?.length > 0 ? (
												response?.map(row => (
													<>
														<tr>
															<td>
																<div>
																	<p>{row.datetime}</p>
																</div>
															</td>
															<td>{formatAddress(row.transaction_id)}</td>
															<td>{row.type}</td>
															<td>{row.method == 'cr' ? row.amount : ''}</td>
															<td>{row.method == 'dr' ? row.amount : ''}</td>
														</tr>
													</>
												))
											) : (
												<tr className='mt-3'>
													<td colSpan={10} className='fw-700 mt-4'>
														No record Found !
													</td>
												</tr>
											)}
										</tbody>
									</table> */}
									<DataTable
										columns={columns}
										className='text-center'
										data={response.data}
										paginationTotalRows={response.count}
										customStyles={dataTableConfigs}
										pagination
										onChangePage={pageNo => {
											setFilter(prev => ({ ...prev, pageNo: pageNo - 1 }))
										}}
										progressPending={Loading}
										progressComponent={<TableLoader />}
										noDataComponent={<NoDataComponent />}
										onChangeRowsPerPage={pageSize => {
											setFilter(prev => ({ ...prev, pageSize }))
										}}
										paginationServer
										persistTableHead={true}
										// paginationPerPage={10}
										paginationRowsPerPageOptions={[5, 10, 20, 30]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default RewardReport
