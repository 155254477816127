import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import Footer from "../../component/footer";
import NewHeader from "../../component/header/NewHeader";
import ScrollToTop from "../../component/utils/useScrollToTop";
import metadata from "../../metaData/metadata";
import "./faq.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
const faqs = [
  {
    id: 1,
    header: "What is KPK Park?",
    text: `KPK Park is a secure staking platform where traders can lock their cryptocurrency investments and receive rewards.`,
  },
  {
    id: 2,
    header: "What is Staking in Cryptocurrencies World?",
    text: `Staking is a process in the world of cryptocurrencies and blockchain technology. It involves the act of participating in the proof-of-stake (PoS) or delegated proof-of-stake (DPoS) consensus mechanisms. Staking is a way for traders to lock up a certain amount of their cryptocurrency holdings in the blockchain network, and in return, they receive rewards in the form of additional cryptocurrency tokens.`,
  },
  {
    id: 3,
    header: "What is the Level Bonus?",
    text: `Our level bonus system empowers users to leverage their staking levels as they are rewarded for their dedication. This not only fosters increased commitment but also unlocks greater bonuses, contributing to the vitality of our thriving ecosystem.`,
  },
  {
    id: 4,
    header: "What is the Growth Level Bonus?",
    text: `Elevated commitment and increased staked amounts result in more substantial rewards.`,
  },
];

const AccordionItem = (props) => {
  const contentEl = useRef();
  const { handleToggle, active, faq } = props;
  const { header, id, text } = faq;

  return (
    <>
      <Helmet>
        <title>{metadata.faq.title}</title>
        <meta name="description" content={metadata.faq.description} />
        <meta name="keywords" content={metadata.faq.keywords} />
      </Helmet>
      <div className="rc-accordion-card">
        <div className="rc-accordion-header">
          <div
            className={`rc-accordion-toggle p-3 ${
              active === id ? "active" : ""
            }`}
            onClick={() => handleToggle(id)}
          >
            <h5 className="rc-accordion-title">{header}</h5>
            <FontAwesomeIcon
              icon={faChevronDown}
              className="rc-accordion-icon"
            />
          </div>
        </div>
        <div
          ref={contentEl}
          className={`rc-collapse ${active === id ? "show" : ""}`}
          style={
            active === id
              ? { height: contentEl.current.scrollHeight }
              : { height: "0px" }
          }
        >
          <div className="rc-accordion-body">
            <p className="mb-0">{text}</p>
          </div>
        </div>
      </div>
    </>
  );
};

const FaqPage = () => {
  const [active, setActive] = useState(null);
  const [searchparams] = useSearchParams();
  const mobType = searchparams.get("type");
  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <>
      {mobType !== "mobile" && <NewHeader />}
      <ScrollToTop />
      <div class="maincontent leftrightCir">
        <div class=" pt-0">
          <div class="box-primary">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="bt-yellow mb-1">KPK PARK</div>
                  <div class="secTit mb-0">Frequently Asked Questions</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="pb-5 mt-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="faqaccorbox">
                  {faqs.map((faq, index) => {
                    return (
                      <AccordionItem
                        key={faq.number}
                        active={active}
                        handleToggle={handleToggle}
                        faq={faq}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {mobType !== "mobile" && <Footer />}
    </>
  );
};

export default FaqPage;
