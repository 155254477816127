import { faEye, faEyeSlash, faHome } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Spinner } from 'reactstrap'
import Helper from '../../Helper/Helper'
import loginImg from '../../assets/images/kp-new/loginimg.svg'
import logoImg from '../../assets/images/kp-new/logo.svg'
import './login.scss'
import OTPmodal from '../../component/OTPmodal'
import metadata from '../../metaData/metadata'
import { Helmet } from 'react-helmet'
import metaBig from '../../assets/images/metabig.svg'
import metaSmall from '../../assets/images/metasmall.svg'
const Loginpage = () => {
	const navigate = useNavigate()
	const { PostData } = Helper
	const [passwordtype, setPasswordtype] = useState('password')
	const [isLoading, setIsLoading] = useState(false)
	const [otp, setOtp] = useState('')
	const [modal, setModal] = useState(false)
	const [keys, setKey] = useState(0)
	const [isOtpLoading, setIsOtpLoading] = useState(false)
	const [showText, setShowText] = useState(false)
	let current_token = localStorage.getItem('kk')

	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues
	} = useForm({
		mode: 'all',
		reValidateMode: 'onChange'
	})

	useEffect(() => {
		document.body.classList.add('kpstakepage')
	}, [])

	const handleEyeChanged = () => {
		setPasswordtype(() => (passwordtype === 'text' ? 'password' : 'text'))
	}

	const toggleModal = () => {
		setOtp('')
		setModal(!modal)
	}

	const onSubmit = () => {
		const data = getValues()
		const payload = {
			...data,
			deviceType: 'web',
			device_token: current_token ? current_token : ''
		}
		setIsLoading(true)
		setKey(prev => prev + 1)
		PostData('user', 'user/userLogin', payload, false)
			.then(res => {
				if (res.status) {
					Helper.toasts(res.message, true)
					if (res.otp_required) {
						setModal(true)
					} else {
						localStorage.setItem('tip', res.encryptedId)
						localStorage.setItem('logaccess', res.token)
						localStorage.setItem(
							'membership_status',
							res.data.membership_status
						)

						setTimeout(() => navigate('/user/dashboard'), 1000)
					}
				} else {
					if (res.data?.activation_link_required) {
						setShowText(true)
						Helper.toasts(res.message, false, 60000)
					} else {
						setShowText(false)
						res.message && Helper.toasts(res.message, false)
					}
				}
			})
			.finally(() => setIsLoading(false))
	}

	const handleOTPsubmit = () => {
		const data = getValues()
		const payload = {
			otp,
			email: data.email,
			type: 'login',
			deviceType: 'web'
		}
		setIsOtpLoading(true)
		PostData('user', 'user/otpverify', payload)
			.then(res => {
				if (res.status) {
					localStorage.setItem('tip', res.encryptedId)
					localStorage.setItem('logaccess', res.token)

					setTimeout(() => navigate('/user/dashboard'), 1000)
					setModal(false)
				}
			})
			.finally(() => setIsOtpLoading(false))
	}

	const HandleResendMail = () => {
		const data = getValues()
		Helper.PostData(
			'user',
			'user/resent_activatemail',
			{ email: data.email },
			true
		).then(res => {
			if (res.status) {
				setShowText(false)
			}
		})
	}
	return (
		<>
			<Helmet>
				<title>{metadata.login.title}</title>
				<meta name='description' content={metadata.login.description} />
				<meta name='keywords' content={metadata.login.keywords} />
			</Helmet>
			<div className='kp-stake-plateformpage vh-100 logincir'>
				<div className='row no-gutters h-100'>
					<div className='col-xl-7 col-lg-6 order-lg-1 order-2'>
						<div className='row h-100 no-gutters'>
							<div className='offset-xl-2 col-xl-9'>
								<div className='loginleft h-100'>
									<div className='d-flex align-items-start flex-column justify-content-around h-100'>
										<div className='logologinDiv d-none d-lg-block d-xl-block'>
											<img
												alt='logoImg'
												src={metaSmall}
												className='img-fluid logoLogin'
												style={{ width: '166px' }}
											/>
										</div>
										<div className='logocenter py-5 text-center mx-auto'>
											<img
												alt='loginImg'
												src={metaBig}
												className='img-fluid logoimg'
											/>
										</div>
										<div className='row w-100 align-items-center'>
											<div className='col-md-6 text-center text-md-left'></div>
											<div className='col-md-6  text-center text-md-right'>
												<div className='fs-14 light-text'>
													&copy; 2023 - KPK PARK - All Rights Reserved.
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='col-xl-5 col-lg-6 order-lg-2 order-1'>
						<div className='loginright h-100'>
							<div className='row'>
								<div className='col-xl-9'>
									<div className='logologinDiv d-lg-none mb-3 text-center'>
										<img
											alt='logoImg'
											src={metaSmall}
											className='img-fluid logoLogin'
											style={{ width: '166px' }}
										/>
									</div>
									<div className='loginformbox'>
										<div className='loginbx'>
											<div className='d-flex align-items-center justify-content-between'>
												<div className='fs-30 fw-500 mb-4'>Login</div>
												<div className='text-right mb-4'>
													{' '}
													<Link
														className='text-light'
														title='Go to Home'
														to='/'
													>
														<FontAwesomeIcon icon={faHome} size={'lg'} />
													</Link>
												</div>
											</div>
											<Form
												onSubmit={handleSubmit(onSubmit)}
												autoComplete='off'
											>
												<div className='row'></div>
												<div className='form-group formInputs loginInput mb-4'>
													<label>
														Email / Mobile Number
														<span className='text-primary'>*</span>
													</label>
													<input
														type='text'
														className='form-control form-control-lg'
														placeholder='Enter Email / Mobile Number'
														data-autocomplete-domain={window.location.origin}
														name='email'
														{...register('email', {
															required: '* Email / Mobile Number is required'
														})}
													/>
													{errors.email && (
														<p className='text-danger mt-2'>
															{errors.email?.message}
														</p>
													)}
												</div>
												<div className='form-group formInputs loginInput mb-4'>
													<label>
														Password<span className='text-primary'>*</span>
													</label>
													<div className='input-group mb-3'>
														<input
															type={passwordtype}
															className='form-control form-control-lg'
															placeholder='Enter Password'
															{...register('password', {
																required: '* Password is required'
															})}
															data-autocomplete-domain={window.location.origin}
														/>

														<div className='input-group-append'>
															<span
																className='input-group-text cur-poin'
																onClick={handleEyeChanged}
																id='basic-addon2'
															>
																{passwordtype === 'password' ? (
																	<FontAwesomeIcon icon={faEyeSlash} />
																) : (
																	<FontAwesomeIcon icon={faEye} />
																)}
															</span>
														</div>
													</div>
													{errors.password && (
														<p className='text-danger '>
															{errors.password?.message}
														</p>
													)}
												</div>

												<div className='row mt-4 justify-content-end'>
													<div className='col-6'>
														<div className='text-right'>
															<Link
																to='/oauth/forgetpassword'
																className='text-primary fs-14 fw-400'
															>
																Forgot Password?
															</Link>
														</div>
													</div>
												</div>

												{showText && (
													<div className='text-center light-text mb-4 ml-auto order-1 order-md-2 mt-3'>
														{' '}
														Didn't receive activation mail?
														<Link
															className='text-primary ml-2'
															onClick={HandleResendMail}
														>
															Resend
														</Link>
													</div>
												)}

												<div className='mt-4 mb-2'>
													<button
														type='submit'
														disabled={isLoading}
														className='btn btn-primary btn-block fs-18 fw-500'
													>
														{isLoading ? <Spinner size='sm' /> : 'Login'}
													</button>
												</div>
											</Form>
										</div>
										<div className='text-center light-text mb-4 ml-auto order-1 order-md-2'>
											{' '}
											Not a member?
											<Link className='text-primary ml-2' to='/oauth/register'>
												Register for free
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<OTPmodal
					otp={otp}
					setOtp={setOtp}
					modal={modal}
					toggleModal={toggleModal}
					loader={isOtpLoading}
					handleApiCall={handleOTPsubmit}
					keys={keys}
					resendOTP={onSubmit}
					sentTo={Helper.formatEmailAddress(getValues().email)}
				/>
			</div>
		</>
	)
}

export default Loginpage
