import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import Helper from '../../Helper/Helper'
import { dataTableConfigs } from '../../configs/dataTableConfigs'
import { TableLoader } from '../../component/Loaders'
import NoDataComponent from '../../component/Reusable/no-data-component'
import SearchReuse from '../../component/Reusable/searchReuse'
import DatePickerReuse from '../../component/Reusable/DatePickerReuse'
import { toFixedUtil } from '../../component/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
const StakeBonusHistory = () => {
	const initialPageData = { pageNo: 0, pageSize: 10 }

	const [stakingBonusHistory, setStakeBonusHistory] = useState({
		data: [],
		count: 0
	})
	const [Loading, setLoading] = useState(false)
	const [filters, setFilter] = useState({
		currency_symbol: 'all',
		search_by: '',
		status: 'all',
		start_date: '',
		end_date: '',
		...initialPageData,
		search: '',
		sort: 'Oldest'
	})
	const [copied1, setCopied1] = useState({ status: false, index: -1 })

	const copyStatus = (index, setCopy) => {
		setCopy({ status: true, index })
		setTimeout(() => {
			setCopy({ status: false, index: -1 })
		}, 500)
	}

	useEffect(() => {
		getStateHistory()
	}, [filters])

	const getStateHistory = () => {
		setLoading(true)
		Helper.PostData('user', 'stake/dailyStakeRewards', filters, false)
			.then(res => {
				if (res.status) {
					setStakeBonusHistory({ data: res.data, count: res.totalCount })
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const columns = [
		{
			name: 'S.No',
			sortable: true,
			selector: (row, index) => row.index,
			cell: (row, index) => <td>{index + 1}</td>
		},
		{
			name: 'Tnx.Id',
			sortable: true,
			selector: (row, index) => row.transaction_id,
			cell: (row, index) => {
				return (
					<td>
						{row.transaction_id ? (
							<>
								{Helper.formatAddress(row.transaction_id)}
								{'   '}
								{copied1.status && copied1.index === index ? (
									<span className='ml-2'>✔</span>
								) : (
									<FontAwesomeIcon
										className='cursor-pointer'
										style={{ color: 'green' }}
										icon={faCopy}
										title='Copy'
										onClick={() =>
											Helper.copyText(
												row.transaction_id,
												copyStatus,
												index,
												setCopied1
											)
										}
									/>
								)}
							</>
						) : (
							' -- '
						)}
					</td>
				)
			}
		},

		{
			name: 'Stake KPK',
			selector: row => row.amount,
			sortable: true
		},
		{
			name: 'Reward KPK',
			selector: row => toFixedUtil(row.rwd_amount, 2),
			sortable: true
		},
		{
			name: 'Percentage',
			selector: row => row.percentage,
			sortable: true
		},
		{
			name: 'Durations',
			sortable: true,
			selector: row => row.duration
		},
		{
			name: 'Currency',
			sortable: true,
			selector: row => row.currency
		},
		{
			name: 'Date & Time',
			sortable: true,
			selector: (row, index) => row.created_at,

			cell: (row, index) =>
				moment(row.created_at).format('YYYY-MM-DD hh:mm:ss A')
		}

		// {
		// 	name: 'Status',
		// 	sortable: true,
		// 	selector: (row) => row.status
		// }
	]
	return (
		<div>
			<div className='trans-body'>
				<div className='trans-TabCnt'>
					<div className='stakingbonus-tabcnt'>
						<div className='bonus-Head'>
							<h5>Staking Rewards</h5>
						</div>
						<div className='bonus-Body'>
							<div className='tbl-showentriessearch'>
								<div className='showentries-RightCnt'>
									<div className='inr-searchcnt'>
									<div className="row">
										<DatePickerReuse
											setFilters={setFilter}
											className='col-lg-4'
										/>
										<SearchReuse
										className='col-lg-4'
											setFilters={setFilter}
											initialPageData={initialPageData}
											placeholder={'Tnx.Id/Percentage/Duration/Currency'}
										/>
										</div>

									</div>
								</div>
							</div>
							<div className='cmntbl'>
								<div className='table-responsive'>
									<DataTable
										columns={columns}
										className='text-center'
										data={stakingBonusHistory.data}
										paginationTotalRows={stakingBonusHistory.count}
										customStyles={dataTableConfigs}
										pagination
										paginationServer
										onChangePage={pageNo => {
											setFilter(prev => ({ ...prev, pageNo: pageNo - 1 }))
										}}
										progressPending={Loading}
										progressComponent={<TableLoader />}
										noDataComponent={<NoDataComponent />}
										onChangeRowsPerPage={pageSize => {
											setFilter(prev => ({ ...prev, pageSize }))
										}}
										persistTableHead={true}
										// paginationPerPage={10}
										paginationRowsPerPageOptions={[5, 10, 20, 30]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default StakeBonusHistory
