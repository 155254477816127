import { createSlice } from "@reduxjs/toolkit";

let initialState ={
    Message:""
}
const RegisterSlice = createSlice({
    name:"registerMessage",
    initialState,
    reducers:{
    setRegisteMessage:(state,action)=>{
        state.Message = action.payload
    }
    }
})

export const {setRegisteMessage}= RegisterSlice.actions
export default RegisterSlice.reducer