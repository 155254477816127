import {
  faEye,
  faInfoCircle,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useForm } from "react-hook-form";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Helper from "../../../Helper/Helper";
import { SpinnerLoader, TableLoader } from "../../../component/Loaders";
import CloseBtn from "../../../component/Reusable/CloseBtn";
import NoDataComponent from "../../../component/Reusable/no-data-component";
import { dataTableConfigs } from "../../../configs/dataTableConfigs";
// import monthArray from "./month";
import { ProgressBar } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { v4 as uuidv4 } from "uuid";
import SearchReuse from "../../../component/Reusable/searchReuse";
import "./goalachiever.scss";
import DatePicker from 'react-datepicker'
import moment from "moment";

const GoalsHistoryNew = () => {
  const initialPageData = { pageNo: 0, pageSize: 10 };
  const [response, setResponse] = useState({
    data: [],
    count: 0,
  });
  const now = 100;
  const [Loading, setLoading] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [viewInfoModal, setViewInfoModal] = useState(false);
  const [viewDetails, setViewDetails] = useState();
  console.log("viewDetails: ", viewDetails);
  const [filters, setFilter] = useState({
    search_by: "",
    status: "all",
    ...initialPageData,
  });
  const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')

	const fromMinDate = () => {
		let date = new Date()
		date.setFullYear(2023)
		date.setDate(1)
		date.setMonth(0)
		return date
	}
  const {
    control: controlMonth,
    handleSubmit: handleSubmitMonth,
    setValue: setValueMonth,
    getValues: getValesMonth,
  } = useForm({
    defaultValues: {
      month: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
      },
    },
  });

  const handleMonthChange = (year, month) => {
    // Update the form value when the month changes
    setValueMonth("month", { year, month });
    console.log("year, month: ", year, month);
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
    watch,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const toggleAdd = () => {
    setAddModal(!addModal);
  };
  const toggleAddClose = () => {
    setAddModal(false);
    reset();
    setInput([{ target_goal_count: "", goal_name: "" }]);
  };

  //Past Month Disabled
  // const now = new Date();
  // const currentMonth = now.getMonth() + 1;
  // const [selectedYear, setSelectedYear] = useState("");
  // const [selectedMonth, setSelectedMonth] = useState("");

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  // const handleYearChange = (e) => {
  //   setSelectedYear(e.target.value);
  //   setSelectedMonth(""); // Clear selected month when year changes
  // };
  let selectedYear = watch("goal_year");
  const isMonthDisabled = (month) => {
    if (!selectedYear) return false;
    const yearNumber = parseInt(selectedYear, 10);
    return yearNumber === currentYear && month < currentMonth;
  };

  //Goal View Info Modal Start
  const toggleViewInfo = (rowData) => {
    console.log("rowDataInfo: ", rowData);
    setViewInfoModal(!viewInfoModal);
    setViewDetails(rowData);
  };

  const toggleViewInfoClose = () => {
    setViewInfoModal(false);
    updateReset();
    setInput([{ target_goal_count: "", goal_name: "" }]);
  };
  //Goal View Details Modal Start
  const toggleView = (rowData) => {
    console.log("rowData: ", rowData);
    let optimizeObj = rowData?.goalDetails?.map((item, index) => {
      return {
        id: uuidv4(),
        target_goal_count: item.target_goal_count,
        goal_name: item.goal_name,
        status: item.status,
      };
    });
    console.log("optimizeObj: ", optimizeObj);
    setViewDetailsModal(!viewDetailsModal);
    setViewDetails(rowData);
    setInput(optimizeObj);
  };

  const toggleViewDetailsClose = () => {
    setViewDetailsModal(false);
    updateReset();
    setInput([{ target_goal_count: "", goal_name: "" }]);
  };

  //goals update useform
  const {
    handleSubmit: handleUpdate,
    control: updateControl,
    formState: { errors: updateErrors },
    reset: updateReset,
    register: updateRegister,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: { targetGoalAmonut: "", goalName: "" },
  });

  const goalList = () => {
    setLoading(true);
    Helper.PostData("goals", "goal/getMyReferGoalList", filters, false).then(
      (res) => {
        console.log("resgoal: ", res);
        if (res.status) {
          setResponse({
            data: res.results,
            count: res.count,
          });
          setLoading(false);
        } else {
          setResponse({
            data: [],
            count: 0,
          });
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    goalList();
  }, [filters]);

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
      width: "100px",
    },
    {
      name: "Goal Name",
      selector: (row, index) => row.goalUser,
      sortable: true,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.goalUser}</td>
      ),
    },
    {
      name: "Acheived Goal Count",
      sortable: true,
      selector: (row, index) => row.acheivedGoals,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.acheivedGoals}</td>
      ),
    },
    {
      name: "Target Goal Count",
      sortable: true,
      selector: (row, index) => row.targetGoals,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">{row.targetGoals}</td>
      ),
    },
    {
      name: "Action",
      sortable: true,
      selector: (row, index) => row.id,
      cell: (row, index) => (
        <td className="text-center cursor-pointer">
          <FontAwesomeIcon icon={faEye} onClick={() => toggleView(row)} />
          <FontAwesomeIcon
            icon={faInfoCircle}
            onClick={() => toggleViewInfo(row)}
            className="ml-2"
          />
        </td>
      ),
    },
  ];

  //   Add Goals
  const [GoalsName, setGoalsName] = useState([]);
  const [GoalsUsers, setGoalsUsers] = useState([]);
  const [LoadingAdd, setLoadingAdd] = useState(false);

  useEffect(() => {
    Helper.PostData("goals", "goal/goalDropdown", {}, false).then((res) => {
      if (res.status) {
        setGoalsName(res.data);
      }
    });
    Helper.PostData(
      "goals",
      "goal/getUsersFrGoal",
      { userId: localStorage.getItem("tip") },
      false
    ).then((res) => {
      if (res.status) {
        let dataObj = res.data;
        const options = dataObj.map((item) => ({
          label: `${item.username}${item.rank ? ` (${item.rank})` : ""}`,
          value: item.uniqueId,
        }));
        setGoalsUsers(options);
      }
    });
  }, []);

  const HandleGoalSchedule = (data) => {
    if (input[0]?.goal_name == "") {
      NotificationManager.error("Please add Goal Name");
    } else if (input[0]?.target_goal_count == "") {
      NotificationManager.error("Please add target");
    } else if (input[0]?.target_goal_count <= 0) {
      NotificationManager.error("Value must be greater than 0");
    } else {
      setLoadingAdd(true);
      let param = {
        goal_users: data?.goal_users,
        goal_month: data?.goal_month,
        goal_year: selectedYear,
        goaldetails: input,
      };
      Helper.PostData("goals", "goal/addGoals", param, true)
        .then((res) => {
          if (res.status) {
            setLoadingAdd(false);
            setAddModal(false);
            reset();
            setInput([{ target_goal_count: "", goal_name: "" }]);
            goalList();
          }
        })
        .finally(() => {
          setLoadingAdd(false);
        });
    }
  };

  //handle update goal
  const HandleUpdateGoal = (data) => {
    console.log("data: ", data);
    if (input[0]?.target_goal_count <= 0) {
      NotificationManager.error("Value must be greater than 0");
    }
    setLoadingAdd(true);
    let params = {
      goalId: viewDetails?.id,
      goaldetails: input,
    };
    Helper.PostData("goals", "goal/updateGoals", params, true)
      .then((resp) => {
        if (resp.status) {
          setViewDetailsModal(false);
          updateReset();
          setInput([{ target_goal_count: "", goal_name: "" }]);
          goalList();
          setLoadingAdd(false);
        }
      })
      .finally(() => {
        // setViewDetailsModal(false)
        setLoadingAdd(false);
      });
  };
  useEffect(() => {
    if (viewDetails) {
      updateReset({
        target_goal_count: viewDetails?.targetGoalAmonut || "",
        goal_name: viewDetails?.goalName,
      });
    }
  }, [viewDetails, updateReset]);

  let header = [
    { label: "Goal Name", key: "goalName" },
    { label: "Target Goal Count", key: "targetGoalCount" },
    { label: "Goal Start Time", key: "goalStartTime" },
    { label: "Goal End Time", key: "goalEndTime" },
  ];
  const csvReport = {
    data: response?.data,
    headers: header,
    filename: "GoalsReport.csv",
  };

  const [input, setInput] = useState([
    { id: uuidv4(), target_goal_count: "", goal_name: "" },
  ]);
  console.log("input: ", input);

  const addInput = () => {
    setInput([
      ...input,
      { id: uuidv4(), target_goal_count: "", goal_name: "" },
    ]);
  };

  const removeInput = (id) => {
    const newInput = input.filter((item) => item.id !== id);
    setInput(newInput);
  };

  const handleInputChange = (id, field, value) => {
    console.log("value: ", value);
    setInput((prevInput) =>
      prevInput.map((item) =>
        item.id === id ? { ...item, [field]: value } : item
      )
    );
  };

  const [monthArray, setMonthArray] = useState();
  const [yearArray, setYearArray] = useState();
  console.log("monthArray: ", monthArray);
  useEffect(() => {
    Helper.PostData("goals", "goal/goalMonthDropdown", {}, false).then(
      (res) => {
        console.log("resadas: ", res);
        if (res.status) {
          setMonthArray(res?.data?.month);
          setYearArray(res?.data?.year);
        } else {
        }
      }
    );
  }, []);


  return (
    <Fragment>
      <div>
        <div className="trans-body">
          <div className="trans-TabCnt">
            <div className="stakingbonus-tabcnt">
              <div className="bonus-Head">
                <h5>Goals History</h5>
              </div>
              <div className="bonus-Body">
                <div className="tbl-header-items">
                  <div className="showentries-LeftCnt">
                    <div className="row inr-cnt">
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <div class="wallet-in-ser ">
                          <div class="input-login-1  ml-lg-2">
                            <DatePicker
                              dateFormat="M,yyyy"
                              className="date-input"
                              placeholderText="Start Date"
                              selected={fromDate}
                              minDate={fromMinDate()}
                              maxDate={new Date()}
                              showMonthYearPicker
                              onChange={(date) => {
                                if (date) {
                                  setFilter((prev) => ({
                                    ...prev,
                                    goal_month:
                                      moment(date).format("M,YYYY"),
                                  }));
                                  setFromDate(date);
                                } else {
                                  setFromDate("");
                                  setToDate("");
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-12">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            setFilter((prev) => ({
                              ...prev,
                              status: e.target.value,
                            }))
                          }
                        >
                          <option value="all">All</option>
                          <option value={1}>Completed</option>
                          <option value={0}>InComplete</option>
                        </select>
                      </div>
                      <dv className="col-lg-3 col-md-4 col-sm-12">
                        <SearchReuse
                          setFilters={setFilter}
                          initialPageData={initialPageData}
                          placeholder={"Search"}
                        />
                      </dv>
                      <div className="col-lg-3 col-md-4 col-sm-12 text-center">
                        <button
                          className="btn btn-primary"
                          onClick={() => toggleAdd()}
                        >
                          Add Goals
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cmntbl">
                  <div className="table-responsive">
                    <div className="back-btn text-right cursor-pointer mb-4">
                      {/* <CSVLink {...csvReport}>
                          <FontAwesomeIcon
                            icon={faDownload}
                            className=""
                            size="sm"
                          />{" "}
                          Export
                        </CSVLink> */}
                    </div>
                    <DataTable
                      columns={columns}
                      className="text-center"
                      data={response.data}
                      paginationTotalRows={response.count}
                      customStyles={dataTableConfigs}
                      pagination={true}
                      paginationServer
                      onChangePage={(pageNo) => {
                        setFilter((prev) => ({ ...prev, pageNo: pageNo - 1 }));
                      }}
                      progressPending={Loading}
                      progressComponent={<TableLoader />}
                      noDataComponent={<NoDataComponent />}
                      onChangeRowsPerPage={(pageSize) => {
                        setFilter((prev) => ({ ...prev, pageSize }));
                      }}
                      persistTableHead={true}
                      paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={addModal}
        toggle={toggleAdd}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleAddClose}
          close={<CloseBtn onClick={() => toggleAddClose()} />}
        >
          Schedule Goals
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(HandleGoalSchedule)} autoComplete="off">
            <div class="row">
              <div className="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Select Goal User<span className="text-primary">*</span>
                  </label>
                  <select
                    class="form-control"
                    name="goal_users"
                    {...register("goal_users", {
                      required: "* User is required",
                    })}
                  >
                    <option hidden value="">
                      Select Goal User
                    </option>
                    {GoalsUsers?.map((item, index) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </select>
                  {errors.goal_users && (
                    <p className="text-danger">{errors.goal_users.message}</p>
                  )}
                </div>
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Select Year<span className="text-primary">*</span>
                  </label>
                  <select
                    class="form-control"
                    name="goal_year"
                    // value={selectedYear}
                    // onChange={handleYearChange}
                    {...register("goal_year", {
                      required: "* Year is required",
                    })}
                  >
                    <option hidden value="">
                      Select Goal Year
                    </option>
                    {yearArray?.map((item, index) => (
                      <option value={item.name}>{item.name}</option>
                    ))}
                  </select>
                  {errors.goal_year && (
                    <p className="text-danger">{errors.goal_year.message}</p>
                  )}
                </div>

                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Select Month<span className="text-primary">*</span>
                  </label>
                  <select
                    class="form-control"
                    name="goal_month"
                    // value={selectedMonth}
                    // onChange={(e) => setSelectedMonth(e.target.value)}
                    {...register("goal_month", {
                      required: "* Month is required",
                    })}
                  >
                    <option hidden value="">
                      Select Goal Month
                    </option>
                    {monthArray?.map((item, index) => (
                      <option
                        disabled={isMonthDisabled(item.value)}
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {errors.goal_month && (
                    <p className="text-danger">{errors.goal_month.message}</p>
                  )}
                </div>
                {/* Goal Add Card Input field start */}
                <div className="goal-add">
                  {input.map((item, index) => (
                    <Fragment key={item.id}>
                      <div className="card mb-2" key={item.id}>
                        <div
                          className="d-flex align-items-center"
                          style={{ justifyContent: "space-between" }}
                        >
                          <div className="inputs-form">
                            <div className="form-group formInputs walletInput">
                              <label className="col-form-label">
                                Select Goal Name
                                <span className="text-primary">*</span>
                              </label>
                              <select
                                className="form-control"
                                dafaultValue={item.goal_name}
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    "goal_name",
                                    e.target.value
                                  )
                                }
                              >
                                <option value="" hidden>
                                  Select Goal Name
                                </option>
                                {GoalsName.map((goal, idx) => (
                                  <option
                                    key={idx}
                                    value={goal}
                                    hidden={input.some(
                                      (d) => d?.goal_name === goal
                                    )}
                                  >
                                    {goal}
                                  </option>
                                ))}
                              </select>
                              {errors[`${item.id}-goal_name`] && (
                                <p className="text-danger">
                                  {errors[`${item.id}-goal_name`].message}
                                </p>
                              )}
                            </div>
                            <div className="form-group formInputs walletInput">
                              <label className="col-form-label">
                                Target Count
                                <span className="text-primary">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Enter Target Count"
                                defaultValue={item.target_goal_count}
                                onChange={(e) =>
                                  handleInputChange(
                                    item.id,
                                    "target_goal_count",
                                    e.target.value
                                  )
                                }
                              />
                              {errors[`${item.id}-target_goal_count`] && (
                                <p className="text-danger">
                                  {
                                    errors[`${item.id}-target_goal_count`]
                                      .message
                                  }
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="btn-group">
                            {index == input.length - 1 && input.length < 9 && (
                              <button
                                className="btn btn-success"
                                type="button"
                                onClick={addInput}
                                disabled={
                                  input[index]?.goal_name == "" ||
                                  input[index]?.target_goal_count == ""
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  size="md"
                                  className="text-light cursor-pointer"
                                />
                              </button>
                            )}
                            {input.length > 1 && (
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() => removeInput(item.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faMinus}
                                  size="md"
                                  className="text-light"
                                  style={{ cursor: "pointer" }}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </div>
                {/* Goal Add Card Input field end */}
              </div>
            </div>
            <div class="text-center mb-2 mt-3">
              {LoadingAdd ? (
                <button
                  class="btn btn-primary px-4"
                  type="button"
                  disabled={LoadingAdd}
                >
                  <SpinnerLoader />
                </button>
              ) : (
                <button class="btn btn-primary px-4" type="submit">
                  Confirm
                </button>
              )}
            </div>
          </form>
        </ModalBody>
      </Modal>

      {/* Goal View and Update Modal */}
      <Modal
        isOpen={viewDetailsModal}
        toggle={toggleView}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleViewDetailsClose}
          close={<CloseBtn onClick={() => toggleViewDetailsClose()} />}
        >
          Goals Details
        </ModalHeader>
        <ModalBody>
          <>
            <div class="info">
              <span>Goal Creater Name</span>
              <div className="text-success">{viewDetails?.goalCreatorName}</div>
            </div>
            <div class="info">
              <span>User Name</span>
              <div>{viewDetails?.goalUser}</div>
            </div>
            <div class="info">
              <span>Goal Month</span>
              <div>{viewDetails?.goalMonth}</div>
            </div>
            <div class="row">
              <div className="col-12">
                <form
                  onSubmit={handleUpdate(HandleUpdateGoal)}
                  autoComplete="off"
                >
                  <div className="goal-add">
                    {input.map((item, index) => (
                      <Fragment key={item.id}>
                        <div className="card mb-2" key={item.id}>
                          <div
                            className="mr-2 mt-2"
                            style={{ textAlign: "end" }}
                          >
                            {item?.status == "InComplete" ? (
                              <h6 className="text-danger">{item?.status}</h6>
                            ) : (
                              <h6 className="text-success">{item?.status}</h6>
                            )}
                          </div>
                          <div
                            className="d-flex align-items-center"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div className="inputs-form">
                              <div className="form-group formInputs walletInput">
                                <label className="col-form-label">
                                  Select Goal Name
                                  <span className="text-primary">*</span>
                                </label>
                                {item?.goal_name && (
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Target Count"
                                    defaultValue={item?.goal_name}
                                    disabled={item?.goal_name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        item.id,
                                        "goal_name",
                                        e.target.value
                                      )
                                    }
                                  />
                                )}
                                {!item?.goal_name && (
                                  <select
                                    className="form-control"
                                    disabled={item?.goal_name}
                                    onChange={(e) =>
                                      handleInputChange(
                                        item.id,
                                        "goal_name",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="" hidden>
                                      Select Goal Name
                                    </option>
                                    {GoalsName.map((goal, idx) => (
                                      <option
                                        key={idx}
                                        value={goal}
                                        hidden={input.some(
                                          (d) => d?.goal_name == goal
                                        )}
                                      >
                                        {goal}
                                      </option>
                                    ))}
                                  </select>
                                )}
                                {errors[`${item.id}-goal_name`] && (
                                  <p className="text-danger">
                                    {errors[`${item.id}-goal_name`].message}
                                  </p>
                                )}
                              </div>
                              <div className="form-group formInputs walletInput">
                                <label className="col-form-label">
                                  Target Count
                                  <span className="text-primary">*</span>
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Enter Target Count"
                                  defaultValue={item?.target_goal_count}
                                  onChange={(e) =>
                                    handleInputChange(
                                      item.id,
                                      "target_goal_count",
                                      e.target.value
                                    )
                                  }
                                />
                                {errors[`${item.id}-target_goal_count`] && (
                                  <p className="text-danger">
                                    {
                                      errors[`${item.id}-target_goal_count`]
                                        .message
                                    }
                                  </p>
                                )}
                              </div>
                            </div>
                            <div
                              className="btn-group mr-2"
                              style={{ gap: "15px" }}
                            >
                              {index == input.length - 1 &&
                                input.length < 9 && (
                                  <button
                                    className="btn btn-success"
                                    type="button"
                                    onClick={addInput}
                                    // disabled={input[index]?.goal_name == "" ||
                                    //   input[index]?.target_goal_count == ""}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      size="md"
                                      className="text-light cursor-pointer"
                                    />
                                  </button>
                                )}
                              {input.length > 1 && (
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  onClick={() => removeInput(item.id)}
                                  hidden={item?.status}
                                >
                                  <FontAwesomeIcon
                                    icon={faMinus}
                                    size="md"
                                    className="text-light"
                                    style={{ cursor: "pointer" }}
                                  />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    ))}
                  </div>

                  <div class="text-center mb-2 mt-3">
                    {LoadingAdd ? (
                      <button
                        class="btn btn-primary px-4"
                        type="button"
                        disabled={LoadingAdd}
                      >
                        <SpinnerLoader />
                      </button>
                    ) : (
                      <>
                        <button class="btn btn-primary px-4" type="submit">
                          Update
                        </button>
                      </>
                    )}
                  </div>
                </form>
                {/* Goal Add Card Input field end */}
              </div>
            </div>
          </>
          {/* Update Goal End */}
        </ModalBody>
      </Modal>
      {/* Goal Info Modal */}
      <Modal
        isOpen={viewInfoModal}
        toggle={toggleView}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleViewInfoClose}
          close={<CloseBtn onClick={() => toggleViewInfoClose()} />}
        >
          Goals Info
        </ModalHeader>
        <ModalBody>
          <>
            <div class="info">
              <span>Goal Creater Name</span>
              <div className="text-success">{viewDetails?.goalCreatorName}</div>
            </div>
            <div class="info">
              <span>User Name</span>
              <div>{viewDetails?.goalUser}</div>
            </div>
            <div class="info">
              <span>Goal Month</span>
              <div>{"August"}</div>
            </div>
            <div class="row">
              <div className="col-lg-12">
                {viewDetails?.goalDetails?.map((item, index) => {
                  let target = item.target_goal_count;
                  let achieved = item.acheivedGoalCount;
                  let calculate = (+achieved / +target) * 100;
                  let displyValue =
                    +calculate == 0 ? 0 : +calculate > 100 ? 100 : +calculate;
                  return (
                    <div className="goal-prgs-card">
                      <div className="card mb-3">
                        <div className="goalk-progress-vert-load">
                          <div className="goal-content-hprz d-flex justify-content-between mt-2">
                            <p className="mb-0">{item.goal_name}</p>
                            <span>
                              Target :{" "}
                              <span className="text-primary fw-500">
                                {item.target_goal_count}
                              </span>
                            </span>
                          </div>
                          <ProgressBar
                            now={now}
                            // label={`${now}%`}
                            className="mt-2"
                          />
                          <span
                            className="progress-bar-label fs-12"
                            style={{ visibility: "hidden" }}
                          >
                            {`${now}%`}
                          </span>
                          <ProgressBar
                            now={displyValue}
                            label={`${Number(displyValue).toFixed(1)}%`}
                            className="mt-2 acieved-hor"
                          />
                          <span className="progress-baracheive-label fs-12">
                            {`${Number(displyValue).toFixed(1)}%`}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {/* <div className="goalk-progress-vert-load mt-2">
                  <div className="goal-content-hprz d-flex justify-content-between">
                    <p className="mb-0">Growth Level Bonus</p>
                    <span>
                      Target : <span className="text-primary fw-500">1000</span>
                    </span>
                  </div>
                  <ProgressBar now={now} label={`${now}%`} className="mt-2" />
                  <ProgressBar
                    now={80}
                    label={`${80}%`}
                    className="mt-2 acieved-hor"
                  />
                </div>
                <div className="goalk-progress-vert-load mt-2">
                  <div className="goal-content-hprz d-flex justify-content-between">
                    <p className="mb-0">Team Leads</p>
                    <span>
                      Target : <span className="text-primary fw-500">1000</span>
                    </span>
                  </div>
                  <ProgressBar now={now} label={`${now}%`} className="mt-2" />
                  <ProgressBar
                    now={50}
                    label={`${50}%`}
                    className="mt-2 acieved-hor"
                  />
                </div> */}
              </div>

              {/* <Spring from={{ percent: 0 }} to={{ percent: 90 }}>
                  {(props) => (
                    <>
                      {viewDetails?.goalDetails?.map((item, index) => {
                        const targetCount = +item.targetGoalAmonut;
                        const achievedCount = +item.acheivedGoalCount;
                        const achievedPercent =
                          (achievedCount / targetCount) * 100;

                        return (
                          <div className="progress" key={index}>
                            <div
                              className="progress-bar target"
                              style={{
                                height: `${props.percent}%`, // Adjust this height as needed
                              }}
                            >
                              <span className="sr-only">{`Target: ${100}`}</span>
                            </div>
                            <div
                              className="progress-bar achieved"
                              style={{
                                height: `${props.percent}%`,
                                width: `${50}%`,
                              }}
                            >
                              <span className="sr-only">{`Achieved: ${50}`}</span>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </Spring> */}
            </div>
          </>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default GoalsHistoryNew;
