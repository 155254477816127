import React, { useEffect, useState } from "react";
import "./contestreward.scss";
import contestrewardImg from "../../assets/images/contestRewardImg.png";
import Helper from "../../Helper/Helper";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import nodata from "../../assets/images/nodata.png";
import { ScreenLoader } from "../../component/Loaders";
import LazyImageLoad from "../../utils/LazyImageLoad";

function ContestReward() {
  const [filter, setFilters] = useState({
    pageNo: 1,
    pagesize: 10,
  });
  const navigate = useNavigate();
  const [contestdata, setContestData] = useState([]);
  const [screenLoading, setScreenLoading] = useState(false);

  useEffect(() => {
    setScreenLoading(true);
    Helper.PostData("local", "contest/get_all_contest", filter, false)
      .then((res) => {
        setScreenLoading(true);
        if (res.status) {
          console.log("res", res);
          setContestData(res.data);
          setScreenLoading(false);
        }
      })
      .finally(() => {
        setScreenLoading(false);
      });
  }, []);
  const handleViewContest = (id) => {
    navigate(`/user/contest/${id}`);
  };
  return (
    <>
      <ScreenLoader loading={screenLoading}>
        <div class="maincontent1 leftrightCir mb-4">
          <div class="">
            <div class="">
              <div class="container">
                <div class="row">
                  <div class="col-12">
                    <div className="pageTit text-center">KPK Contest</div>
                  </div>
                </div>

                <div className="row">
                  {contestdata?.length > 0 ? (
                    contestdata?.map((item, index) => (
                      <div className="col-lg-4 mb-4 col-md-6">
                        <div
                          className="contestRewardCard cursor-pointer"
                          onClick={() => handleViewContest(item.id)}
                        >
                          <div className="cr-img">
                            <LazyImageLoad
                              image={
                                <img
                                  src={item.banner_image}
                                  alt="contestrewardImg"
                                  className="img-fluid"
                                  width="328"
                                  height="250"
                                />
                              }
                            />
                          </div>
                          <div>
                            <div className="cardTit">{item.title}</div>
                            <div className="row justify-content-between">
                              <div className="col-6">
                                {moment(item.start_date).format("MMM DD YYYY")}
                              </div>
                              <div className="col-6 text-right">
                                {moment(item.end_date).format("MMM DD YYYY")}
                              </div>
                            </div>
                          </div>
                          <div
                            className="cardDesc"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          ></div>
                          <div className="cardbotbx">
                            <div className="d-flex justify-content-center">
                              <div>
                                {item.status == 0 ? (
                                  <div className="text-green">Completed</div>
                                ) : (
                                  <div className="text-yellow">On-Going</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12 text-center mt-5 mb-5">
                      <p className="text-center" style={{ color: "#bab5b5" }}>
                        <img
                          alt="cryptocurrency exchange"
                          src={nodata}
                          className="img-fluid"
                        />{" "}
                      </p>
                      <span
                        className="text-center"
                        style={{
                          color: "#848e9c",
                          fontSize: "13px",
                        }}
                      >
                        No record found
                      </span>{" "}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScreenLoader>
    </>
  );
}

export default ContestReward;
