import React, { useEffect, useState } from 'react';
import '../register/register.scss';
import { Link } from 'react-router-dom';

import logoImg from '../../assets/images/kp-new/logo.svg';
import loginImg from '../../assets/images/kp-new/loginimg.svg';

const OTPsendpage = () => {
    useEffect(() => {
        document.body.classList.add('kpstakepage');
    })
    return (

        <div className="kp-stake-plateformpage vh-100 logincir">
            <div className="row no-gutters h-100">
                <div className="col-xl-7 col-lg-6">
                    <div className="row h-100 no-gutters">
                        <div className="offset-xl-3 col-xl-9">
                            <div className="loginleft h-100">
                                <div className="d-flex align-items-start flex-column justify-content-around h-100">
                                    <div className="logologinDiv d-none d-lg-block d-xl-block">
                                        <img src={logoImg} className="img-fluid logoLogin" style={{ width: "166px" }} />
                                    </div>
                                    <div className="logocenter py-5 text-center mx-auto">
                                        <img src={loginImg} alt='loginimg' className="img-fluid logoimg" />
                                    </div>
                                    <div className="row w-100 align-items-center">
                                        <div className="col-md-6 text-center text-md-left">
                                            <Link className="backhomebtn mb-3 mb-lg-0" type="button" to="/">Back to Home <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.304 2.25171L12.8554 11.3975C12.8333 11.5374 12.7564 11.6628 12.6418 11.7461C12.5272 11.8294 12.3842 11.8637 12.2442 11.8416C12.1043 11.8194 11.9789 11.7425 11.8956 11.6279C11.8123 11.5133 11.778 11.3703 11.8002 11.2303L13.047 3.35794L1.051 12.0736C0.936216 12.1509 0.795886 12.1807 0.659573 12.1567C0.52326 12.1326 0.401608 12.0566 0.320247 11.9446C0.238885 11.8326 0.204167 11.6934 0.223405 11.5563C0.242644 11.4192 0.314337 11.295 0.423382 11.2097L12.4194 2.4941L4.54701 1.24723C4.40707 1.22507 4.28167 1.14822 4.19839 1.0336C4.11511 0.918972 4.08078 0.77596 4.10294 0.636021C4.12511 0.496082 4.20195 0.370679 4.31658 0.287399C4.4312 0.20412 4.57421 0.169786 4.71415 0.19195L13.8599 1.6405C13.9999 1.66266 14.1253 1.73951 14.2085 1.85413C14.2918 1.96876 14.3262 2.11177 14.304 2.25171Z" fill="#1140ff" />
                                            </svg>
                                            </Link>
                                        </div>
                                        <div className="col-md-6  text-center text-md-right">
                                            <div className="fs-14 light-text">&copy; 2023 - Koinpark - All Rights Reserved.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-lg-6">
                    <div className="loginright h-100">
                        <div className="row">
                            <div className="col-xl-9">
                                <div className="logologinDiv d-lg-none mb-3 text-center">
                                    <img src={loginImg} alt='logoimg' className="img-fluid logoLogin" style={{ width: "166px" }} />
                                </div>
                                <div className="loginformbox">
                                    <div className="loginbx">
                                        <div className="d-flex align-items-center flex-column flex-md-row">
                                            <div className="fs-30 fw-500 mb-4 order-2 order-md-1">Email OTP</div>
                                            <div className="text-right light-text mb-4 ml-auto order-1 order-md-2">
                                                <br />
                                                <Link className="text-primary" to='/'></Link></div>
                                        </div>


                                        <div className="form-group formInputs loginInput mb-4">
                                            <label>Enter Received OTP<span className="text-primary" maxlength="1">*</span></label>

                                            <div className="row form-row">
                                                <div className='col'>
                                                    <input className='form-control' type='text' />
                                                </div>
                                                <div className='col'>
                                                    <input className='form-control' type='text' />
                                                </div>
                                                <div className='col'>
                                                    <input className='form-control' type='text' />
                                                </div>
                                                <div className='col'>
                                                    <input className='form-control' type='text' />
                                                </div>
                                                <div className='col'>
                                                    <input className='form-control' type='text' />
                                                </div>
                                                <div className='col'>
                                                    <input className='form-control' type='text' />
                                                </div>
                                                {/* <div className="col-12">
                                                    <ng-otp-input type="tel" className="cust-otp" (onInputChange)="onOtpChange($event)" inputmode="numeric" [config]="{length:6}"></ng-otp-input>
                                                </div> */}
                                            </div>
                                        </div>



                                        <div className="mt-4 mb-4">
                                            <button type="button" className="btn btn-primary btn-block fs-18 fw-500 btn-send">Send</button>
                                            {/* <button className="btn btn-primary btn-block fs-18 fw-500 btn-send" type="button" >Loading...<i
                                        className="fa fa-spinner fa-spin"></i></button> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    );
}

export default OTPsendpage;