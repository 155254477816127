import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Helper from '../../../Helper/Helper'
import cancel from '../../../assets/images/cancel.webp'
import { SpinnerLoader } from '../../../component/Loaders'
import '../modalpage.scss'

const ConfirmationModal = ({ getBotPieChart, Data, getOrderInfo }) => {
	const [modal, setModal] = useState(false)
	const [deleteLoading, setDeleteLoading] = useState(false)
	const toggle = () => {
		setModal(!modal)
	}

	const handleDelete = () => {
		setDeleteLoading(true)
		const payload = {
			exchange_account_id: +Data.exchange_id
		}
		Helper.PostData('user', 'user/botRouter/remove_account', payload, true)
			.then(res => {
				if (res.status) {
					getBotPieChart()
					getOrderInfo()
					setModal(false)
				}
			})
			.finally(() => {
				setDeleteLoading(false)
			})
	}

	return (
		<>
			<button type='button' class='btn-exg-del px-4' onClick={toggle}>
				Delete
			</button>
			<Modal
				isOpen={modal}
				toggle={toggle}
				className={'modal-md modal-dialog-centered mymodal'}
				size={'sm'}
				backdrop='static'
				keyboard={false}
			>
				<ModalBody className='p-4'>
					<div className='text-center mb-2'>
						<img src={cancel} width={'50%'} />
					</div>
					<p className='text-center'>
						<b>Do you want to delete an exchange?</b>
						<br />
						<b>{Data.exchange_name}</b>
					</p>
					<div className='d-flex justify-content-between mt-3'>
						<div>
							<button type='button' class='btn-exg  px-4' onClick={toggle}>
								Cancel
							</button>
						</div>
						<div>
							{!deleteLoading ? (
								<button
									type='button'
									class='btn-exg-del px-4'
									onClick={() => handleDelete()}
								>
									Delete
								</button>
							) : (
								<button type='button' class='btn-exg-del px-4' disabled>
									<SpinnerLoader />
								</button>
							)}
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default ConfirmationModal
