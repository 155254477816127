import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NumericFormat } from 'react-number-format'
import { useDispatch } from 'react-redux'
import { Form, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Helper from '../../../Helper/Helper'
import { ButtonLoader } from '../../../component/Loaders'
import CloseBtn from '../../../component/Reusable/CloseBtn'
import { setCallProfileApi } from '../../../component/header/headerslice'

const Transfer = ({ getBotUserWallet, botUserWalletData }) => {
	const [modal, setModal] = useState(false)
	const [btnLoading, setBtnLoading] = useState(false)
	const [balance, setBalance] = useState('')

	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		reset
	} = useForm({
		mode: 'all',
		reValidateMode: 'onChange'
	})

	const toggle = () => {
		reset()
		setBalance('')
		setModal(!modal)
	}

	const onSubmit = data => {
		setBtnLoading(true)
		Helper.PostData('aabu', 'user/botRouter/move_to_hold', { ...data }, true)
			.then(res => {
				if (res.status) {
					reset()
					setBalance('')
					setModal(false)
					getBotUserWallet()
				}
			})
			.finally(() => setBtnLoading(false))
	}

	return (
		<>
			<button type='button' class='btn btn-primary px-4' onClick={toggle}>
				Transfer
			</button>
			<Modal
				isOpen={modal}
				toggle={toggle}
				className={'modal-md modal-dialog-centered mymodal'}
				size={'md'}
				backdrop='static'
				keyboard={false}
			>
				<ModalHeader toggle={toggle} close={<CloseBtn onClick={toggle} />}>
					Transfer to hold wallet
				</ModalHeader>
				<ModalBody className='p-4'>
					<Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
						<div class='row'>
							<div className='col-12'>
								<div class='form-group formInputs walletInput'>
									<div className='d-flex justify-content-between'>
										<label class='col-form-label'>
											Wallet <span className='text-danger'>*</span>
										</label>
										{balance && (
											<span className='text-gray'>
												Balance:&nbsp;
												<b className='text-light'>
													{botUserWalletData[balance]}
												</b>
												&nbsp;KPK
											</span>
										)}
									</div>

									<div className='form-group formInputs loginInput'>
										<select
											className='form-control form-control-lg'
											{...register('wallet_type', {
												required: '* Please select wallet'
											})}
											onChange={({ target: { selectedOptions } }) => {
												setBalance(selectedOptions[0].getAttribute('data-key'))
											}}
										>
											<option selected value={''} hidden>
												Select Wallet
											</option>
											<option data-key='main_wallet' value='Main'>
												Main Wallet
											</option>
											<option data-key='bonus_wallet' value='Reward'>
												Reward Wallet
											</option>
										</select>
										{errors.wallet_type && (
											<p className='text-danger'>
												{errors.wallet_type.message}
											</p>
										)}
									</div>
									{balance && (
										<>
											<label class='col-form-label'>
												Amount <span className='text-danger'>*</span>
											</label>
											<Controller
												control={control}
												name='amount'
												rules={{
													required: '* Amount is required',
													validate: value => {
														let min = +botUserWalletData.min
														let max = +botUserWalletData.max
														let wallet_bal = +botUserWalletData[balance]
														value = +value
														if (value) {
															if (value > wallet_bal) {
																return `* Insufficient main balance`
															} else if (value < min) {
																return `* Minimum transfer amount is ${min} KPK`
															} else if (value > max) {
																return `* Maximum transfer amount is ${max} KPK`
															} else {
																return true
															}
														} else {
															return '* Amount is required'
														}
													}
												}}
												render={({ field: { onChange, name, value } }) => (
													<NumericFormat
														onChange={onChange}
														placeholder='0.00'
														className='form-control fromInput'
														name={name}
														value={value}
														allowNegative={false}
														// decimalScale={0}
														// maxLength={7}
													/>
												)}
											/>
											{errors.amount && (
												<p className='text-danger'>{errors.amount.message}</p>
											)}
										</>
									)}
								</div>
								<div className='d-flex justify-content-between min-max'>
									<div>
										<span className='text-gray'>Min</span>
										<span>
											<b> {+botUserWalletData.min} KPK</b>
										</span>
									</div>
									<div>
										<span className='text-gray'>Max</span>
										<span>
											<b> {+botUserWalletData.max} KPK</b>
										</span>
									</div>
								</div>
								{!btnLoading ? (
									<button className='btn btn-primary w-100 mt-3' type='submit'>
										Submit
									</button>
								) : (
									<button
										className='btn btn-primary w-100 mt-3'
										disabled
										type='button'
									>
										<ButtonLoader />
									</button>
								)}
							</div>
						</div>
					</Form>
				</ModalBody>
			</Modal>
		</>
	)
}

export default Transfer
