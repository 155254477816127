import React, { useState } from "react";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { Step, StepLabel, Stepper } from "@mui/material";
import Helper from "../../../Helper/Helper";
import { setProfileData } from "../../../component/header/headerslice";
import { setCryptoWithdrawStoreOtpType } from "./cryptoWithdrawSlice";

function TransferEnterMailOtp({props}) {
  return (
    <div className="otp">
      <label className="mt-3 text-light">Enter the 6 digit mail code</label>
      {/* <Form onSubmit={props.handleSubmit(props.WithdrawRequest)}> */}
        <div className="mt-3">
          <OtpInput
            value={props.otp}
            onChange={(otp) => {
              props.setOtp(otp);
            }}
            numInputs={6}
            inputStyle="form-control"
            containerStyle="cdChIpR cdChIpR1"
            className="form-group"
            isInputNum={true}
          />
        </div>

        <div className="mt-3 text-center">
          {props.otp && props.otp.length === 6 ? (
            <button
              className="btn btn-primary btn-block fs-18 fw-500"
              disabled={props.loader}
            >
              {props.loader ? <Spinner size="sm" /> : "Submit"}
            </button>
          ) : (
            <button className="btn btn-primary btn-block fs-18 fw-500" disabled>
              {props.loader ? <Spinner size="sm" /> : "Submit"}
            </button>
          )}
        </div>
        <div className="text-center mt-1">{props.timer}</div>
      {/* </Form> */}
    </div>
  );
}
function TransferEnterMobileOtp({props}) {
  return (
    <div className="otp">
      <label className="mt-3 text-light">Enter the 6 digit mobile code</label>
      {/* <Form onSubmit={props.handleSubmit(props.WithdrawRequest)}> */}
        <div className="mt-3">
          <OtpInput
            value={props.otp}
            onChange={(otp) => {
              props.setOtp(otp);
            }}
            numInputs={6}
            inputStyle="form-control"
            containerStyle="cdChIpR cdChIpR1"
            className="form-group"
            isInputNum={true}
          />
        </div>

        <div className="mt-3 text-center">
          {props.otp && props.otp.length === 6 ? (
            <button
              className="btn btn-primary btn-block fs-18 fw-500"
              disabled={props.loader}
            >
              {props.loader ? <Spinner size="sm" /> : "Submit"}
            </button>
          ) : (
            <button className="btn btn-primary btn-block fs-18 fw-500" disabled>
              {props.loader ? <Spinner size="sm" /> : "Submit"}
            </button>
          )}
        </div>
        <div className="text-center mt-1">{props.timerMobT}</div>
      {/* </Form> */}
    </div>
  );
}
function getStepContent(step, setActiveStep, props) {
  switch (step) {
    case 0:
      return <TransferEnterMailOtp props={props} />;
    case 1:
      return <TransferEnterMobileOtp props={props} />;
  }
}
export function TransferOtpVerification(props) {
  const checkType = useSelector(
    (state) => state.withdrawCryptoParamReducer.transOtp
  );
  const dispatch = useDispatch();
  const [steps, setSteps] = useState([
    "Email Authentication",
    "Mobile Authentication",
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const methods = useForm({
    defaultValues: {},
  });
  const withdrawParamDetails = useSelector(
    (state) => state.withdrawParamReducer.payloadWith
  );
  const WithdrawRequest = (value, type) => {
    let valueResend = {
      receiver_address: withdrawParamDetails?.recev_addr,
      amount:withdrawParamDetails?.amount
    };
    if (checkType == "email") {
      props.setLoading(true);
      Helper.PostData(
        "wallet",
        "withdraw/reward_withdraw_initiate",
        {
          user_id: localStorage.getItem("tip"),
          currency_symbol: "KPK",
          device_type: "web",
          amount: withdrawParamDetails.amount,
          otp_code: props.otp,
          otp_type: "email",
          receiver_address: withdrawParamDetails.recev_addr,
          validation: 2,
        },
        true
      ).then((res) => {
        if (res.status) {
          props.setLoading(false);
          props.setOtp("");
          props.AutoWithdrawRequest(valueResend, "mobile");
          setActiveStep(activeStep + 1);
          dispatch(setCryptoWithdrawStoreOtpType("mobile"));
          Helper.PostData("user", "user/getuserprofile", {}, false).then(
            (res) => {
              if (res.status) {
                dispatch(setProfileData(res.user[0]));
              }
            }
          );
        } else {
          props.setLoading(false);
        }
      });
    } else {
      props.setLoading(true);
      Helper.PostData(
        "wallet",
        "withdraw/reward_withdraw_initiate",
        {
          user_id: localStorage.getItem("tip"),
          currency_symbol: "KPK",
          device_type: "web",
          amount: withdrawParamDetails.amount,
          otp_code: props.otp,
          otp_type: "mobile",
          receiver_address: withdrawParamDetails.recev_addr,
          validation: 2,
        },
        true
      ).then((res) => {
        if (res.status) {
          props.setOtp("");
          // setCheckType("mobile");
          props.setLoading(false);
          props.setOtpModal(false);
          props.setModal(false);
          props.toggleClose();
          props.setAmount("");
          setActiveStep(0);
          dispatch(setCryptoWithdrawStoreOtpType("email"));
        } else {
          props.setLoading(false);
        }
      });
    }
  };
  return (
    <Modal
      className="ver_pop"
      size="sm"
      isOpen={props.otpModal}
      toggleModal={props.toggleOtp}
    >
      <ModalHeader toggle={props.toggleCloseOtp}>
        <h5 className="text-center text-light">OTP Verification</h5>
      </ModalHeader>
      <ModalBody>
        <div className="modal-otp-withdraw">
          <Stepper activeStep={activeStep} alternativeLabel className="">
            {steps.map((step) => (
              <Step key={step.key} completed={step.status}>
                <StepLabel
                  style={{
                    textTransform: "capitalize",
                    color: "white !important",
                  }}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="otp">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(WithdrawRequest)}>
              {getStepContent(activeStep, setActiveStep, props)}
            </form>
          </FormProvider>
        </div>
      </ModalBody>
    </Modal>
  );
}
