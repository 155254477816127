import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { deviceType } from "react-device-detect";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import Helper from "../../../Helper/Helper";
import { TextLoader } from "../../../component/Loaders";
import CloseBtn from "../../../component/Reusable/CloseBtn";
import {
  setCallProfileApi,
  setStakeModel,
} from "../../../component/header/headerslice";
import ConfirmStakeModal from "../confirmstake";
import "./staking.scss";

const StakingModal = () => {
  const {
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
    register,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const { handleSubmit: submit1 } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [stakeFormData, setStakeFormData] = useState({});
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [stakingData, setStakingData] = useState([]);
  const [packages, setPackages] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [months, setMonths] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const toggleConfirmationModal = () =>
    setConfirmationModal(!confirmationModal);

  const Profile = useSelector((state) => state.header.profileData);
  const stakeModal = useSelector((state) => state.header.stakeModal);

  useEffect(() => {
    Helper.GetData("stake", "stake/getStakeList").then((res) => {
      if (res.status) {
        let { data } = res;
        let currency = [];
        let packagesArr = [
          ...new Set(
            data.map((pack) => {
              currency.push(pack.currency);
              return pack.stakingpackage;
            })
          ),
        ];
        currency = [...new Set(currency)];
        setStakingData(data);
        let sortingData = packagesArr.sort((a, b) => a - b);
        setPackages(sortingData);
        setCurrency(currency[0]);
        if (currency.length == 1) {
          setValue("currency", currency[0]);
          getWalletBalance(currency[0], data);
        }

      }
    });

  }, []);

  useEffect(() => {
    reset({});
    setModal(stakeModal);
  }, [stakeModal]);

  const handleSelectPackage = (pack) => {
    reset({ month: "", percentage: "" });
    let months = stakingData
      .filter((data) => data.stakingpackage == pack)
      .map((item) => item.stakingmonth);
    setMonths(months);
  };

  const getWalletBalance = (currency_symbol, stakeData) => {
    setSelectedCurrency({});
    const currency = stakeData.find((pack) => pack.currency == currency_symbol);
    let param = {
      user_id: localStorage.getItem("tip"),
      currency_symbol,
      currency_id: currency?.currency_id,
    };
    setBalanceLoading(true);
    Helper.PostData("stake", "address/get_balance", param, false).then(
      (res) => {
        if (res.status) {
          setSelectedCurrency(res.data);
          setBalanceLoading(false);
        }
      }
    );
  };

  const handleSelectMonth = (month) => {
    let { staking_package } = getValues();

    let choosedStakeData = stakingData.find(
      (data) =>
        data.stakingpackage == staking_package &&
        data.currency == currency &&
        data.stakingmonth == month
    );
    setValue("percentage", choosedStakeData?.rewardspercentage);
    setValue("stakeId", choosedStakeData?.id);
    setValue("skaing_id_per",choosedStakeData)
  };

  const handleFormSubmit = (formdata) => {
    let {skaing_id_per} = getValues()
    if (
      selectedCurrency.available_balance ||
      selectedCurrency.available_balance == 0
    ) {
      let reward_amount_per_month =
        (formdata.staking_package / 100) * formdata.percentage;
      let overall_reward_amount =skaing_id_per?.staking_id == "ST_13245691" ? skaing_id_per?.stakingpackage: formdata.month * reward_amount_per_month;
      let stake_date = moment(Date.now()).format("MMM D, YYYY");
      let data = {
        ...formdata,
        user_random_id: Profile.userrandomid,
        reward_amount_per_month,
        stake_date,
        overall_reward_amount,
        currency: currency,
      };
     
      setStakeFormData(data);
      toggleConfirmationModal();
    }
  };

  const handleStake = () => {
    setBtnLoading(true);
    let param = { stakeId: getValues("stakeId"), device_type: deviceType };
    Helper.PostData("stake", "stake/stakebyuser", param)
      .then((res) => {
        if (res.status) {
          setMonths([]);
          setConfirmationModal(false);
          dispatch(setStakeModel(false));
          dispatch(setCallProfileApi());
          navigate("/user/dashboard?activeTab=4");
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={"modal-md modal-dialog-centered mymodal"}
        size={"md"}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggle}
          close={<CloseBtn onClick={() => dispatch(setStakeModel(false))} />}
        >
          Staking
        </ModalHeader>
        <ModalBody className="px-4">
          <Form onSubmit={handleSubmit(handleFormSubmit)} autoComplete="off">
            <div class="row">
              <div class="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">User ID</label>
                  <input
                    class="form-control"
                    name="user_random_id"
                    {...register("user_random_id")}
                    disabled
                    defaultValue={Profile.userrandomid}
                  />
                </div>
                {/* <div class='form-group formInputs walletInput'>
								<label class='col-form-label'>Currency</label>
								<input class='form-control' name='gettingcurrency' />
								<br />
								<p>Balance : 20</p>
							</div> */}
                <div className="row">
                  <div class="col-6">
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">Currency</label>
                      <input
                        class="form-control"
                        name="currency"
                        disabled
                        value={currency}
                        {...register("currency", {
                          // required: '* Currency is required'
                        })}
                        // onChange={({ target: { value } }) =>
                        // 	getWalletBalance(value, stakingData)
                        // }
                      />

                      {/* {errors.currency && (
												<p className='text-danger'>{errors.currency.message}</p>
											)} */}
                    </div>
                  </div>

                  <div class="col-6">
                    {/* {selectedCurrency.available_balance ||
										(selectedCurrency.available_balance == 0 && ( */}
                    <div className="pt-5">
                      <p>
                        Balance :{" "}
                        {balanceLoading ? (
                          <TextLoader />
                        ) : (
                          <>
                            {selectedCurrency.available_balance}{" "}
                            {selectedCurrency.currency_symbol}
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  {/* ))} */}
                </div>
                {selectedCurrency.currency_symbol && (
                  <div class="form-group formInputs walletInput">
                    <label class="col-form-label">Staking Package</label>
                    <select
                      class="form-control"
                      name="staking_package"
                      {...register("staking_package", {
                        required: "* Staking Package is required",
                        validate: (value) => {
                          if (+value <= +selectedCurrency.available_balance) {
                            return true;
                          } else {
                            return `* Insufficient ${selectedCurrency.currency_symbol} Balance`;
                          }
                        },
                      })}
                      onChange={({ target: { value } }) =>
                        handleSelectPackage(value)
                      }
                    >
                      {packages.length > 1 && (
                        <option value="" selected hidden>
                          Choose your Staking Package
                        </option>
                      )}
                      {packages.map((pack, index) => (
                        <option value={pack} key={index}>
                          {pack}
                        </option>
                      ))}
                    </select>
                    {errors.staking_package && (
                      <p className="text-danger">
                        {errors.staking_package.message}
                      </p>
                    )}
                  </div>
                )}
                {months.length > 0 && (
                  <>
                    <div class="form-group formInputs walletInput">
                      <label class="col-form-label">Duration Month</label>
                      <select
                        class="form-control"
                        name="month"
                        {...register("month", {
                          required: "* Duration Month is required",
                        })}
                        onChange={({ target: { value } }) =>
                          handleSelectMonth(value)
                        }
                      >
                        {/* {months.length > 0 && ( */}
                        <option value="" selected hidden>
                          Choose your Duration Month
                        </option>
                        {/* // )} */}
                        {months.map((month, index) => (
                          <option value={month} key={index}>
                            {month}
                          </option>
                        ))}
                      </select>
                      {errors.month && (
                        <p className="text-danger">{errors.month.message}</p>
                      )}
                    </div>
                  </>
                )}
                {months[0] !== "24" && (
                  <div class="form-group formInputs walletInput">
                    <label class="col-form-label">Percentage</label>
                    <input
                      class="form-control"
                      type="text"
                      {...register("percentage", {})}
                      disabled
                    />
                  </div>
                )}
                <div class="text-center mb-2">
                  <button class="btn btn-primary px-4" type="submit">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
      <ConfirmStakeModal
        toggle={toggleConfirmationModal}
        modal={confirmationModal}
        handleSubmit={submit1}
        handleAPICall={handleStake}
        FormData={stakeFormData}
        loader={btnLoading}
      />
    </>
  );
};

export default StakingModal;
