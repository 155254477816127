import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import BotPrivateRoute from "./PrivateRoutes/BotPrivateRoute";
import UserPrivateRoutes from "./PrivateRoutes/UserPrivateRoutes";
import CRMPrivateRoutes from "./PrivateRoutes/crmPrivateRoutes";
import ReferralTree from "./ReferralTree";
import IosApp from "./ReferralTree/IosTree";
import MobileReferalTree from "./ReferralTree/MobileReferalTree";
import UnAuth from "./UnAuth";
import BotLayout from "./layout/BotLayout";
import ContestLayout from "./layout/ContestLayout";
import DashAppLayout from "./layout/dashapplayout";
import Mainlayout from "./layout/mainlayout";
import metaData from "./metaData/metadata";
import BotsInfo from "./pages/Bot";
import ViewSingleBotInfo from "./pages/Bot/viewBotInfo";
import BotDashboard from "./pages/Botdashboard";
import WebConfirm from "./pages/Confirmation/WebConfirm";
import PCEvent from "./pages/Event/Event";
import PCEventdetail from "./pages/EventDetails/EventDetails";
import Incubator from "./pages/Incubator";
import NotFound from "./pages/NotFound/NotFound";
import Pricing from "./pages/Pricing/pricing1";
import AboutusPage from "./pages/aboutus";
import Paymentpolicypage from "./pages/bot-payment-policy";
import Contactuspage from "./pages/contact";
import ViewTicket from "./pages/contact/viewTicket";
import ContestReward from "./pages/contestreward";
import DashboardPage from "./pages/dashboard";
import Exchangepage from "./pages/exchange";
import FaqPage from "./pages/faq";
import Forgetpassword from "./pages/forgetpassword";
import HomePage from "./pages/home";
import Loginpage from "./pages/login";
import OTPsendpage from "./pages/otpsend";
import Plotbooking from "./pages/plotbooking";
import CustomModal from "./pages/plotbooking/test";
import Privacypolicypage from "./pages/privacy";
import ProfilePage from "./pages/profile";
import Registerpage from "./pages/register";
import RegisterConfirm from "./pages/register/RegisterConfirm";
import Resetpassword from "./pages/resetpassword";
import TermsandConditionPage from "./pages/terms";
import WinnerUser from "./pages/winningUser";
import brandlogo from "./assets/images/kp-new/kpklogo.webp";
import CRMDashboard from "./pages/CRM";
import Test from "./pages/plotbooking/test";
function App() {
  console.log("ver :", "2.1.2.2.1")
  useEffect(() => {
    const handleTokenReceived = (event) => {
      const receivedToken = event.detail;
      localStorage.setItem("kk", receivedToken);
    };
    window.addEventListener("tokenReceived", handleTokenReceived);
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaData.home.title}</title>
        <meta name="description" content={metaData.home.description} />
        <meta name="keywords" content={metaData.home.keywords} />
        <link
          rel="preload"
          href="../fonts/Euclid-Circular-B-Light.woff2"
          as="font"
          type="font/woff2"
          crossorigin
        />
        <link
          rel="preload"
          href="../fonts/Euclid-Circular-B-Regular.woff2"
          as="font"
          type="font/woff2"
          crossorigin
        />
        <link
          rel="preload"
          href="../fonts/Euclid-Circular-B-Medium.woff2"
          as="font"
          type="font/woff2"
          crossorigin
        />
        <link
          rel="preload"
          href="../fonts/Euclid-Circular-B-SemiBold.woff2"
          as="font"
          type="font/woff2"
          crossorigin
        />
        <link
          rel="preload"
          href="../fonts/Euclid-Circular-B-Bold.woff2"
          as="font"
          type="font/woff2"
          crossorigin
        />
        <link rel="preload" href={brandlogo} as="image" />
      </Helmet>
      <BrowserRouter>
        <>
          <Routes>
            {/* HomePage */}

            <Route path="/" element={<Mainlayout />}>
              <Route index element={<HomePage />} />
            </Route>

            {/* UnAuth Routes before Login */}
            <Route path="/oauth" element={<UnAuth />}>
              <Route path="login" element={<Loginpage />} />
              <Route path="register" element={<Registerpage />} />
              <Route path="resetpassword" element={<Resetpassword />} />
              <Route path="forgetpassword" element={<Forgetpassword />} />
              <Route path="otpsend" element={<OTPsendpage />} />
            </Route>

            {/*User Private Routes after login */}
            <Route element={<UserPrivateRoutes />}>
              <Route path="/user" element={<DashAppLayout />}>
                <Route path="dashboard" element={<DashboardPage />} />
                <Route path="wallet" element={<ProfilePage />} />
                <Route path="exchange" element={<Exchangepage />} />
                <Route path="plotbook" element={<Plotbooking />} />
                <Route path="referral" element={<ReferralTree />} />
                <Route path="ticket" element={<ViewTicket />} />
                <Route path="support" element={<Contactuspage />} />
                <Route path="bot" element={<BotsInfo />} />
              </Route>
              <Route element={<BotPrivateRoute />}>
                <Route path="/bot" element={<BotLayout />}>
                  <Route path="dashboard" element={<BotDashboard />} />
                  <Route
                    path="view-order-info"
                    element={<ViewSingleBotInfo />}
                  />
                </Route>
              </Route>
            </Route>
            <Route element={<CRMPrivateRoutes />}>
              <Route path="/crm" element={<DashAppLayout />}>
                <Route path="dashboard" element={<CRMDashboard />} />
              </Route>
            </Route>
            <Route path="app/referral" element={<MobileReferalTree />} />
            <Route path="ios/referral" element={<IosApp />} />
            <Route path="/user" element={<ContestLayout />}>
              <Route path="contest" element={<ContestReward />} />
              <Route path="contest/:id" element={<WinnerUser />} />
            </Route>
            {/* Other Routes */}
            <Route path="/webconfirm" element={<WebConfirm />} />
            <Route path="/event" element={<PCEvent />} />
            <Route path="/event/:id" element={<PCEventdetail />} />
            <Route path="/registerConfirm" element={<RegisterConfirm />} />
            <Route path="/test" element={<CustomModal />} />
            <Route path="/enjs" element={<Test />} />
            <Route path="/sub-incubator" element={<Incubator />} />
            <Route path="/aboutus" element={<AboutusPage />} />
            <Route path="/terms" element={<TermsandConditionPage />} />
            <Route path="/privacy" element={<Privacypolicypage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route
              path="/bot-membership"
              element={<Pricing footer={true} from={"main"} />}
            />
            <Route path="/payment-policy" element={<Paymentpolicypage />} />
            {/* <Route path='/delete-account' element={<Delete />} /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      </BrowserRouter>
    </>
  );
}

export default App;
