import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useParams } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Helper from "../../Helper/Helper";
import closeIcon from "../../assets/images/close-x.svg";
import { ScreenLoader } from "../../component/Loaders";
import { toFixedUtil } from "../../component/utils";
import "./winninguser.scss";
import linkarr1 from "../../assets/images/linkarr-1.svg";
import linkarr2 from "../../assets/images/linkarr-2.svg";
import linkarr3 from "../../assets/images/linkarr-3.svg";
import linkarr4 from "../../assets/images/linkarr-4.svg";
function LeaderBoard({ toggleImage }) {
  const { id } = useParams();
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings2 = {
    dots: window.innerWidth > 767 ? true : false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [PrizeList, setPrizeList] = useState({
    data: [],
    contestStatus: "",
  });
  const [ContestInfo, setContestInfo] = useState({});
  const [LeaderBoard, setLeaderBoard] = useState([]);
  const [LeaderBoardTable, setLeaderBoardTable] = useState([]);
  const [AchievedTable, setAchievedTable] = useState([]);
  const [IsShows, setIsShows] = useState({});
  const [screenLoading, setScreenLoading] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [isOpen, setIsOpen] = useState({ open: false, index: -1 });

  useEffect(() => {
    setScreenLoading(true);
    Helper.PostData(
      "local",
      "contest/get_all_contest_prize",
      {
        // contest_id: "1",
        type: "modal",
      },
      false
    )
      .then((res) => {
        if (res.status) {
          console.log("res", res);
          setPrizeList({
            data: res.data,
            contestStatus: res.contest_status,
          });

          // change contest_status
          if (res.contest_status == 1) {
            Helper.PostData(
              "local",
              "contest/get_contest",
              { user_id: localStorage.getItem("tip"), contest_id: id },
              false
            ).then((resp) => {
              if (resp.status) {
                setContestInfo(resp);
              }
            });
          } else {
            Helper.PostData(
              "local",
              "contest/get_final_leaderboard",
              {
                contest_id: id,
              },
              false
            ).then((resp) => {
              if (resp.status) {
                setLeaderBoard(resp.data);
              }
            });
          }
        }
      })
      .finally(() => {
        setScreenLoading(false);
      });
    Helper.PostData(
      "local",
      "user/contestGallery",
      {
        id: id,
      },
      false
    )
      .then((resp) => {
        if (resp.status) {
          console.log("resp", resp);
          setGallery(resp.data[0].gallery);
        }
      })
      .finally(() => {
        setScreenLoading(false);
      });
  }, []);
  const [UserCount, setUserCount] = useState({
    levelCount: "",
    growthLevel: "",
    dirRef: "",
    teamRef: "",
    batchRank: "",
  });

  useEffect(() => {
    Helper.PostData(
      "local",
      "contest/leader_board_table",
      {
        user_id: localStorage.getItem("tip"),
        type: "modal",
      },
      false
    ).then((res) => {
      if (res.status) {
        setLeaderBoardTable(res.nextTarget);
        setAchievedTable(res.achieved);
        setIsShows(res.isShows);
        let conte = res.contestData;
        setUserCount({
          levelCount: conte.data.levelBonus,
          growthLevel: conte.data.growthLevelBonus,
          dirRef: conte.data.dirRef,
          teamRef: conte.data.teamRef,
          batchRank: conte.data.batchRank,
          stake: conte.data.stakeAmount,
        });
      }
    });
  }, []);

  return (
    <>
      <ScreenLoader loading={screenLoading}>
        <div class="leftrightCir mb-5">
          <div class="">
            <div className="container bot-db">
              {localStorage.getItem("tip") && (
                <div className="row">
                  <div className="col-lg-12 mb-4 col-md-12 col-sm-12">
                    {AchievedTable?.length > 0 && (
                      <div class="cmntbl venu_bnl mt-3">
                        <div className="table-responsive">
                          <div className="text-center">
                            <h4>Achieved</h4>
                          </div>
                          <table className="table bot-tbl ledr_tbl">
                            <thead>
                              <tr>
                                <th>Price</th>
                                {IsShows?.level == 1 ? (
                                  <th>Level Bonus</th>
                                ) : (
                                  <th className="d-none">Level Bonus</th>
                                )}
                                {IsShows?.growth == 1 ? (
                                  <th>Growth Level Bonus</th>
                                ) : (
                                  <th className="d-none">Growth Level Bonus</th>
                                )}
                                {IsShows?.direct == 1 ? (
                                  <th>Direct Ref Count</th>
                                ) : (
                                  <th className="d-none">Direct Ref Count</th>
                                )}
                                {IsShows?.team == 1 ? (
                                  <th>Team Ref Count</th>
                                ) : (
                                  <th className="d-none">Team Ref Count</th>
                                )}
                                {IsShows?.stake == 1 ? (
                                  <th>Stake</th>
                                ) : (
                                  <th className="d-none">Stake</th>
                                )}
                                {IsShows?.batch == 1 ? (
                                  <th>Batch</th>
                                ) : (
                                  <th className="d-none">Batch</th>
                                )}
                                {/* <th>Status</th> */}
                              </tr>
                            </thead>
                            {AchievedTable?.map((item, index) => (
                              <tbody>
                                <tr key={index}>
                                  <td style={{ width: "15px" }}>
                                    <div className="dtails">
                                      <img
                                        src={item.prize_sub_image}
                                        className="img-fluid"
                                      />
                                    </div>
                                  </td>
                                  {IsShows.level == 1 ? (
                                    <td>
                                      {" "}
                                      {item.volume_level_amount_min == "0" ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <>
                                          <div class="">
                                            <div
                                              class="circle per-29"
                                              style={{
                                                backgroundImage: `conic-gradient(#3766cc ${
                                                  Number(
                                                    (UserCount.levelCount /
                                                      item.volume_level_amount_min) *
                                                      100
                                                  ).toFixed(2) > 100
                                                    ? "100"
                                                    : Number(
                                                        (UserCount.levelCount /
                                                          item.volume_level_amount_min) *
                                                          100
                                                      ).toFixed(2)
                                                }%, #d5d4d4 0)`,
                                              }}
                                            >
                                              <div class="inner">
                                                {" "}
                                                {Number(
                                                  (UserCount.levelCount /
                                                    item.volume_level_amount_min) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100%"
                                                  : Number(
                                                      (UserCount.levelCount /
                                                        item.volume_level_amount_min) *
                                                        100
                                                    ).toFixed(2) + "%"}
                                              </div>
                                            </div>
                                          </div>
                                          <p className="mt-2">
                                            {toFixedUtil(UserCount.levelCount) +
                                              "/" +
                                              item.volume_level_amount_min}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                  ) : (
                                    <td className="d-none">
                                      {" "}
                                      {item.volume_level_amount_min == "0" ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <>
                                          <div class="">
                                            <div
                                              class="circle per-29"
                                              style={{
                                                backgroundImage: `conic-gradient(#3766cc ${
                                                  Number(
                                                    (UserCount.levelCount /
                                                      item.volume_level_amount_min) *
                                                      100
                                                  ).toFixed(2) > 100
                                                    ? "100"
                                                    : Number(
                                                        (UserCount.levelCount /
                                                          item.volume_level_amount_min) *
                                                          100
                                                      ).toFixed(2)
                                                }%, #d5d4d4 0)`,
                                              }}
                                            >
                                              <div class="inner">
                                                {" "}
                                                {Number(
                                                  (UserCount.levelCount /
                                                    item.volume_level_amount_min) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100%"
                                                  : Number(
                                                      (UserCount.levelCount /
                                                        item.volume_level_amount_min) *
                                                        100
                                                    ).toFixed(2) + "%"}
                                              </div>
                                            </div>
                                          </div>
                                          <p className="mt-2">
                                            {toFixedUtil(UserCount.levelCount) +
                                              "/" +
                                              item.volume_level_amount_min}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                  )}
                                  {IsShows.growth == 1 ? (
                                    <td>
                                      {item.volume_growth_amount_min == "0" ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <>
                                          <div class="">
                                            <div
                                              class="circle per-29"
                                              style={{
                                                backgroundImage: `conic-gradient(rgb(167 51 167) ${
                                                  Number(
                                                    (UserCount.growthLevel /
                                                      item.volume_growth_amount_min) *
                                                      100
                                                  ).toFixed(2) > 100
                                                    ? "100"
                                                    : Number(
                                                        (UserCount.growthLevel /
                                                          item.volume_growth_amount_min) *
                                                          100
                                                      ).toFixed(2)
                                                }%, rgb(213, 212, 212) 0)`,
                                              }}
                                            >
                                              <div class="inner">
                                                {" "}
                                                {Number(
                                                  (UserCount.growthLevel /
                                                    item.volume_growth_amount_min) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.growthLevel /
                                                        item.volume_growth_amount_min) *
                                                        100
                                                    ).toFixed(2)}
                                                %
                                              </div>
                                            </div>
                                          </div>
                                          <p className="mt-2">
                                            {Number(
                                              UserCount.growthLevel
                                            ).toFixed(2) +
                                              "/" +
                                              item.volume_growth_amount_min}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                  ) : (
                                    <td className="d-none">
                                      {item.volume_growth_amount_min == "0" ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <>
                                          <div class="">
                                            <div
                                              class="circle per-29"
                                              style={{
                                                backgroundImage: `conic-gradient(rgb(167 51 167) ${
                                                  Number(
                                                    (UserCount.growthLevel /
                                                      item.volume_growth_amount_min) *
                                                      100
                                                  ).toFixed(2) > 100
                                                    ? "100"
                                                    : Number(
                                                        (UserCount.growthLevel /
                                                          item.volume_growth_amount_min) *
                                                          100
                                                      ).toFixed(2)
                                                }%, rgb(213, 212, 212) 0)`,
                                              }}
                                            >
                                              <div class="inner">
                                                {" "}
                                                {Number(
                                                  (UserCount.growthLevel /
                                                    item.volume_growth_amount_min) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.growthLevel /
                                                        item.volume_growth_amount_min) *
                                                        100
                                                    ).toFixed(2)}
                                                %
                                              </div>
                                            </div>
                                          </div>
                                          <p className="mt-2">
                                            {Number(
                                              UserCount.growthLevel
                                            ).toFixed(2) +
                                              "/" +
                                              item.volume_growth_amount_min}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                  )}
                                  {IsShows.direct == 1 ? (
                                    <td>
                                      {item.member_direct_count == "0" ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <>
                                          <div class="">
                                            <div
                                              class="circle per-29"
                                              style={{
                                                backgroundImage: `conic-gradient(rgb(94 68 223) ${
                                                  Number(
                                                    (UserCount.dirRef /
                                                      item.member_direct_count) *
                                                      100
                                                  ).toFixed(2) > 100
                                                    ? "100"
                                                    : Number(
                                                        (UserCount.dirRef /
                                                          item.member_direct_count) *
                                                          100
                                                      ).toFixed(2)
                                                }%, rgb(213, 212, 212)0deg)`,
                                              }}
                                            >
                                              <div class="inner">
                                                {" "}
                                                {Number(
                                                  (UserCount.dirRef /
                                                    item.member_direct_count) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.dirRef /
                                                        item.member_direct_count) *
                                                        100
                                                    ).toFixed(2)}
                                                %
                                              </div>
                                            </div>
                                          </div>
                                          <p className="mt-2">
                                            {toFixedUtil(UserCount.dirRef) +
                                              "/" +
                                              item.member_direct_count}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                  ) : (
                                    <td className="d-none">
                                      {item.member_direct_count == "0" ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <>
                                          <div class="">
                                            <div
                                              class="circle per-29"
                                              style={{
                                                backgroundImage: `conic-gradient(rgb(94 68 223) ${
                                                  Number(
                                                    (UserCount.dirRef /
                                                      item.member_direct_count) *
                                                      100
                                                  ).toFixed(2) > 100
                                                    ? "100"
                                                    : Number(
                                                        (UserCount.dirRef /
                                                          item.member_direct_count) *
                                                          100
                                                      ).toFixed(2)
                                                }%, rgb(213, 212, 212)0deg)`,
                                              }}
                                            >
                                              <div class="inner">
                                                {" "}
                                                {Number(
                                                  (UserCount.dirRef /
                                                    item.member_direct_count) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.dirRef /
                                                        item.member_direct_count) *
                                                        100
                                                    ).toFixed(2)}
                                                %
                                              </div>
                                            </div>
                                          </div>
                                          <p className="mt-2">
                                            {toFixedUtil(UserCount.dirRef) +
                                              "/" +
                                              item.member_direct_count}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                  )}
                                  {IsShows.team == 1 ? (
                                    <td>
                                      {item.member_team_count == "0" ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <>
                                          <div class="">
                                            <div
                                              class="circle per-29"
                                              style={{
                                                backgroundImage: `conic-gradient(rgb(199 204 55) ${
                                                  Number(
                                                    (UserCount.teamRef /
                                                      item.member_team_count) *
                                                      100
                                                  ).toFixed(2) > 100
                                                    ? "100"
                                                    : Number(
                                                        (UserCount.teamRef /
                                                          item.member_team_count) *
                                                          100
                                                      ).toFixed(2)
                                                }%, rgb(213, 212, 212) 0)`,
                                              }}
                                            >
                                              <div class="inner">
                                                {" "}
                                                {Number(
                                                  (UserCount.teamRef /
                                                    item.member_team_count) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.teamRef /
                                                        item.member_team_count) *
                                                        100
                                                    ).toFixed(2)}
                                                %
                                              </div>
                                            </div>
                                          </div>
                                          <p className="mt-2">
                                            {toFixedUtil(UserCount.teamRef) +
                                              "/" +
                                              item.member_team_count}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                  ) : (
                                    <td className="d-none">
                                      {item.member_team_count == "0" ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <>
                                          <div class="">
                                            <div
                                              class="circle per-29"
                                              style={{
                                                backgroundImage: `conic-gradient(rgb(199 204 55) ${
                                                  Number(
                                                    (UserCount.teamRef /
                                                      item.member_team_count) *
                                                      100
                                                  ).toFixed(2) > 100
                                                    ? "100"
                                                    : Number(
                                                        (UserCount.teamRef /
                                                          item.member_team_count) *
                                                          100
                                                      ).toFixed(2)
                                                }%, rgb(213, 212, 212) 0)`,
                                              }}
                                            >
                                              <div class="inner">
                                                {" "}
                                                {Number(
                                                  (UserCount.teamRef /
                                                    item.member_team_count) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.teamRef /
                                                        item.member_team_count) *
                                                        100
                                                    ).toFixed(2)}
                                                %
                                              </div>
                                            </div>
                                          </div>
                                          <p className="mt-2">
                                            {toFixedUtil(UserCount.teamRef) +
                                              "/" +
                                              item.member_team_count}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                  )}
                                  {IsShows.stake == 1 ? (
                                    <td>
                                      {item.stake_amount == "0" ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <>
                                          <div class="">
                                            <div
                                              class="circle per-29"
                                              style={{
                                                backgroundImage: `conic-gradient(rgb(18 217 180) ${
                                                  Number(
                                                    (UserCount.stake /
                                                      item.stake_amount) *
                                                      100
                                                  ).toFixed(2) > 100
                                                    ? "100"
                                                    : Number(
                                                        (UserCount.stake /
                                                          item.stake_amount) *
                                                          100
                                                      ).toFixed(2)
                                                }%, rgb(213, 212, 212) 0)`,
                                              }}
                                            >
                                              <div class="inner">
                                                {" "}
                                                {Number(
                                                  (UserCount.stake /
                                                    item.stake_amount) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.stake /
                                                        item.stake_amount) *
                                                        100
                                                    ).toFixed(2)}
                                                %
                                              </div>
                                            </div>
                                          </div>
                                          <p className="mt-2">
                                            {toFixedUtil(UserCount.stake) +
                                              "/" +
                                              item.stake_amount}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                  ) : (
                                    <td className="d-none">
                                      {item.stake_amount == "0" ? (
                                        <FontAwesomeIcon
                                          icon={faCheck}
                                          style={{ color: "green" }}
                                        />
                                      ) : (
                                        <>
                                          <div class="">
                                            <div
                                              class="circle per-29"
                                              style={{
                                                backgroundImage: `conic-gradient(rgb(18 217 180) ${
                                                  Number(
                                                    (UserCount.stake /
                                                      item.stake_amount) *
                                                      100
                                                  ).toFixed(2) > 100
                                                    ? "100"
                                                    : Number(
                                                        (UserCount.stake /
                                                          item.stake_amount) *
                                                          100
                                                      ).toFixed(2)
                                                }%, rgb(213, 212, 212) 0)`,
                                              }}
                                            >
                                              <div class="inner">
                                                {" "}
                                                {Number(
                                                  (UserCount.stake /
                                                    item.stake_amount) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.stake /
                                                        item.stake_amount) *
                                                        100
                                                    ).toFixed(2)}
                                                %
                                              </div>
                                            </div>
                                          </div>
                                          <p className="mt-2">
                                            {toFixedUtil(UserCount.stake) +
                                              "/" +
                                              item.stake_amount}
                                          </p>
                                        </>
                                      )}
                                    </td>
                                  )}
                                  {IsShows.batch == 1 ? (
                                    <td className="text-danger">
                                  {(() => {
                                      if (item.batch_condition == "equal") {
                                        if (
                                          item.batch_rank == UserCount.batchRank
                                        ) {
                                          return (
                                            <p className="text-green">
                                              {item.batchName}
                                            </p>
                                          );
                                        } else {
                                          return (
                                            <p className="text-danger">
                                              {item.batchName}
                                            </p>
                                          );
                                        }
                                      } else {
                                        if (
                                          item.batch_rank <= UserCount.batchRank
                                        ) {
                                          return (
                                            <p className="text-green">
                                              {item.batchName}
                                            </p>
                                          );
                                        } else {
                                          return (
                                            <p className="text-danger">
                                              {item.batchName}
                                            </p>
                                          );
                                        }
                                      }
                                    })()}
                                    </td>
                                  ) : (
                                    <td className="text-danger d-none">
                                      {item.batch_rank <=
                                      UserCount.batchRank ? (
                                        <p className="text-green">
                                          {item.batchName}
                                        </p>
                                      ) : (
                                        <p className="text-danger">
                                          {item.batchName}
                                        </p>
                                      )}
                                    </td>
                                  )}
                                  {/* <td>
                                    <div>
                                      <p>
                                        {item.eligibleStatus ==
                                        "Not Eligible" ? (
                                          <p className="text-danger">
                                            Not Eligible
                                          </p>
                                        ) : (
                                          <p className="text-success">
                                            Eligible
                                          </p>
                                        )}
                                      </p>
                                    </div>
                                  </td> */}
                                </tr>
                              </tbody>
                            ))}
                          </table>
                        </div>
                      </div>
                    )}
                    <div class="cmntbl venu_bnl mt-3">
                      <div className="table-responsive">
                        <div className="text-center">
                          <h4>Target</h4>
                        </div>
                        <table className="table bot-tbl ledr_tbl">
                          <thead>
                            <tr>
                              <th>Price</th>
                              {IsShows?.level == 1 ? (
                                <th>Level Bonus</th>
                              ) : (
                                <th className="d-none">Level Bonus</th>
                              )}
                              {IsShows?.growth == 1 ? (
                                <th>Growth Level Bonus</th>
                              ) : (
                                <th className="d-none">Growth Level Bonus</th>
                              )}
                              {IsShows?.direct == 1 ? (
                                <th>Direct Ref Count</th>
                              ) : (
                                <th className="d-none">Direct Ref Count</th>
                              )}
                              {IsShows?.team == 1 ? (
                                <th>Team Ref Count</th>
                              ) : (
                                <th className="d-none">Team Ref Count</th>
                              )}
                              {IsShows?.stake == 1 ? (
                                <th>Stake</th>
                              ) : (
                                <th className="d-none">Stake</th>
                              )}
                              {IsShows?.batch == 1 ? (
                                <th>Batch</th>
                              ) : (
                                <th className="d-none">Batch</th>
                              )}
                              {/* <th>Status</th> */}
                            </tr>
                          </thead>
                          {LeaderBoardTable?.map((item, index) => (
                            <tbody>
                              <tr key={index}>
                                <td style={{ width: "15px" }}>
                                  <div className="dtails">
                                    <img
                                      src={item.prize_sub_image}
                                      className="img-fluid"
                                    />
                                  </div>
                                </td>
                                {IsShows.level == 1 ? (
                                  <td>
                                    {" "}
                                    {item.volume_level_amount_min == "0" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <>
                                        <div class="">
                                          <div
                                            class="circle per-29"
                                            style={{
                                              backgroundImage: `conic-gradient(#3766cc ${
                                                Number(
                                                  (UserCount.levelCount /
                                                    item.volume_level_amount_min) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.levelCount /
                                                        item.volume_level_amount_min) *
                                                        100
                                                    ).toFixed(2)
                                              }%, #d5d4d4 0)`,
                                            }}
                                          >
                                            <div class="inner">
                                              {" "}
                                              {Number(
                                                (UserCount.levelCount /
                                                  item.volume_level_amount_min) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? "100%"
                                                : Number(
                                                    (UserCount.levelCount /
                                                      item.volume_level_amount_min) *
                                                      100
                                                  ).toFixed(2) + "%"}
                                            </div>
                                          </div>
                                        </div>
                                        <p className="mt-2">
                                          {toFixedUtil(UserCount.levelCount) +
                                            "/" +
                                            item.volume_level_amount_min}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                ) : (
                                  <td className="d-none">
                                    {" "}
                                    {item.volume_level_amount_min == "0" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <>
                                        <div class="">
                                          <div
                                            class="circle per-29"
                                            style={{
                                              backgroundImage: `conic-gradient(#3766cc ${
                                                Number(
                                                  (UserCount.levelCount /
                                                    item.volume_level_amount_min) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.levelCount /
                                                        item.volume_level_amount_min) *
                                                        100
                                                    ).toFixed(2)
                                              }%, #d5d4d4 0)`,
                                            }}
                                          >
                                            <div class="inner">
                                              {" "}
                                              {Number(
                                                (UserCount.levelCount /
                                                  item.volume_level_amount_min) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? "100%"
                                                : Number(
                                                    (UserCount.levelCount /
                                                      item.volume_level_amount_min) *
                                                      100
                                                  ).toFixed(2) + "%"}
                                            </div>
                                          </div>
                                        </div>
                                        <p className="mt-2">
                                          {toFixedUtil(UserCount.levelCount) +
                                            "/" +
                                            item.volume_level_amount_min}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                )}
                                {IsShows.growth == 1 ? (
                                  <td>
                                    {item.volume_growth_amount_min == "0" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <>
                                        <div class="">
                                          <div
                                            class="circle per-29"
                                            style={{
                                              backgroundImage: `conic-gradient(rgb(167 51 167) ${
                                                Number(
                                                  (UserCount.growthLevel /
                                                    item.volume_growth_amount_min) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.growthLevel /
                                                        item.volume_growth_amount_min) *
                                                        100
                                                    ).toFixed(2)
                                              }%, rgb(213, 212, 212) 0)`,
                                            }}
                                          >
                                            <div class="inner">
                                              {" "}
                                              {Number(
                                                (UserCount.growthLevel /
                                                  item.volume_growth_amount_min) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? "100"
                                                : Number(
                                                    (UserCount.growthLevel /
                                                      item.volume_growth_amount_min) *
                                                      100
                                                  ).toFixed(2)}
                                              %
                                            </div>
                                          </div>
                                        </div>
                                        <p className="mt-2">
                                          {Number(
                                            UserCount.growthLevel
                                          ).toFixed(2) +
                                            "/" +
                                            item.volume_growth_amount_min}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                ) : (
                                  <td className="d-none">
                                    {item.volume_growth_amount_min == "0" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <>
                                        <div class="">
                                          <div
                                            class="circle per-29"
                                            style={{
                                              backgroundImage: `conic-gradient(rgb(167 51 167) ${
                                                Number(
                                                  (UserCount.growthLevel /
                                                    item.volume_growth_amount_min) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.growthLevel /
                                                        item.volume_growth_amount_min) *
                                                        100
                                                    ).toFixed(2)
                                              }%, rgb(213, 212, 212) 0)`,
                                            }}
                                          >
                                            <div class="inner">
                                              {" "}
                                              {Number(
                                                (UserCount.growthLevel /
                                                  item.volume_growth_amount_min) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? "100"
                                                : Number(
                                                    (UserCount.growthLevel /
                                                      item.volume_growth_amount_min) *
                                                      100
                                                  ).toFixed(2)}
                                              %
                                            </div>
                                          </div>
                                        </div>
                                        <p className="mt-2">
                                          {Number(
                                            UserCount.growthLevel
                                          ).toFixed(2) +
                                            "/" +
                                            item.volume_growth_amount_min}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                )}
                                {IsShows.direct == 1 ? (
                                  <td>
                                    {item.member_direct_count == "0" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <>
                                        <div class="">
                                          <div
                                            class="circle per-29"
                                            style={{
                                              backgroundImage: `conic-gradient(rgb(94 68 223) ${
                                                Number(
                                                  (UserCount.dirRef /
                                                    item.member_direct_count) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.dirRef /
                                                        item.member_direct_count) *
                                                        100
                                                    ).toFixed(2)
                                              }%, rgb(213, 212, 212)0deg)`,
                                            }}
                                          >
                                            <div class="inner">
                                              {" "}
                                              {Number(
                                                (UserCount.dirRef /
                                                  item.member_direct_count) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? "100"
                                                : Number(
                                                    (UserCount.dirRef /
                                                      item.member_direct_count) *
                                                      100
                                                  ).toFixed(2)}
                                              %
                                            </div>
                                          </div>
                                        </div>
                                        <p className="mt-2">
                                          {toFixedUtil(UserCount.dirRef) +
                                            "/" +
                                            item.member_direct_count}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                ) : (
                                  <td className="d-none">
                                    {item.member_direct_count == "0" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <>
                                        <div class="">
                                          <div
                                            class="circle per-29"
                                            style={{
                                              backgroundImage: `conic-gradient(rgb(94 68 223) ${
                                                Number(
                                                  (UserCount.dirRef /
                                                    item.member_direct_count) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.dirRef /
                                                        item.member_direct_count) *
                                                        100
                                                    ).toFixed(2)
                                              }%, rgb(213, 212, 212)0deg)`,
                                            }}
                                          >
                                            <div class="inner">
                                              {" "}
                                              {Number(
                                                (UserCount.dirRef /
                                                  item.member_direct_count) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? "100"
                                                : Number(
                                                    (UserCount.dirRef /
                                                      item.member_direct_count) *
                                                      100
                                                  ).toFixed(2)}
                                              %
                                            </div>
                                          </div>
                                        </div>
                                        <p className="mt-2">
                                          {toFixedUtil(UserCount.dirRef) +
                                            "/" +
                                            item.member_direct_count}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                )}
                                {IsShows.team == 1 ? (
                                  <td>
                                    {item.member_team_count == "0" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <>
                                        <div class="">
                                          <div
                                            class="circle per-29"
                                            style={{
                                              backgroundImage: `conic-gradient(rgb(199 204 55) ${
                                                Number(
                                                  (UserCount.teamRef /
                                                    item.member_team_count) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.teamRef /
                                                        item.member_team_count) *
                                                        100
                                                    ).toFixed(2)
                                              }%, rgb(213, 212, 212) 0)`,
                                            }}
                                          >
                                            <div class="inner">
                                              {" "}
                                              {Number(
                                                (UserCount.teamRef /
                                                  item.member_team_count) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? "100"
                                                : Number(
                                                    (UserCount.teamRef /
                                                      item.member_team_count) *
                                                      100
                                                  ).toFixed(2)}
                                              %
                                            </div>
                                          </div>
                                        </div>
                                        <p className="mt-2">
                                          {toFixedUtil(UserCount.teamRef) +
                                            "/" +
                                            item.member_team_count}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                ) : (
                                  <td className="d-none">
                                    {item.member_team_count == "0" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <>
                                        <div class="">
                                          <div
                                            class="circle per-29"
                                            style={{
                                              backgroundImage: `conic-gradient(rgb(199 204 55) ${
                                                Number(
                                                  (UserCount.teamRef /
                                                    item.member_team_count) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.teamRef /
                                                        item.member_team_count) *
                                                        100
                                                    ).toFixed(2)
                                              }%, rgb(213, 212, 212) 0)`,
                                            }}
                                          >
                                            <div class="inner">
                                              {" "}
                                              {Number(
                                                (UserCount.teamRef /
                                                  item.member_team_count) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? "100"
                                                : Number(
                                                    (UserCount.teamRef /
                                                      item.member_team_count) *
                                                      100
                                                  ).toFixed(2)}
                                              %
                                            </div>
                                          </div>
                                        </div>
                                        <p className="mt-2">
                                          {toFixedUtil(UserCount.teamRef) +
                                            "/" +
                                            item.member_team_count}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                )}
                                {IsShows.stake == 1 ? (
                                  <td>
                                    {item.stake_amount == "0" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <>
                                        <div class="">
                                          <div
                                            class="circle per-29"
                                            style={{
                                              backgroundImage: `conic-gradient(rgb(18 217 180) ${
                                                Number(
                                                  (UserCount.stake /
                                                    item.stake_amount) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.stake /
                                                        item.stake_amount) *
                                                        100
                                                    ).toFixed(2)
                                              }%, rgb(213, 212, 212) 0)`,
                                            }}
                                          >
                                            <div class="inner">
                                              {" "}
                                              {Number(
                                                (UserCount.stake /
                                                  item.stake_amount) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? "100"
                                                : Number(
                                                    (UserCount.stake /
                                                      item.stake_amount) *
                                                      100
                                                  ).toFixed(2)}
                                              %
                                            </div>
                                          </div>
                                        </div>
                                        <p className="mt-2">
                                          {toFixedUtil(UserCount.stake) +
                                            "/" +
                                            item.stake_amount}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                ) : (
                                  <td className="d-none">
                                    {item.stake_amount == "0" ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        style={{ color: "green" }}
                                      />
                                    ) : (
                                      <>
                                        <div class="">
                                          <div
                                            class="circle per-29"
                                            style={{
                                              backgroundImage: `conic-gradient(rgb(18 217 180) ${
                                                Number(
                                                  (UserCount.stake /
                                                    item.stake_amount) *
                                                    100
                                                ).toFixed(2) > 100
                                                  ? "100"
                                                  : Number(
                                                      (UserCount.stake /
                                                        item.stake_amount) *
                                                        100
                                                    ).toFixed(2)
                                              }%, rgb(213, 212, 212) 0)`,
                                            }}
                                          >
                                            <div class="inner">
                                              {" "}
                                              {Number(
                                                (UserCount.stake /
                                                  item.stake_amount) *
                                                  100
                                              ).toFixed(2) > 100
                                                ? "100"
                                                : Number(
                                                    (UserCount.stake /
                                                      item.stake_amount) *
                                                      100
                                                  ).toFixed(2)}
                                              %
                                            </div>
                                          </div>
                                        </div>
                                        <p className="mt-2">
                                          {toFixedUtil(UserCount.stake) +
                                            "/" +
                                            item.stake_amount}
                                        </p>
                                      </>
                                    )}
                                  </td>
                                )}
                                {IsShows.batch == 1 ? (
                                  <td className="text-danger">
                                    {(() => {
                                      if (item.batch_condition == "equal") {
                                        if (
                                          item.batch_rank == UserCount.batchRank
                                        ) {
                                          return (
                                            <p className="text-green">
                                              {item.batchName}
                                            </p>
                                          );
                                        } else {
                                          return (
                                            <p className="text-danger">
                                              {item.batchName}
                                            </p>
                                          );
                                        }
                                      } else {
                                        if (
                                          item.batch_rank <= UserCount.batchRank
                                        ) {
                                          return (
                                            <p className="text-green">
                                              {item.batchName}
                                            </p>
                                          );
                                        } else {
                                          return (
                                            <p className="text-danger">
                                              {item.batchName}
                                            </p>
                                          );
                                        }
                                      }
                                    })()}
                                    {/* {item.batch_rank <= UserCount.batchRank ? (
                                      <p className="text-green">
                                        {item.batchName}
                                      </p>
                                    ) : (
                                      <p className="text-danger">
                                        {item.batchName}
                                      </p>
                                    )} */}
                                  </td>
                                ) : (
                                  <td className="text-danger d-none">
                                    {item.batch_rank <= UserCount.batchRank ? (
                                      <p className="text-green">
                                        {item.batchName}
                                      </p>
                                    ) : (
                                      <p className="text-danger">
                                        {item.batchName}
                                      </p>
                                    )}
                                  </td>
                                )}
                                {/* <td>
                                  <div>
                                    <p>
                                      {item.eligibleStatus == "Not Eligible" ? (
                                        <p className="text-danger">
                                          Not Eligible
                                        </p>
                                      ) : (
                                        <p className="text-success">Eligible</p>
                                      )}
                                    </p>
                                  </div>
                                </td> */}
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    </div>
                    {/*-------------------------------- Grid View ----------------------------------- */}
                    <div className="cmntbl gridview  spanl">
                      <div className="row">
                        <button
                          type="button"
                          className="close cls_pos"
                          aria-label="Close"
                          onClick={toggleImage}
                        >
                          <img src={closeIcon} alt="close-x" />
                        </button>

                        {AchievedTable?.length < 0 && (
                          <div
                            className="text-center mt-3"
                            style={{ width: "100%" }}
                          >
                            <h4>Target </h4>
                          </div>
                        )}
                        {AchievedTable?.length > 0 &&
                          AchievedTable?.map((item, index) => (
                            <div className="col-12" key={index}>
                              <div
                                className="text-center mt-3"
                                style={{ width: "100%" }}
                              >
                                <h4>Achieved</h4>
                              </div>
                              <div className="itemDetailsCard mb-2">
                                <div className="row align-items-stretch">
                                  <div className="col-5">
                                    <div className="text-center h-100">
                                      <img
                                        src={item.prize_sub_image}
                                        alt="WinnerGift1"
                                        className="img-fluid imgLeft"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="">
                                      {IsShows?.level == 1 ? (
                                        <>
                                          <div className="spanleft  d-flex">
                                            <span>Level Bonus</span>
                                          </div>
                                          {item.volume_level_amount_min ==
                                          "0" ? (
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <div className="position-relative">
                                              <ProgressBar
                                                className=""
                                                min={0}
                                                max={
                                                  item.volume_level_amount_min
                                                }
                                                now={UserCount.levelCount}
                                              />
                                              <p className="position-absolute top-0 end-0 text-info text-light abd">
                                                {toFixedUtil(
                                                  UserCount.levelCount
                                                ) +
                                                  "/" +
                                                  item.volume_level_amount_min}
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {IsShows?.growth == 1 ? (
                                        <>
                                          <div className="spanleft  d-flex">
                                            <span>Growth Bonus</span>
                                          </div>
                                          {item.volume_growth_amount_min ==
                                          "0" ? (
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <>
                                              <div className="position-relative">
                                                <ProgressBar
                                                  className=""
                                                  min={0}
                                                  max={
                                                    item.volume_growth_amount_min
                                                  }
                                                  now={UserCount.growthLevel}
                                                />
                                                <p className="position-absolute top-0 end-0 text-info text-light abd">
                                                  {toFixedUtil(
                                                    UserCount.growthLevel
                                                  ) +
                                                    "/" +
                                                    item.volume_growth_amount_min}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {IsShows?.direct == 1 ? (
                                        <>
                                          <div className="spanleft  d-flex">
                                            <span>Direct Ref</span>
                                          </div>
                                          {item.member_direct_count == "0" ? (
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <>
                                              <div className="position-relative">
                                                <ProgressBar
                                                  className=""
                                                  min={0}
                                                  max={item.member_direct_count}
                                                  now={UserCount.dirRef}
                                                />
                                                <p className="position-absolute top-0 end-0 text-info text-light abd">
                                                  {toFixedUtil(
                                                    UserCount.dirRef
                                                  ) +
                                                    "/" +
                                                    item.member_direct_count}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {IsShows?.team == 1 ? (
                                        <>
                                          <div className="spanleft  d-flex">
                                            <span>Team Ref</span>
                                          </div>
                                          {item.member_team_count == "0" ? (
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <>
                                              <div className="position-relative">
                                                <ProgressBar
                                                  className=""
                                                  min={0}
                                                  max={item.member_team_count}
                                                  now={UserCount.teamRef}
                                                />
                                                <p className="position-absolute top-0 end-0 text-info text-light abd">
                                                  {toFixedUtil(
                                                    UserCount.teamRef
                                                  ) +
                                                    "/" +
                                                    item.member_team_count}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {IsShows?.stake == 1 ? (
                                        <>
                                          <div className="spanleft  d-flex">
                                            <span>Stake</span>
                                          </div>
                                          {item.stake_amount == "0" ? (
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <>
                                              <div className="position-relative">
                                                <ProgressBar
                                                  className=""
                                                  min={0}
                                                  max={item.stake_amount}
                                                  now={UserCount.stake}
                                                />
                                                <p className="position-absolute top-0 end-0 text-info text-light abd">
                                                  {toFixedUtil(
                                                    UserCount.stake
                                                  ) +
                                                    "/" +
                                                    item.stake_amount}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="d-flex mb-1 mt-1">
                                      {IsShows.batch == 1 ? (
                                        <>
                                          <span className="spanleft">
                                            Batch
                                          </span>
                                          {(() => {
                                      if (item.batch_condition == "equal") {
                                        if (
                                          item.batch_rank == UserCount.batchRank
                                        ) {
                                          return (
                                            <span
                                            className="spanright pl-3"
                                            style={{
                                              color: "green !important",
                                            }}
                                          >
                                            <p className="text-success">
                                              {item.batchName}
                                            </p>
                                          </span>
                                          );
                                        } else {
                                          return (
                                            <p className="text-danger">
                                              {item.batchName}
                                            </p>
                                          );
                                        }
                                      } else {
                                        if (
                                          item.batch_rank <= UserCount.batchRank
                                        ) {
                                          return (
                                            <span
                                            className="spanright pl-3"
                                            style={{
                                              color: "green !important",
                                            }}
                                          >
                                            <p className="text-success">
                                              {item.batchName}
                                            </p>
                                          </span>
                                          );
                                        } else {
                                          return (
                                            <p className="text-danger">
                                              {item.batchName}
                                            </p>
                                          );
                                        }
                                      }
                                    })()}
                                          {item.batch_rank <=
                                          UserCount.batchRank ? (
                                            <span
                                              className="spanright pl-3"
                                              style={{
                                                color: "green !important",
                                              }}
                                            >
                                              <p className="text-success">
                                                {item.batchName}
                                              </p>
                                            </span>
                                          ) : (
                                            <span
                                              className="spankl pl-3"
                                              id="spanKl"
                                              style={{
                                                color: "#e33b3bf2 !important",
                                              }}
                                            >
                                              {item.batchName}
                                            </span>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {/* <div className="d-flex mb-2">
                                      <span className="spanleft">Status</span>
                                      <span className="spanright pl-3">
                                        {item.eligibleStatus ==
                                        "Not Eligible" ? (
                                          <p
                                            className=""
                                            style={{ color: "#ffc107" }}
                                          >
                                            Next Target
                                          </p>
                                        ) : (
                                          <p className="text-success">
                                            Achieved
                                          </p>
                                        )}
                                      </span>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        {AchievedTable?.length > 0 && (
                          <div className="col-12">
                            <div className="link-arr">
                              <div className="bl_ar">
                                <img
                                  src={linkarr1}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="bl_ar">
                                <img
                                  src={linkarr2}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="bl_ar">
                                <img
                                  src={linkarr3}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              <div className="bl_ar">
                                <img
                                  src={linkarr4}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                              {/* <img src={linkarr2} className="img-fluid" alt="" />
                            <img src={linkarr3} className="img-fluid" alt="" />
                            <img src={linkarr4} className="img-fluid" alt="" /> */}
                            </div>
                          </div>
                        )}
                        {LeaderBoardTable?.length > 0 &&
                          LeaderBoardTable?.map((item, index) => (
                            <div className="col-12 " key={index}>
                              <div
                                className="text-center mt-3"
                                style={{ width: "100%" }}
                              >
                                <h4>Target</h4>
                              </div>
                              <div className="itemDetailsCard mb-2 mt-3">
                                <div className="row align-items-stretch">
                                  <div className="col-5">
                                    <div className="text-center h-100">
                                      <img
                                        src={item.prize_sub_image}
                                        alt="WinnerGift1"
                                        className="img-fluid imgLeft"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-7">
                                    <div className="">
                                      {IsShows?.level == 1 ? (
                                        <>
                                          <div className="spanleft  d-flex">
                                            <span>Level Bonus</span>
                                          </div>
                                          {item.volume_level_amount_min ==
                                          "0" ? (
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <div className="position-relative">
                                              <ProgressBar
                                                className=""
                                                min={0}
                                                max={
                                                  item.volume_level_amount_min
                                                }
                                                now={UserCount.levelCount}
                                              />
                                              <p className="position-absolute top-0 end-0 text-info text-light abd">
                                                {toFixedUtil(
                                                  UserCount.levelCount
                                                ) +
                                                  "/" +
                                                  item.volume_level_amount_min}
                                              </p>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      {IsShows?.growth == 1 ? (
                                        <>
                                          <div className="spanleft  d-flex">
                                            <span>Growth Bonus</span>
                                          </div>
                                          {item.volume_growth_amount_min ==
                                          "0" ? (
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <>
                                              <div className="position-relative">
                                                <ProgressBar
                                                  className=""
                                                  min={0}
                                                  max={
                                                    item.volume_growth_amount_min
                                                  }
                                                  now={UserCount.growthLevel}
                                                />
                                                <p className="position-absolute top-0 end-0 text-info text-light abd">
                                                  {toFixedUtil(
                                                    UserCount.growthLevel
                                                  ) +
                                                    "/" +
                                                    item.volume_growth_amount_min}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {IsShows?.direct == 1 ? (
                                        <>
                                          <div className="spanleft  d-flex">
                                            <span>Direct Ref</span>
                                          </div>
                                          {item.member_direct_count == "0" ? (
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <>
                                              <div className="position-relative">
                                                <ProgressBar
                                                  className=""
                                                  min={0}
                                                  max={item.member_direct_count}
                                                  now={UserCount.dirRef}
                                                />
                                                <p className="position-absolute top-0 end-0 text-info text-light abd">
                                                  {toFixedUtil(
                                                    UserCount.dirRef
                                                  ) +
                                                    "/" +
                                                    item.member_direct_count}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {IsShows?.team == 1 ? (
                                        <>
                                          <div className="spanleft  d-flex">
                                            <span>Team Ref</span>
                                          </div>
                                          {item.member_team_count == "0" ? (
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <>
                                              <div className="position-relative">
                                                <ProgressBar
                                                  className=""
                                                  min={0}
                                                  max={item.member_team_count}
                                                  now={UserCount.teamRef}
                                                />
                                                <p className="position-absolute top-0 end-0 text-info text-light abd">
                                                  {toFixedUtil(
                                                    UserCount.teamRef
                                                  ) +
                                                    "/" +
                                                    item.member_team_count}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {IsShows?.stake == 1 ? (
                                        <>
                                          <div className="spanleft  d-flex">
                                            <span>Stake</span>
                                          </div>
                                          {item.stake_amount == "0" ? (
                                            <FontAwesomeIcon
                                              icon={faCheck}
                                              style={{ color: "green" }}
                                            />
                                          ) : (
                                            <>
                                              <div className="position-relative">
                                                <ProgressBar
                                                  className=""
                                                  min={0}
                                                  max={item.stake_amount}
                                                  now={UserCount.stake}
                                                />
                                                <p className="position-absolute top-0 end-0 text-info text-light abd">
                                                  {toFixedUtil(
                                                    UserCount.stake
                                                  ) +
                                                    "/" +
                                                    item.stake_amount}
                                                </p>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="d-flex mb-1 mt-1">
                                      {IsShows.batch == 1 ? (
                                        <>
                                          <span className="spanleft">
                                            Batch
                                          </span>
                                          {item.batch_rank <=
                                          UserCount.batchRank ? (
                                            <span
                                              className="spanright pl-3"
                                              style={{ color: "green" }}
                                            >
                                              <p className="text-success">
                                                {item.batchName}
                                              </p>
                                            </span>
                                          ) : (
                                            <span
                                              className="spankl pl-3"
                                              id="spanKl"
                                              style={{
                                                color: "#e33b3bf2 !important",
                                              }}
                                            >
                                              {item.batchName}
                                            </span>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {/* <div className="d-flex mb-2">
                                      <span className="spanleft">Status</span>
                                      <span className="spanright pl-3">
                                        {item.eligibleStatus ==
                                        "Not Eligible" ? (
                                          <p
                                            className=""
                                            style={{ color: "#ffc107" }}
                                          >
                                            Next Target
                                          </p>
                                        ) : (
                                          <p className="text-success">
                                            Achieved
                                          </p>
                                        )}
                                      </span>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ScreenLoader>
    </>
  );
}

export default LeaderBoard;
