import { faArrowCircleLeft, faComment } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
	Card,
	CardBody,
	CardHeader,
	CardTitle,
	Modal,
	ModalBody,
	ModalHeader,
	Form
} from 'reactstrap'
import Helper from '../../Helper/Helper'
import moment from 'moment'
import CloseBtn from '../../component/Reusable/CloseBtn'
import { useForm } from 'react-hook-form'
import { SpinnerLoader } from '../../component/Loaders'
import Preview from '../../component/Reusable/Preview'

const ViewTicket = () => {
	const [searchParams] = useSearchParams()
	const ticketId = searchParams.get('Id') // rowId
	const [ticketInfo, setTicketInfo] = useState({})
	const [loading, setLoading] = useState(false)
	const [modalLoading, setModalLoading] = useState(false)

	const [modal, setModal] = useState(false)
	const navigate = useNavigate()

	const {
		handleSubmit,
		register,
		formState: { errors },
		reset
	} = useForm({
		mode: 'all',
		reValidateMode: 'onChange'
	})

	useEffect(() => {
		getTicketInfo()
	}, [])

	const toggle = () => {
		setModal(!modal)
		reset()
		setModalLoading(false)
	}

	const handleAddComment = data => {
		setModalLoading(true)
		Helper.PostData(
			'arun',
			'user/userAddTicketComment',
			{ ...data, user_id: localStorage.getItem('tip'), ticketId },
			true
		)
			.then(res => {
				if (res.status) {
					reset()
					setModal(false)
					getTicketInfo()
				}
			})
			.finally(() => {
				setModalLoading(false)
			})
	}

	const getTicketInfo = () => {
		setLoading(true)
		Helper.PostData(
			'arun',
			'user/viewuserTickets',
			{
				user_id: localStorage.getItem('tip'),
				id: ticketId
			},
			false
		)
			.then(res => {
				if (res.status) {
					let data = res.data[0]
					data.descriptionMod = data.description.split('\n')
					console.log('data.descriptionMod: ', data.descriptionMod)
					setTicketInfo(data)
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const Status = ({ sts }) => {
		if (sts == 2) {
			return <div className=' badge text-warn  ml-1'>Re-Open</div>
		} else if (sts == 0) {
			return <div className='badge bg-success text-light ml-1'>Open</div>
		} else if (sts == 1) {
			return <div className='badge bg-danger text-light ml-1'>Closed</div>
		}
	}

	const StatusMsg = sts => {
		let statusObj = {
			0: 'Open',
			1: 'Closed',
			2: 'Re-Open'
		}

		return statusObj[sts]
	}

	const handleReopen = () => {
		setModalLoading(true)
		Helper.PostData(
			'arun',
			'user/reOpenTicket',
			{
				user_id: localStorage.getItem('tip'),
				id: ticketId
			},
			true
		)
			.then(res => {
				if (res.status) {
					setModal(true)
					getTicketInfo()
				}
			})
			.finally(() => {
				setModalLoading(false)
			})
	}

	return (
		<>
			<div class='maincontent leftrightCir pt-2'>
				<section class='pb-5'>
					<div class='container'>
						<div class='row'>
							<div class='col-lg-12'>
								<div className='viewtkt-bg'>
									<div class='container-fluid'>
										<div class='row'>
											<div class='col-lg-12'>
												<div className='d-flex justify-content-between'>
													<h3>
														<FontAwesomeIcon
															icon={faArrowCircleLeft}
															size='sm cur-poin'
															title='Go Back'
															onClick={() => navigate('/user/support')}
														/>
														<span> Support Desk</span>
													</h3>
													{ticketInfo.status == 1 &&
														(modalLoading ? (
															<button class='btn btn-secondary ml-1' disabled>
																<SpinnerLoader />
															</button>
														) : (
															<button
																class='btn btn-secondary ml-1'
																onClick={() => handleReopen()}
															>
																Re-Open
															</button>
														))}
												</div>
												<hr />
												<div className='d-flex justify-content-start '>
													<div>
														<span>Status:</span>
														<Status sts={ticketInfo.status} />
													</div>
													<div className='ml-2'>
														<span>Type:</span> Support
													</div>
													{/* <div className='ml-2'>
											<span>More Info:</span> 
										</div> */}
												</div>
											</div>
										</div>
									</div>

									<div className='mt-4'>
										<div class='container-fluid'>
											<div class='row'>
												<div class='col-lg-9'>
													<div className='row'>
														<div class='col-lg-12'>
															<Card className='card-view'>
																<CardHeader className='cd-head'>
																	<CardTitle>
																		Raised a ticket for: {ticketInfo.subject} [
																		{StatusMsg(ticketInfo.status)}]
																	</CardTitle>
																</CardHeader>
																{/* <CardBody className='sub-hdr'>
																	<FontAwesomeIcon icon={faComment} /> Add
																	Comment
																</CardBody> */}
																<CardBody>
																	<div
																		class='itemDescription'
																		// dangerouslySetInnerHTML={{
																		// 	__html: ticketInfo.descriptionMod ? (
																		// 		ticketInfo.descriptionMod()
																		// 	) : (
																		// 		<></>
																		// 	)
																		// }}
																	>
																		<p>
																			{ticketInfo.descriptionMod?.map(
																				(item, index) => (
																					<>
																						<span key={index}>{item}</span>
																						<br />
																					</>
																				)
																			)}
																		</p>
																	</div>
																	<div>
																		{ticketInfo.image && (
																			<>
																				<h6>Attachment :</h6>
																				<Preview src={ticketInfo.image}>
																					<img
																						src={ticketInfo.image}
																						alt=''
																						className='kycimg2 img-fluid mt-2 cur-poin'
																					/>
																				</Preview>
																			</>
																		)}
																	</div>
																</CardBody>
															</Card>
														</div>
														{ticketInfo.status != 1 && (
															<div className='col-lg-12 mt-3'>
																<button
																	class='btn btn-primary'
																	onClick={toggle}
																>
																	Add Comment
																</button>
															</div>
														)}

														<div className='col-lg-12 mt-3'>
															<div class='table-responsive table-cmt-details'>
																<table class='table table-bordered table-hover  striped-table'>
																	<thead>
																		<tr>
																			<th className='comment'>Comment</th>
																			<th>Created At</th>
																		</tr>
																	</thead>
																	<tbody>
																		{ticketInfo.comments?.length > 0 ? (
																			ticketInfo.comments.map((item, index) => (
																				<tr key={index}>
																					<td className='comment'>
																						{item.tag == 'user' ? (
																							<p>
																								{item.description
																									?.split('\n')
																									.map((data, inx) => (
																										<>
																											<span>{data}</span>
																											<br />
																										</>
																									))}
																							</p>
																						) : (
																							<span
																								dangerouslySetInnerHTML={{
																									__html: item.description
																								}}
																							></span>
																						)}
																					</td>
																					<td>
																						{moment(item.dateTime).format(
																							'MMM D, YYYY ,h:mm:ss A'
																						)}
																						<br />
																						{item.tag == 'user' ? (
																							<span className='text-primary'>
																								You
																							</span>
																						) : (
																							<span className='text-success'>
																								Admin
																							</span>
																						)}
																					</td>
																				</tr>
																			))
																		) : (
																			<tr>
																				<td
																					className='comment text-center'
																					colSpan={2}
																				>
																					No Comment Found
																				</td>
																			</tr>
																		)}
																	</tbody>
																</table>
															</div>
														</div>
														{/* <div className='col-lg-12 mt-3 mb-2'>
															<button class='btn btn-primary' onClick={toggle}>
																Add Comment
															</button>
														</div> */}
													</div>
												</div>
												<div class='col-lg-3'>
													<Card className='card-view'>
														<CardHeader className='cd-head'>
															<CardTitle>Details</CardTitle>
														</CardHeader>
														<CardBody className='tbl-crd-body'>
															<div class=''>
																<table class='table table-bordered table-hover table-item-details'>
																	<tbody>
																		<tr>
																			<th>Ticket Id</th>
																			<td>{ticketInfo.ticketId}</td>
																		</tr>

																		<tr>
																			<th>Status</th>
																			<td>
																				<Status sts={ticketInfo.status} />
																			</td>
																		</tr>
																		<tr>
																			<th>Created At</th>
																			<td>
																				{moment(ticketInfo.created_at).format(
																					'MMM D, YYYY ,h:mm:ss A'
																				)}
																			</td>
																		</tr>
																		{/* <tr>
																			<th>Created By</th>
																			<td>
																				<img class="user-photo" style="width:28px; margin-bottom: 2px;" src="http://192.168.1.54/support/public_html/images/no_photo.png" width="28" />
																				rajeshkumar.s
																			</td>
																		</tr> */}
																	</tbody>
																</table>
															</div>
														</CardBody>
													</Card>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Modal
						isOpen={modal}
						className={'modal-md modal-dialog-centered mymodal'}
						size={'md'}
						toggleModal={toggle}
					>
						<ModalHeader toggle={toggle} close={<CloseBtn onClick={toggle} />}>
							Add Comment
						</ModalHeader>
						<ModalBody className='px-4'>
							<Form onSubmit={handleSubmit(handleAddComment)}>
								<div class='form-group formInputs walletInput mb-3'>
									<label class=''>
										Description
										<span className='text-primary'>*</span>
									</label>
									<textarea
										class='form-control'
										rows='5'
										name='description'
										placeholder='Enter Description'
										formControlName='message'
										{...register('description', {
											required: '*Description is required',
											pattern: {
												value: /[/^\S]/,
												message: '* Whitespace not allowed '
											}
										})}
										autocomplete='off'
									></textarea>
									{errors.description && (
										<p className='text-danger'>{errors.description.message}</p>
									)}
								</div>
								<div class='text-center'>
									{(() => {
										if (modalLoading) {
											return (
												<button class='btn btn-primary' disabled type='button'>
													<SpinnerLoader />
												</button>
											)
										} else {
											return (
												<button class='btn btn-primary' type='submit'>
													Submit
												</button>
											)
										}
									})()}
								</div>
							</Form>
						</ModalBody>
					</Modal>
				</section>
			</div>
		</>
	)
}
export default ViewTicket
