import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export default () => {
	let access = localStorage.getItem('logaccess')

	if (!access) {
		localStorage.clear()
		return <Navigate to='/oauth/login' />
	} else {
		return <Outlet />
	}
}
