import React from "react";
import DataTable from "react-data-table-component";
import { dataTableConfigs } from "../../configs/dataTableConfigs";
import { TableLoader } from "../../component/Loaders";
import NoDataComponent from "../../component/Reusable/no-data-component";

export function LevelMissedBonus(props) {
  return (
    <div className="cmntbl">
      <div className="table-responsive">
        <DataTable
          columns={props.columns1}
          className="text-center"
          data={props.MissedBonus.data}
          paginationTotalRows={props.MissedBonus.count}
          customStyles={dataTableConfigs}
          pagination
          paginationServer
          onChangePage={(pageNo) => {
            props.setFilter((prev) => ({ ...prev, pageNo: pageNo - 1 }));
          }}
          progressPending={props.Loading}
          progressComponent={<TableLoader />}
          noDataComponent={<NoDataComponent />}
          onChangeRowsPerPage={(pageSize) => {
            props.setFilter((prev) => ({ ...prev, pageSize }));
          }}
          persistTableHead={true} // paginationPerPage={10}
          paginationRowsPerPageOptions={[5, 10, 20, 30]} />
      </div>
    </div>
  );
}
