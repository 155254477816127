import {
  faArrowAltCircleLeft,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Helper from "../../Helper/Helper";
import { TableLoader } from "../../component/Loaders";
import NoDataComponent from "../../component/Reusable/no-data-component";
import { dataTableConfigs } from "../../configs/dataTableConfigs";
import DatePickerReuse from "../../component/Reusable/DatePickerReuse";
import { CSVLink } from "react-csv";

export function OverallBatchReport(props) {
  const csvReport = {
    data: props.BatchMember.data && props.BatchMember.data,
    headers: props.header1,
    filename: "batchReports.csv",
  };
  return (
    <div className="trans-body">
      <div className="trans-TabCnt">
        <div className="stakingbonus-tabcnt">
          <div className="bonus-Head">
            <h5>Batch Reports</h5>
          </div>
          <div className="bonus-Body">
            <div className="tbl-showentriessearch jus_start">
              <div className="showentries-RightCnt">
                <div className="inr-searchcnt">
                  <div className="row">
                    <div className="col-lg-4">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          console.log("+e.target.value: ", e.target.name);
                          props.setFilter((prev) => ({
                            ...prev,
                            batchRank: +e.target.value ? +e.target.value : "",
                          }));
                        }}
                      >
                        <option value="all">All</option>
                        {props.BatchList.map((item, index) => (
                          <option value={item.batch_rank}>
                            {item.batch_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-4">
                      <select
                        className="form-control"
                        onChange={(e) => {
                          console.log("+e.target.value: ", e.target.name);
                          props.setFilter((prev) => ({
                            ...prev,
                            month:
                              e.target.value && e.target.value !== "all"
                                ? e.target.value
                                : "",
                          }));
                        }}
                        style={{ textTransform: "capitalize" }}
                      >
                        <option value="all">All</option>
                        {props.FilterMonth.map((item, index) => (
                          <option value={item.current_month}>
                            {item.current_month}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cmntbl">
              <div className="table-responsive">
                <div className="back-btn text-right cursor-pointer mb-4">
                  <CSVLink {...csvReport}>
                    <FontAwesomeIcon icon={faDownload} className="" size="sm" />{" "}
                    Export
                  </CSVLink>
                </div>
                <DataTable
                  columns={props.columns}
                  className="text-center"
                  data={props.BatchMember.data}
                  customStyles={dataTableConfigs}
                  // paginationTotalRows={10}
                  pagination
                  progressPending={props.Loading}
                  progressComponent={<TableLoader />}
                  noDataComponent={<NoDataComponent />}
                  // onChangeRowsPerPage={10}
                  paginationServer
                  persistTableHead={true} // paginationPerPage={10}
                  // paginationRowsPerPageOptions={[5, 10, 20, 30]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export function IndividualBatchReport(props) {
  const [activeTab, setActiveTab] = useState("1");
  const [response, setResponse] = useState([]);
  const [responseMeet, setMeetResponse] = useState([]);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    GetInvestorBatch();
    GetInvestorMeetBatch();
  }, []);
  function GetInvestorBatch() {
    Helper.PostData(
      "inves",
      "crm/investorsListBatch",
      {
        id: props.UserId,
        status: "",
        pageNo: "0",
        pageSize: "10",
      },
      false
    ).then((res) => {
      if (res.status) {
        console.log("res", res.data.results);
        setResponse(res.data.results);
      } else {
        setResponse([]);
      }
    });
  }
  function GetInvestorMeetBatch() {
    Helper.PostData(
      "inves",
      "crm/investorsMeetingListBatch",
      {
        id: props.UserId,
        status: "all",
        pageNo: "all",
        pageSize: "all",
      },
      false
    ).then((res) => {
      if (res.status) {
        console.log("res", res.data.results);
        setMeetResponse(res.data.results);
      } else {
        setMeetResponse([]);
      }
    });
  }
  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },
    {
      name: "Name",
      sortable: true,
      selector: (row, index) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email || "-",
      sortable: true,
    },
    {
      name: "Investor Type",
      selector: (row) => row.investor_type,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        if (row.status == 0) {
          return <spna className="badge bg-danger">Rejected</spna>;
        } else if (row.status == 1) {
          return <spna className="badge bg-warning">Discussion</spna>;
        } else {
          return <spna className="badge bg-success">Converted</spna>;
        }
      },
      sortable: true,
    },
  ];
  const columnsMeet = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },
    {
      name: "Name",
      sortable: true,
      selector: (row, index) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Meeting Type",
      selector: (row) => row.meetingType,
      cell: (row) => {
        if (+row.meetingType == 0) {
          return <div className=" text-warning">Offline</div>;
        } else {
          return <div className="text-success">Online</div>;
        }
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.meetingStatus,
      cell: (row) => {
        if (+row.meetingStatus == 1) {
          return <spna className="badge bg-success">Completed</spna>;
        } else {
          return <spna className="badge bg-warning">Upcoming</spna>;
        }
      },
      sortable: true,
    },
  ];
  const header = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Investor Type", key: "investor_type" },
    { label: "Status", key: "status" },
  ];
  const csvReport = {
    data: response && response,
    headers: header,
    filename: "batchInvestor.csv",
  };
  const headerMeet = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Meeting Type", key: "meetingType" },
    { label: "Meeting Status", key: "meetingStatus" },
  ];
  const csvReportMeet = {
    data: responseMeet && responseMeet,
    headers: headerMeet,
    filename: "batchInvestorMeeting.csv",
  };
  return (
    <div className="trans-body">
      <div className="trans-TabCnt">
        <div className="stakingbonus-tabcnt">
          <div className="bonus-Head">
            <h5>Investor Report</h5>
          </div>
          <div className="bonus-Body">
            <div className="">
              <div className="row">
                <div className="col-lg-6">
                  <Nav tabs className="cursor-pointer">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        Investor
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        Meeting
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <div className="col-lg-4"></div>

                <div className="col-lg-1"></div>
                <div className="col-lg-1">
                  <div
                    className="d-flex align-items-center back-btn text-right cursor-pointer"
                    style={{ gap: "5px" }}
                    onClick={() => props.setShowManual(0)}
                  >
                    <FontAwesomeIcon
                      icon={faArrowAltCircleLeft}
                      className=""
                      size="sm"
                    />{" "}
                    <span>Back</span>
                  </div>
                </div>
              </div>
            </div>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={"1"}>
                <div className="cmntbl mt-3">
                  <div className="table-responsive">
                    <div className="back-btn text-right cursor-pointer mb-2">
                      <CSVLink {...csvReport}>
                        <FontAwesomeIcon
                          icon={faDownload}
                          className=""
                          size="sm"
                        />{" "}
                        Export
                      </CSVLink>
                    </div>
                    <DataTable
                      columns={columns}
                      className="text-center"
                      data={response}
                      customStyles={dataTableConfigs}
                      paginationTotalRows={props.BatchMember.count}
                      pagination
                      onChangePage={(pageNo) => {
                        props.setFilter((prev) => ({
                          ...prev,
                          pageNo: pageNo - 1,
                        }));
                      }}
                      progressPending={props.Loading}
                      progressComponent={<TableLoader />}
                      noDataComponent={<NoDataComponent />}
                      onChangeRowsPerPage={(pageSize) => {
                        props.setFilter((prev) => ({ ...prev, pageSize }));
                      }}
                      paginationServer
                      persistTableHead={true} // paginationPerPage={10}
                      paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    />
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={"2"}>
                <div className="cmntbl mt-3">
                  <div className="table-responsive">
                    <div className="back-btn text-right cursor-pointer mb-2">
                      <CSVLink {...csvReportMeet}>
                        <FontAwesomeIcon
                          icon={faDownload}
                          className=""
                          size="sm"
                        />{" "}
                        Export
                      </CSVLink>
                    </div>
                    <DataTable
                      columns={columnsMeet}
                      className="text-center"
                      data={responseMeet}
                      customStyles={dataTableConfigs}
                      paginationTotalRows={props.BatchMember.count}
                      pagination
                      onChangePage={(pageNo) => {
                        props.setFilter((prev) => ({
                          ...prev,
                          pageNo: pageNo - 1,
                        }));
                      }}
                      progressPending={props.Loading}
                      progressComponent={<TableLoader />}
                      noDataComponent={<NoDataComponent />}
                      onChangeRowsPerPage={(pageSize) => {
                        props.setFilter((prev) => ({ ...prev, pageSize }));
                      }}
                      paginationServer
                      persistTableHead={true} // paginationPerPage={10}
                      paginationRowsPerPageOptions={[5, 10, 20, 30]}
                    />
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </div>
    </div>
  );
}
