import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../component/header'
import Footer from '../component/footer'
import NewHeader from '../component/header/NewHeader'

const Mainlayout = () => {
	return (
		<>
			<NewHeader />
			<Outlet />
			<Footer />
		</>
	)
}

export default Mainlayout
