import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Helper from '../../Helper/Helper'
import { TableLoader } from '../../component/Loaders'
import CopyData2 from '../../component/Reusable/CopyData 2'
import DatePickerReuse2 from '../../component/Reusable/DatePicker2Reuse'
import TableDataStatus from '../../component/Reusable/TableDataStatus'
import NoDataComponent from '../../component/Reusable/no-data-component'
import SearchReuse2 from '../../component/Reusable/searchReuse2'
import FilterSelect from '../../component/Reusable/selectReuse'
import { dataTableConfigs } from '../../configs/dataTableConfigs'
import {
	CheckNegativeValue,
	toFixedLength,
	toFixedUtil
} from '../../component/utils'
const SingleBotHistory = ({ botId }) => {
	const initialPageData = { pageNo: 0, pageSize: 10 }
	const filterOptions = [
		{ name: 'All', value: 'all' },
		{ name: 'Active', value: 1 },
		{ name: 'Completed', value: 2 }
	]

	const [Loading, setLoading] = useState(false)
	const [orderInfo, setOrderInfo] = useState({ data: [], count: 0 })
	const [filters, setFilter] = useState({
		search_by: '',
		status: 'all',
		method: 'all',
		type: 'all',
		bot_id: botId,
		start_date: '',
		end_date: '',
		...initialPageData
	})

	useEffect(() => {
		getBotOrderInfo()
	}, [filters])

	const getBotOrderInfo = () => {
		setLoading(true)
		Helper.PostData('aabu', 'user/botRouter/bot_order_his', filters, false)
			.then(res => {
				if (res.status) {
					setOrderInfo({ data: res.data, count: res.count })
				} else {
					setOrderInfo({ data: [], count: 0 })
				}
			})
			.finally(() => setLoading(false))
	}

	const columns = [
		{
			name: 'S.No',
			sortable: true,
			cell: (row, index) => <td>{index + 1}</td>
		},
		{
			name: 'Txn Id',
			sortable: true,
			cell: row => <CopyData2 data={row.tx_id} />
		},

		{
			name: 'Method',
			cell: row => {
				if (row.method == 'long') {
					return <p className='badge bg-success caps'>{row.method}</p>
				} else if (row.method == 'short') {
					return <p className='badge bg-danger caps'>{row.method}</p>
				}
			},
			sortable: true
		},
		{
			name: 'Type',
			cell: row => {
				if (row.side == 'BUY') {
					return <p className='text-success'>{row.side}</p>
				} else if (row.side == 'SELL') {
					return <p className='text-danger'>{row.side}</p>
				}
			},
			sortable: true
		},
		{
			name: 'Buy Price (USD)',
			selector: row => (row.buy_pirce ? toFixedUtil(row.buy_pirce, 2) : '---'),
			sortable: true
		},
		{
			name: 'Sell Price (USD)',
			selector: row =>
				row.sell_price ? toFixedUtil(row.sell_price, 2) : '---',

			sortable: true
		},
		// {
		// 	name: 'Total Profit (USD)',
		// 	selector: row =>
		// 		row.total_profit || row.total_profit == 0
		// 			? toFixedUtil(row.total_profit, 2)
		// 			: '---',

		// 	sortable: true
		// },
		{
			name: 'Total Profit (USD)',
			cell: row => (
				<CheckNegativeValue tag='p' value={(+row.total_profit).toFixed(4)} />
			),

			sortable: true
		},
		{
			name: 'Buy Order Id',
			sortable: true,
			selector: row => row.buy_order_id,
			cell: row => <CopyData2 data={row.buy_order_id} />
		},
		{
			name: 'Sell Order Id',
			sortable: true,
			selector: row => row.sell_order_id,
			cell: row => <CopyData2 data={row.sell_order_id} />
		},

		{
			name: 'Buy Date',
			sortable: true,
			cell: row =>
				moment(new Date(+row.buy_date)).format('YYYY-MM-DD hh:mm:ss A')
		},
		{
			name: 'Sell Date',
			sortable: true,
			cell: row =>
				row.sell_date
					? moment(new Date(+row.sell_date)).format('YYYY-MM-DD hh:mm:ss A')
					: '---'
		},

		// {
		// 	name: 'Date & Time',
		// 	sortable: true,
		// 	cell: row => moment(row.created_at).format('YYYY-MM-DD hh:mm:ss A')
		// },

		{
			name: 'Status',
			sortable: true,
			cell: row => {
				if (row.status == 1) {
					return <p className='badge bg-pending'>{'Active'}</p>
				} else if (row.status == 2) {
					return <p className='badge bg-success'>{'Completed'}</p>
				}
			}
		}
	]
	return (
		<div>
			<div className='trans-body'>
				<div className='trans-TabCnt'>
					<div className='stakingbonus-tabcnt'>
						{/* <div className='d-flex justify-content-between '>
							<div></div>
							<div className='bonus-Head'>
								<h5>Bot Request History</h5>
							</div>
						</div> */}

						<div className='bonus-Body'>
							<div className='row mb-2'>
								<FilterSelect
									setFilters={setFilter}
									className='col-lg-2'
									initialPageData={initialPageData}
									options={filterOptions}
								/>
								<FilterSelect
									setFilters={setFilter}
									className='col-lg-2'
									initialPageData={initialPageData}
									options={[
										{ name: 'All', value: 'all' },
										{ name: 'Long', value: 'long' },
										{ name: 'Short', value: 'short' }
									]}
									label='Method'
									filterKey='method'
								/>
								<DatePickerReuse2 setFilters={setFilter} className='col-lg-2' />
								<SearchReuse2
									setFilters={setFilter}
									className='col-lg-4'
									placeholder={'Txn Id'}
									initialPageData={initialPageData}
								/>
							</div>
							<div className='cmntbl'>
								<div className='table-responsive'>
									<DataTable
										columns={columns}
										className='text-center'
										data={orderInfo.data}
										paginationTotalRows={orderInfo.count}
										customStyles={dataTableConfigs}
										pagination
										paginationServer
										onChangePage={pageNo => {
											setFilter(prev => ({ ...prev, pageNo: pageNo - 1 }))
										}}
										progressPending={Loading}
										progressComponent={<TableLoader />}
										noDataComponent={<NoDataComponent />}
										onChangeRowsPerPage={pageSize => {
											setFilter(prev => ({ ...prev, pageSize }))
										}}
										persistTableHead={true}
										// paginationPerPage={10}
										paginationRowsPerPageOptions={[5, 10, 20, 30]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SingleBotHistory
