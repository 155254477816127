const { createSlice } = require("@reduxjs/toolkit");

let initialState = {
  payloadCry: "",
  transOtp: "email",
};
const CryptoWithdrawReducer = createSlice({
  name: "CryptoWithRed",
  initialState,
  reducers: {
    setCryptoWithdrawParams: (state, action) => {
      state.payloadCry = action.payload;
    },
    setCryptoWithdrawStoreOtpType: (state, action) => {
      state.transOtp = action.payload;
    },
  },
});
export const { setCryptoWithdrawParams,setCryptoWithdrawStoreOtpType } = CryptoWithdrawReducer.actions;
export default CryptoWithdrawReducer.reducer;
