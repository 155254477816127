import { useNavigate } from "react-router-dom";
import success from "../../assets/images/index/icons8-done-94__1x_.png";
import { useEffect } from "react";
import Footer from "../../component/footer";
import Header from "../../component/header";
import NewHeader from "../../component/header/NewHeader";

const RegisterConfirm = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("#wert")) {
      setTimeout(() => {
        // navigate("/oauth/login");
        localStorage.clear();
      }, 3000);
    }
  }, []);
  return (
    <>
      <NewHeader />
      <div className="container">
        <div class="blog_header_bg_1 text-center pt-5 pb-lg-3"></div>
      </div>
      <div className="container">
        <section class="blog-sec-1">
          <div class="container">
            <div class="row justify-content-center">
              <div
                class="col-xl-8 mt-5 pt-5"
                style={{
                  background: "#151515",
                  borderRadius: "15px",
                  padding: "10px",
                }}
              >
                <div class="lutc_box">
                  <div class="row justify-content-center mb-3">
                    <div class="col-md-12 text-center">
                      <img
                        alt="cryptocurrency exchange"
                        src={success}
                        style={{ width: "70px" }}
                        className="img-fluid"
                      ></img>
                    </div>
                    <div class="col-md-12 text-center text-light">
                      <h3 className="mt-3">Registration Done Successfully!!</h3>
                      <p className="mt-2">
                        Email Activation Link Sent to your Registered Mail Id.
                        Kindly Click and Activate your Account
                      </p>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="mt-5 pt-5">
        <Footer />
      </div>
    </>
  );
};

export default RegisterConfirm;
