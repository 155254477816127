import QRCode from 'qrcode';

export const generateQR = async (text) => {
	try {
		const qrCode = await QRCode.toDataURL(text);
		return qrCode;
	} catch (err) {
		console.error(err);
	}
};
