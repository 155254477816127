import React, { useEffect, useState } from 'react'
import Helper from '../../Helper/Helper'
import { TableLoader } from '../../component/Loaders'
import NoDataComponent from '../../component/Reusable/no-data-component'
import ProgressLine from '../../component/progressBar'
import { toFixedLength } from '../../component/utils'
import ConfirmationModal from '../modalpage/Confirmation/confirmation'
import UpdateExchange from '../modalpage/UpdateExchange'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faExclamationCircle,
	faExclamationTriangle,
	faInfoCircle
} from '@fortawesome/free-solid-svg-icons'
import ConnectExchange from '../modalpage/ConnectExchange'

const BotAssetCard = ({ botPieChartLoading, getBotPieChart }) => {
	console.log('botPieChartLoading: ', botPieChartLoading)

	const [exchanges, setExchanges] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => getOrderInfo(), [])

	const getOrderInfo = () => {
		setLoading(true)
		Helper.GetData('aabu', 'user/botRouter/user_exchange_list')
			.then(res => {
				if (res.status) {
					setExchanges(res.data)
				} else {
					setExchanges([])
				}
			})
			.finally(() => setLoading(false))
	}

	return (
		<>
			<div className='row mb-3'>
				<div className='col-lg-12 text-right'>
					<div className='d-flex justify-content-between'>
						<div className='bonus-Head flex-1'>
							<h5>Connected Exchanges</h5>
						</div>
						<div>
							<ConnectExchange getBotPieChart={getBotPieChart} />
						</div>
					</div>
				</div>
			</div>
			<div class='row'>
				<div class='col-lg-12'>
					{!loading ? (
						<div className='row'>
							{exchanges.length > 0 ? (
								exchanges.map((item, index) => (
									<div key={index} className='col-md-6 col-sm-6 col-lg-6 mb-4'>
										<div className='exgs'>
											<div className='exgs-head d-flex justify-content-between'>
												<div>
													<h6>{item.exchange_name}</h6>
													<span>{item.exchange_code}</span>
												</div>
												<div>
													<span className='text-light'>
														Exchange Balance
													</span>
													<span> ${toFixedLength(item.total_usdt, 2)} </span>
												</div>
											</div>
											{(() => {
												if (+item.total_usdt == 0) {
													return (
														<>
															<div className='text-center mt-3'>
																<FontAwesomeIcon
																	icon={faExclamationCircle}
																	size='2x'
																	color='#a48184'
																/>
																<h4>Insufficient funds</h4>
																<p className='mb-0'>
																	Make a deposit to your exchange to start
																	trading
																</p>
															</div>

															<div className='row justify-content-between mt-4'>
																<div className='col-6'>
																	<UpdateExchange ExchangeData={item} />
																</div>
																<div className='col-6 text-right'>
																	<ConfirmationModal
																		getBotPieChart={getBotPieChart}
																		Data={item}
																		getOrderInfo={getOrderInfo}
																	/>
																</div>
															</div>
														</>
													)
												} else if (item.apikeystatus) {
													return (
														<>
															<div className='text-center mt-3'>
																<FontAwesomeIcon
																	icon={faExclamationCircle}
																	size='2x'
																	color='#a48184'
																/>
																<h5>API key is no longer valid</h5>
																<p className='mb-0'>Please Update API key</p>
															</div>

															<div className='row justify-content-between mt-4'>
																<div className='col-6'>
																	<UpdateExchange ExchangeData={item} />
																</div>
																<div className='col-6 text-right'>
																	<ConfirmationModal
																		getBotPieChart={getBotPieChart}
																		Data={item}
																		getOrderInfo={getOrderInfo}
																	/>
																</div>
															</div>
														</>
													)
												} else {
													return (
														<>
															<div className='exgs-prog'>
																<ProgressLine
																	label='Multiple parts'
																	visualParts={[
																		{
																			percentage: `${item.percentage}%`,
																			color: '#3366cc',
																			title: 'USDT'
																		}
																	]}
																/>
															</div>
															<div>
																{+item.total_usdt < 10 && (
																	<p className='dep-warn'>
																		<span className='wrg'>
																			<FontAwesomeIcon
																				icon={faExclamationTriangle}
																			/>
																		</span>
																		&nbsp;Please make a deposit ASAP!
																	</p>
																)}
															</div>
															<div className='exgs-body'>
																<div className='d-flex justify-content-between'>
																	<span>Total</span>
																	<div>
																		<span>
																			$ {toFixedLength(item.total_usdt, 2)}{' '}
																		</span>
																		<span className='inn-span'>
																			/ {toFixedLength(item.total_btc, 8)} BTC
																		</span>
																	</div>
																</div>
																{/* 
														<div className='d-flex justify-content-between mt-2'>
															<span>24hrs changes</span>
															<CheckNegativeValue
																value={botBalance.day_profit_usd_percentage}
																content={'%'}
																tag={'span'}
															/>
															<CheckNegativeValue
																value={botBalance.day_profit_btc_percentage}
																content={'%'}
																tag={'span'}
															/>
														</div> */}
															</div>
															<div className='row justify-content-between mt-4'>
																<div className='col-6'>
																	<UpdateExchange ExchangeData={item} />
																</div>
																<div className='col-6 text-right'>
																	<ConfirmationModal
																		getBotPieChart={getBotPieChart}
																		Data={item}
																		getOrderInfo={getOrderInfo}
																	/>
																</div>
															</div>
														</>
													)
												}
											})()}
										</div>
									</div>
								))
							) : (
								<div className='col-12'>
									<NoDataComponent image />
								</div>
							)}
						</div>
					) : (
						<div className='row justify-content-center align-items-center mh-200px'>
							<TableLoader />
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default BotAssetCard
