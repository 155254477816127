import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Helper from '../../../Helper/Helper'
import { ButtonLoader } from '../../../component/Loaders'
import CloseBtn from '../../../component/Reusable/CloseBtn'
import './updateExchange.scss'

const UpdateExchange = ({ ExchangeData }) => {
	console.log('ExchangeData: ', ExchangeData)
	const [btnLoading, setBtnLoading] = useState(false)
	const [modal, setModal] = useState(false)
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset
	} = useForm({
		mode: 'onSubmit',
		reValidateMode: 'onChange'
	})

	const toggle = () => {
		reset()
		setModal(!modal)
	}

	const onSubmit = data => {
		setBtnLoading(true)
		Helper.PostData(
			'newBot',
			'user/botRouter/update_account',
			{ ...data, exchange_account_id: +ExchangeData.exchange_id },
			true
		)
			.then(res => {
				if (res.status) {
					reset()
					setModal(false)
				}
			})
			.finally(() => setBtnLoading(false))
	}
	return (
		<>
			<button type='button' class='btn-exg  px-4' onClick={toggle}>
				Update
			</button>
			<Modal
				isOpen={modal}
				toggle={toggle}
				className={'modal-md modal-dialog-centered mymodal'}
				size={'md'}
				backdrop='static'
				keyboard={false}
			>
				<ModalHeader toggle={toggle} close={<CloseBtn onClick={toggle} />}>
					Edit exchange
					<span className='ml-2 caps exg-name'>
						{ExchangeData.exchange_name}
					</span>
					<p className='upt-exc-info'>
						Changing the API Key won't affect any trading activities.Your
						settings and trading history will be saved
					</p>
				</ModalHeader>
				<ModalBody className='p-4'>
					<div className='update_ex'>
						<div class='row'>
							<div className='col-12'>
								<div className='note_cnt_exg'>
									<div>
										Connect keys securely
										<a
											className='txt-dec-none ml-1'
											target='_blank'
											href='https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072'
										>
											Full Guide
										</a>{' '}
									</div>
									<div className='mt-2'>
										1. How to Create API Keys on Binance
										<a
											className='txt-dec-none ml-1'
											target='_blank'
											href='https://www.binance.com/en/support/faq/how-to-create-api-keys-on-binance-360002502072'
										>
											Click here
										</a>
									</div>
									{/* <div className='mt-1'>
										2. Select third-party apps and choose 3Commas:
									</div>
									<div className='copy_ip_bg'>
										<div className='d-flex justify-content-between align-items-center'>
											<span>193.31.111.100 193.31....</span>
											<span>
												<a className='txt-dec-none ml-1' href='#'>
													<b> Copy</b>
												</a>
											</span>
										</div>
									</div> */}
									<div className='mt-1'>
										2. Paste generated data in inputs below
									</div>
								</div>
							</div>
						</div>
						<Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
							<div class='row'>
								<div className='col-12'>
									<div class='form-group formInputs walletInput'>
										<label class='col-form-label'>
											API Key <span className='text-danger'>*</span>
										</label>
										<input
											class='form-control'
											name='api_key'
											placeholder='Enter API Key'
											{...register('api_key', {
												required: '* API Key is required',
												pattern: {
													value: /[/^\S]/,
													message: '* Whitespace not allowed '
												}
											})}
										/>
										{errors.api_key && (
											<p className='text-danger'>{errors.api_key.message}</p>
										)}
									</div>
									<div class='form-group formInputs walletInput'>
										<label for='secret_key' class='col-form-label'>
											API Secret <span className='text-danger'>*</span>
										</label>
										<input
											class='form-control'
											name='secret_key'
											id='secret_key'
											placeholder='Enter API Secret'
											{...register('secret_key', {
												required: '* API Secret is required',
												pattern: {
													value: /[/^\S]/,
													message: '* Whitespace not allowed '
												}
											})}
										/>
										{errors.secret_key && (
											<p className='text-danger'>{errors.secret_key.message}</p>
										)}
									</div>
									{/* <div className='mt-3'>
										<a href='' className='txt-dec-none'>
											Available account type
										</a>
									</div> */}
									{!btnLoading ? (
										<button
											className='btn btn-primary w-100 mt-3'
											type='submit'
										>
											Update
										</button>
									) : (
										<button
											className='btn btn-primary w-100 mt-3'
											disabled
											type='button'
										>
											<ButtonLoader />
										</button>
									)}
								</div>
							</div>
						</Form>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default UpdateExchange
