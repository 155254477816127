import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const LazyImageLoad = ({ image }) => (
  <div>
    <LazyLoadImage
      className={image.props.className}
      alt={image.props.alt}
      height={image.props.height}
      src={image.props.src}
      width={image.props.width}
    />
  </div>
);

export default LazyImageLoad;
