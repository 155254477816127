import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import Helper from "../../Helper/Helper";
import { TableLoader } from "../../component/Loaders";
import CopyData2 from "../../component/Reusable/CopyData 2";
import DatePickerReuse2 from "../../component/Reusable/DatePicker2Reuse";
import TableDataStatus from "../../component/Reusable/TableDataStatus";
import NoDataComponent from "../../component/Reusable/no-data-component";
import SearchReuse2 from "../../component/Reusable/searchReuse2";
import FilterSelect from "../../component/Reusable/selectReuse";
import { dataTableConfigs } from "../../configs/dataTableConfigs";
import BotRequest from "../modalpage/BotRequest";
const BotRequestHistory = () => {
  const initialPageData = { pageNo: 0, pageSize: 10 };
  const filterOptions = [
    { name: "All", value: "all" },
    { name: "Pending", value: 0 },
    { name: "Completed", value: 1 },
    { name: "Rejected", value: 2 },
  ];
  const [botRequestData, setBotRequestHistory] = useState({
    data: [],
    count: 0,
  });
  const [Loading, setLoading] = useState(false);
  const [filters, setFilter] = useState({
    search_by: "",
    status: "all",
    start_date: "",
    end_date: "",
    ...initialPageData,
  });
  const callAPI = useSelector((state) => state.modal.callBotRequestHistoryAPI);

  useEffect(() => {
    getBotReqHistory();
  }, [filters]);

  useEffect(() => {
    if (callAPI) {
      getBotReqHistory();
    }
  }, [callAPI]);

  const getBotReqHistory = () => {
    setLoading(true);
    Helper.PostData("user", "trade_bot/user_bot_request_list", filters, false)
      .then((res) => {
        if (res.status) {
          console.log("res: ", res);
          setBotRequestHistory({ data: res.data, count: res.Count });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      cell: (row, index) => <td>{index + 1}</td>,
    },
    {
      name: "Exchange Name",
      sortable: true,
      cell: (row) => <CopyData2 data={row.exchage_name} />,
    },

    {
      name: "Pair",
      selector: (row) => row.pair,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) =>
        `${row.amount} ${row.pair ? row.pair.split("/")[0] : ""}`,
      sortable: true,
    },
    {
      name: "Leverage",
      selector: (row) => row.leverage,
      sortable: true,
    },
    {
      name: "Bot Req Id",
      sortable: true,
      selector: (row) => row.bot_req_id,
      cell: (row) => <CopyData2 data={row.bot_req_id} />,
    },

    {
      name: "Date & Time",
      sortable: true,
      cell: (row) => moment(row.created_at).format("YYYY-MM-DD hh:mm:ss A"),
    },

    {
      name: "Status",
      sortable: true,
      cell: (row) => <TableDataStatus status={row.status} />,
    },
  ];
  return (
    <div>
      <div className="trans-body">
        <div className="trans-TabCnt">
          <div className="stakingbonus-tabcnt">
            <div className="d-flex justify-content-between ">
              <div></div>
              <div className="bonus-Head">
                <h5 className="text-end ml-5 mt-xsm-2">Create Bot History</h5>
              </div>
              <div className="">
                <BotRequest button={true} />
              </div>
            </div>

            <div className="bonus-Body">
              <div className="row mb-2">
                <FilterSelect
                  setFilters={setFilter}
                  className="col-lg-2"
                  initialPageData={initialPageData}
                  options={filterOptions}
                />
                <DatePickerReuse2 setFilters={setFilter} className="col-lg-3" />
                <SearchReuse2
                  setFilters={setFilter}
                  className="col-lg-4"
                  placeholder={"Exg Name/Bot Req Id"}
                  initialPageData={initialPageData}
                />
              </div>
              <div className="cmntbl">
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    className="text-center"
                    data={botRequestData.data}
                    customStyles={dataTableConfigs}
                    pagination
                    paginationServer
                    paginationTotalRows={botRequestData.count}
                    onChangePage={(pageNo) => {
                      setFilter((prev) => ({ ...prev, pageNo: pageNo - 1 }));
                    }}
                    onChangeRowsPerPage={(pageSize) => {
                      setFilter((prev) => ({ ...prev, pageSize }));
                    }}
                    progressPending={Loading}
                    progressComponent={<TableLoader />}
                    noDataComponent={<NoDataComponent />}
                    persistTableHead={true}
                    paginationRowsPerPageOptions={[5, 10, 20, 30]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotRequestHistory;
