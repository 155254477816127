import React from 'react'
import { PhotoProvider, PhotoView } from 'react-photo-view'
const Preview = ({ src, children }) => {
	return (
		<PhotoProvider>
			<PhotoView src={src}>{children}</PhotoView>
		</PhotoProvider>
	)
}

export default Preview
