import React from 'react'
import noData from '../../assets/images/nodata.png'
const NoDataComponent = ({ image }) => {
	if (image) {
		return (
			<div className='text-center py-4'>
				<img src={noData} />
				<h6 className='mt-1'>No Connected Exchanges Found</h6>
			</div>
		)
	} else {
		return (
			<div className='text-center py-4'>
				<h6>No Records Found</h6>
			</div>
		)
	}
}
export default NoDataComponent
