import React, { useState } from 'react'
import './subscriptionHistory.scss'
import SubscriptionHistoryTables from '../../BotHistoryTables/SubscriptionHistoryTable'
import { useNavigate } from 'react-router-dom'

const SubscriptionHistory = () => {
	const navigate = useNavigate()
	const [currentMembership, setCurrentMembership] = useState({})

	return (
		<>
			<div className='sub-his'>
				<div className='container'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='page-heading mt-3'>
								<h3 className='heading'>Subscription History</h3>
								<p className='head-info'>
									Manage your purchases for KPK Park Bot Membership, MarketPlace Signals
									and Apps
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='container p-0'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='current-plan'>
								<p className='plan-head'>Current Plan</p>
								<h4 className='plan'>
									{currentMembership.name}{' '}
									<span>{currentMembership.duration}&nbsp;Days</span>
								</h4>
								<div className='expire mt-3'>
									<span  dangerouslySetInnerHTML={{__html:currentMembership.End_date}}>
										
									</span>
									<div>
										<button
											className='btn btn-primary'
											onClick={() => navigate('/bot-membership')}
										>
											Extend
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<SubscriptionHistoryTables
					setCurrentMembership={setCurrentMembership}
				/>
			</div>
		</>
	)
}

export default SubscriptionHistory
