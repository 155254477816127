import React from "react";
import "./about.scss";
import { Link, useSearchParams } from "react-router-dom";
import Header from "../../component/header";
import Footer from "../../component/footer";
import ScrollToTop from "../../component/utils/useScrollToTop";
import metadata from "../../metaData/metadata";
import { Helmet } from "react-helmet";
import NewHeader from "../../component/header/NewHeader";
const AboutusPage = () => {
  const [searchparams] = useSearchParams();
  const mobType = searchparams.get("type");
  console.log("mobType: ", mobType);
  return (
    <>
      <Helmet>
        <title>{metadata.aboutus.title}</title>
        <meta name="description" content={metadata.aboutus.description} />
        <meta name="keywords" content={metadata.aboutus.keywords} />
      </Helmet>
      {mobType !== "mobile" && <NewHeader />}
      <ScrollToTop />
      <div class="maincontent leftrightCir">
        <div class="pt-4">
          <div class="box-primary">
            <div class="container">
              {/* <div class="text-left mb-3"><Link class="fs-15 fw-400" to="/"><i class="bi bi-arrow-left"></i> BACK </Link></div> */}
              <div class="row">
                <div class="col-12">
                  <div class="bt-yellow mb-1">KPK PARK</div>
                  <div class="secTit mb-0">About Us</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="pb-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="secTit mb-2"></div>
                <div class="fs-16 fw-400 light-text mb-4">
                  <p></p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="card-aboutus py-5 px-3 mb-5">
                  <div class="px-lg-5">
                    <div class="row">
                      <div class="col-lg-5">
                        <div class="bt-yellow mb-1">KPK PARK</div>
                        <div class="secTit mb-4">About Us</div>
                      </div>
                      <div class="col-lg-7">
                        <div class="subTxt mb-0 text-justify">
                          <p>
                            KPK Park is a platform where you can stake your
                            cryptocurrency and generate passive income through
                            rewards. It enables you to discover the most
                            suitable projects for staking and connect with a
                            network of knowledgeable educators and influential
                            individuals in the crypto space. At KPK Park, you
                            have access to a variety of Proof of Stake (POS) and
                            masternode staking options, allowing you to select
                            the optimal projects that align with your goals and
                            maximize your rewards. The platform's extensive
                            network of educators and community leaders offers
                            valuable insights into different cryptocurrencies
                            and their respective staking opportunities. KPK Park
                            has grown into a reputable crypto staking platform,
                            trusted by thousands of users worldwide. The
                            platform and community of KPK Park embody a vision
                            to establish the largest crypto community globally,
                            driven by education and empowerment.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {mobType !== "mobile" && <Footer />}
    </>
  );
};

export default AboutusPage;
