import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../component/header'
import Footer from '../component/footer'
import NewHeader from '../component/header/NewHeader'

const BotLayout = () => {
	return (
		<>
			<NewHeader />

			<div class='maincontent mt-5'>
				<div class=''>
					<Outlet />
				</div>
			</div>

			<Footer />
		</>
	)
}

export default BotLayout
