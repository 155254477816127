import React, { useState } from 'react'
import searchicon from '../../assets/images/search-icon.svg'

const SearchReuse = ({ setFilters, initialPageData, placeholder,className }) => {
	const [searchValue, setSearchData] = useState('')

	const onSubmit = e => {
		e.preventDefault()
		if (searchValue) {
			setFilters(prev => ({
				...prev,
				...initialPageData,
				search_by: searchValue
			}))
		}
	}

	return (
		<div className={className}>
		<div className='search-input jik_kil'>
			<form onSubmit={onSubmit}>
				<input className='form-control'
					type='text'
					placeholder={placeholder}
					onChange={({ target: { value } }) => {
						if (!value) {
							setFilters(prev => ({
								...prev,
								...initialPageData,
								search_by: 'all'
							}))
						}
						setSearchData(() => value)
					}}
				/>
				<span>
					<a type='button' onClick={onSubmit}>
						<img src={searchicon} className='img-fluid ' alt='' />
					</a>
				</span>
			</form>
		</div>
		</div>
	)
}

export default SearchReuse
