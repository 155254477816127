import { Step, StepLabel, Stepper } from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import Helper from "../../../Helper/Helper";
import { setStoreOtpType } from "./slice";

function EnterMailOtp({ props }) {
  return (
    <div className="otp">
      <label className="mt-3 text-light">Enter the 6 digit mail code</label>
      <div className="mt-3">
        <OtpInput
          value={props.otp}
          onChange={(otp) => {
            props.setOtp(otp);
          }}
          numInputs={6}
          inputStyle="form-control"
          containerStyle="cdChIpR cdChIpR1"
          className="form-group"
          isInputNum={true}
        />
      </div>

      <div className="mt-3 text-center">
        {props.otp && props.otp.length === 6 ? (
          <button
            className="btn btn-primary btn-block fs-18 fw-500"
            disabled={props.loader}
          >
            {props.loader ? <Spinner size="sm" /> : "Submit"}
          </button>
        ) : (
          <button className="btn btn-primary btn-block fs-18 fw-500" disabled>
            {props.loader ? <Spinner size="sm" /> : "Submit"}
          </button>
        )}
      </div>
      <div className="text-center mt-1">{props.timer}</div>
    </div>
  );
}

function EnterMobileOtp({ props }) {
  return (
    <div className="otp">
      <label className="mt-3 text-light">Enter the 6 digit mobile code</label>
      <div className="mt-3">
        <OtpInput
          value={props.otp}
          onChange={(otp) => {
            props.setOtp(otp);
          }}
          numInputs={6}
          inputStyle="form-control"
          containerStyle="cdChIpR cdChIpR1"
          className="form-group"
          isInputNum={true}
        />
      </div>

      <div className="mt-3 text-center">
        {props.otp && props.otp.length === 6 ? (
          <button
            className="btn btn-primary btn-block fs-18 fw-500"
            disabled={props.loader}
          >
            {props.loader ? <Spinner size="sm" /> : "Submit"}
          </button>
        ) : (
          <button className="btn btn-primary btn-block fs-18 fw-500" disabled>
            {props.loader ? <Spinner size="sm" /> : "Submit"}
          </button>
        )}
      </div>
      <div className="text-center mt-1">{props.timerMobile}</div>
    </div>
  );
}

function getStepContent(step, setActiveStep, props) {
  switch (step) {
    case 0:
      return <EnterMailOtp props={props} />;
    case 1:
      return <EnterMobileOtp props={props} />;
  }
}
export function WithdrawOtp(props) {
  const dispatch = useDispatch();
  // const [checkType, setCheckType] = useState("email");
  const checkType = useSelector((state)=>state.withdrawParamReducer.storeOtp)
  const [steps, setSteps] = useState([
    "Email Authentication",
    "Mobile Authentication",
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const methods = useForm({
    defaultValues: {},
  });
  const WithdrawVerify = (data) => {
    let valueResend = {
      receiver_address: props.withdrawCryptoDetails?.receiver_address,
    };
    console.log("checkType: ", checkType);
    if (checkType == "email") {
      props.setLoader(true);
      Helper.PostData(
        "wallet",
        "withdraw/crypto_withdraw_initiate",
        {
          user_id: localStorage.getItem("tip"),
          currency_symbol: props.variant.currency,
          networkt_ype: props.variant.network,
          device_type: "web",
          otp_type: checkType,
          amount: props.withdrawCryptoDetails?.amount || "50",
          otp_code: props.otp,
          receiver_address:
            props.withdrawCryptoDetails?.receiver_address ||
            "0x70788a88eeCfC5670D7c05d787eFFa1A3A536c8e",
          tag: "gopinath---sf",
          validation: 2,
        },
        true
      )
        .then((res) => {
          if (res.status) {
            props.setOtp("");
            // setCheckType("mobile");
            props.setLoader(false);
            props.AutoWithdrawRequest(valueResend, "mobile");
            setActiveStep(activeStep + 1);
            dispatch(setStoreOtpType("mobile"));
          } else {
            props.setLoader(false);
            // setCheckType("email");
          }
        })
        .finally(() => {
          props.setLoader(false);
        });
    } else {
      props.setLoader(true);
      Helper.PostData(
        "wallet",
        "withdraw/crypto_withdraw_initiate",
        {
          user_id: localStorage.getItem("tip"),
          currency_symbol: props.variant.currency,
          networkt_ype: props.variant.network,
          device_type: "web",
          otp_type: "mobile",
          amount: props.withdrawCryptoDetails?.amount || "50",
          otp_code: props.otp,
          receiver_address:
            props.withdrawCryptoDetails?.receiver_address ||
            "0x70788a88eeCfC5670D7c05d787eFFa1A3A536c8e",
          tag: "gopinath---sf",
          validation: 2,
        },
        true
      )
        .then((res) => {
          if (res.status) {
            props.setOtp("");
            // setCheckType("mobile");
            props.setLoader(false);
            props.setOtpModal(false);
            props.setModal(false);
            props.toggleClose();
            props.setAmount("");
            setActiveStep(0);
            dispatch(setStoreOtpType("email"));
          } else {
            props.setLoader(false);
            // setCheckType("email");
          }
        })
        .finally(() => {
          props.setLoader(false);
        });
    }
  };
  return (
    <Modal
      className="ver_pop"
      isOpen={props.otpModal}
      size="sm"
      toggleModal={props.toggleOtp}
    >
      <ModalHeader toggle={props.toggleCloseOtp}>
        <h5 className="text-center text-light">OTP Verification</h5>
      </ModalHeader>
      <ModalBody>
        <div className="modal-otp-withdraw">
          <Stepper activeStep={activeStep} alternativeLabel className="">
            {steps.map((step) => (
              <Step key={step.key} completed={step.status}>
                <StepLabel
                  style={{
                    textTransform: "capitalize",
                    color: "white !important",
                  }}
                >
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="otp">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(WithdrawVerify)}>
              {getStepContent(activeStep, setActiveStep, props)}
            </form>
          </FormProvider>
        </div>
      </ModalBody>
    </Modal>
  );
}
