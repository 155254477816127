import {
  faAlignCenter,
  faMinusCircle,
  faPlusCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Tree } from "react-d3-tree";
import "./referralTree.scss";
// import profile from '../assets/images/img_avatar.png'
import profileimg from "../assets/images/dash-profile.svg";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import Helper from "../Helper/Helper";
import { isObjectHaveKey } from "../component/utils";

function NewNodeMOdal(hierarchyPointNode, nodeDatum, loadingNode) {
  console.log(
    "hierarchyPointNode, nodeDatum, loadingNode: ",
    hierarchyPointNode,
    nodeDatum,
    loadingNode
  );
  return (
    <div
      className={`wrapper-tree highlight-${hierarchyPointNode.depth}`}
      title={nodeDatum.email}
    >
      <div className="user-card">
        <div className="user-card-img">
          <img
            src={nodeDatum.profile_image || profileimg}
            alt=""
            style={{ filter: "hue-rotate(180deg)" }}
          />
        </div>
        <div className="user-card-info user-card-info_andr text-dark" style={{ marginTop: "-3px" }}>
          <h2 title={nodeDatum.username}>{nodeDatum.username}</h2>
          <div className="row">
            {/* <div className="d-flex"> */}
            <div className="col-md-8 d-flex">
              <p className="mt-0">
                <span>ID </span>
                <br /> {nodeDatum.userrandomid}
              </p>
              <p className="jik_vk">
                <span>DOJ</span> <br />
                {moment(nodeDatum.doj).format("YYYY/MM/DD")}
              </p>
            </div>
            {/* </div> */}
          </div>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-between">
              <p>
                <span style={{ color: "black" }}>Volume </span> <br />
                {nodeDatum.userStake_volume}
              </p>
              <p>
                <span style={{ color: "black" }} className="ml-2">
                  <FontAwesomeIcon icon={faUser} />{" "}
                </span>
                {nodeDatum.dir_ref_count}
                {loadingNode.includes(nodeDatum.user_id) && (
                  <Spinner size="sm" className="tree_node_spinner" />
                )}
              </p>
              <p className="ml-2">
                {+nodeDatum.dir_ref_count > 0 ? (
                  nodeDatum.children?.length > 0 ? (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      size="lg"
                      color="#b04242"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      size="lg"
                      className=""
                      color="#b04242"
                    />
                  )
                ) : (
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    size="lg"
                    style={{ visibility: "hidden" }}
                    color="#b04242"
                  />
                )}
              </p>
            </div>
          </div>

          {/* <div className="d-flex">
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              {+nodeDatum.dir_ref_count > 0 ? (
                nodeDatum.children?.length > 0 ? (
                  <FontAwesomeIcon
                    icon={faMinusCircle}
                    size="lg"
                    color="#b04242" />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    size="lg"
                    className=""
                    color="#b04242" />
                )
              ) : (
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  size="lg"
                  style={{ visibility: "hidden" }}
                  color="#b04242" />
              )}
            </div>
          </div> */}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
const MobileReferalTree = () => {
  const treeRef = useRef();
  const [searchParams] = useSearchParams();
  const mobType = searchParams.get("type");
  const mobToken = searchParams.get("token");
  const mobId = searchParams.get("value");
  const [Profile1, setProfile] = useState({});
  useEffect(() => {
    if (mobToken) {
      localStorage.setItem("logaccess", mobToken);
      setTimeout(() => {
        if (localStorage.getItem("logaccess")) {
          Helper.PostData("user", "user/getuserprofile", {}, false).then(
            (res) => {
              if (res.status) {
                setProfile(res.user[0]);
              }
            }
          );
        }
      }, 1000);
    }
  }, []);
  const [treeData, setTreeData] = useState({});
  const [translate, setTranslate] = useState({ x: 700, y: 100 });
  const [dimensions, setDimensions] = useState({ width: 700, height: 200 });
  const [loadingNode, setLoadingNode] = useState([]);
  const [key, setKey] = useState(1);

  const [initialZoom, setInitialZoom] = useState(
    (width = window.screen.width) => {
      // if (width < 575) {
      // 	return 1
      // }
      if (width >= 1200) {
        return 1.3;
      } else if (992 <= width && width <= 1199) {
        return 1.2;
      } else if (768 <= width && width <= 991) {
        return 1.1;
      } else if (576 <= width && width <= 767) {
        return 1;
      }
      return 1;
    }
  );
  useEffect(() => {
    if (mobToken) {
      localStorage.setItem("logaccess", mobToken);
    }
  }, [mobToken]);

  useEffect(() => {
    if (isObjectHaveKey(Profile1)) {
      let RootData = {
        user_id: mobId,
        username: Profile1.username,
        userrandomid: Profile1.userrandomid,
        userStake_volume: Profile1.MyStaking,
        profile_image: Profile1.profile_img,
        dir_ref_count: Profile1.directcount,
        doj: Profile1.created_at,
        email: Profile1.email,
        children: [],
      };
      setTreeData(RootData);
      handleToggle(RootData);
    }
  }, [Profile1]);

  useEffect(() => {
    document.body.classList.add("kpstakepage");
    handleGetBoundingClientRect();
  }, []);

  const handleGetBoundingClientRect = () => {
    const element = treeRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const centerX = (rect.left + rect.right) / 2;
      const centerY = (rect.top + rect.bottom) / 2;
      const center = (rect.width + rect.height) / 2;
      setTranslate({ x: centerX, y: 30 });
      setDimensions({ width: rect.width, height: rect.height });
      // Use the properties of 'rect' as needed (e.g., rect.top, rect.left, rect.width, rect.height, etc.)
    }
  };

  const handleToggle = async (node) => {
    // Check if the node has already been fetched
    if (node._children?.length === 0) {
      setTreeData((prevTreeData) => {
        const updatedTreeData = { ...prevTreeData };
        const nodeToUpdate = findNode(updatedTreeData, node.user_id);
        if (nodeToUpdate) {
          nodeToUpdate._children = nodeToUpdate.children;
          nodeToUpdate.children = [];
        }
        return updatedTreeData;
      });
    } else if (node.children.length === 0) {
      try {
        if (node._children?.length) {
          // setting already fetched data while reopen the nodes
          setTreeData((prevTreeData) => {
            const updatedTreeData = { ...prevTreeData };
            const nodeToUpdate = findNode(updatedTreeData, node.user_id);
            if (nodeToUpdate) {
              nodeToUpdate.children = nodeToUpdate._children;
              nodeToUpdate._children = [];
            }
            return updatedTreeData;
          });
        } else {
          setLoadingNode((prev) => [...prev, node.user_id]);
          const fetchedNodes = await fetchDataFromServer(node);
          setTreeData((prevTreeData) => {
            const updatedTreeData = { ...prevTreeData };
            const nodeToUpdate = findNode(updatedTreeData, node.user_id);
            if (nodeToUpdate) {
              nodeToUpdate.children = fetchedNodes;
              nodeToUpdate._children = [];
            }
            return updatedTreeData;
          });
          setLoadingNode((prev) =>
            [...prev].filter((item) => item != node.user_id)
          );
        }
      } catch (error) {
        console.error("Error fetching nodes:", error);
      }
    }
    //  else {
    // 	// If the node has already been fetched, just toggle the _collapsed property
    // 	setTreeData(prevTreeData => {
    // 		const updatedTreeData = { ...prevTreeData }
    // 		const nodeToUpdate = findNode(updatedTreeData, node.user_id)
    // 		if (nodeToUpdate) {
    // 			nodeToUpdate._collapsed = !nodeToUpdate._collapsed // Toggle the collapsed state
    // 		}
    // 		return updatedTreeData
    // 	})
    // }
  };

  const fetchDataFromServer = async ({ user_id }) => {
    let fetchedData = await Helper.PostData(
      "user",
      "auth/userTree",
      {
        user_id,
      },
      false
    );
    if (fetchedData.status) {
      return fetchedData.data;
    }
  };

  // Utility function to find a node by its name in the tree data
  const findNode = (node, targetName) => {
    if (node.user_id === targetName) {
      return node;
    }
    if (node.children) {
      for (const child of node.children) {
        const foundNode = findNode(child, targetName);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };

  return (
    <Fragment>
      <div className="leftrightCir">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div class="text-center">
                <div class="top-head-plot-book mb-0">
                  Referral Tree{" "}
                  <FontAwesomeIcon
                    icon={faAlignCenter}
                    className="ml-2 cur-poin"
                    color="#1ff"
                    title="Center Tree"
                    onClick={() => {
                      setTranslate((prev) => {
                        return { ...prev };
                      });
                      setKey((prev) => prev + 1);
                    }}
                  />
                </div>
              </div>
              <div
                id="treeWrapper-mob"
                ref={treeRef}
                style={{ width: "100%", height: "100vh" }}
              >
                <Tree
                  data={treeData}
                  orientation="vertical"
                  collapsible={true}
                  nodeSize={{ x: 160, y: 75 }}
                  separation={{ siblings: 1.1, nonSiblings: 1.3 }}
                  depthFactor={200} //300
                  key={key}
                  translate={translate}
                  scale={0.8}
                  dimensions={dimensions}
                  scaleExtent={{ min: 0.1, max: 2.5 }}
                  zoom={initialZoom}
                  allowForeignObjects={true}
                  centeringTransitionDuration={800}
                  renderCustomNodeElement={({
                    nodeDatum,
                    hierarchyPointNode,
                  }) => {
                    console.log("nodeDatum: ", nodeDatum);

                    return (
                      <foreignObject
                        width="160"
                        height="65"
                        x="-80"
                        onClick={() =>
                          +nodeDatum.dir_ref_count > 0 &&
                          handleToggle(nodeDatum)
                        }
                      >
                        {NewNodeMOdal(
                          hierarchyPointNode,
                          nodeDatum,
                          loadingNode
                        )}
                      </foreignObject>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MobileReferalTree;
