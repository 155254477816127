import React, { useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ReactDatePicker from "react-datepicker";
import { SpinnerLoader } from "../../../component/Loaders";
import { Controller } from "react-hook-form";
import CloseBtn from "../../../component/Reusable/CloseBtn";
import Select, { components } from "react-select";

export const AddFollowUp = (props) => {
  let sendLead = props;
  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">No Options</span>
        <br />
        <span className="custom-css-class">
          Kindly Add Your{" "}
          <span
            className="text-info cursor-pointer text-decoration-underline"
            onClick={() => sendLead.IsTabNavigate()}
          >
            Leads
          </span>
        </span>
      </components.NoOptionsMessage>
    );
  };
  return (
    <Modal
      isOpen={props.addLeadsModal}
      toggle={props.toggleAdd}
      className="modal-md modal-dialog-centered mymodal"
      size="md"
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader
        toggle={props.toggleAddClose}
        close={<CloseBtn onClick={() => props.toggleAddClose()} />}
      >
        Add FollowUp
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={props.handleSubmit(props.HandleAddFollowUp)}
          autoComplete="off"
        >
          <div class="row">
            <div className="col-12">
              <div class="form-group formInputs walletInput">
                <label class="col-form-label">
                  User Name<span className="text-primary">*</span>
                </label>
                <input
                  type="text"
                  style={{cursor:"not-allowed"}}
                  class="form-control "
                  name="follow_up_users"
                  defaultValue={props.followUpRow?.username}
                  disabled={props.followUpRow?.username}
                />

                {props.errors.follow_up_users && (
                  <p className="text-danger">
                    {props.errors.follow_up_users.message}
                  </p>
                )}
              </div>
              <div class="form-group formInputs walletInput">
                <label class="col-form-label">
                  Title<span className="text-primary">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="title"
                  placeholder="Enter Title"
                  {...props.register("title", {
                    required: "* Title is required",
                  })}
                />
                {props.errors.title && (
                  <p className="text-danger">{props.errors.title.message}</p>
                )}
              </div>
              <div class="form-group formInputs walletInput">
                <label class="col-form-label">
                  Description<span className="text-primary">*</span>
                </label>
                <textarea
                  class="form-control"
                  rows="3"
                  name="meeting_description"
                  placeholder="Enter Description"
                  formControlName="message"
                  {...props.register("meeting_description", {
                    required: "* Description is required",
                    pattern: {
                      value: /[/^\S]/,
                      message: "* Whitespace not allowed ",
                    },
                  })}
                  autocomplete="off"
                ></textarea>
                {props.errors.meeting_description && (
                  <p className="text-danger">
                    {props.errors.meeting_description.message}
                  </p>
                )}
              </div>

              <div class="form-group formInputs walletInput">
                <label class="col-form-label">
                  Location<span className="text-primary">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  name="location"
                  placeholder="Enter Location"
                  {...props.register("location", {
                    required: "* Location is required",
                  })}
                />
                {props.errors.location && (
                  <p className="text-danger">{props.errors.location.message}</p>
                )}
              </div>
              <div class="wallet-in-ser formInputs walletInput">
                <label>
                  Start Time<span className="text-primary">*</span>
                </label>
                <div class="input-login-1  ml-lg-2">
                  <Controller
                    name="fromtime"
                    control={props.control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      const selectedStartDate = value ? new Date(value) : null;
                      return (
                        <ReactDatePicker
                          className="form-control"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={selectedStartDate}
                          minDate={props.fromMinDate()}
                          dropdownMode="select"
                          peekNextMonth
                          showTimeSelect={true}
                          dateFormat="dd/MM/yyyy HH:mm:ss"
                          placeholderText="DD/MM/YYYY HH:MM:SS"
                        />
                      );
                    }}
                  />
                  {props.errors.fromtime && (
                    <p>
                      {props.errors.fromtime.type === "required" ? (
                        <p className="text-danger">* Start Time is required</p>
                      ) : (
                        <p className="text-danger">
                          {props.errors.fromtime.message}
                        </p>
                      )}
                    </p>
                  )}
                </div>
              </div>
              <br />
              <div class="wallet-in-ser formInputs walletInput">
                <label>
                  End Time<span className="text-primary">*</span>
                </label>
                <div class="input-login-1  ml-lg-2">
                  <Controller
                    name="totime"
                    control={props.control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => {
                      const selectedStartDate = value ? new Date(value) : null;
                      return (
                        <ReactDatePicker
                          className="form-control"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={selectedStartDate}
                          minDate={props.fromMinDate()}
                          dropdownMode="select"
                          peekNextMonth
                          showTimeSelect={true}
                          dateFormat="dd/MM/yyyy HH:mm:ss"
                          placeholderText="DD/MM/YYYY HH:MM:SS"
                        />
                      );
                    }}
                  />
                  {props.errors.totime && (
                    <p>
                      {props.errors.totime.type === "required" ? (
                        <p className="text-danger">* End Time is required</p>
                      ) : (
                        <p className="text-danger">
                          {props.errors.totime.message}
                        </p>
                      )}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mb-2 mt-3">
            {props.LoadingAdd ? (
              <button
                class="btn btn-primary px-4"
                type="button"
                disabled={props.LoadingAdd}
              >
                <SpinnerLoader />
              </button>
            ) : (
              <button class="btn btn-primary px-4" type="submit">
                Confirm
              </button>
            )}
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
