import React from "react";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ButtonLoader } from "../../../component/Loaders";
import CloseBtn from "../../../component/Reusable/CloseBtn";

const ConfirmStakeModal = ({
  modal,
  toggle,
  handleSubmit,
  loader,
  handleAPICall,
  FormData,
}) => {
  console.log("FormData: ", FormData);
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={"modal-md modal-dialog-centered mymodal"}
        size={"md"}
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader toggle={toggle} close={<CloseBtn onClick={toggle} />}>
          Staking Information
        </ModalHeader>
        <ModalBody className="px-4">
          <Form onSubmit={handleSubmit(handleAPICall)}>
            <div class="row">
              <div class="col-12">
                <div class="form-group formInputs walletInput d-flex justify-content-between">
                  <label class="col-form-label">User ID</label>
                  <label class="col-form-label">
                    {FormData.user_random_id}
                  </label>
                </div>
                <div class="form-group formInputs walletInput d-flex justify-content-between">
                  <label class="col-form-label">Currency</label>
                  <label class="col-form-label">{FormData.currency}</label>
                </div>
                <div class="form-group formInputs walletInput d-flex justify-content-between">
                  <label class="col-form-label">Staking Date</label>
                  <label class="col-form-label">{FormData.stake_date}</label>
                </div>
                <div class="form-group formInputs walletInput d-flex justify-content-between">
                  <label class="col-form-label">Staking volume</label>
                  <label class="col-form-label">
                    {FormData.staking_package}
                  </label>
                </div>
                <div class="form-group formInputs walletInput d-flex justify-content-between">
                  <label class="col-form-label">Duration Month</label>
                  <label class="col-form-label">{FormData.month} Month</label>
                </div>
                <div class="form-group formInputs walletInput d-flex justify-content-between">
                  <label class="col-form-label">Reward Amount Per Month</label>
                  <label class="col-form-label">
                    {FormData.reward_amount_per_month}
                  </label>
                </div>
                <div class="form-group formInputs walletInput d-flex justify-content-between">
                  <label class="col-form-label">OverAll Reward Amount</label>
                  <label class="col-form-label">
                    {FormData.overall_reward_amount}
                  </label>
                </div>
                <div class="form-group formInputs walletInput d-flex justify-content-between">
                  <label class="col-form-label">Percentage</label>
                  <label class="col-form-label">{FormData.percentage}</label>
                </div>
                <div class="text-center mb-2">
                  {loader ? (
                    <button type="button" disabled class="btn btn-primary px-4">
                      <ButtonLoader />
                    </button>
                  ) : (
                    <button type="submit" class="btn btn-primary px-4">
                      Stake
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ConfirmStakeModal;
