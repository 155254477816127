import { Slider } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Form, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Helper from '../../../Helper/Helper'
import { ButtonLoader } from '../../../component/Loaders'
import CloseBtn from '../../../component/Reusable/CloseBtn'
import { setBotRequestModal, setCallBotRequestHistoryAPI } from '../modalSlice'
import './BotRequest.scss'

const useStyles = makeStyles({
	sliderLabel: {
		color: 'white' // Set the label color to white
	}
})

const BotRequest = ({ button }) => {
	const classes = useStyles()
	const [loading, setLoading] = useState(true)
	const [btnLoading, setBtnLoading] = useState(false)
	const [selectedPair, setSelectedPair] = useState('')
	const [exchangeHistory, setExchangesHistory] = useState([])
	const [pairs, setPairs] = useState([])
	const [selectedExchangeInfo, setSelectedExchangeInfo] = useState({
		min_value: 0,
		max_value: 0
	})
	const [leverageERR, setleverageERR] = useState('')

	const [exchangeId, setExchangeId] = useState('')
	const [leverageInfo, setLeverageInfo] = useState({
		min_leverage: 0,
		max_leverage: 0
	})

	let { min_leverage, max_leverage } = leverageInfo

	// const [leverage, setLeverage] = useState({ value: null, bk_value: null })
	const [leverage, setLeverage] = useState({ value: 6, bk_value: 50 })
	console.log('leverage: ', leverage)

	const marks = [
		{
			value: 1, //need to add index position as value like(index+1) to get equal space between marks
			label: '1x',
			bk_value: 1
		},
		{
			value: 2,
			label: '2x',
			bk_value: 2
		},
		// {
		// 	value: 3,
		// 	label: '3x',
		// 	bk_value: 3
		// },
		{
			value: 3,
			label: '5x',
			bk_value: 5
		},
		{
			value: 4,
			label: '10x',
			bk_value: 10
		},
		{
			value: 5,
			label: '25x',
			bk_value: 25
		},
		{
			value: 6,
			label: '50x',
			bk_value: 50
		},
		{
			value: 7,
			label: '75x',
			bk_value: 75
		},
		{
			value: 8,
			label: '100x',
			bk_value: 100
		}
	]

	// const marks = [1, 2, 3, 4, 5, 6].map(value => ({
	// 	value,
	// 	label: `${calculateValue(value)}x`
	// }))

	const calculateValue = value => {
		let data = marks.find(item => item.value == value)
		if (data) {
			const { bk_value } = data
			return bk_value
		}
		return 0
	}

	const handleLeverageChange = (_e, value) => {
		let bk_value = calculateValue(value)
		setLeverage({ value, bk_value })
		if (+min_leverage > bk_value) {
			setleverageERR(`* Minimum leverage is ${min_leverage}x`)
		} else if (+max_leverage < bk_value) {
			setleverageERR(`* Maximum leverage is ${max_leverage}x`)
		} else {
			setleverageERR('')
		}
	}

	const modalState = useSelector(state => state.modal.botRequestModal)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const {
		handleSubmit,
		register,
		formState: { errors },
		control,
		reset
	} = useForm({
		mode: 'all',
		reValidateMode: 'onChange'
	})

	const toggle = from => {
		console.log('from: ', from)
		reset()
		if (from == 'button') {
			dispatch(setBotRequestModal(true))
		} else {
			dispatch(setBotRequestModal(false))
		}
		setExchangeId('')
		setleverageERR('')
	}

	useEffect(() => {
		if (modalState) {
			getExchangeNetworks()
		}
	}, [modalState])

	const getExchangeNetworks = () => {
		Helper.PostData(
			'aabu',
			'trade_bot/user_exchange_list',
			{ user_id: localStorage.getItem('tip') },
			false
		)
			.then(res => {
				if (res.status) {
					setExchangesHistory([
						...res.data
						// {
						// 	exchange_name: 'bybit_usdt_perpetual_Aabu3',
						// 	exchange_id: '3248785290',
						// 	min_leverage: '10',
						// 	max_leverage: '75',
						// 	min_value: '1',
						// 	max_value: '10',
						// 	pair: ['USDT/USDT', 'ETH/USDT']
						// }
					])
				}
			})
			.finally(() => setLoading(false))
	}

	const onSubmit = data => {
		if (leverage.bk_value) {
			if (!leverageERR) {
				setBtnLoading(true)
				Helper.PostData(
					'newBot',
					'user/botRouter/user_bot_request',
					{ ...data, leverage: leverage.bk_value, exchange_id: exchangeId },
					true
				)
					.then(res => {
						if (res.status) {
							dispatch(setBotRequestModal(false))
							// dispatch(setCallProfileApi())
							dispatch(setCallBotRequestHistoryAPI())
							reset()
							setExchangeId('')
							setSelectedPair('')
							button
								? navigate('/bot/dashboard?activeTab=4')
								: navigate('/bot/dashboard?activeTab=5')
						}
					})
					.finally(() => setBtnLoading(false))
			}
		} else {
			Helper.toasts('Please Choose Leverage', false)
		}
	}

	const exchangeOptions = useMemo(() => {
		return (
			<>
				<option value='' selected hidden>
					Choose Exchange
				</option>
				{!exchangeHistory.length ? (
					<option value='' disabled>
						No Connected Exchanges
					</option>
				) : (
					[...exchangeHistory].map((item, index) => (
						<option value={item.exchange_name} key={index}>
							{item.exchange_name}
						</option>
					))
				)}
			</>
		)
	}, [exchangeHistory])

	const OptionComponent = ({ pairs }) => (
		<>
			<option value='' selected hidden>
				Choose your Pair
			</option>
			{[...pairs].map((item, index) => (
				<option value={item} key={index}>
					{item}
				</option>
			))}
		</>
	)

	// function valueLabelFormat(value) {
	// 	return marks.findIndex(mark => mark.value === value) + 1
	// }

	const pairOptions = useMemo(() => {
		return <OptionComponent key={exchangeId} pairs={pairs} />
	}, [pairs])

	return (
		<>
			{button && (
				<button
					type='button'
					class='btn btn-primary px-4'
					onClick={() => toggle('button')}
				>
					Create Bot
				</button>
			)}
			<Modal
				isOpen={modalState}
				className={'modal-md modal-dialog-centered mymodal'}
				size={'md'}
				backdrop='static'
				keyboard={false}
			>
				<ModalHeader close={<CloseBtn onClick={() => toggle('close')} />}>
					Create Bot
				</ModalHeader>
				<ModalBody className='p-4'>
					<div className='connect_ex'>
						<Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
							<div class='row'>
								<div className='col-12'>
									<div class='form-group formInputs walletInput'>
										<label class='col-form-label'>
											Exchange <span className='text-danger'>*</span>
										</label>
										<select
											class='form-control'
											name='exchange_name'
											{...register('exchange_name', {
												required: '* Exchange is required',
												onChange: ({ target: { selectedIndex } }) => {
													let data = exchangeHistory[selectedIndex - 1]
													console.log('data: ', data)
													setSelectedExchangeInfo(prev => ({
														...prev,
														...data
													}))
													reset({
														amount: '',
														exchange_name: data.exchange_name
													})
													// setLeverage({ value: null, bk_value: null })
													setSelectedPair('')
													setExchangeId(data.exchange_id)
													setPairs(data.pair)
													setLeverageInfo({
														min_leverage: data.min_leverage,
														max_leverage: data.max_leverage
													})
												}
											})}
										>
											{exchangeOptions}
										</select>

										{errors.exchange_name && (
											<p className='text-danger'>
												{errors.exchange_name.message}
											</p>
										)}
									</div>
									<div class='form-group formInputs walletInput'>
										<label class='col-form-label' htmlFor='exchange_id'>
											Exchange ID <span className='text-danger'>*</span>
										</label>
										<input
											class='form-control'
											name='exchange_id'
											id='exchange_id'
											placeholder='Exchange ID'
											readOnly
											value={exchangeId}
											{...register('exchange_id', {
												required: false
												// pattern: {
												// 	value: /[/^\S]/,
												// 	message: '* Whitespace not allowed '
												// }
											})}
										/>
										{errors.exchange_id && (
											<p className='text-danger'>
												{errors.exchange_id.message}
											</p>
										)}
									</div>
									{exchangeId ? (
										<>
											<div class='form-group formInputs walletInput'>
												<label class='col-form-label'>
													Pair <span className='text-danger'>*</span>
												</label>
												<select
													class='form-control'
													name='pair'
													{...register('pair', {
														required: '* Pair is required',
														onChange: ({ target: { value } }) => {
															let firstCurrency = value.split('/')[0]
															setSelectedPair(firstCurrency)
															reset({ amount: '', pair: value })
														}
													})}
												>
													{pairOptions}
												</select>
												{errors.pair && (
													<p className='text-danger'>{errors.pair.message}</p>
												)}
											</div>
											{/* {selectedPair && (
												<div class='form-group formInputs walletInput'>
													<label class='col-form-label'>
														Amount <span className='text-danger'>*</span>
													</label>
													<Controller
														control={control}
														name='amount'
														rules={{
															required: '* Amount is required',
															validate: value => {
																console.log('value: ', value)
																let min = +selectedExchangeInfo.min_value
																let max = +selectedExchangeInfo.max_value
																value = +value
																if (value) {
																	if (value < min) {
																		return `* Minimum amount is ${min} ${selectedPair}`
																	} else if (value > max) {
																		return `* Maximum amount is  ${max} ${selectedPair}`
																	} else {
																		return true
																	}
																} else {
																	return '* Amount is required'
																}
															}
														}}
														render={({ field: { onChange, name, value } }) => (
															<NumericFormat
																onChange={onChange}
																placeholder='0.00'
																className='form-control fromInput'
																name={name}
																value={value}
																allowNegative={false}
																// decimalScale={0}
																// maxLength={7}
															/>
														)}
													/>
													{errors.amount && (
														<p className='text-danger'>
															{errors.amount.message}
														</p>
													)}
												</div>
											)} */}
											{/* {selectedPair && (
												<div className='d-flex justify-content-between min-max px-2'>
													<div>
														<span className='text-gray'>Min</span>
														<span>
															<b>
																{' '}
																{selectedExchangeInfo.min_value} {selectedPair}
															</b>
														</span>
													</div>
													<div>
														<span className='text-gray'>Max</span>
														<span>
															<b>
																{' '}
																{selectedExchangeInfo.max_value} {selectedPair}
															</b>
														</span>
													</div>
												</div>
											)} */}
											<div class='form-group formInputs walletInput '>
												<label for='secret_key' class='col-form-label'>
													Leverage<span className='text-danger'>*</span>
												</label>
												<div className='px-2'>
													<div className={leverage.value ? 'thumb-active' : ''}>
														<Slider
															aria-label='Small steps'
															// defaultValue={0}
															// valueLabelFormat={valueLabelFormat}
															// getAriaValueText={valuetext}
															min={1}
															max={marks.length}
															step={null}
															// onChange={handleLeverageChange}
															valueLabelDisplay='off'
															marks={marks}
															value={leverage.value}
															key={exchangeId}
															size='medium'
														/>
													</div>
													{leverageERR && (
														<p className='text-danger'>{leverageERR}</p>
													)}
													<div className='d-flex justify-content-between min-max'>
														<div>
															<span className='text-gray'>Min</span>
															<span>
																<b> {min_leverage}x</b>
															</span>
														</div>
														<div>
															<span className='text-gray'>Max</span>
															<span>
																<b> {max_leverage}x</b>
															</span>
														</div>
													</div>
												</div>
												{/* <div className='ra
												nge-bar-cd mt-1 pt-1'>
													<div className=''>
														<Nav
															tabs
															className={(() => {
																let classes = 'trade-tab-2 range_bar_1'
																if (activeTabSell == '1' && 1 == min_leverage) {
																	classes = `${classes} line_25`
																} else if (
																	activeTabSell == '2' &&
																	1 == max_leverage
																) {
																	classes = `${classes} line_50`
																} else if (activeTabSell == '3') {
																	classes = `${classes} line_75`
																} else if (activeTabSell == '5') {
																	classes = `${classes} line_100`
																} else if (activeTabSell == '25') {
																	classes = `${classes} line_125`
																} else if (activeTabSell == '50') {
																	classes = `${classes} line_150`
																}
																return classes
															})()}
														>
															<div className='line_brfy'>
																<div className='line_brfy_in_0'></div>
																<div className='line_brfy_in'></div>
																<div className='line_brfy_in_2'></div>
																<div className='line_brfy_in_3'></div>
																<div className='line_brfy_in_4'></div>
																<div className='line_brfy_in_5'></div>
															</div>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: activeTabSell === '1'
																	})}
																	onClick={() => {
																		toggleSell('1')
																		// percentageCalculationSell(25)
																	}}
																>
																	<div className='rud_q ml-0'>
																		<div className='in_dodt '></div>
																	</div>
																	1x
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: activeTabSell === '2'
																	})}
																	onClick={() => {
																		toggleSell('2')
																		// percentageCalculationSell(50)
																	}}
																>
																	<div className='rud_q'>
																		<div className='in_dodt'></div>
																	</div>
																	2x
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: activeTabSell === '3'
																	})}
																	onClick={() => {
																		toggleSell('3')
																		// percentageCalculationSell(75)
																	}}
																>
																	<div className='rud_q'>
																		<div className='in_dodt'></div>
																	</div>
																	3x
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: activeTabSell === '5'
																	})}
																	onClick={() => {
																		toggleSell('5')
																		// percentageCalculationSell(100)
																	}}
																>
																	<div className='rud_q mr-0'>
																		<div className='in_dodt'></div>
																	</div>
																	5x
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: activeTabSell === '25'
																	})}
																	onClick={() => {
																		toggleSell('25')
																		// percentageCalculationSell(100)
																	}}
																>
																	<div className='rud_q mr-0'>
																		<div className='in_dodt'></div>
																	</div>
																	25x
																</NavLink>
															</NavItem>
															<NavItem>
																<NavLink
																	className={classnames({
																		active: activeTabSell === '50'
																	})}
																	onClick={() => {
																		toggleSell('50')
																		// percentageCalculationSell(100)
																	}}
																>
																	<div className='rud_q mr-0'>
																		<div className='in_dodt'></div>
																	</div>
																	50x
																</NavLink>
															</NavItem>
														</Nav>
													</div>
												</div> */}
											</div>
										</>
									) : (
										<></>
									)}
									{!btnLoading ? (
										<button
											className='btn btn-primary w-100 mt-3'
											type='submit'
										>
											Submit
										</button>
									) : (
										<button
											className='btn btn-primary w-100 mt-3'
											disabled
											type='button'
										>
											<ButtonLoader />
										</button>
									)}
								</div>
							</div>
						</Form>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default BotRequest
