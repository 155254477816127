const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  payloadWith: {},
  storeOtp:"email"
};
const withdrawReducer = createSlice({
  name: "withdrawParam",
  initialState,
  reducers: {
    setWithdrawParam: (state, action) => {
      state.payloadWith = action.payload;
    },
    setStoreOtpType: (state, action) => {
      state.storeOtp = action.payload;
    },
  },
});

export const { setWithdrawParam,setStoreOtpType } = withdrawReducer.actions;
export default withdrawReducer.reducer;
