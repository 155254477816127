import moment from 'moment'
import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
const DatePickerReuse2 = ({
	setFilters,
	className,
	fromDateKey = 'start_date',
	endDateKey = 'end_date'
}) => {
	const [fromDate, setFromDate] = useState('')
	const [toDate, setToDate] = useState('')

	const fromMinDate = () => {
		let date = new Date()
		date.setFullYear(2023)
		date.setDate(1)
		date.setMonth(0)
		return date
	}
	return (
		<>
			<div className={className}>
				<div class='input-login-1'>
					<DatePicker
						dateFormat='dd-MM-yyyy'
						className='date-input'
						placeholderText='Start Date'
						selected={fromDate}
						minDate={fromMinDate()}
						maxDate={new Date()}
						onChange={date => {
							if (date) {
								setFilters(prev => ({
									...prev,
									[fromDateKey]: moment(date).format('YYYY-MM-DD')
								}))
								setFromDate(date)
							} else {
								setFromDate('')
								setToDate('')
							}
						}}
					/>
				</div>
			</div>
			<div className={className}>
				{/* <div class='jik_kil'> */}
				<div class='input-login-1'>
					<DatePicker
						disabled={!fromDate}
						title={!fromDate ? 'Choose Start Date' : ''}
						dateFormat='dd-MM-yyyy'
						className='date-input'
						placeholderText='End Date'
						minDate={fromDate}
						maxDate={new Date()}
						selected={toDate}
						onChange={date => {
							setToDate(date)
							setFilters(prev => ({
								...prev,
								[endDateKey]: moment(date).format('YYYY-MM-DD')
							}))
						}}
					/>
				</div>
			</div>
			{/* </div> */}
		</>
	)
}

export default DatePickerReuse2
