import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Step, StepLabel, Stepper } from '@mui/material'
import classnames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Tooltip as ReactToolTip } from 'react-tooltip'
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap'
import Helper from '../../Helper/Helper'
import linkarr1 from '../../assets/images/linkarr-1.svg'
import linkarr2 from '../../assets/images/linkarr-2.svg'
import linkarr3 from '../../assets/images/linkarr-3.svg'
import linkarr4 from '../../assets/images/linkarr-4.svg'
import Tree from '../../assets/images/tree.png'
import { ScreenLoader } from '../../component/Loaders'
import { setProfileData } from '../../component/header/headerslice'
import { toFixedLength } from '../../component/utils'
import BotsInfo from '../Bot'
import SubscriptionHistory from '../Bot/SubscriptionHistory'
import BotAssetCard from '../Bot/botAssetCard'
import BotHistory from '../BotHistoryTables/Bot-history'
import BotAssets from '../BotHistoryTables/BotAssets'
import BotRequestHistory from '../BotHistoryTables/botRequestHistory'
import GrowthLevelBonusBot from '../BotHistoryTables/growth-level-bot'
import LevelBonusBot from '../BotHistoryTables/level-bonus-bot'
import Pricing from '../Pricing/pricing1'
import ConnectExchange from '../modalpage/ConnectExchange'
import Transfer from '../modalpage/Transfer/transfer'
import Activate from './Activate'
import './botdashboard.scss'

const BotDashboard = () => {
	const {
		handleSubmit,
		control,
		formState: { errors },
		register
	} = useForm({
		mode: 'all',
		reValidateMode: 'onChange'
	})
	let currentStep = 0
	const dispatch = useDispatch()
	const [searchParams, setSearchParams] = useSearchParams({ activeTab: 1 })
	const [EarningInfo, setEarningInfo] = useState({})
	const navigate = useNavigate()
	const active_tab = searchParams.get('activeTab')
	const [activeTab, setActiveTab] = useState(active_tab || '1')
	const [copied, setCopied] = useState({ status: false, index: -1 })
	const { copyText } = Helper
	const [cardLoader, setCardLoader] = useState(false)
	const [steps, setSteps] = useState([])
	const [ranks, setRanks] = useState({})
	const [imgData, setImgData] = useState('')
	const [ImageLoader, setImageLoader] = useState(false)
	const [modal, setModal] = useState(false)
	const [modalImage, setModalImage] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [botTotalValue, setBotTotalValue] = useState({})
	const [exchangeAssetData, setExchangeAssetData] = useState([])
	const [botUserWalletData, setBotUserWalletData] = useState({})
	const [botPieChartData, setBotPieChartData] = useState([
		['Exchange', 'Amount']
	])
	const [botPieChartLoading, setBotPieChartLoading] = useState(false)
	const Profile = useSelector(state => state.header.profileData)

	useEffect(() => {
		getBotPieChart()
		toggleImage()
		getProgressBar()
		getBotUserWallet()
	}, [])

	const getBotUserWallet = () => {
		// setBotHistoryLoading(false)
		Helper.GetData('local', 'user/botRouter/get_user_wallet')
			.then(res => {
				if (res.status) {
					setBotUserWalletData(res.data)
					setTimeout(() => handleOpenTooltip(), 2000)
				}
			})
			.finally(() => {
				// setBotHistoryLoading(false)
			})
	}

	const getBotPieChart = () => {
		setBotPieChartLoading(true)
		Helper.GetData('newBot', 'user/botRouter/user_exchange_list')
			.then(res => {
				console.log("res: ", res);
				if (res.status) {
					const { data } = res
					const modifiedPieData = [...botPieChartData]
					let totalValue = { usd_value: 0, btc_value: 0 }
					console.log("totalValue: ", totalValue);
					for (let { exchange_name, total_usdt = 0, total_btc = 0 } of data) {
						totalValue.usd_value += +total_usdt
						totalValue.btc_value += +total_btc

						if (exchange_name && (total_usdt || total_usdt == 0)) {
							modifiedPieData.push([exchange_name, total_usdt || 0.000000001])
						}
					}
					console.log('modifiedPieData: ', modifiedPieData)
					setBotPieChartData(modifiedPieData)
					setBotTotalValue(totalValue)
					setExchangeAssetData(res.data)
				} else {
					setActiveTab('2')
					setExchangeAssetData([])
				}
			})
			.finally(() => {
				setBotPieChartLoading(false)
			})
	}

	const toggleImage = () => {
		setModalImage(!modalImage)
	}
	const handleOpenTooltip = () => {
		setIsOpen(true)
		setTimeout(() => setIsOpen(false), 7000)
	}
	// const FormData = {};
	const toggle = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab)
			// navigate(`/bot/dashboard?activeTab=${tab}`)
			setSearchParams(
				prev => {
					prev.set('activeTab', tab)
					return prev
				},
				{ replace: true }
			)
		}
	}

	const copyStatus = (index, setCopy) => {
		setCopy({ status: true, index })
		setTimeout(() => {
			setCopy({ status: false, index: -1 })
		}, 500)
	}

	const getProgressBar = () => {
		Helper.GetData('user', 'user/botRouter/level_progress_bar').then(res => {
			if (res.status) {
				setSteps(() => [...res.data])
				setRanks(res.membership_details)
			}
		})
	}

	const ImageUploader = val => {
		setImageLoader(true)
		let bankImage = new FormData()
		bankImage.append('image', val[0])
		bankImage.append('type', 'profile')
		Helper.PostImageData('Image', 'user/profileUpload', bankImage, false).then(
			res => {
				if (res.status) {
					localStorage.setItem('qwsdeser', res.data.imageurl)
					setImgData(res.data.imageurl)
					Helper.PostData(
						'user',
						'user/profileUpdate',
						{ profile_image: res.data.imageurl },
						true
					)
						.then(resp => {
							if (resp.status) {
								Helper.PostData('user', 'user/getuserprofile', {}, false).then(
									res => {
										if (res.status) {
											dispatch(setProfileData(res.user[0]))
										}
									}
								)
							} else {
								setImageLoader(false)
							}
						})
						.finally(() => setImageLoader(false))
				} else {
				}
			}
		)
	}

	const isCheckFileType = (val, img) => {
		let imgtype = val[0].type
		if (
			imgtype != 'image/jpg' &&
			imgtype != 'image/jpeg' &&
			imgtype != 'image/png' &&
			imgtype != 'image/pdf'
		) {
			return '* Only .jpg, .jpeg, .png files are allowed'
		} else {
			ImageUploader(val, img)
			return true
		}
	}

	const toggleMail = () => {
		setModal(!modal)
	}

	return (
		<>
			<div className='dashboard-main'>
				<ScreenLoader loading={!Object.keys(Profile).length}>
					<div className='container dash-container'>
						<div className='row'>
							<div className='col-12'>
								<div className=' mb-3'>
									<div className='row justify-content-between'>
										<div className='col-lg-6 col-xl-4 d-flex '>
											<h5 className='dashboard-headTxt m-0 mb-3 mb-lg-0'>
												Bot Dashboard
											</h5>
											<img
												src={Tree}
												title='Referal Tree'
												className='img-fluid text-align-end ml-3 cursor-pointer'
												onClick={() => navigate('/user/referral')}
												alt=''
												style={{ width: '40px', height: '40px' }}
											/>
										</div>
										<div className='col-lg-6 '>
											<div className='text-right'>
												{/* <ConnectExchange getBotPieChart={getBotPieChart} /> */}
											</div>
										</div>
									</div>

									<div className='row mt-1'>
										<div className='col-xl-4 mt-3'>
											<div className='dshead-bg hg_min'>
												<div className='dashprofile-InrCnt '>
													<div className='dashpro-datecnt d-lg-flex     align-items-start'>
														{/* <div className='dash-proImg dash-proImg_edit mr-3'>
															{(() => {
																if (ImageLoader) {
																	return <Spinner />
																} else {
																	return (
																		<img
																			src={Profile.profile_img || profileimg}
																			className='img-fluid rounded-circle'
																			alt=''
																			height='80'
																			width='80'
																		/>
																	)
																}
															})()}
															<div className='setting-pro-2 '>
																<div className='variants'>
																	<div class='file'>
																		<input
																			id='input-file'
																			className='fileUpload'
																			type='file'
																			{...register('passportImg', {
																				validate: val => {
																					if (val.length != 0) {
																						return isCheckFileType(
																							val,
																							'passportImg'
																						)
																					} else {
																						return '* Please Choose file to upload'
																					}
																				}
																			})}
																		/>
																		<label
																			for='input-file'
																			className='btn btn-primary-edit mw-auto py-1'
																			style={{
																				color: 'rgb(212 202 202)',
																				width: '30px',
																				height: '30px',
																				borderRadius: '50%',
																				backgroundColor: '#1140ff',
																				padding: '3px'
																			}}
																		>
																			<FontAwesomeIcon icon={faEdit} />
																		</label>
																	</div>
																</div>
															</div>
														</div> */}
														<div className='dash-proCnt'>
															<h5 className='text-white mb-0'>
																{Profile.username}
															</h5>
															<h4
																className='text-yellow mt-2 '
																style={{ fontSize: '1.125rem !important' }}
															>
																{' '}
																{Profile.userrandomid}
															</h4>
															<p className='light-blue mb-0 mail_break'>
																{Profile.email}
															</p>

															<div className='mt-2 d-flex     align-items-center flex-wrap justify-content-between'>
																<p class='para-fs14 text-left mr-1 mb-2'>
																	Referral ID
																</p>
																{Profile.stakestatus == 'true' ? (
																	<h5
																		className='mb-2'
																		style={{ color: '#ffb721' }}
																	>
																		{Profile.useruniqueid}

																		{copied.status && copied.index === 2 ? (
																			<span className='ml-2  text-success'>
																				✔
																			</span>
																		) : (
																			<span
																				className='ml-2 cur-poin'
																				title='Copy'
																			>
																				<svg
																					width='20'
																					height='20'
																					viewBox='0 0 20 20'
																					fill='none'
																					xmlns='http://www.w3.org/2000/svg'
																					onClick={() =>
																						copyText(
																							Profile.useruniqueid,
																							copyStatus,
																							2,
																							setCopied
																						)
																					}
																				>
																					<g clip-path='url(#clip0_117_3054)'>
																						<path
																							d='M15 6.66675H8.33335C7.41288 6.66675 6.66669 7.41294 6.66669 8.33341V15.0001C6.66669 15.9206 7.41288 16.6667 8.33335 16.6667H15C15.9205 16.6667 16.6667 15.9206 16.6667 15.0001V8.33341C16.6667 7.41294 15.9205 6.66675 15 6.66675Z'
																							stroke='white'
																							stroke-linecap='round'
																							stroke-linejoin='round'
																						/>
																						<path
																							d='M13.3333 6.66659V4.99992C13.3333 4.55789 13.1577 4.13397 12.8452 3.82141C12.5326 3.50885 12.1087 3.33325 11.6666 3.33325H4.99998C4.55795 3.33325 4.13403 3.50885 3.82147 3.82141C3.50891 4.13397 3.33331 4.55789 3.33331 4.99992V11.6666C3.33331 12.1086 3.50891 12.5325 3.82147 12.8451C4.13403 13.1577 4.55795 13.3333 4.99998 13.3333H6.66665'
																							stroke='white'
																							stroke-linecap='round'
																							stroke-linejoin='round'
																						/>
																					</g>
																					<defs>
																						<clipPath id='clip0_117_3054'>
																							<rect
																								width='20'
																								height='20'
																								fill='white'
																							/>
																						</clipPath>
																					</defs>
																				</svg>
																			</span>
																		)}
																	</h5>
																) : (
																	'--'
																)}
															</div>

															<div className='reflink-one flex-column mt-1 flex-lg-row align-items-lg-center align-items-start justify-content-between mb-3'>
																<div className='mr-2'>
																	<p className='para-fs14 m-0'>Referral Link</p>
																</div>
																{Profile.stakestatus == 'true' ? (
																	<div className='ref-link '>
																		<div>
																			<p className='m-0 P_lk'>
																				{Helper.formatRefLink(
																					Profile.referallink
																				)}
																			</p>
																		</div>
																		<div
																			className='copy-icon pl-2 cur-poin'
																			title='copy'
																		>
																			{copied.status && copied.index === 1 ? (
																				<span className='ml-2  text-success'>
																					✔
																				</span>
																			) : (
																				<svg
																					width='20'
																					height='20'
																					viewBox='0 0 20 20'
																					fill='none'
																					xmlns='http://www.w3.org/2000/svg'
																					onClick={() =>
																						copyText(
																							Profile.referallink,
																							copyStatus,
																							1,
																							setCopied
																						)
																					}
																				>
																					<g clip-path='url(#clip0_117_3054)'>
																						<path
																							d='M15 6.66675H8.33335C7.41288 6.66675 6.66669 7.41294 6.66669 8.33341V15.0001C6.66669 15.9206 7.41288 16.6667 8.33335 16.6667H15C15.9205 16.6667 16.6667 15.9206 16.6667 15.0001V8.33341C16.6667 7.41294 15.9205 6.66675 15 6.66675Z'
																							stroke='white'
																							stroke-linecap='round'
																							stroke-linejoin='round'
																						/>
																						<path
																							d='M13.3333 6.66659V4.99992C13.3333 4.55789 13.1577 4.13397 12.8452 3.82141C12.5326 3.50885 12.1087 3.33325 11.6666 3.33325H4.99998C4.55795 3.33325 4.13403 3.50885 3.82147 3.82141C3.50891 4.13397 3.33331 4.55789 3.33331 4.99992V11.6666C3.33331 12.1086 3.50891 12.5325 3.82147 12.8451C4.13403 13.1577 4.55795 13.3333 4.99998 13.3333H6.66665'
																							stroke='white'
																							stroke-linecap='round'
																							stroke-linejoin='round'
																						/>
																					</g>
																					<defs>
																						<clipPath id='clip0_117_3054'>
																							<rect
																								width='20'
																								height='20'
																								fill='white'
																							/>
																						</clipPath>
																					</defs>
																				</svg>
																			)}
																		</div>
																	</div>
																) : (
																	'--'
																)}
															</div>
															<div className='reflink-one flex-column mt-1 flex-lg-row  align-items-start justify-content-between mb-3 mb-lg-0'>
																<div className='mr-2'>
																	<p className='para-fs14 m-0'>Active Plan</p>
																</div>
																<div>
																	<h6 className='mb-0 text-right'>
																		<b>{ranks.plan_name}</b>
																	</h6>
																	<p className='mb-0 text-gray'>
																		Expired in {ranks.remaining_days} day{' '}
																		<Link to='/bot-membership'>
																			Extend
																		</Link>
																	</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='col-xl-8 mt-3'>
											<div className='Dash-Head dshead-bg  '>
												<div className='row align-items-center'>
													<div className='col-lg-12'>
														<div className='row align-items-center mt-2'>
															<div
																className='col-lg-12 px-2'
																style={{ textAlign: 'center ' }}
															>
																{Profile.Is_mail_verified == 1 ? (
																	<>
																		<h5 className='para-fs14'>
																			Level Commission
																		</h5>
																		<Stepper
																			activeStep={currentStep}
																			alternativeLabel
																			className='step-progress-bar nd_steps mt-3'
																		>
																			{steps.map(step => (
																				<Step
																					key={step.key}
																					completed={step.status}
																				>
																					<StepLabel
																						style={{
																							textTransform: 'capitalize',
																							color: 'white !important'
																						}}
																					>
																						{step.key}
																					</StepLabel>
																				</Step>
																			))}
																		</Stepper>
																	</>
																) : (
																	<Activate />
																)}
															</div>
														</div>
														<div className='row align-items-center'>
															<div className='col-lg-12 text-align-center'>
																<div className='d-flex justify-content-lg-center justify-content-center'>
																	{/* <button
                                  className="btn btn-primary ml-2"
                                  onClick={() => navigate("/user/referral")}
                                >
                                  Referal Tree
                                </button> */}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className='dashprofile-box dshead-bg mt-3'>
												<div className='dashprofile-InrMainCnt'>
													<div className='row align-items-center'>
														<div className='col-lg-12 my-4 my-lg-0 '>
															<div className='dashprofile-InrCnt'>
																<div className='curnext-rank anth_wd d-flex align-items-center justify-content-between'>
																	<div>
																		<h6>Main Balance</h6>
																		<p className='para-fs14'>
																			{toFixedLength(
																				botUserWalletData.main_wallet,
																				2
																			)}
																			<span> KPK</span>
																		</p>
																	</div>
																	<div className=''>
																		<h6 className=''>Hold Balance</h6>
																		<p className='para-fs14'>
																			{toFixedLength(
																				botUserWalletData.hold_wallet,
																				2
																			)}
																			<span> KPK</span>
																			{+botUserWalletData.hold_wallet == 0 && (
																				<span className='wrg-hold'>
																					<FontAwesomeIcon
																						cursor={'pointer'}
																						data-tooltip-variant='warning'
																						onMouseEnter={() => setIsOpen(true)}
																						onMouseLeave={() =>
																							setIsOpen(false)
																						}
																						data-tooltip-id='wrg-hold'
																						icon={faExclamationTriangle}
																					/>
																					<ReactToolTip
																						id='wrg-hold'
																						isOpen={isOpen}
																						opacity={1}
																					>
																						<div
																							style={{
																								display: 'flex',
																								flexDirection: 'row',
																								color: 'black'
																							}}
																						>
																							<span>
																								Please Transfer amount to hold
																								Balance!
																							</span>
																						</div>
																					</ReactToolTip>
																				</span>
																			)}
																		</p>
																		{/* {+botUserWalletData.hold_wallet == 0 && (
																			<p className='dep-warn mb-0'>
																				<span className='wrg-hold'>
																					<FontAwesomeIcon
																						icon={faExclamationTriangle}
																					/>
																				</span>
																				&nbsp;Please Transfer amount to hold
																				Balance!
																			</p>
																		)} */}
																	</div>
																	<div className='link-arr d-sm-flex d-none align-items-center'>
																		<img
																			src={linkarr1}
																			className='img-fluid'
																			alt=''
																		/>
																		<img
																			src={linkarr2}
																			className='img-fluid'
																			alt=''
																		/>
																		<img
																			src={linkarr3}
																			className='img-fluid'
																			alt=''
																		/>
																		<img
																			src={linkarr4}
																			className='img-fluid'
																			alt=''
																		/>
																	</div>
																	<div>
																		{/* <p className='para-fs14'>Transfer</p> */}
																		{/* <h6 className='para-fs14'>Transfer</h6>
																		 */}
																		<Transfer
																			getBotUserWallet={getBotUserWallet}
																			botUserWalletData={botUserWalletData}
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<section className='dashpro-sec'>
						<div className='container dash-container'>
							<div className='row'>
								<div className='col-12'></div>
							</div>
						</div>
					</section>

					<section className='newbonus-sec bot-db'>
						<div className='container dash-container'>
							<div className='dashboard-maincnt transdet-sec'>
								<div className='newdashboard-grid'>
									<div className='dashboard-sidetabul'>
										<div className='mypills'>
											<Nav pills>
												<NavItem>
													<NavLink
														className={classnames({
															active: activeTab === '1'
														})}
														onClick={() => {
															toggle('1')
														}}
													>
														Portfolio
													</NavLink>
												</NavItem>
												{/* {exchangeAssetData.length > 0 && ( */}
												<NavItem>
													<NavLink
														className={classnames({
															active: activeTab === '2'
														})}
														onClick={() => {
															toggle('2')
														}}
													>
														Exchanges
													</NavLink>
												</NavItem>
												{/* )} */}
												{/* <NavItem>
													<NavLink
														className={classnames({
															active: activeTab === '3'
														})}
														onClick={() => {
															toggle('3')
														}}
													>
														Assets
													</NavLink>
												</NavItem> */}
												<NavItem>
													<NavLink
														className={classnames({
															active: activeTab === '4'
														})}
														onClick={() => {
															toggle('4')
														}}
													>
														Bot History
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: activeTab === '5'
														})}
														onClick={() => {
															toggle('5')
														}}
													>
														Create Bot
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: activeTab === '6'
														})}
														onClick={() => {
															toggle('6')
														}}
													>
														Level Bonus
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: activeTab === '7'
														})}
														onClick={() => {
															toggle('7')
														}}
													>
														Growth Level Bonus
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: activeTab === '8'
														})}
														onClick={() => {
															toggle('8')
														}}
													>
														Subscription
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active: activeTab === '9'
														})}
														onClick={() => {
															toggle('9')
														}}
													>
														Subscription History
													</NavLink>
												</NavItem>
											</Nav>
											<TabContent activeTab={activeTab}>
												{(() => {
													if (activeTab === '1') {
														return (
															<BotsInfo
																botPieChartLoading={botPieChartLoading}
																botPieChartData={botPieChartData}
																botTotalValue={botTotalValue}
															/>
														)
													} else if (activeTab === '2') {
														return (
															<BotAssetCard
																botPieChartLoading={botPieChartLoading}
																getBotPieChart={getBotPieChart}
															/>
														)
													} else if (activeTab === '3') {
														return (
															<BotAssets
																botPieChartLoading={botPieChartLoading}
																data={exchangeAssetData}
															/>
														)
													} else if (activeTab === '4') {
														return <BotHistory />
													} else if (activeTab === '5') {
														return <BotRequestHistory />
													} else if (activeTab === '6') {
														return <LevelBonusBot />
													} else if (activeTab === '7') {
														return <GrowthLevelBonusBot />
													} else if (activeTab === '8') {
														return <Pricing />
													} else if (activeTab === '9') {
														return <SubscriptionHistory />
													}
												})()}
											</TabContent>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</ScreenLoader>
			</div>
		</>
	)
}

export default BotDashboard
