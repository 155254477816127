import React, { useEffect, useState } from 'react'
import searchicon from '../../assets/images/search-icon.svg'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import Helper from '../../Helper/Helper'
import { dataTableConfigs } from '../../configs/dataTableConfigs'
import { TableLoader } from '../../component/Loaders'
import NoDataComponent from '../../component/Reusable/no-data-component'
import DatePickerReuse from '../../component/Reusable/DatePickerReuse'
import SearchReuse from '../../component/Reusable/searchReuse'
import { toFixedUtil } from '../../component/utils'
import { useSelector } from 'react-redux'
import CopyData from '../../component/Reusable/CopyData'
const StakeHistory = () => {
	const initialPageData = { pageNo: 0, pageSize: 10 }

	const [stakingHistory, setStakeHistory] = useState({ data: [], count: 0 })
	const callApi = useSelector(state => state.header.callProfileApi)

	const [Loading, setLoading] = useState(false)
	const [filters, setFilter] = useState({
		currency_symbol: 'all',
		search_by: '',
		status: 'all',
		start_date: '',
		end_date: '',
		...initialPageData,
		search: '',
		sort: 'Oldest'
	})

	useEffect(() => {
		getStateHistory()
	}, [filters, callApi])

	const getStateHistory = () => {
		setLoading(true)
		Helper.PostData('user', 'stake/getuserstakehistory', filters, false)
			.then(res => {
				if (res.status) {
					setStakeHistory({ data: res.data, count: res.totalCount })
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const columns = [
		{
			name: 'S.No',
			sortable: true,
			selector: (row, index) => row.index,
			cell: (row, index) => <td>{index + 1}</td>
		},
		{
			name: 'Tnx.Id',
			sortable: true,
			selector: (row, index) => row.transaction_id,
			cell: (row, index) => {
				return (
					<CopyData
						data={row.transaction_id}
						index={index}
						formatValue={true}
					/>
				)
			}
		},
		{
			name: 'Staking KPK',
			selector: row => row.amount,
			sortable: true
		},
		{
			name: 'Percentage',
			selector: row => row.percentage,
			sortable: true
		},
		{
			name: 'Total Months',
			sortable: true,
			selector: row => row.duration
		},
		{
			name: 'Remains Days',
			sortable: true,
			selector: row => row.RemainsDays
		},
		{
			name: 'Reward KPK',
			sortable: true,
			selector: row => toFixedUtil(row.rewardamount, 2)
		},
		{
			name: 'Date & Time',
			sortable: true,
			selector: (row, index) => row.created_at,

			cell: (row, index) => (
				<td>{moment(row.created_at).format('YYYY-MM-DD hh:mm:ss A')}</td>
			)
		}
		// {
		// 	name: 'Status',
		// 	sortable: true,
		// 	selector: (row) => row.status
		// }
	]
	return (
		<div>
			<div className='trans-body'>
				<div className='trans-TabCnt'>
					<div className='stakingbonus-tabcnt'>
						<div className='bonus-Head'>
							<h5>Stake History</h5>
						</div>
						<div className='bonus-Body'>
							<div className='tbl-showentriessearch'>
								<div className='showentries-RightCnt'>
									<div className='inr-searchcnt'>
										<div className="row">
										<DatePickerReuse
											setFilters={setFilter}
											className='col-lg-4'
										/>
										<SearchReuse
										className='col-lg-4'
											setFilters={setFilter}
											initialPageData={initialPageData}
											placeholder={'Tnx.Id/Duration/Staking amount'}
										/>

										</div>
										
										
										
									</div>
								</div>
							</div>
							<div className='cmntbl'>
								<div className='table-responsive'>
									<DataTable
										columns={columns}
										className='text-center'
										data={stakingHistory.data}
										paginationTotalRows={stakingHistory.count}
										customStyles={dataTableConfigs}
										pagination
										paginationServer
										onChangePage={pageNo => {
											setFilter(prev => ({ ...prev, pageNo: pageNo - 1 }))
										}}
										progressPending={Loading}
										progressComponent={<TableLoader />}
										noDataComponent={<NoDataComponent />}
										onChangeRowsPerPage={pageSize => {
											setFilter(prev => ({ ...prev, pageSize }))
										}}
										persistTableHead={true}
										// paginationPerPage={10}
										paginationRowsPerPageOptions={[5, 10, 20, 30]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default StakeHistory
