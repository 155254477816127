import React, { Suspense, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import metadata from "../../metaData/metadata";
import "./home.scss";
const SecondSection = React.lazy(() => import("./SecondSection"));
const ThirdSection = React.lazy(() => import("./ThirdSection"));
const FourthSection = React.lazy(() => import("./FourthSection"));
const FifthSection = React.lazy(() => import("./FifthSection"));

const HomePageOld = () => {
  useEffect(() => {
    const img = new Image();
    img.src = "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmWrTQBtTfABDmKcQyZmbAME3F55pvtzpgXqkqGpsMM6cJ"; // Replace with the actual path to your image
  }, []);

  const location = useLocation();
  const navigate = useNavigate();
  const [Index, setIndex] = useState("");
  const [isMobile, setMobile] = useState(window.innerWidth <= 600);
  const sliderRef = useRef();
  const [ToggleButtons, setToggleButtons] = useState([
    "Staking Bonus",
    "Level Bonus",
    "Growth Level Bonus",
  ]);
  useEffect(() => {
    const zohoScript = document.createElement("script");
    zohoScript.type = "text/javascript";
    zohoScript.defer = true;
    zohoScript.id = "zsiqscript";
    zohoScript.src = "https://salesiq.zohopublic.com/widget";
    document.body.appendChild(zohoScript);
  }, [location.pathname]);

  const settings = {
    dots: false,
    infinite: true,
    arrow: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings1 = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleOnClick = (index) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index);
    }
    setIndex(index);
  };

  const HandleStart = () => {
    localStorage.getItem("tip")
      ? navigate("/user/dashboard")
      : navigate("/oauth/login");
  };

  return (
    <>
      <Helmet>
        <title>{metadata.home.title}</title>
        <meta name="description" content={metadata.home.description} />
        <meta name="keywords" content={metadata.home.keywords} />
        <link rel="preload" href="https://inytesconnedwipdvfs.koinpark.com/ipfs/QmWrTQBtTfABDmKcQyZmbAME3F55pvtzpgXqkqGpsMM6cJ" as="image" />
      </Helmet>
      <div className={`maincontent ${isMobile ? "" : "sec1cir"}`}>
        <div className="p-0" id="homesec1">
          <div className="py-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10">
                  <div className="text-center">
                    <div className="banTit">
                      A{" "}
                      <span className="text-primary">Centralised Finance</span>
                      &nbsp;Platform Staking Park Coin Crypto
                    </div>
                    <div className="mt-n4">
                      <img
                        src={"https://inytesconnedwipdvfs.koinpark.com/ipfs/QmWrTQBtTfABDmKcQyZmbAME3F55pvtzpgXqkqGpsMM6cJ"}
                        className="img-media-ban"
                        width="489"
                        height="421"
                        alt="BannerCenterImg"
                      />
                    </div>
                    <div>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={HandleStart}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<div className="loading-spinner"></div>}>
          <SecondSection settings={settings} HandleStart={HandleStart} />
          <ThirdSection />
          <FourthSection
            Index={Index}
            sliderRef={sliderRef}
            ToggleButtons={ToggleButtons}
            settings1={settings1}
            handleOnClick={handleOnClick}
            HandleStart={HandleStart}
          />
          <FifthSection HandleStart={HandleStart} />
        </Suspense>
      </div>
    </>
  );
};

export default HomePageOld;
