import CryptoJS from "crypto-js";
import * as jose from "jose";

function generateRandomString(length) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}
export function generateEncryptJwt() {
  const text = generateRandomString(12);
  const cipherText = CryptoJS.AES.encrypt(
    text,
    CryptoJS.enc.Utf8.parse("c349554b3bf8dec67fa1d3a6148baa14"),
    {
      iv: CryptoJS.enc.Utf8.parse("b7fc61413a018571"),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return cipherText.toString();
}
export const generateJwtToken = async (baseApiUrl, encryptToken) => {
  const key = CryptoJS.enc.Utf8.parse("c349554b3bf8dec67fa1d3a6148baa14");
  const iv = CryptoJS.enc.Utf8.parse("b7fc61413a018571");
  const decrypted = CryptoJS.AES.decrypt(encryptToken, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const generatedRandomString = decrypted.toString(CryptoJS.enc.Utf8);
  const secretKey = baseApiUrl + generatedRandomString;
  const encoder = new TextEncoder("utf-8");
  const key_new = encoder.encode(secretKey);
  const jwt = await new jose.SignJWT({})
    .setProtectedHeader({ alg: "HS256" })
    .setIssuedAt()
    .setExpirationTime("10s")
    .sign(key_new);
  return jwt;
};
