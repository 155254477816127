import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../component/footer";
import NewHeader from "../component/header/NewHeader";

const DashAppLayout = () => {
  let path = window.location.pathname;
  useEffect(()=>{
  },[path])
  let matchRoute = path.split("/");
  return (
    <>
      <NewHeader />
      {path ===
      `/user/contest/${matchRoute && matchRoute[3] ||path ===
        `/user/contest` }` ? (
        <div class="maincontent">
          <div class="">
            <Outlet />
          </div>
        </div>
      ) : (
        <div class="maincontent">
          <div class="pageContent pt-5">
            <Outlet />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default DashAppLayout;
