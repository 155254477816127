import React, { useEffect, useMemo, useState } from 'react'
import { Form, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import OtpInput from 'react-otp-input'
import Countdown from 'react-countdown'
import './otpModal.scss'
import { TextLoader } from '../Loaders'
import { useForm } from 'react-hook-form'
import Helper from '../../Helper/Helper'
import { isObjectHaveKey } from '../utils'

const OTPmodal = ({
	modal,
	toggleModal,
	otp,
	setOtp,
	handleApiCall,
	loader,
	keys,
	resendOTP,
	sentTo,
	data = {}
}) => {
	const { handleSubmit } = useForm({
		mode: 'all',
		reValidateMode: 'onChange'
	})

	const dataAvailable = isObjectHaveKey(data)

	const Completionist = () => {
		return (
			<div className='text-center mt-2'>
				<p className='receive-msg'>
					Didn't receive code ?{' '}
					<span
						type='submit'
						onClick={() => resendOTP(false)}
						className='timer'
					>
						Resend
					</span>
				</p>
			</div>
		)
	}

	const renderer = ({ minutes, seconds, completed }) => {
		if (completed) {
			return <Completionist />
		} else {
			return (
				<span style={{ fontSize: '13px', color: 'gray' }}>
					Receive OTP in :{' '}
					<p
						style={{
							color: 'blue',
							display: 'contents',
							fontSize: '12px',
							fontWeight: '400'
						}}
					>
						<span className='text-light'>
							{minutes}:{seconds}
						</span>
					</p>
				</span>
			)
		}
	}

	const timer = useMemo(
		() => (
			<Countdown date={Date.now() + 60000} renderer={renderer} key={keys} />
		),
		[keys]
	)

	return (
		<Modal
			className='ver_pop'
			size='sm'
			isOpen={modal}
			toggleModal={toggleModal}
		>
			<ModalHeader toggle={toggleModal}>
				<h5 className='text-center text-light'>OTP Verification</h5>
			</ModalHeader>
			<ModalBody>
				{dataAvailable && (
					<>
							<p>Kindly Confirm Referral Person Name Before Submit OTP.</p>

					<div className='d-flex justify-content-between'>
						<p className='mt-2 text-light'>Refered by</p>
						<p className='mt-2' style={{ color: '#ffb721' }}>
							<b>{data.referal_name}</b>
						</p>
					</div>
							</>
				)}
				<div className='d-flex justify-content-between'>
					<p className=' text-light'>OTP Sent To</p>
					<p className=' text-light'>
						<b>{sentTo}</b>
					</p>
				</div>

				<div className='otp'>
					<label className='mt-1 text-light'>Enter the 6 digit code</label>
					<Form onSubmit={handleSubmit(handleApiCall)}>
						<div className='mt-2'>
							<OtpInput
								value={otp}
								onChange={otp => {
									setOtp(otp)
								}}
								numInputs={6}
								inputStyle='form-control'
								containerStyle='cdChIpR cdChIpR1'
								className='form-group'
								isInputNum={true}
							/>
						</div>

						<div className='mt-3 text-center'>
							{otp && otp.length == 6 ? (
								<button
									className='btn btn-primary btn-block fs-18 fw-500'
									disabled={loader}
								>
									{loader ? <Spinner size='sm' /> : 'Submit'}
								</button>
							) : (
								<button
									className='btn btn-primary btn-block fs-18 fw-500'
									disabled
								>
									{loader ? <Spinner size='sm' /> : 'Submit'}
								</button>
							)}
						</div>
					
						<div className='text-center  mt-1'>{timer}</div>
					</Form>
				</div>
			</ModalBody>
		</Modal>
	)
}

export default OTPmodal
