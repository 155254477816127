import React from "react";
import { Form, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { register } from "../../metaData/metadata";
import { TextLoader } from "../../component/Loaders";

export function PlotBookingModal(props) {
  console.log("props: ", props);
  console.log(
    props.Balance < props.total || props.BonusBalance < props.total,
    props.BonusBalance,
    props.Balance,
    props.total,
    props.Balance < props.total,
    props.BonusBalance < props.total
  );
  return (
    <Modal
      isOpen={props.modal}
      toggleModal={() => props.setModal(!props.modal)}
      className="modal-md modal-dialog-centered mymodal"
      size="md"
      backdrop="static"
      keyboard={false}
    >
      <ModalHeader
        toggle={() => props.setModal(!props.modal)}
        close={props.closeBtn}
        className="modalTitle"
      >
        Advance Plot Booking
      </ModalHeader>
      <ModalBody className="px-4">
        <div class="row">
          <div class="col-12">
            <Form
              onSubmit={props.handleSubmit(() =>
                props.AdvancePlotBooking("verify")
              )}
            >
              <div class="form-group formInputs walletInput">
                <label class="col-form-label">State</label>
                <input
                  class="form-control"
                  name="lname"
                  disabled
                  placeholder="{{contentShow}}"
                  defaultValue={props.tooltipId}
                />
              </div>
              <div class="form-group formInputs walletInput">
                <label class="col-form-label">Plots</label>
                <select
                  class="form-control"
                  placeholder="Enter Your Name"
                  required
                  onChange={(e) => props.setPlots(e.target.value)}
                >
                  <option value="" disabled selected hidden>
                    Select your Plots
                  </option>
                  <option value={1} selected={props.plots == 1}>
                    1
                  </option>
                  <option value={2} selected={props.plots == 2}>
                    2
                  </option>
                  <option value={3} selected={props.plots == 3}>
                    3
                  </option>
                  <option value={4} selected={props.plots == 4}>
                    4
                  </option>
                  <option value={5} selected={props.plots == 5}>
                    5
                  </option>
                  <option value={6} selected={props.plots == 6}>
                    6
                  </option>
                  <option value={7} selected={props.plots == 7}>
                    7
                  </option>
                  <option value={8} selected={props.plots == 8}>
                    8
                  </option>
                  <option value={9} selected={props.plots == 9}>
                    9
                  </option>
                  <option value={10} selected={props.plots == 10}>
                    10
                  </option>
                </select>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div class="form-group formInputs walletInput">
                    <label class="col-form-label">
                      Per Plot Advance Booking
                    </label>
                    <input
                      class="form-control"
                      input
                      type="text"
                      maxlength="16"
                      onkeypress="return (event.charCode == 8) ? null : (event.charCode >= 48 && event.charCode <= 57)"
                      inputmode="numeric"
                      placeholder="Enter Your Amount"
                      required
                      value={`${props.perPlotPrice?.singleplot_price} KPK `}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div class="form-group formInputs walletInput">
                    <label _ngcontent-dui-c91="" class="col-form-label">
                      Quantity
                      <span _ngcontent-dui-c91="" class="text-primary">
                        *
                      </span>
                    </label>
                    <div _ngcontent-dui-c91="" class="input-group">
                      <div _ngcontent-dui-c91="" class="input-group-prepend">
                        <button
                          _ngcontent-dui-c91=""
                          type="button"
                          class="bg-transparent border-0 py-0 px-2"
                          onClick={() => props.HandleIncreament("decreament")}
                        >
                          <svg
                            _ngcontent-dui-c91=""
                            xmlns="http://www.w3.org/2000/svg"
                            width="29"
                            height="29"
                            viewBox="0 0 29 29"
                            fill="none"
                          >
                            <path
                              _ngcontent-dui-c91=""
                              d="M19.3337 27.4897H3.62533C2.46533 27.4897 1.51074 26.5351 1.51074 25.3751V9.66675C1.51074 4.32591 4.32616 1.5105 9.66699 1.5105H19.3337C24.6745 1.5105 27.4899 4.32591 27.4899 9.66675V19.3334C27.4899 24.6742 24.6745 27.4897 19.3337 27.4897ZM9.66699 3.323C5.34116 3.323 3.32324 5.34091 3.32324 9.66675V25.3751C3.32324 25.5442 3.45616 25.6772 3.62533 25.6772H19.3337C23.6595 25.6772 25.6774 23.6592 25.6774 19.3334V9.66675C25.6774 5.34091 23.6595 3.323 19.3337 3.323H9.66699Z"
                              fill="#6C6C79"
                            ></path>
                            <path
                              _ngcontent-dui-c91=""
                              d="M18.7288 15.4062H10.2705C9.77509 15.4062 9.36426 14.9954 9.36426 14.5C9.36426 14.0046 9.77509 13.5938 10.2705 13.5938H18.7288C19.2243 13.5938 19.6351 14.0046 19.6351 14.5C19.6351 14.9954 19.2243 15.4062 18.7288 15.4062Z"
                              fill="#6C6C79"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <input
                        _ngcontent-dui-c91=""
                        type="text"
                        placeholder="0"
                        readonly=""
                        class="form-control ng-untouched ng-pristine ng-valid"
                        defaultValue={props.plots}
                        onChange={(e) => {
                          props.handleLimitChange(e);
                          props.setPlots(e.target.value);
                        }}
                      />
                      <div _ngcontent-dui-c91="" class="input-group-append">
                        <button
                          _ngcontent-dui-c91=""
                          type="button"
                          class="bg-transparent border-0 py-0 px-2"
                          onClick={() => props.HandleIncreament("increament")}
                        >
                          <svg
                            _ngcontent-dui-c91=""
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="29"
                            viewBox="0 0 30 29"
                            fill="none"
                          >
                            <path
                              _ngcontent-dui-c91=""
                              d="M19.8337 27.4897H4.12533C2.96533 27.4897 2.01074 26.5351 2.01074 25.3751V9.66675C2.01074 4.32591 4.82616 1.5105 10.167 1.5105H19.8337C25.1745 1.5105 27.9899 4.32591 27.9899 9.66675V19.3334C27.9899 24.6742 25.1745 27.4897 19.8337 27.4897ZM10.167 3.323C5.84116 3.323 3.82324 5.34091 3.82324 9.66675V25.3751C3.82324 25.5442 3.95616 25.6772 4.12533 25.6772H19.8337C24.1595 25.6772 26.1774 23.6592 26.1774 19.3334V9.66675C26.1774 5.34091 24.1595 3.323 19.8337 3.323H10.167Z"
                              fill="#6C6C79"
                            ></path>
                            <path
                              _ngcontent-dui-c91=""
                              d="M19.2288 15.4062H10.7705C10.2751 15.4062 9.86426 14.9954 9.86426 14.5C9.86426 14.0046 10.2751 13.5938 10.7705 13.5938H19.2288C19.7243 13.5938 20.1351 14.0046 20.1351 14.5C20.1351 14.9954 19.7243 15.4062 19.2288 15.4062Z"
                              fill="#6C6C79"
                            ></path>
                            <path
                              _ngcontent-dui-c91=""
                              d="M15 19.6354C14.5046 19.6354 14.0938 19.2246 14.0938 18.7292V10.2708C14.0938 9.77542 14.5046 9.36459 15 9.36459C15.4954 9.36459 15.9062 9.77542 15.9062 10.2708V18.7292C15.9062 19.2246 15.4954 19.6354 15 19.6354Z"
                              fill="#6C6C79"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div class="form-group formInputs walletInput">
                    <label class="col-form-label">Choose Wallet</label>
                    <select
                      className="form-control"
                      onChange={(e) => props.setWallet(e.target.value)}
                    >
                      <option value="mainwallet">Main Wallet</option>
                      <option value="rewardwallet">Reward Wallet</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="form-group formInputs walletInput">
                    <label class="col-form-label">
                      {props.wallet == "rewardwallet" ?"Available Reward Balance" : "Available Wallet Balance"}{" "}
                    </label>
                    {"    "}
                    {props.wallet == "rewardwallet" ? (
                      props.BalanceLoading ? (
                        <div className="form-control d-flex">
                          <TextLoader />
                          <p className="ml-3">KPK</p>
                        </div>
                      ) : (
                        <input
                          class="form-control"
                          input
                          type="text"
                          name="AvailBal"
                          maxlength="6"
                          value={props.BonusBalance + "KPK"}
                          readonly
                          disabled
                        />
                      )
                    ) : props.BalanceLoading ? (
                      <div className="form-control d-flex">
                        <TextLoader />
                        <p className="ml-3">KPK</p>
                      </div>
                    ) : (
                      <input
                        class="form-control"
                        input
                        type="text"
                        name="AvailBal"
                        maxlength="6"
                        value={props.Balance + "KPK"}
                        readonly
                        disabled
                      />
                    )}

                    {props.wallet == "rewardwallet"
                      ? props.BonusBalance < props.total && (
                          <p className="text-danger">* Insufficient Balance</p>
                        )
                      : props.Balance < props.total && (
                          <p className="text-danger">* Insufficient Balance</p>
                        )}
                  </div>
                </div>
                {props.plots && (
                  <div className="col-md-4">
                    <div class="form-group formInputs walletInput">
                      <label>Total</label>
                      <h3 className="text-info">
                        {+props.plots * +props.perPlotPrice?.singleplot_price}{" "}
                        KPK
                      </h3>
                    </div>
                  </div>
                )}
              </div>
              <div class="text-center mb-2">
                {(() => {
                  if (props.loader) {
                    return (
                      <button
                        class="btn btn-primary px-4"
                        type="button"
                        disabled={props.loader}
                      >
                        <Spinner size="sm" />
                      </button>
                    );
                  } else if (props.plots == 0) {
                    return (
                      <button
                        class="btn btn-primary px-4"
                        diabled
                        type="button"
                        readonly
                        style={{
                          cursor: "not-allowed",
                        }}
                      >
                        Proceed For Booking
                      </button>
                    );
                  } else if (props.wallet == "mainwallet") {
                    if (props.Balance < props.total) {
                      return (
                        <button
                          class="btn btn-primary px-4"
                          diabled
                          type="button"
                          readonly
                          style={{
                            cursor: "not-allowed",
                          }}
                        >
                          Proceed For Booking
                        </button>
                      );
                    } else {
                      return (
                        <button class="btn btn-primary px-4" type="submit">
                          Proceed For Booking
                        </button>
                      );
                    }
                  } else if (props.wallet == "rewardwallet") {
                    if (props.BonusBalance < props.total) {
                      return (
                        <button
                          class="btn btn-primary px-4"
                          diabled
                          type="button"
                          readonly
                          style={{
                            cursor: "not-allowed",
                          }}
                        >
                          Proceed For Booking
                        </button>
                      );
                    } else {
                      return (
                        <button class="btn btn-primary px-4" type="submit">
                          Proceed For Booking
                        </button>
                      );
                    }
                  }
                  // else if (props.Balance < props.total || props.BonusBalance < props.total) {
                  //   return (
                  //     <button
                  //       class="btn btn-primary px-4"
                  //       diabled
                  //       type="button"
                  //       readonly
                  //       style={{
                  //         cursor: "not-allowed",
                  //       }}
                  //     >
                  //       Proceed For Booking
                  //     </button>
                  //   );
                  // }
                  else {
                    return (
                      <button class="btn btn-primary px-4" type="submit">
                        Proceed For Booking
                      </button>
                    );
                  }
                })()}
              </div>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
