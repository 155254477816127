import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const UnAuth = () => {
	let access = localStorage.getItem('logaccess')
	if (access) {
		return <Navigate to='/user/dashboard' />
	} else {
		return <Outlet />
	}
}
export default UnAuth
