import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Helper from "../../../Helper/Helper";
import { SpinnerLoader, TableLoader } from "../../../component/Loaders";
import NoDataComponent from "../../../component/Reusable/no-data-component";
import { dataTableConfigs } from "../../../configs/dataTableConfigs";
// import "../Incubator/incubator.scss";
import { faEye, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import editp from "../../../assets/images/edit.svg";
import updatep from "../../../assets/images/refresh-2.png";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import CloseBtn from "../../../component/Reusable/CloseBtn";
import { Switch } from "@mui/material";
import DatePickerReuseCrm from "../../../component/Reusable/DatePickerReuse_crm";
import { NumericFormat } from "react-number-format";

const BopMeetingHistory = () => {
  const initialPageData = { pageNo: 0, pageSize: 10 };
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
  });
  const {
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
    control: control1,
    reset: reset1,
    register: register1,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      title: "",
      description: "",
      address: "",
      location: "",
      start_date: "",
      end_date: "",
      viewStatus: "",
    },
  });
  const {
    handleSubmit: handleSubmitUp,
    register: registerUp,
    formState: { errors: errorsUp },
    control: controlUp,
    reset: resetUp,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {
      remarks: "",
      attendance_users: "",
      bop_meeting_status: "",
    },
  });
  const [incubatorData, setIncubatorHistory] = useState({
    data: [],
    count: 0,
  });
  const fromMinDate = () => {
    let date = new Date();
    return date;
  };

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [IsRemark, setIsRemark] = useState(false);
  const [Load, setLoad] = useState(false);
  const [rowInfo, setRowInfo] = useState("");
  console.log("rowInfo: ", rowInfo);
  const [RadioType, setRadioType] = useState([
    { name: "Only Me", value: "1" },
    { name: "Only my team members", value: "2" },
    { name: "To All Members", value: "3" },
  ]);
  const [ViewRadioStatus, setViewRadioStatus] = useState("");
  const [remarkSelected, setRemarkSelected] = useState(null);
  const toggleRemark = (row) => {
    setRemarkSelected(row.id);
    setIsRemark(!IsRemark);
    resetUp();
  };
  const toggleRemarkClose = (row) => {
    setIsRemark(false);
    resetUp();
  };
  const [Loading, setLoading] = useState(false);
  const [filters, setFilter] = useState({
    search_by: "",
    ...initialPageData,
    type: "room",
  });
  console.log("filters: ", filters);
  const [isMeet, setIsMeet] = useState(false);
  const toggleMeet = (row) => {
    setRowInfo(row);
    setIsMeet(!isMeet);
    reset();
  };
  const toggleMeetclose = () => {
    setMeetLoad(false);
    setIsMeet(false);
    reset();
  };
  const [meetLoad, setMeetLoad] = useState(false);
  const HandleMeetSchedule = (data) => {
    setMeetLoad(true);
    Helper.PostData("meet", "bopMeeting/editBopMeeting", {
      method: "Add",
      type: rowInfo,
      title: data.title,
      description: data.description,
      viewStatus: data.viewStatus,
      address: data.address,
      location: data.location,
      start_date: moment(data.start_date).format("YYYY-MM-DD HH:mm:ss"),
      end_date: moment(data.end_date).format("YYYY-MM-DD HH:mm:ss"),
    })
      .then((res) => {
        if (res.status) {
          setIsMeet(!isMeet);
          setMeetLoad(false);
          getStateHistory();
        }
      })
      .finally(() => {
        setMeetLoad(false);
      });
  };
  const handleRadioChange = (value) => {
    console.log("e: ", value);
    setViewRadioStatus(value);
  };
  const HandleMeetUpdateSchedule = (data) => {
    setMeetLoad(true);
    Helper.PostData("meet", "bopMeeting/editBopMeeting", {
      method: "Edit",
      type: meetUpInfo?.type,
      title: data.title ? data.title : meetUpInfo?.title,
      description: data.description
        ? data.description
        : meetUpInfo?.description,
      viewStatus: ViewRadioStatus,
      address: data.address ? data.address : meetUpInfo?.address,
      location: data.location ? data.location : meetUpInfo?.location,
      start_date: data.start_date
        ? moment(data.start_date).format("YYYY-MM-DD HH:mm:ss")
        : meetUpInfo?.start_date,
      end_date: data.end_date
        ? moment(data.end_date).format("YYYY-MM-DD HH:mm:ss")
        : meetUpInfo?.end_date,
      bopmeeting_id: meetUpInfo?.id,
    })
      .then((res) => {
        if (res.status) {
          setMeetUpdate(false);
          setMeetLoad(false);
          getStateHistory();
        }
      })
      .finally(() => {
        setMeetLoad(false);
      });
  };
  useEffect(() => {
    getStateHistory();
  }, [filters]);

  const getStateHistory = () => {
    setLoading(true);
    Helper.PostData("meet", "bopMeeting/bopMeetingList", filters, false)
      .then((res) => {
        if (res.status) {
          setIncubatorHistory({
            data: res.data.results,
            count: res.data.count,
          });
        } else {
          setIncubatorHistory({
            data: [],
            count: 0,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, index) => row.index,
      cell: (row, index) => <td>{index + 1}</td>,
    },

    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    // {
    //   name: "Meeting Type",
    //   sortable: true,
    //   selector: (row) => row.type,
    // },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.bop_meeting_status,
      cell: (row) => {
        if (row.bop_meeting_status == 0) {
          return <div className=" badge text-warn">Scheduled</div>;
        } else if (row.bop_meeting_status == 1) {
          return <div className="badge bg-success">Completed</div>;
        } else {
          return <div className="badge bg-danger">Rejected</div>;
        }
      },
    },
    {
      name: "Meeting Time",
      sortable: true,
      selector: (row, index) => row.start_date,

      cell: (row, index) => (
        <td>{moment(row.start_date).format("YYYY-MM-DD hh:mm:ss A")}</td>
      ),
    },
    {
      name: "Action",
      sortable: true,
      selector: (row) => row.action,
      cell: (row, index) => (
        <td className="cursor-pointer">
          {(() => {
            if (row.editStatus == 1 && row.bop_meeting_status == 0) {
              // creater
              return (
                <>
                  <img
                    src={editp}
                    className="img-fluid cursor-pointer"
                    onClick={() => toggleMeetUpdate(row)}
                  />
                  {row.status == 3 && (
                    <img
                      src={updatep}
                      className="img-fluid cursor-pointer"
                      onClick={() => toggleRemark(row)}
                    />
                  )}
                </>
              );
            } else {
              return (
                <>
                  <FontAwesomeIcon
                    icon={faEye}
                    size="sm"
                    className="cursor-pointer"
                    onClick={() => toggleModalRemark(row)}
                  />
                </>
              );
            }
          })()}
        </td>
      ),
    },
  ];
  const AddRemark = (data) => {
    setLoad(true);
    Helper.PostData(
      "meet",
      "bopMeeting/updateBopMeetingStatus",
      {
        ...data,
        bopmeeting_id: remarkSelected,
      },
      true
    )
      .then((res) => {
        if (res.status) {
          setLoad(false);
          setIsRemark(false);
          getStateHistory();
        }
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const [meetUpdate, setMeetUpdate] = useState(false);
  const [meetUpInfo, setMeetUpInfo] = useState({});
  const toggleMeetUpdate = (row) => {
    setMeetUpInfo(row);
    setViewRadioStatus(row?.view_status);
    setMeetUpdate(!meetUpdate);
  };
  const toggleMeetUpdateClose = () => {
    setMeetUpdate(false);
    reset1();
  };
  useEffect(() => {
    if (meetUpInfo) {
      reset1({
        title: meetUpInfo.title || "",
        description: meetUpInfo.description || "",
        address: meetUpInfo.address || "",
        location: meetUpInfo.location || "",
        start_date: meetUpInfo.start_date || "",
        end_date: meetUpInfo.end_date || "",
        viewStatus: meetUpInfo.viewStatus || "",
      });
    }
  }, [meetUpInfo, reset1]);

  const [infoModalRemark, setInfoModalRemark] = useState(false);
  const [infoRemark, setInfoRemark] = useState({});
  const toggleModalRemark = (row) => {
    console.log("row: ", row);
    setInfoModalRemark(!infoModalRemark);
    setInfoRemark(row);
  };
  const toggleModalRemarkClose = () => {
    setInfoModalRemark(false);
  };
  return (
    <div>
      <div className="trans-body">
        <div className="trans-TabCnt">
          <div className="stakingbonus-tabcnt">
            <div className="bonus-Head">
              <h5>BOP Meeting</h5>
            </div>
            <div className="bonus-Body">
              <div className="dashboard-navs">
                <div className="row px-2">
                  <div className="col-lg-6">
                    <Nav tabs className="cursor-pointer">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggle("1");
                            setFilter((prev) => ({
                              ...prev,
                              type: "room",
                            }));
                          }}
                        >
                          Room Consultancy
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggle("2");
                            setFilter((prev) => ({
                              ...prev,
                              type: "company",
                            }));
                          }}
                        >
                          Company Meeting
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={"1"}>
                  <div className="cmntbl mt-4">
                    <div className="table-responsive">
                      <div className="row mb-4">
                        <DatePickerReuseCrm
                          setFilters={setFilter}
                          className="col-lg-4"
                        />
                        <div className="col-lg-4 text-right">
                          <button
                            className="btn btn-primary"
                            onClick={() => toggleMeet("room")}
                          >
                            Schedule Meeting
                          </button>
                        </div>
                      </div>
                      <DataTable
                        columns={columns}
                        className="text-center"
                        data={incubatorData.data}
                        paginationTotalRows={incubatorData.count}
                        customStyles={dataTableConfigs}
                        pagination={true}
                        paginationServer
                        onChangePage={(pageNo) => {
                          setFilter((prev) => ({
                            ...prev,
                            pageNo: pageNo - 1,
                          }));
                        }}
                        progressPending={Loading}
                        progressComponent={<TableLoader />}
                        noDataComponent={<NoDataComponent />}
                        onChangeRowsPerPage={(pageSize) => {
                          setFilter((prev) => ({ ...prev, pageSize }));
                        }}
                        persistTableHead={true}
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tabId={"2"}>
                  <div className="cmntbl mt-4">
                    <div className="table-responsive">
                      <div className="row mb-4">
                        <DatePickerReuseCrm
                          setFilters={setFilter}
                          className="col-lg-4"
                        />
                        <div className="col-lg-4 text-right">
                          <button
                            className="btn btn-primary"
                            onClick={() => toggleMeet("company")}
                          >
                            Schedule Meeting
                          </button>
                        </div>
                      </div>
                      <DataTable
                        columns={columns}
                        className="text-center"
                        data={incubatorData.data}
                        paginationTotalRows={incubatorData.count}
                        customStyles={dataTableConfigs}
                        pagination={true}
                        paginationServer
                        onChangePage={(pageNo) => {
                          setFilter((prev) => ({
                            ...prev,
                            pageNo: pageNo - 1,
                          }));
                        }}
                        progressPending={Loading}
                        progressComponent={<TableLoader />}
                        noDataComponent={<NoDataComponent />}
                        onChangeRowsPerPage={(pageSize) => {
                          setFilter((prev) => ({ ...prev, pageSize }));
                        }}
                        persistTableHead={true}
                        paginationRowsPerPageOptions={[5, 10, 20, 30]}
                      />
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={IsRemark}
        toggle={toggleRemark}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleRemarkClose}
          close={<CloseBtn onClick={() => toggleRemarkClose(remarkSelected)} />}
        >
          Meeting Remark
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmitUp(AddRemark)} autoComplete="off">
            <div className="row">
              <div className="col-lg-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">Update Status</label>
                  <select
                    className="form-control form-control-lg"
                    name="bop_meeting_status"
                    placeholder="Select Status"
                    {...registerUp("bop_meeting_status", {
                      required: "* Status is required",
                    })}
                  >
                    <option value="" hidden>
                      Select Status
                    </option>
                    <option value={1}>Completed</option>
                    <option value={2}>Rejected</option>
                  </select>
                  {errorsUp.bop_meeting_status && (
                    <p className="text-danger">
                      {errorsUp.bop_meeting_status.message}
                    </p>
                  )}
                </div>
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Attendance Count<span className="text-primary">*</span>
                  </label>
                  <Controller
                    control={controlUp}
                    name="attendance_users"
                    rules={{
                      required: "* Attendance count is required ",
                    }}
                    render={({ field: { onChange, name, value } }) => (
                      <NumericFormat
                        onChange={onChange}
                        placeholder="0"
                        className="form-control  pr-4"
                        name={name}
                        value={value}
                        allowNegative={false}
                        decimalScale={0}
                        maxLength={7}
                      />
                    )}
                  />
                  {errorsUp.attendance_users && (
                    <p className="text-danger">
                      {errorsUp.attendance_users.message}
                    </p>
                  )}
                </div>
                <div className="form-group formInputs loginInput mb-4">
                  <label>
                    Remarks<span className="text-primary">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    name="remarks"
                    placeholder="Enter Remarks"
                    formControlName="message"
                    {...registerUp("remarks", {
                      required: "* Remarks is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                    autocomplete="off"
                  ></textarea>
                  {errorsUp.remarks && (
                    <p className="text-danger">{errorsUp.remarks.message}</p>
                  )}
                </div>
                <div class="text-center mb-2 mt-3">
                  {/* {LoadingUp ? (
                    <button
                      class="btn btn-primary px-4"
                      type="button"
                      disabled={LoadingUp}
                    >
                      <SpinnerLoader />
                    </button>
                  ) : ( */}
                  <button class="btn btn-primary px-4" type="submit">
                    Confirm
                  </button>
                  {/* )} */}
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={isMeet}
        toggle={toggleMeet}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleMeetclose}
          close={<CloseBtn onClick={() => toggleMeetclose()} />}
        >
          <h5>Schedule Meeting</h5>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(HandleMeetSchedule)} autoComplete="off">
            <div class="row">
              <div className="col-12">
                <div className="text-right">
                  <p className="text-primary">
                    {filters?.type == "room"
                      ? "Room Consultancy "
                      : "Company Meeting"}
                  </p>
                </div>
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Title<span className="text-primary">*</span>
                  </label>
                  <input
                    class="form-control"
                    name="title"
                    placeholder="Enter Title"
                    {...register("title", {
                      required: "* Title is required",
                    })}
                  />
                  {errors.title && (
                    <p className="text-danger">{errors.title.message}</p>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group formInputs loginInput mb-4">
                  <label>
                    Description<span className="text-primary">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    name="description"
                    placeholder="Enter Description"
                    formControlName="message"
                    {...register("description", {
                      required: "* Description is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                    autocomplete="off"
                  ></textarea>
                  {errors.description && (
                    <p className="text-danger">{errors.description.message}</p>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group formInputs loginInput mb-4">
                  <label>
                    Address<span className="text-primary">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    name="address"
                    placeholder="Enter Address"
                    formControlName="message"
                    {...register("address", {
                      required: "* Address is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                    autocomplete="off"
                  ></textarea>
                  {errors.address && (
                    <p className="text-danger">{errors.address.message}</p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Location<span className="text-primary">*</span>
                  </label>
                  <input
                    class="form-control"
                    name="location"
                    placeholder="Enter Location"
                    {...register("location", {
                      required: "* Location is required",
                    })}
                  />
                  {errors.location && (
                    <p className="text-danger">{errors.location.message}</p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div class="wallet-in-ser formInputs walletInput">
                  <label>
                    Start Time<span className="text-primary">*</span>
                  </label>
                  <div class="input-login-1  ml-lg-2">
                    <Controller
                      name="start_date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value } }) => {
                        const selectedStartDate = value
                          ? new Date(value)
                          : null;

                        return (
                          <ReactDatePicker
                            className="form-control"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={selectedStartDate}
                            minDate={fromMinDate()}
                            dropdownMode="select"
                            peekNextMonth
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            placeholderText="DD/MM/YYYY HH:MM:SS"
                          />
                        );
                      }}
                    />
                    {errors.start_date && (
                      <p>
                        {errors.start_date.type === "required" ? (
                          <p className="text-danger">
                            * Start Time is required
                          </p>
                        ) : (
                          <p className="text-danger">
                            {errors.start_date.message}
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div class="wallet-in-ser formInputs walletInput">
                  <label>
                    End Time<span className="text-primary">*</span>
                  </label>
                  <div class="input-login-1  ml-lg-2">
                    <Controller
                      name="end_date"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, onBlur, value } }) => {
                        const selectedStartDate = value
                          ? new Date(value)
                          : null;

                        return (
                          <ReactDatePicker
                            className="form-control"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={selectedStartDate}
                            minDate={fromMinDate()}
                            dropdownMode="select"
                            peekNextMonth
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            placeholderText="DD/MM/YYYY HH:MM:SS"
                          />
                        );
                      }}
                    />
                    {errors.end_date && (
                      <p>
                        {errors.end_date.type === "required" ? (
                          <p className="text-danger">* End Time is required</p>
                        ) : (
                          <p className="text-danger">
                            {errors.end_date.message}
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {RadioType?.map((item, index) => (
                <div className="col-4 mt-3">
                  <div class="form-group input-login-1 ">
                    <div
                      className="d-flex align-items-center flex-wrap"
                      style={{ flexDirection: "column" }}
                    >
                      <>
                        <div
                          className="d-flex align-items-center "
                          style={{ gap: "10px" }}
                        >
                          <input
                            type="radio"
                            value={item.value}
                            {...register("viewStatus", {
                              required: "* Please select an option",
                            })}
                            id={`radio-card-${item.value}`}
                            className="radio-btn-aml cur-not"
                          />
                          <label
                            htmlFor={`radio-card-${item.value}`}
                            className="radio-card mb-0 text-light cur-not"
                          >
                            {item.name}
                          </label>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              ))}
              {errors.viewStatus && (
                <p className="text-danger ml-4">{errors.viewStatus.message}</p>
              )}
            </div>
            <div class="text-center mb-2 mt-3">
              <button class="btn btn-primary px-4" type="submit">
                Confirm
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={meetUpdate}
        toggle={toggleMeetUpdate}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleMeetUpdateClose}
          close={<CloseBtn onClick={() => toggleMeetUpdateClose()} />}
        >
          Update Meeting
        </ModalHeader>
        <ModalBody>
          <form
            onSubmit={handleSubmit1(HandleMeetUpdateSchedule)}
            autoComplete="off"
          >
            <div class="row">
              <div className="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Title<span className="text-primary">*</span>
                  </label>
                  <input
                    class="form-control"
                    name="title"
                    defaultValue={meetUpInfo?.title}
                    placeholder="Enter Title"
                    {...register1("title", {
                      required: "* Title is required",
                    })}
                  />
                  {errors1.title && (
                    <p className="text-danger">{errors1.title.message}</p>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group formInputs loginInput mb-4">
                  <label>
                    Description<span className="text-primary">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    name="description"
                    defaultValue={meetUpInfo?.description}
                    placeholder="Enter Description"
                    formControlName="message"
                    {...register1("description", {
                      required: "* Description is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                    autocomplete="off"
                  ></textarea>
                  {errors1.description && (
                    <p className="text-danger">{errors1.description.message}</p>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group formInputs loginInput mb-4">
                  <label>
                    Address<span className="text-primary">*</span>
                  </label>
                  <textarea
                    class="form-control"
                    rows="5"
                    defaultValue={meetUpInfo?.address}
                    name="address"
                    placeholder="Enter Address"
                    formControlName="message"
                    {...register1("address", {
                      required: "* Address is required",
                      pattern: {
                        value: /[/^\S]/,
                        message: "* Whitespace not allowed ",
                      },
                    })}
                    autocomplete="off"
                  ></textarea>
                  {errors1.address && (
                    <p className="text-danger">{errors1.address.message}</p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div class="form-group formInputs walletInput">
                  <label class="col-form-label">
                    Location<span className="text-primary">*</span>
                  </label>
                  <input
                    class="form-control"
                    name="location"
                    placeholder="Enter Location"
                    defaultValue={meetUpInfo?.location}
                    {...register1("location", {
                      required: "* Location is required",
                    })}
                  />
                  {errors1.location && (
                    <p className="text-danger">{errors1.location.message}</p>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div class="wallet-in-ser formInputs walletInput">
                  <label>
                    Start Time<span className="text-primary">*</span>
                  </label>
                  <div class="input-login-1  ml-lg-2">
                    <Controller
                      name="start_date"
                      control={control1}
                      rules={{ required: true }}
                      defaultValue={
                        meetUpInfo?.start_date
                          ? new Date(meetUpInfo.start_date)
                          : ""
                      }
                      render={({ field: { onChange, onBlur, value } }) => {
                        const selectedStartDate = value
                          ? new Date(value)
                          : null;

                        return (
                          <ReactDatePicker
                            className="form-control"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={selectedStartDate}
                            minDate={fromMinDate()}
                            dropdownMode="select"
                            peekNextMonth
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            placeholderText="DD/MM/YYYY HH:MM:SS"
                          />
                        );
                      }}
                    />
                    {errors1.start_date && (
                      <p>
                        {errors1.start_date.type === "required" ? (
                          <p className="text-danger">
                            * Start Time is required
                          </p>
                        ) : (
                          <p className="text-danger">
                            {errors1.start_date.message}
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div class="wallet-in-ser formInputs walletInput">
                  <label>
                    End Time<span className="text-primary">*</span>
                  </label>
                  <div class="input-login-1  ml-lg-2">
                    <Controller
                      name="end_date"
                      control={control1}
                      rules={{ required: true }}
                      defaultValue={
                        meetUpInfo?.end_date
                          ? new Date(meetUpInfo.end_date)
                          : ""
                      }
                      render={({ field: { onChange, onBlur, value } }) => {
                        const selectedStartDate = value
                          ? new Date(value)
                          : null;

                        return (
                          <ReactDatePicker
                            className="form-control"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={selectedStartDate}
                            minDate={fromMinDate()}
                            dropdownMode="select"
                            peekNextMonth
                            showTimeSelect={true}
                            dateFormat="dd/MM/yyyy HH:mm:ss"
                            placeholderText="DD/MM/YYYY HH:MM:SS"
                          />
                        );
                      }}
                    />
                    {errors1.end_date && (
                      <p>
                        {errors1.end_date.type === "required" ? (
                          <p className="text-danger">* End Time is required</p>
                        ) : (
                          <p className="text-danger">
                            {errors1.end_date.message}
                          </p>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {RadioType?.map((item, index) => {
                return (
                  <div className="col-4 mt-3" key={index}>
                    <div class="form-group input-login-1 ">
                      <div
                        className="d-flex align-items-center flex-wrap"
                        style={{ flexDirection: "column" }}
                      >
                        <>
                          <div
                            className="d-flex align-items-center "
                            style={{ gap: "10px" }}
                          >
                            <input
                              type="radio"
                              checked={+item.value == ViewRadioStatus}
                              onChange={(e) => handleRadioChange(item.value)}
                              id={`radio-card-${item.value}`}
                              className="radio-btn-aml cur-not"
                            />
                            <label
                              htmlFor={`radio-card-${item.value}`}
                              className="radio-card mb-0 text-light cur-not"
                            >
                              {item.name}
                            </label>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                );
              })}
              {errors1.viewStatus && (
                <p className="text-danger ml-4">{errors1.viewStatus.message}</p>
              )}
            </div>
            <div class="text-center mb-2 mt-3">
              <button class="btn btn-primary px-4" type="submit">
                Confirm
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={infoModalRemark}
        toggle={toggleModalRemark}
        className="modal-md modal-dialog-centered mymodal"
        size="md"
        backdrop="static"
        keyboard={false}
      >
        <ModalHeader
          toggle={toggleModalRemarkClose}
          close={<CloseBtn onClick={() => toggleModalRemarkClose()} />}
        >
          Meeting Details
        </ModalHeader>
        <ModalBody>
          <>
            <div class="info">
              <span>Title</span>
              <div className="text-success">{infoRemark?.title || "--"}</div>
            </div>
            <div class="info">
              <span>Description</span>
              <div>{infoRemark?.description || "--"}</div>
            </div>
            <div class="info">
              <span>Location </span>
              <div>{infoRemark?.location || "--"}</div>
            </div>
            <div class="info">
              <span>Meeting Start Time</span>
              <div>{infoRemark?.start_date || "--"}</div>
            </div>
            <div class="info">
              <span>Meeting End Time</span>
              <div>{infoRemark?.end_date || "--"}</div>
            </div>
            <div class="info">
              <span>Status</span>
              <div className="wwwdsafsdfdsfs">
                {infoRemark?.bop_meeting_status == 0 ? (
                  <span className="text-warning">Pending</span>
                ) : infoRemark?.bop_meeting_status == 1 ? (
                  <span className="text-success">Completed</span>
                ) : (
                  <span className="text-danger">Rejected</span>
                )}
              </div>
            </div>
            <div class="info">
              <span>Remarks</span>
              <div>{infoRemark?.remarks || "--"}</div>
            </div>
          </>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default BopMeetingHistory;
