import { configureStore } from "@reduxjs/toolkit";
import headerReducer from "../component/header/headerslice";
import modalReducer from "../pages/modalpage/modalSlice";
import botReducer from "../pages/Bot/botSlice";
import withdrawReducer from "../pages/modalpage/withdraw/slice";
import withdrawCryReducer from "../pages/modalpage/withdraw/cryptoWithdrawSlice";

export default configureStore({
  reducer: {
    header: headerReducer,
    modal: modalReducer,
    bot: botReducer,
    withdrawParamReducer: withdrawReducer,
	withdrawCryptoParamReducer:withdrawCryReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
});
