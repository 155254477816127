import React, { useEffect, useState } from 'react'
import { SpinnerLoader } from '../../component/Loaders'
import Helper from '../../Helper/Helper'
import { checkObjValues, toFixedUtil } from '../../component/utils'
import LevelSvg from './svg/levelSvg'

const LevelMembers = () => {
	const [cardLevelLoader, setCardLevelLoader] = useState(false)
	const [levelInfo, setLevelInfo] = useState({})

	useEffect(() => {
		getLevelMembersCount()
	}, [])
	const getLevelMembersCount = () => {
		setCardLevelLoader(true)
		Helper.PostData('user', 'stake/levelMembersCount', {}, false)
			.then(res => {
				if (res.status) {
					setLevelInfo(checkObjValues(res.data))
				}
			})
			.finally(() => {
				setCardLevelLoader(false)
			})
	}
	return (
		<>
			<div className='dashboard-inrgrid'>
				<div className='Bonus-Box'>
					<div className='BonusBox-Circle'>
						<LevelSvg />
					</div>
					<div className='Bonus-InrCnt'>
						<p>Level 1 Members</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{levelInfo.first_level_members}
								<span></span>
							</h5>
						)}
					</div>
					<div className='Bonus-InrCnt'>
						<p>Team 1 Business</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{toFixedUtil(levelInfo.first_level_team_business, 2)}
								<span></span>
							</h5>
						)}
					</div>
				</div>
				<div className='Bonus-Box'>
					<div className='BonusBox-Circle'>
						<LevelSvg />
					</div>
					<div className='Bonus-InrCnt'>
						<p>Level 2 Members</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{levelInfo.second_level_members}

								<span></span>
							</h5>
						)}
					</div>
					<div className='Bonus-InrCnt'>
						<p>Team 2 Business</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{toFixedUtil(levelInfo.second_level_team_business, 2)}
								<span></span>
							</h5>
						)}
					</div>
				</div>{' '}
				<div className='Bonus-Box'>
					<div className='BonusBox-Circle'>
						<LevelSvg />
					</div>
					<div className='Bonus-InrCnt'>
						<p>Level 3 Members</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{levelInfo.third_level_members}
								<span></span>
							</h5>
						)}
					</div>
					<div className='Bonus-InrCnt'>
						<p>Team 3 Business</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{toFixedUtil(levelInfo.third_level_team_business, 2)}
								<span></span>
							</h5>
						)}
					</div>
				</div>{' '}
				<div className='Bonus-Box'>
					<div className='BonusBox-Circle'>
						<LevelSvg />
					</div>
					<div className='Bonus-InrCnt'>
						<p>Level 4 Members</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{levelInfo.fourth_level_members}
								<span></span>
							</h5>
						)}
					</div>
					<div className='Bonus-InrCnt'>
						<p>Team 4 Business</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{toFixedUtil(levelInfo.fourth_level_team_business, 2)}
								<span></span>
							</h5>
						)}
					</div>
				</div>{' '}
				<div className='Bonus-Box'>
					<div className='BonusBox-Circle'>
						<LevelSvg />
					</div>
					<div className='Bonus-InrCnt'>
						<p>Level 5 Members</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{levelInfo.fifth_level_members}

								<span></span>
							</h5>
						)}
					</div>
					<div className='Bonus-InrCnt'>
						<p>Team 5 Business</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{toFixedUtil(levelInfo.fifth_level_team_business, 2)}
								<span></span>
							</h5>
						)}
					</div>
				</div>
				<div className='Bonus-Box'>
					<div className='BonusBox-Circle'>
						<LevelSvg />
					</div>
					<div className='Bonus-InrCnt'>
						<p>Level 6 Members</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{levelInfo.sixth_level_members}

								<span></span>
							</h5>
						)}
					</div>
					<div className='Bonus-InrCnt'>
						<p>Team 6 Business</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{toFixedUtil(levelInfo.sixth_level_team_business, 2)}
								<span></span>
							</h5>
						)}
					</div>
				</div>
				<div className='Bonus-Box'>
					<div className='BonusBox-Circle'>
						<LevelSvg />
					</div>
					<div className='Bonus-InrCnt'>
						<p>Level 7 Members</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{levelInfo.seventh_level_members}

								<span></span>
							</h5>
						)}
					</div>
					<div className='Bonus-InrCnt'>
						<p>Team 7 Business</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{toFixedUtil(levelInfo.seventh_level_team_business, 2)}
								<span></span>
							</h5>
						)}
					</div>
				</div>
				<div className='Bonus-Box'>
					<div className='BonusBox-Circle'>
						<LevelSvg />
					</div>
					<div className='Bonus-InrCnt'>
						<p>Level 8 Members</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{levelInfo.eigith_level_members}

								<span></span>
							</h5>
						)}
					</div>
					<div className='Bonus-InrCnt'>
						<p>Team 8 Business</p>

						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{toFixedUtil(levelInfo.eigith_level_team_business, 2)}
								<span></span>
							</h5>
						)}
					</div>
				</div>
			</div>
			<div className='dashboard-inrgrid-level4'>
				<div className='Bonus-Box'>
					<div className='BonusBox-Circle'>
						<LevelSvg />
					</div>
					<div className='Bonus-InrCnt'>
						<p>Total Team Business</p>
						{cardLevelLoader ? (
							<h5>
								<SpinnerLoader />
							</h5>
						) : (
							<h5>
								{toFixedUtil(levelInfo.total_team_business, 2)} <span></span>
							</h5>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default LevelMembers
