import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import Tree from "react-d3-tree";
import "./referralTree.scss";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignCenter,
  faMinusCircle,
  faPlusCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "reactstrap";
import Helper from "../Helper/Helper";
import { isObjectHaveKey } from "../component/utils";
import { useSearchParams } from "react-router-dom";

const myTreeData = [
  {
    name: "Gaurang",
    attributes: {
      keyA: "val A",
      keyB: "val B",
      keyC: "val C",
    },
    children: [
      {
        name: "Avadhoot",
        attributes: {
          keyA: "val A",
          keyB: "val B",
          keyC: "val C",
        },
        children: [
          {
            name: "Richard",
          },
          {
            name: "Constantine",
            children: [
              {
                name: "Mia",
              },
            ],
          },
          {
            name: "Daniel",
          },
        ],
      },
      {
        name: "Mia",
      },
      {
        name: "Varun",
        attributes: {
          keyA: "val A",
          keyB: "val B",
          keyC: "val C",
        },
        children: [
          {
            name: "Ivo",
            attributes: {
              keyA: "val A",
              keyB: "val B",
              keyC: "val C",
            },
            children: [
              {
                name: "Level 2: A",
                attributes: {
                  keyA: "val A",
                  keyB: "val B",
                  keyC: "val C",
                },
                children: [
                  {
                    name: "Level 2: A",
                    attributes: {
                      keyA: "val A",
                      keyB: "val B",
                      keyC: "val C",
                    },
                  },
                  {
                    name: "Level 2: B",
                  },
                ],
              },
              {
                name: "Level 2: B",
              },
            ],
          },
          {
            name: "Vijay",
          },
        ],
      },
      {
        name: "Mohit",
        children: [
          {
            name: "Rohit",
            attributes: {
              keyA: "val A",
              keyB: "val B",
              keyC: "val C",
            },
            children: [
              {
                name: "Level 2: A",
                attributes: {
                  keyA: "val A",
                  keyB: "val B",
                  keyC: "val C",
                },
                children: [
                  {
                    name: "Level 2: A",
                    attributes: {
                      keyA: "val A",
                      keyB: "val B",
                      keyC: "val C",
                    },
                  },
                  {
                    name: "Level 2: B",
                  },
                ],
              },
            ],
          },
          {
            name: "Pranav",
          },
        ],
      },
    ],
  },
];

const test = {
  shape: "rect",
  shapeProps: {
    width: 10,
    height: 10,
    x: -20,
    y: 20,
    stroke: "#2F80ED",
  },
};

class NodeLabel extends React.PureComponent {
  render() {
    const { className, hierarchyPointNode, nodeDatum, loadingNode } =
      this.props;
    console.log(
      "hierarchyPointNode, nodeDatum, loadingNode: ",
      hierarchyPointNode,
      nodeDatum,
      loadingNode
    );
    return (
      <>
        <div className="user_card_ios d-flex">
          <div
            class="user-card-img"
            style={{
              background:
                hierarchyPointNode.depth == 0
                  ? "#d5ab08"
                  : hierarchyPointNode.depth == 1
                  ? "#8b0505"
                  : hierarchyPointNode.depth == 0
                  ? "Blue"
                  : hierarchyPointNode.depth == 0
                  ? "black"
                  : "Purple",
            }}
          >
            <img
              src="https://inytesconnedwipdvfs.koinpark.com/ipfs/QmexjdqNSRgTo1myWJ9Gn4eUbx3uU49SSh24i33p71JyMF"
              alt=""
            />
          </div>
          <div class="user-card-info text-dark" style={{ background: "white" }}>
            <h2 title={nodeDatum.username}>{nodeDatum.username}</h2>
            <div className="d-flex">
              <p class="mt-0 text-dark">
                <span style={{ color: "red" }}>ID </span>
                <br />
                {nodeDatum.userrandomid}
              </p>
              <p class="jik_vk text-dark">
                <span style={{ color: "red" }}>DOJ</span> <br />
                {moment(nodeDatum.doj).format("YYYY/MM/DD")}
              </p>
            </div>
            <div className="d-flex">
              <p>
                <span style={{ color: "red" }}>Volume </span> <br />
                {nodeDatum.userStake_volume}
              </p>
              <p>
                <span style={{ color: "red" }} className="ml-3">
                  <FontAwesomeIcon icon={faUser} />{" "}
                </span>
                {nodeDatum.dir_ref_count}
                {loadingNode.includes(nodeDatum.user_id) && (
                  <Spinner size="sm" className="tree_node_spinner" />
                )}
              </p>
            </div>
            <div className="">
              <p className="">
                {+nodeDatum.dir_ref_count > 0 ? (
                  nodeDatum.children?.length > 0 ? (
                    <FontAwesomeIcon
                      icon={faMinusCircle}
                      size="lg"
                      color="#b04242"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      size="lg"
                      className=""
                      color="#b04242"
                    />
                  )
                ) : (
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    size="lg"
                    style={{ visibility: "hidden" }}
                    color="#b04242"
                  />
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

function IosApp() {
  const treeRef = useRef();
  const [searchParams] = useSearchParams();
  const mobType = searchParams.get("type");
  const mobToken = searchParams.get("token");
  const mobId = searchParams.get("value");
  const [Profile1, setProfile] = useState({});
  useEffect(() => {
    if (mobToken) {
      localStorage.setItem("logaccess", mobToken);
      setTimeout(() => {
        if (localStorage.getItem("logaccess")) {
          Helper.PostData("user", "user/getuserprofile", {}, false).then(
            (res) => {
              if (res.status) {
                setProfile(res.user[0]);
              }
            }
          );
        }
      }, 1000);
    }
  }, []);
  const [treeData, setTreeData] = useState({});
  const [translate, setTranslate] = useState({ x: 700, y: 100 });
  const [dimensions, setDimensions] = useState({ width: 700, height: 200 });
  const [loadingNode, setLoadingNode] = useState([]);
  const [key, setKey] = useState(1);

  const [initialZoom, setInitialZoom] = useState(
    (width = window.screen.width) => {
      // if (width < 575) {
      // 	return 1
      // }
      if (width >= 1200) {
        return 1.3;
      } else if (992 <= width && width <= 1199) {
        return 1.2;
      } else if (768 <= width && width <= 991) {
        return 1.1;
      } else if (576 <= width && width <= 767) {
        return 1;
      }
      return 1;
    }
  );
  useEffect(() => {
    if (mobToken) {
      localStorage.setItem("logaccess", mobToken);
    }
  }, [mobToken]);

  useEffect(() => {
    if (isObjectHaveKey(Profile1)) {
      let RootData = {
        user_id: mobId,
        username: Profile1.username,
        userrandomid: Profile1.userrandomid,
        userStake_volume: Profile1.MyStaking,
        profile_image: Profile1.profile_img,
        dir_ref_count: Profile1.directcount,
        doj: Profile1.created_at,
        email: Profile1.email,
        children: [],
      };
      setTreeData(RootData);
      handleToggle(RootData);
    }
  }, [Profile1]);

  useEffect(() => {
    document.body.classList.add("kpstakepage");
    handleGetBoundingClientRect();
  }, []);

  const handleGetBoundingClientRect = () => {
    const element = treeRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const centerX = (rect.left + rect.right) / 2;
      const centerY = (rect.top + rect.bottom) / 2;
      const center = (rect.width + rect.height) / 2;
      setTranslate({ x: centerX, y: 30 });
      setDimensions({ width: rect.width, height: rect.height });
      // Use the properties of 'rect' as needed (e.g., rect.top, rect.left, rect.width, rect.height, etc.)
    }
  };

  const handleToggle = async (node) => {
    // Check if the node has already been fetched
    if (node._children?.length === 0) {
      setTreeData((prevTreeData) => {
        const updatedTreeData = { ...prevTreeData };
        const nodeToUpdate = findNode(updatedTreeData, node.user_id);
        if (nodeToUpdate) {
          nodeToUpdate._children = nodeToUpdate.children;
          nodeToUpdate.children = [];
        }
        return updatedTreeData;
      });
    } else if (node.children.length === 0) {
      try {
        if (node._children?.length) {
          // setting already fetched data while reopen the nodes
          setTreeData((prevTreeData) => {
            const updatedTreeData = { ...prevTreeData };
            const nodeToUpdate = findNode(updatedTreeData, node.user_id);
            if (nodeToUpdate) {
              nodeToUpdate.children = nodeToUpdate._children;
              nodeToUpdate._children = [];
            }
            return updatedTreeData;
          });
        } else {
          setLoadingNode((prev) => [...prev, node.user_id]);
          const fetchedNodes = await fetchDataFromServer(node);
          setTreeData((prevTreeData) => {
            const updatedTreeData = { ...prevTreeData };
            const nodeToUpdate = findNode(updatedTreeData, node.user_id);
            if (nodeToUpdate) {
              nodeToUpdate.children = fetchedNodes;
              nodeToUpdate._children = [];
            }
            return updatedTreeData;
          });
          setLoadingNode((prev) =>
            [...prev].filter((item) => item != node.user_id)
          );
        }
      } catch (error) {
        console.error("Error fetching nodes:", error);
      }
    }
  };

  const fetchDataFromServer = async ({ user_id }) => {
    let fetchedData = await Helper.PostData(
      "user",
      "auth/userTree",
      {
        user_id,
      },
      false
    );
    if (fetchedData.status) {
      return fetchedData.data;
    }
  };

  // Utility function to find a node by its name in the tree data
  const findNode = (node, targetName) => {
    if (node.user_id === targetName) {
      return node;
    }
    if (node.children) {
      for (const child of node.children) {
        const foundNode = findNode(child, targetName);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return null;
  };
  return (
    <div className="leftrightCir">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div class="text-center">
              <div class="top-head-plot-book mb-0">
                Referral Tree{" "}
                <FontAwesomeIcon
                  icon={faAlignCenter}
                  className="ml-2 cur-poin"
                  color="#1ff"
                  title="Center Tree"
                  onClick={() => {
                    setTranslate((prev) => {
                      return { ...prev };
                    });
                    setKey((prev) => prev + 1);
                  }}
                />
              </div>
            </div>
            <div id="treeWrapper" style={{ width: "100%", height: "100vh" }}>
              <Tree
                data={treeData}
                nodeSvgShape={test}
                pathFunc="step"
                separation={{ siblings: 2, nonSiblings: 2 }}
                orientation="vertical"
                translate={translate}
                allowForeignObjects={true}
                renderCustomNodeElement={({
                  nodeDatum,
                  hierarchyPointNode,
                }) => {
                  console.log("nodeDatum: ", nodeDatum);
                  return (
                    <foreignObject
                      width="220"
                      height="200"
                      x="-100"
                      y="-50"
                      onClick={() =>
                        +nodeDatum.dir_ref_count > 0 && handleToggle(nodeDatum)
                      }
                    >
                      <NodeLabel
                        className="myLabelComponentInSvg"
                        nodeDatum={nodeDatum}
                        hierarchyPointNode={hierarchyPointNode}
                        loadingNode={loadingNode}
                      />
                    </foreignObject>
                  );
                }}
                // nodeLabelComponent={{

                //   render: <NodeLabel className="myLabelComponentInSvg" />,
                //   foreignObjectWrapper: {
                //     width: 220,
                //     height: 200,
                //     y: -50,
                //     x: -100,
                //   }
                // }}
                initialDepth={0.02}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IosApp;
