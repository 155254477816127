const { useEffect, useState } = require('react')

export const useYears = () => {
	const [years, setYears] = useState([])
	useEffect(() => {
		let startYear = 1920
		let endYear = new Date().getFullYear() - 18
		let years = []
		for (let year = startYear; year <= endYear; year++) {
			years.push(year)
		}
		setYears([...years].reverse())
	}, [])

	return years
}

export const useMonths = () => {
	let monthsArr = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]
	const [months, setMonths] = useState(monthsArr)
	return { months, setMonths }
}

export const useDays = () => {
	const [days, setDays] = useState([])

	useEffect(() => {
		getDays({})
	}, [])

	const checkLeapYear = choosed_year => {
		if (choosed_year) {
			if (
				(choosed_year % 4 === 0 && choosed_year % 100 !== 0) ||
				choosed_year % 400 === 0
			) {
				return true
			}
		}
		return false
	}

	const getDays = ({ choosed_month = '01', choosed_year }) => {
		let noOfDays = {
			'01': 31, // jan
			'02': checkLeapYear(choosed_year) ? 29 : 28, //feb
			'03': 31, //mar
			'04': 30, //apr
			'05': 31, //may
			'06': 30, //jub
			'07': 31, //jul
			'08': 31, //aug
			'09': 30, //sep
			10: 31, //oct
			11: 30, //nov
			12: 31 //dec
		}
		let startDay = 1
		let endDay = noOfDays[choosed_month]
		let days = []
		for (let day = startDay; day <= endDay; day++) {
			if (String(day).length > 1) {
				days.push(day)
			} else {
				days.push('0' + day)
			}
		}
		setDays(days)
	}

	return { days, getDays }
}
