const metaData = {
  login: {
    title: "Login to your KPK PARK account now!",
    description:
      "Login to your KPK PARK account and access your dashboard. Start staking to earn high rewards.",
    keywords:
      "Login, sign in, KPK PARK sign in, crypto stalking sign in, KPK PARK account sign in",
    Ogtitle: "Login to your KPK PARK account now!",
    Ogdescription:
      "Login to your KPK PARK account and access your dashboard. Start staking to earn high rewards.",
    twitterTitle: "Login to your KPK PARK account now!",
    twitterDescription:
      "Login to your KPK PARK account and access your dashboard. Start staking to earn high rewards.",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },

  register: {
    title: "Register and start staking cryptos",
    description:
      "The most trusted crypto staking platform. Register at KPK PARK and earn rewards from passive income. Enjoy staking!",
    Ogtitle: "Register and start staking cryptos",
    Ogdescription:
      "The most trusted crypto staking platform. Register at KPK PARK and earn rewards from passive income. Enjoy staking!",
    twitterTitle: "Register and start staking cryptos",
    twitterDescription:
      "The most trusted crypto staking platform. Register at KPK PARK and earn rewards from passive income. Enjoy staking!",
    keywords: "Register, sign up, KPK PARK sign up, crypto stalking sign up",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },

  home: {
    title: "KPK PARK - Cryptocurrency Staking Platform",
    description:
      "KPK PARK is a centralized finance platform. Discover the appropriate projects for staking and get connected with individuals in the crypto space.",
    Ogtitle: "KPK PARK - Cryptocurrency Staking Platform",
    Ogdescription:
      "KPK PARK is a centralized finance platform. Discover the appropriate projects for staking and get connected with individuals in the crypto space.",
    twitterTitle: "KPK PARK - Cryptocurrency Staking Platform",
    twitterDescription:
      "KPK PARK is a centralized finance platform. Discover the appropriate projects for staking and get connected with individuals in the crypto space.",
    keywords:
      "crypto staking platform, cryptocurrency stalking, KPK PARK, cryptocurrency stalking platform, cryptocurrency stalking",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },
  aboutus: {
    title:
      "KPK PARK: Stake your crypto and earn passive income through rewards",
    description:
      "The platform and community of KPK Park embody a vision to establish an immense crypto community globally. Register and start staking.",
    Ogtitle:
      "KPK PARK: Stake your crypto and earn passive income through rewards",
    Ogdescription:
      "The platform and community of KPK Park embody a vision to establish an immense crypto community globally. Register and start staking.",
    twitterTitle:
      "KPK PARK: Stake your crypto and earn passive income through rewards",
    twitterDescription:
      "The platform and community of KPK Park embody a vision to establish an immense crypto community globally. Register and start staking.",
    keywords:
      "KPK PARK, Best stalking platform, crypto stalking platform, cryptocurrency stalking platform, stake cryptos, Best crypto stalking platform",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },
  faq: {
    title: "KPK PARK - Frequently asked questions(FAQs)",
    description:
      "Find answers to frequently asked questions regarding our services. Get the details you require for a smooth and knowledgeable interaction with us",
    Ogtitle: "KPK PARK - Frequently asked questions(FAQs)",
    Ogdescription:
      "Find answers to frequently asked questions regarding our services. Get the details you require for a smooth and knowledgeable interaction with us",
    twitterTitle: "KPK PARK - Frequently asked questions(FAQs)",
    twitterDescription:
      "Find answers to frequently asked questions regarding our services. Get the details you require for a smooth and knowledgeable interaction with us",
    keywords:
      "KPK PARK FAQ, FAQ crypto stalking, KPK PARK crypto stalking service",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },
  contact: {
    title: "KPK PARK - Support",
    description:
      "Reach out to us for any assistance. We're here to help with your inquiries, provide guidance, and ensure a seamless experience.",
    Ogtitle: "KPK PARK - Support",
    Ogdescription:
      "Reach out to us for any assistance. We're here to help with your inquiries, provide guidance, and ensure a seamless experience.",
    twitterTitle: "KPK PARK - Support",
    twitterDescription:
      "Reach out to us for any assistance. We're here to help with your inquiries, provide guidance, and ensure a seamless experience.",
    keywords:
      "KPK PARK support, KPK PARK address, how to contact KPK PARK, KPK PARK support",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },
  terms: {
    title: "KPK PARK - Terms and Conditions",
    description:
      "Review our Terms and Conditions to understand your rights and obligations when using our services. Read the Clear guidelines to ensure a transparent and secure experience with us.",
    Ogtitle: "KPK PARK - Terms and Conditions",
    Ogdescription:
      "Review our Terms and Conditions to understand your rights and obligations when using our services. Read the Clear guidelines to ensure a transparent and secure experience with us.",
    twitterTitle: "KPK PARK - Terms and Conditions",
    twitterDescription:
      "Review our Terms and Conditions to understand your rights and obligations when using our services. Read the Clear guidelines to ensure a transparent and secure experience with us.",
    keywords: "KPK PARK terms and conditions, Terms and conditions",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },
  privacy: {
    title: "KPK PARK Privacy policy",
    description:
      "The KPK PARK privacy policy explains how we collect and use the data of users who visit our website.",
    twitterTitle: "KPK PARK Privacy policy",
    twitterDescription:
      "The KPK PARK privacy policy explains how we collect and use the data of users who visit our website.",
    Ogtitle: "KPK PARK Privacy policy",
    Ogdescription:
      "The KPK PARK privacy policy explains how we collect and use the data of users who visit our website.",
    keywords: "KPK PARK, KPK PARK privacy policy",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },
  support: {
    title: "Get Instant support from us",
    description:
      "Reach out to our dedicated team of experts. Your financial journey is our priority.",
    Ogtitle: "Get Instant support from us",
    Ogdescription:
      "Reach out to our dedicated team of experts. Your financial journey is our priority.",
    twitterTitle: "Get Instant support from us",
    twitterDescription:
      "Reach out to our dedicated team of experts. Your financial journey is our priority.",
    keywords:
      "KPK PARK support, KPK PARK support page, Support help KPK PARK, KPK PARK 24/7 support",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },
  exchange: {
    title: "Utilize Our Staking Exchange to Increase Your Crypto Earnings",
    description:
      "Stake your cryptos on our reputable exchange platform to take part in the future of finance. Securely sign in to enjoy convenient staking services",
    Ogtitle: "Utilize Our Staking Exchange to Increase Your Crypto Earnings",
    Ogdescription:
      "Stake your cryptos on our reputable exchange platform to take part in the future of finance. Securely sign in to enjoy convenient staking services",
    twitterTitle:
      "Utilize Our Staking Exchange to Increase Your Crypto Earnings",
    twitterDescription:
      "Stake your cryptos on our reputable exchange platform to take part in the future of finance. Securely sign in to enjoy convenient staking services",
    keywords:
      "crypto exchange, crypto stalking, KPK PARK, crypto stalking service, crypto stalking, best exchange platform",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },
  incubator: {
    title: "KPK PARK Incubator ",
    description:
      "Users can stake, transfer, or resale Metaverse incubator NFT at KPK PARK. It provides monthly income, direct-level income, and money from the sale of plots.",
    Ogtitle: "KPK PARK Incubator ",
    Ogdescription:
      "Users can stake, transfer, or resale Metaverse incubator NFT at KPK PARK. It provides monthly income, direct-level income, and money from the sale of plots.",
    twitterTitle: "KPK PARK Incubator ",
    twitterDescription:
      "Users can stake, transfer, or resale Metaverse incubator NFT at KPK PARK. It provides monthly income, direct-level income, and money from the sale of plots.",
    keywords: "",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },
  plotbook: {
    title: "Book your plot now at the world’s first political metaverse!",
    description:
      "Book your plot at KPK PARK and earn revenues through activities like Plot Resale Profit, Games, and Entertainment, Rental Tax, NFT sales, etc.",
    Ogtitle: "Book your plot now at the world’s first political metaverse!",
    Ogdescription:
      "Book your plot at KPK PARK and earn revenues through activities like Plot Resale Profit, Games, and Entertainment, Rental Tax, NFT sales, etc.",
    twitterTitle:
      "Book your plot now at the world’s first political metaverse!",
    twitterDescription:
      "Book your plot at KPK PARK and earn revenues through activities like Plot Resale Profit, Games, and Entertainment, Rental Tax, NFT sales, etc.",
    keywords: "",
    ogimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    ogSimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
    twimage:
      "https://inytesconnedwipdvfs.koinpark.com/ipfs/QmNuNdSMQwLT5JMG2ik9CUKmwosagvQpmvMuUCd4mrEaRM",
  },
  // faq: {
  // 	title: '',
  // 	description: '',
  // 	keywords: ''
  // }
};

module.exports = metaData;
