import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { useSelector } from 'react-redux'
import Helper from '../../Helper/Helper'
import { TableLoader } from '../../component/Loaders'
import CopyData2 from '../../component/Reusable/CopyData 2'
import DatePickerReuse2 from '../../component/Reusable/DatePicker2Reuse'
import TableDataStatus from '../../component/Reusable/TableDataStatus'
import NoDataComponent from '../../component/Reusable/no-data-component'
import SearchReuse2 from '../../component/Reusable/searchReuse2'
import FilterSelect from '../../component/Reusable/selectReuse'
import { dataTableConfigs } from '../../configs/dataTableConfigs'
import { createObj, toFixedUtil } from '../../component/utils'
const LevelBonusBot = () => {
	const initialPageData = { pageNo: 0, pageSize: 10 }
	const filterOptions = [
		{ name: 'All', value: 'all' },
		{ name: 'Eligible', value: 'eligible' },
		{ name: 'Not Eligible', value: 'not-eligible' }
	]
	const [botRequestData, setBotLevelHistory] = useState({
		data: [],
		count: 0
	})
	const [Loading, setLoading] = useState(false)
	const [filters, setFilter] = useState({
		search_by: '',
		eligible_status: 'all',
		start_date: '',
		end_date: '',
		level: 'all',
		Bonuspercentage: 'all',
		...initialPageData
	})

	useEffect(() => {
		getBotLevelHistory()
	}, [filters])

	const getBotLevelHistory = () => {
		setLoading(true)
		Helper.PostData(
			'user',
			'user/botRouter/bot_level_bonus_his',
			filters,
			false
		)
			.then(res => {
				if (res.status) {
					setBotLevelHistory({ data: res.data, count: res.Count })
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const columns = [
		{
			name: 'S.No',
			sortable: true,
			cell: (row, index) => <td>{index + 1}</td>
		},
		{
			name: 'Subscriber',
			sortable: true,
			cell: row => <CopyData2 data={row.subscribedusername} />
		},

		{
			name: 'Level Bonus(KPK)',
			selector: row => toFixedUtil(row.levelbonusamount, 2),
			sortable: true
		},
		{
			name: 'Level',
			selector: row => row.level,
			sortable: true
		},
		{
			name: 'Bonus %',
			selector: row => row.Bonuspercentage,
			sortable: true
		},
		{
			name: 'Trx Id',
			sortable: true,
			selector: row => row.lvl_transaction_id,
			cell: row => <CopyData2 data={row.lvl_transaction_id} />
		},
		// {
		// 	name: 'Subs Type',
		// 	selector: row => row.subs_type,
		// 	sortable: true
		// },

		{
			name: 'Date & Time',
			sortable: true,
			cell: row => moment(row.created_at).format('YYYY-MM-DD hh:mm:ss A')
		},

		{
			name: 'Eligible status',
			sortable: true,
			cell: row => {
				if (row.eligible_status == 'not-eligible') {
					return <p className='badge bg-danger'>Not Eligible</p>
				} else if (row.eligible_status == 'eligible') {
					return <p className='badge bg-success'>Eligible</p>
				}
			}
		}
	]
	return (
		<div>
			<div className='trans-body'>
				<div className='trans-TabCnt'>
					<div className='stakingbonus-tabcnt'>
						<div className='d-flex justify-content-center'>
							<div className='bonus-Head'>
								<h5>Level Bonus History</h5>
							</div>
						</div>

						<div className='bonus-Body'>
							<div className='row mb-2'>
								<div className='col-lg-5'>
									<div className='row'>
										<FilterSelect
											setFilters={setFilter}
											className='col-lg-4'
											initialPageData={initialPageData}
											options={filterOptions}
											label='Eligibility Status'
											filterKey='eligible_status'
										/>
										<FilterSelect
											setFilters={setFilter}
											className='col-lg-4'
											initialPageData={initialPageData}
											options={[
												filterOptions[0],
												...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
													createObj
												)
											]}
											label='Level'
											filterKey='level'
										/>
										<FilterSelect
											setFilters={setFilter}
											className='col-lg-4'
											initialPageData={initialPageData}
											options={[
												filterOptions[0],
												...[25, 15, 10, 8, 5, 3, 2].map(createObj)
											]}
											label='Bonus %'
											filterKey='Bonuspercentage'
										/>
									</div>
								</div>
								<DatePickerReuse2 setFilters={setFilter} className='col-lg-2' />
								<SearchReuse2
									setFilters={setFilter}
									className='col-lg-3'
									placeholder={'Subscriber/Trx Id'}
									initialPageData={initialPageData}
								/>
							</div>
							<div className='cmntbl'>
								<div className='table-responsive'>
									<DataTable
										columns={columns}
										className='text-center'
										data={botRequestData.data}
										paginationTotalRows={botRequestData.count}
										customStyles={dataTableConfigs}
										pagination
										paginationServer
										onChangePage={pageNo => {
											setFilter(prev => ({ ...prev, pageNo: pageNo - 1 }))
										}}
										progressPending={Loading}
										progressComponent={<TableLoader />}
										noDataComponent={<NoDataComponent />}
										onChangeRowsPerPage={pageSize => {
											setFilter(prev => ({ ...prev, pageSize }))
										}}
										persistTableHead={true}
										// paginationPerPage={10}
										paginationRowsPerPageOptions={[5, 10, 20, 30]}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LevelBonusBot
